// 英文
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'Message',
    IMErrorMessage: 'IM connection failed, click to try again! ',
  },
  "appWebSocketDetails": {
    sendOption: 'Send',
    send: 'send',
    fileFail: 'Please upload & format pictures',
    IMportError: 'Currently not connected, please try again! ',
    IMErrorMessage: 'IM connection failed, please try again! ',
    retryBtn: 'Retry',
  },
  "appLoginIndex": {
    navTitle: 'Login',
    projectName: 'Manage store',
    formLabel1: 'Email',
    formLabel2: 'password',
    placeholder: 'Please enter',
    hint: "If you don't have an account, ",
    clickRegister: 'Click to register',
    forgetPass: 'forgot password',
    optionBtn: 'Login',
    validateError: 'Please complete the input! ',
  },
  "appForgetPassIndex": {
    navTitle: 'Forgot password',
    formLabel1: 'Login mailbox',
    formLabel2: 'Verification code',
    formLabel3: 'New password',
    formLabel4: 'Confirm password',
    formHint1: 'Please enter your login email',
    formHint2: 'Please enter a new password',
    formHint3: 'Please enter the verification code',
    formHint4: 'Please enter your password twice',
    formOption: 'Send',
    sendOption: 'Countdown:',
    optionsBtn: 'Confirm',
  },
  'footer': {
    home: "Store",
    project: 'Product',
    info: 'Message',
    order: 'Order',
    user: 'My',
  },
  'setDrawer': {
    label1: 'Multi-language settings',
    label2: 'Store registration',
    label3: 'Store login',
    label4: 'Customer Service',
    label5: 'Download Android application',
    label6: 'Download ios application',
    optionsBtn: 'Cancel',
  },
  'appHomeIndex': {
    accountLabel1: 'Sales',
    accountLabel2: 'traffic',
    cardLabel1: 'Data overview',
    cardLabel2: 'Data report',
    cardLabel3: 'Amount overview',
    ech1Title: 'Total sales',
    ech2Title: 'Total number of orders',
    ech3Title: 'Store traffic',
    echBuoy1Title: 'Total sales',
    echBuoy2Title: 'Total number of orders',
    echBuoy3Title: 'Store traffic',
    dataCardTitle1: 'Sales',
    dataCardTitle2: 'Traffic',
    dataCardTitle3: 'Total number of orders',
    dataCardTitle4: 'Total outstanding amount',
    dataCardDayTags: 'Today',
    dataYesterTags: "Yesterday's data",
    dataYesterTags1: "Quantity of unchecked orders",
    cardMenusLabel1: 'Tax',
    cardMenusLabel2: 'Stored value',
    cardMenusLabel3: 'Withdrawal',
    cardMenusLabel4: 'Share invitation',
    cardMenusLabel5: 'Product List',
    cardMenusLabel6: 'Order list',
    cardMenusLabel7: 'Business Report',
    taxPopupTitle: 'Currently need to pay taxes:',
    popupBtn: 'Go and pay taxes',
    cardStateLabel1: 'Balance',
    cardStateLabel2: 'Withdrawal',
    cardStateLabel3: 'Income',
    cardStateLabel4: 'Team',
    cardSellHotTitle: 'Top 10 hot-selling items',
    salesLabel: 'Sales:',
  },
  'appSetLanguage': {
    navTitle: 'Multi-language settings',
    affirm: 'confirm',
  },
  "appServiceIndex": {
    navTitle: 'Customer Service',
    customerService: 'Exclusive customer service',
    helloTitle: 'Hello',
    welcomeTitle: 'I am yours<, you can contact me through the following channels',
    iconInformation: 'Please select communication software for consultation',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: 'Balance',
    blanceOption1: 'Withdraw',
    blanceOption2: 'Store value',
    incomeLabel1: 'Cumulative income',
    incomeLabel2: 'Cumulative withdrawals',
    listLabel1: 'Store information',
    listLabel2: 'Withdrawal method settings',
    listLabel3: 'Fund Record',
    listLabel4: 'Business Report',
    listLabel5: 'Discount activity',
    listLabel7: 'Loan',
    listLabel8: 'Go to the mall',
    listLabel9: 'Download application',
    listLabelLast: 'Exit',
  },
  'downloadAppIndex': {
    navTitle: 'Download',
    downloadAndroidApp: "Android Download",
    downloadIosApp: "IOS Download",
  },
  "appDiscountActivity": {
    navTitle: 'Discount activity',
    popupLabel1: 'Amount',
    popupPlace1: 'Please enter the amount',
    popupLabel2: 'Discount',
    popupPlace2: 'Please enter discount',
    popupOption: 'Confirm',
    listLabel1: 'Required amount:',
    listLabel2: 'Discount ratio:',
    listLabel3: 'Discount time:',
    dialogTitle: "Confirm whether to delete!",
    dialogAffirm: 'Confirm',
    dialogClose: "Cancel",
  },
  "appInviteIndex": {
    navTitle: 'Invite new activities',
    headerTitle: 'Invite friends to earn rewards',
    copyOption: 'copy',
    copySuccess: 'Copy successful',
    inviteTitle: 'Total number of invitations',
    inviteLabel1: 'Number of people invited:',
    inviteLabel2: 'Total commission:',
    ruleTitle: 'Invitation Rule',
    ruleHint1: ' 1. The inviter can invite friends to complete the registration by sharing the promotion link, and the invited friends will be associated with you;',
    ruleHint2: ' 2. After inviting users to complete the payment, you can get a share of their profits;',
    ruleHint3: ' 3. Only third-level invitation rewards are supported, and the reward ratio is: <%, >%, @%; ',
  },
  "appInviteUserRecordIndex": {
    navTitle: 'Invite user record',
    recordTitle1: 'Total number of users',
    recordTitle2: 'Level 1',
    recordTitle3: 'Level 2',
    recordTitle4: 'Level 3',
    tabLabel1: 'All',
    tabLabel2: 'Level 1',
    tabLabel3: 'Second level',
    tabLabel4: 'Level 3',
    listLabel1: 'Level:',
    listLabel2: 'Profit:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: 'Invitation revenue record',
    recordTitle1: 'Total revenue',
    recordTitle2: 'Level 1',
    recordTitle3: 'Level 2',
    recordTitle4: 'Level 3',
    tabLabel1: 'All',
    tabLabel2: 'Level 1',
    tabLabel3: 'Second level',
    tabLabel4: 'Level 3',
    listLabel1: 'Profit:',
    listLabel2: 'Proportion:',
  },
  "appWithdrawIndex": {
    navTitle: 'Withdrawal',
    currencyType1: 'Fiat currency',
    currencyType2: 'Virtual currency',
    formLabel1: 'Currency type:',
    formPlace1: 'Please select currency type',
    formLabel2: 'Select currency:',
    formPlace2: 'Please select currency type',
    formLabel3: 'Withdrawal amount:',
    formPlace3: 'Please enter the withdrawal amount',
    formLabel4: 'Withdrawal password:',
    formPlace4: 'Please enter the withdrawal password:',
    balanceLabel: 'Current balance:',
    amountLabel: 'Actual arrival:',
    optionsBtn: 'Submit',
    dialogClose: 'Cancel',
    dialogAffirm: 'Confirm',
    currencySelectError: 'Please select currency type',
    currencyError: 'Please select currency',
    affirmDialogDialogTitle1: 'You have not set a payment password. Do you want to set it up? ',
    affirmDialogDialogTitle2: 'You have not set the withdrawal channel, do you want to set it? ',
    affirmDialogBtn2: 'Cancel',
    affirmDialogBtn1: 'Confirm',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Withdrawal method setting',
    currencyType1: 'Fiat currency',
    currencyType2: 'Virtual currency',
    formLabel1: 'Currency type:',
    formPlace1: 'Please select currency type',
    formLabel2: 'Select currency:',
    formPlace2: 'Please select currency type',
    addressLabel: 'Address:',
    addressPlace: 'Please enter the virtual currency address',
    hintLabel: 'Important Tip',
    hintValue: 'Only transfer ¥ assets',
    optionsBtn: 'Submit',
    dialogClose: 'Cancel',
    dialogAffirm: 'Confirm',
    currencySelectError: 'Please select currency type',
    currencyError: 'Please select currency',
    currencyFail: 'Not yet open',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'real name',
    formPlace1: 'Please enter your real name',
    formLabel2: 'Email',
    formPlace2: 'Please enter your email',
    formLabel3: 'Mobile phone number',
    formPlace3: 'Please enter your mobile phone number',
    formLabel4: 'PIX type',
    formPlace4: 'Please select PIX type',
    formLabel5: 'PIX account',
    formPlace5: 'Please enter your PIX account number',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'Country/Region',
    formPlace1: 'Please enter country/region',
    formLabel2: 'name',
    formPlace2: 'Please enter a name',
    formLabel3: 'Last name',
    formPlace3: 'Please enter your last name',
    formLabel4: 'swift',
    formPlace4: 'Please enter swift',
    formLabel5: 'Bank name',
    formPlace5: 'Please enter the bank name',
    formLabel6: 'iban',
    formPlace6: 'Please enter iban',
    formLabel7: 'Mobile phone number',
    formPlace7: 'Please enter your mobile phone number',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'Country/Region',
    formPlace1: 'Please enter country/region',
    formLabel2: 'name',
    formPlace2: 'Please enter a name',
    formLabel3: 'Last name',
    formPlace3: 'Please enter your last name',
    formLabel4: 'swift',
    formPlace4: 'Please enter swift',
    formLabel5: 'ach',
    formPlace5: 'Please enter ach',
    formLabel6: 'Bank name',
    formPlace6: 'Please enter the bank name',
    formLabel7: 'Bank Account',
    formPlace7: 'Please enter bank account',
    formLabel8: 'Province or state',
    formPlace8: 'Please enter the province or state',
    formLabel9: 'Mobile phone number',
    formPlace9: 'Please enter your mobile phone number',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'Country/Region',
    formPlace1: 'Please enter country/region',
    formLabel2: 'name',
    formPlace2: 'Please enter a name',
    formLabel3: 'Last name',
    formPlace3: 'Please enter your last name',
    formLabel4: 'Bank code',
    formPlace4: 'Please enter the bank code',
    formLabel5: 'Bank name',
    formPlace5: 'Please enter the bank name',
    formLabel6: 'Bank Account',
    formPlace6: 'Please enter bank account',
    formLabel7: 'Mobile phone number',
    formPlace7: 'Please enter your mobile phone number',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'Country/Region',
    formPlace1: 'Please enter country/region',
    formLabel2: 'name',
    formPlace2: 'Please enter a name',
    formLabel3: 'Last name',
    formPlace3: 'Please enter your last name',
    formLabel4: 'zengin',
    formPlace4: 'Please enter zengin',
    formLabel5: 'Bank name',
    formPlace5: 'Please enter the bank name',
    formLabel6: 'Bank Account',
    formPlace6: 'Please enter bank account',
    formLabel7: 'Province or state',
    formPlace7: 'Please enter the province or state',
    formLabel8: 'Mobile phone number',
    formPlace8: 'Please enter your mobile phone number',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'Country/Region',
    formPlace1: 'Please enter country/region',
    formLabel2: 'name',
    formPlace2: 'Please enter a name',
    formLabel3: 'Last name',
    formPlace3: 'Please enter your last name',
    formLabel4: 'BSB',
    formPlace4: 'Please enter BSB',
    formLabel5: 'Bank name',
    formPlace5: 'Please enter the bank name',
    formLabel6: 'Bank Account',
    formPlace6: 'Please enter bank account',
    formLabel7: 'Province or state',
    formPlace7: 'Please enter the province or state',
    formLabel8: 'Mobile phone number',
    formPlace8: 'Please enter your mobile phone number',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'name',
    formPlace1: 'Please enter a name',
    formLabel2: 'Last name',
    formPlace2: 'Please enter your last name',
    formLabel3: 'Bank name',
    formPlace3: 'Please enter the bank name',
    formLabel4: 'Bank Account',
    formPlace4: 'Please enter bank account',
    formLabel5: 'Mobile phone number',
    formPlace5: 'Please enter your mobile phone number',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Bank name',
    formPlace1: 'Please enter the bank name',
    formLabel2: 'Account',
    formPlace2: 'Please enter your account',
    formLabel3: 'clabe account',
    formPlace3: 'Please enter your clabe account',
    formLabel4: 'Mobile phone number',
    formPlace4: 'Please enter your mobile phone number',
  },
  'appStatisticsReportIndex': {
    navTitle: 'Operating Report',
    cardLabel1: 'Total sales',
    cardLabel2: 'Total purchase amount',
    cardLabel3: 'Total profit',
    cardLabel4: 'Total number of orders',
    listLabel1: 'Time:',
    listLabel2: 'Order:',
    listLabel3: 'Sales:',
    listLabel4: 'Purchase amount:',
    profitLabel: 'profit',
    searchDateTitle: 'Document time',
    searchIntervalTitle: 'Interval time',
    searchStartTimePlace: 'Start time',
    searchEndTimePlace: 'End time',
    searchOptions1: 'Reset',
    searchOptions2: 'Confirm',
    searchDateAffirm: 'confirm',
    searchDateClose: 'Cancel',
    searchIntervalLabel1: 'All',
    searchIntervalLabel2: 'Today',
    searchIntervalLabel3: 'Yesterday',
    searchIntervalLabel4: 'This week',
    searchIntervalLabel5: 'This month',
    searchIntervalLabel6: 'This year',
    searchIntervalOptions: 'Cancel',
  },
  "appAmountRechargeIndex": {
    navTitle: 'Stored value',
    currencyType1: 'Fiat currency',
    currencyType2: 'Virtual currency',
    formLabel1: 'Currency type:',
    formPlace1: 'Please select currency type',
    formLabel2: 'Select currency:',
    formPlace2: 'Please select currency type',
    formLabel3: 'Stored value amount:',
    formPlace3: 'Please enter the stored value amount',
    formLabel4: 'Recharge method',
    formPlace4: 'Please select the storage method:',
    balanceLabel: 'Current balance:',
    amountLabel: 'Actual arrival:',
    optionsBtn: 'Submit',
    dialogClose: 'Cancel',
    dialogAffirm: 'Confirm',
    currencySelectError: 'Please select currency type',
    currencyError: 'Please select currency',
    successCopy: 'Copy successfully',
    hintLabel: "Important reminder:",
    hintValue: 'Only transfer of ¥ assets is allowed',
    legalHint: 'Please contact customer service!'
  },
  'appShopInfoIndex': {
    navTitle: 'Store information',
    navEditText: 'Edit',
    formLabel1: 'Store image',
    formLabel2: 'store name',
    formLabel3: 'Login email',
    formLabel4: 'invitation code',
    formLabel5: 'Login password',
    formLabel6: 'Payment password',
    formLabel7: 'Description message',
    formLabel8: 'Save background image',
    formLabel9: 'Market background image',
    formLabel10: 'Certificate information',
    formHint1: 'Document front',
    formHint2: 'The reverse side of the certificate',
    fileFail: 'Please upload pictures and format',
    optionsBtn: 'Submit',
  },
  'appShopInfoEdit': {
    navTitle: 'Store InformationEdit',
    formLabel1: 'Store image',
    formLabel2: 'store name',
    formLabel3: 'Login email',
    formLabel4: 'invitation code',
    formLabel7: 'Description message',
    formLabel8: 'Save background image',
    formLabel9: 'Market background image',
    fileFail: 'Please upload pictures and format',
    optionsBtn: 'Submit',
  },
  'appChangeLoginPass': {
    navTitle: 'Change login password',
    formLabel1: 'Old password',
    formLabel2: 'New password',
    formLabel3: 'Verification code',
    formHint1: 'Please enter the old password',
    formHint2: 'Please enter a new password',
    formHint3: 'Please enter the verification code',
    formOption: 'Send',
    sendOption: 'Countdown:',
    optionsBtn: 'Confirm',
  },
  'appChangePayPass': {
    navTitle: 'Change payment password',
    formLabel1: 'Payment password',
    formLabel2: 'Verification code',
    formHint1: 'Please enter payment password',
    formHint2: 'Please enter the verification code',
    formOption: 'Send',
    sendOption: 'Countdown:',
    optionsBtn: 'Confirm',
  },
  "appFundingRecordsIndex": {
    navTitle: 'Fund Record',
    searchRecordsTitle: 'Fund Type',
    searchDateTitle: 'Time selection',
    searchFundingLabel1: 'All funds',
    searchFundingLabel2: 'Deposit type',
    searchFundingLabel3: 'Withdrawal record',
    searchOptions1: 'Reset',
    searchOptions2: 'Confirm',
    searchDateAffirm: 'confirm',
    searchDateClose: 'Cancel',
    stareTimePlace: 'Start time',
    endTimePlace: 'End time',
    typeAll: 'All',
  },
  "appProjectIndex": {
    navTitle: 'Product List',
    searchPlace: 'Please enter the product name you want to search for',
    shelvesLabel: 'upper and lower shelves',
    brandPlace: 'Please select a brand',
    classPlace: 'Please select a category',
    searchOptions1: "Reset",
    searchOptions2: 'Confirm',
    shelvesTag1: "All",
    shelvesTag2: "on shelves",
    shelvesTag3: "off shelves",
    salesLabel: 'Sales:',
    dialogAffirm: 'Confirm',
    dialogClose: 'Cancel',
    listLabel: 'Up and down shelves:',
  },
  "appProjectLibraryIndex": {
    navTitle: "Product Library",
    searchPlace: 'Please enter the product name you want to search for',
    brandPlace: 'Please select a brand',
    classPlace: 'Please select a category',
    searchOptions1: "Reset",
    searchOptions2: 'Confirm',
    salesLabel: 'Sales:',
    dialogAffirm: 'Confirm',
    dialogClose: 'Cancel',
    productPackageLabel1: 'Product Package',
    productPackageLabel2: '(Quickly Add Product)',

    selectAll: 'Multiple Choice',
    selectHint: 'Only supports up to 50 products',
    submitOptions: 'Submit',
    dialogHint: 'Are you sure to batch list this product?',

  },
  'appProductPackageIndex': {
    navTitle: 'Product Package',
    listLabel1: 'Recommended star rating:',
    listLabel2: 'Synchronization status:',
    listLabel3: 'Product Quantity:',
    optionsBtn: 'Sync',
    dialogHint: 'Are you sure you want to use this product package?',
    dialogOptions1: 'Cancel',
    dialogOptions2: 'Confirm',
    messageToast: 'Submission successful, product information synchronization in progress...',
  },
  'appProductPackageList': {
    navTitle: 'Product Package Details',
    salesLabel: 'Sales:',
  },
  "appProductFirstIndex": {
    navTitle: 'Product Information',
    nextStep: 'Next step',
    label1: 'Product name:',
    label2: 'Product brand:',
    label3: 'Product category:',
    label4: 'Minimum purchase price:',
    label5: 'Minimum sales price:',
    label6: 'Minimum market price:',
    label7: 'Cover image:',
    label8: 'Product picture:',

  },
  'appProductNextStepIndex': {
    navTitle: 'Product Specifications',
    previous: 'previous step',
    navBtn: 'Submit',
    label1: 'Purchase price:',
    placeHint: 'Please enter',
    label2: 'Sales price:',
    label3: 'Market price:',
    specLabel: 'Specification attribute:',
    optionBtn: "Confirm",
    validateMessage1: 'The original price cannot be lower than the purchase price',
    validateMessage2: 'The original price cannot be lower than the current sales price',
    validateMessage3: 'The sales price cannot be lower than the purchase price',
    validateMessage4: 'The sales price cannot be greater than the original price',
    shelvesTitle: 'Do you want to load or unload the shelves? ',
    shelvesOptions1: 'No',
    shelvesOptions2: 'Yes',
  },
  "appOrderIndex": {
    tagsLabel1: 'All',
    orderTotalNumber: 'Total order number',
    listLabel1: 'Order date:',
    listLabel2: 'Total sales:',
    listLabel3: 'Product quantity:',
    listLabel4: 'Order status:',
    listLabel5: 'Payment status:',
    listHint: 'Purchase',
    searchOptionBtn: 'Search',
    searchPlace: 'Please enter search content',
    searchDateStartPlace: 'Start time',
    searchDateEndPlace: 'End time',
    searchDateAffirm: 'confirm',
    searchDateClose: 'Cancel',
    searchTypePayTitle: 'Payment status',
    payTags1: 'All',
    payTags2: 'Pending payment',
    payTags3: 'Paid',
    searchTypeOrderTitle: 'Order status',
    searchOptions1: 'Reset',
    searchOptions2: 'Confirm',
    purDialogTitle: 'Buy all',
    purDialogLabel1: 'Purchase amount:',
    purDialogLabel2: 'Sales amount:',
    purDialogLabel3: 'Profit amount:',
    purOptions: "Confirm",
    purRowTitle: 'Purchase',
    purRowLabel1: 'Purchase amount:',
    purRowLabel2: 'Sales amount:',
    purRowLabel3: 'Profit amount:',
  },
  "appOrderDetailIndex": {
    navTitle: 'Order details',
    detailInfoLabel1: 'Order number:',
    detailInfoLabel2: 'Order time:',
    detailInfoLabel3: 'Payment method:',
    detailInfoLabel4: 'Order status:',
    detailInfoLabel5: 'Payment status:',
    productLabel1: 'Product Information',
    productAttributesLabel: 'Product attributes:',
    productAccountLabel1: 'Sales:',
    productAccountLabel2: 'Purchase amount:',
    countInfoLabel1: 'Total sales:',
    countInfoLabel2: 'Total purchase amount:',
    countInfoLabel3: 'Total profit:',
    countInfoLabel4: 'Total quantity:',
    countInfoLabel5: 'Discount ratio:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Order Logistics',
    logisticsLabel: 'Order',
    listLabel1: "Name:",
    listLabel2: "Telephone:",
    listLabel3: "Postcode:",
    listLabel4: "Address:",
  },
  "appOrderDetailRecord": {
    navTitle: 'Order Record',
    logisticsLabel: 'Order',
    recordEnumLabel1: 'Customer creates order',
    recordEnumLabel2: 'Customer payment order',
    recordEnumLabel3: 'Store purchase order',
    recordEnumLabel4: 'Order starts shipping',
    recordEnumLabel5: 'User confirms receipt',
    recordEnumLabel6: 'System repayment arrives',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Version upgrade, please confirm? ',
    loadingTitle: 'Downloading...',
    systemUpgrade: 'System upgrade',
    upgradeImmediately: 'Confirm',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Loading", // Loading
    finishedText: "None", //None
    loadingText: "Loading", // Loading
    nullText: 'No data yet',
    loadingFinished: "Loading completed",
    listNullText: 'No more',
    pullIngText: 'Pull down to refresh',
    loosingText: 'Release to refresh',
    pullingSuccess: 'Pull-down loading successful',
    webSocketSuccess: 'You have a new message',
    imLinkSuccess: 'IM service connection successful! ',
    imNonsupport: 'This browser does not support websocket',
    imLinkLoading: 'IM is linking, please wait...',
    imLinkErrorLoading: 'IM connection failed, please refresh the page or contact the developer! ',
    dialogHint: 'Do you already have an account?',
    dialogClose: 'Register',
    dialogAffirm: 'Login',
  },
};
export default obj;
