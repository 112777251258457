
// 默认环境
const environment = {
  web: 'web',
  android: 'android',
  ios: 'ios',
  tiktok: 'tiktok'
};

// 二级域名 对照 环境
const domainMapEnvironment = {
  seller: environment.web,
  sellerios: environment.ios,
  sellertiktok: environment.tiktok
};

// 环境 对照 商城二级域名
let environmentMapMallDomain = {};
environmentMapMallDomain[environment.web] = '';
environmentMapMallDomain[environment.android] = '';
environmentMapMallDomain[environment.ios] = 'ios.';
environmentMapMallDomain[environment.tiktok] = 'tiktok.';

export { environment, domainMapEnvironment, environmentMapMallDomain }