import service from '../required/axios'
export default {
  // 法定货币列表获取
  getCurrencyFiatList (data) {
    return service({
      url: '/finance.get_currency_fiat_list',
      method: "get",
      params: data
    })
  },
  // 虚拟货币列表获取
  getCurrencyVirtualList (data) {
    return service({
      url: '/finance.get_currency_virtual_list',
      method: "get",
      params: data
    })
  },
  // 汇率获取
  getExchangeRate (data) {
    return service({
      url: '/finance.get_exchange_rate',
      method: "post",
      data: data
    })
  },
  // 充值通道获取
  getChannelList (data) {
    return service({
      url: '/finance.get_recharge_channel',
      method: "post",
      data: data
    })
  },
  // 充值通道获取
  setRechargePort (data) {
    return service({
      url: '/finance.recharge',
      method: "post",
      data: data
    })
  },
}