import service from '@/required/axios'
export default {
  // 邀请数据获取
  getInviteSumData (data) {
    return service({
      url: 'share.commission_sum',
      method: "post",
      data: data,
    })
  },
  getUserLists (data) {
    return service({
      url: 'share.lists',
      method: "get",
      params: data
    })
  },
  
  getIncomeLists (data) {
    return service({
      url: 'share.commission_list',
      method: "get",
      params: data
    })
  },
}