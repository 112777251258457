import service from '@/required/axios'
export default {
  // 上传图片
  importUpload (data) {
    return service({
      url: 'common.upload',
      method: "POST",
      data: data
    })
  },
  // 设置基本信息
  setStore_info (data) {
    return service({
      url: 'shop.change',
      method: "POST",
      data: data
    })
  },
  // 获取基本信息
  getShopInfoPort (data) {
    return service({
      url: 'shop.info',
      method: "post",
      data: data
    })
  },
  // 修改登录密码
  setChangeLoginPass (data) {
    return service({
      url: 'shop.change_password',
      method: "post",
      params: data
    })
  },
  // 修改支付密码
  setChangePayPass (data) {
    return service({
      url: 'shop.change_pay_password',
      method: "post",
      params: data
    })
  },
  //发送邮箱验证码
  sendEmailCode (data) {
    return service({
      url: 'common.send_email_verify_code',
      method: "post",
      params: data
    })
  },
}