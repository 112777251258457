import service from '../required/axios'
export default {
  // 首页所有数据
  getHome_interface (data) {
    return service({
      url: 'home.report',
      method: "post",
      data: data
    })
  },
  // 首页近30日销售数据
  getHomeLine_interface (data) {
    return service({
      url: 'home.line_chart',
      method: "post",
      data: data
    })
  },
  // SKU销售排行列表
  skuLists_interface (data) {
    return service({
      url: 'salesStatistics.sku_ranking_lists',
      method: "get",
      params: data
    })
  },
  // 获取基本信息
  getShopInfo_interface (data) {
    return service({
      url: 'shop.info',
      method: "post",
      data:data
    })
  },
}