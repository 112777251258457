// 西班牙语
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'Mensaje',
    IMErrorMessage: 'Error en la conexión de mensajería instantánea, haga clic para intentarlo de nuevo. ',

  },
  "appWebSocketDetails": {
    sendOption: 'Enviar',
    send: 'enviar',
    fileFail: 'Por favor cargue y formatee imágenes',
    IMportError: 'Actualmente no conectado, ¡inténtelo de nuevo! ',
    IMErrorMessage: 'Error en la conexión de mensajería instantánea, ¡inténtelo de nuevo! ',
    retryBtn: 'Reintentar',
  },
  "appLoginIndex": {
    navTitle: 'Iniciar sesión',
    projectName: 'Administrar tienda',
    formLabel1: 'Correo electrónico',
    formLabel2: 'contraseña',
    placeholder: 'Por favor ingrese',
    hint: "Si no tienes una cuenta",
    clickRegister: 'Haga clic para registrarse',
    forgetPass: 'olvidé mi contraseña',
    optionBtn: 'Iniciar sesión',
    validateError: '¡Por favor complete la entrada! ',
  },
  "appForgetPassIndex": {
    navTitle: 'Olvidé mi contraseña',
    formLabel1: 'Buzón de inicio de sesión',
    formLabel2: 'Código de verificación',
    formLabel3: 'Nueva contraseña',
    formLabel4: 'Confirmar contraseña',
    formHint1: 'Ingrese su correo electrónico de inicio de sesión',
    formHint2: 'Ingrese una nueva contraseña',
    formHint3: 'Ingrese el código de verificación',
    formHint4: 'Ingrese su contraseña dos veces',
    formOption: 'Enviar',
    sendOption: 'Cuenta atrás:',
    optionsBtn: 'Confirmar',
  },
  'footer': {
    home: "Almacenar",
    project: 'Producto',
    info: 'Mensaje',
    order: 'orden',
    user: 'Mi',
  },
  'setDrawer': {
    label1: 'Configuración multilingüe',
    label2: 'Registro de tienda',
    label3: 'Inicio de sesión en tienda',
    label4: 'Servicio al cliente',
    label5: 'Descargar aplicación para Android',
    label6: 'Descargar aplicación ios',
    optionsBtn: 'Cancelar',
  },
  'appHomeIndex': {
    accountLabel1: 'Ventas',
    accountLabel2: 'tráfico',
    cardLabel1: 'Resumen de datos',
    cardLabel2: 'Reporte de datos',
    cardLabel3: 'Resumen de montos',
    ech1Title: 'Ventas totales',
    ech2Title: 'Número total de pedidos',
    ech3Title: 'Tráfico de la tienda',
    echBuoy1Title: 'Ventas totales',
    echBuoy2Title: 'Número total de pedidos',
    echBuoy3Title: 'Tráfico de la tienda',
    dataCardTitle1: 'Ventas',
    dataCardTitle2: 'Tráfico',
    dataCardTitle3: 'Número total de pedidos',
    dataCardTitle4: 'Importe total pendiente',
    dataCardDayTags: 'Hoy',
    dataYesterTags: "Datos de ayer",
    dataYesterTags1: "Cantidad de pedidos no verificados",
    cardMenusLabel1: 'Impuesto',
    cardMenusLabel2: 'Valor almacenado',
    cardMenusLabel3: 'Retiro',
    cardMenusLabel4: 'Compartir invitación',
    cardMenusLabel5: 'Lista de productos',
    cardMenusLabel6: 'Lista de pedidos',
    cardMenusLabel7: 'Informe comercial',
    taxPopupTitle: 'Actualmente necesito pagar impuestos:',
    popupBtn: 'Ve y paga impuestos',
    cardStateLabel1: 'Saldo',
    cardStateLabel2: 'Retiro',
    cardStateLabel3: 'Ingresos',
    cardStateLabel4: 'Equipo',
    cardSellHotTitle: 'Los 10 artículos más vendidos',
    salesLabel: 'Ventas:',
  },
  'appSetLanguage': {
    navTitle: 'Configuración multilingüe',
    affirm: 'confirmar',
  },
  "appServiceIndex": {
    navTitle: 'Servicio al cliente',
    customerService: 'Servicio de atención al cliente exclusivo',
    helloTitle: 'Hola',
    welcomeTitle: 'Soy tuyo<, puedes contactarme a través de los siguientes canales',
    iconInformation: 'Seleccione el software de comunicación para consulta',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: 'Saldo',
    blanceOption1: 'Retirar',
    blanceOption2: 'Almacenar valor',
    incomeLabel1: 'Ingresos acumulados',
    incomeLabel2: 'Retiros acumulados',
    listLabel1: 'Almacenar información',
    listLabel2: 'Configuración del método de retiro',
    listLabel3: 'Registro de fondos',
    listLabel4: 'Informe comercial',
    listLabel5: 'Actividad de descuento',
    listLabel7: 'Préstamo',
    listLabel8: 'Ir al centro comercial',
    listLabel9: 'Descargar aplicación',
    listLabelLast: 'Salir',
  },
  'downloadAppIndex': {
    navTitle: 'Descargar',
    downloadAndroidApp: "Descarga de Android",
    downloadIosApp: "Descarga de IOS",
  },
  "appDiscountActivity": {
    navTitle: 'Actividad de descuento',
    popupLabel1: 'Cantidad',
    popupPlace1: 'Ingrese el monto',
    popupLabel2: 'Descuento',
    popupPlace2: 'Ingrese el descuento',
    popupOption: 'Confirmar',
    listLabel1: 'Cantidad requerida:',
    listLabel2: 'Relación de descuento:',
    listLabel3: 'Tiempo de descuento:',
    dialogTitle: "¡Confirma si deseas eliminar!",
    dialogAffirm: 'Confirmar',
    dialogClose: "Cancelar",
  },
  "appInviteIndex": {
    navTitle: 'Invitar a nuevas actividades',
    headerTitle: 'Invita a amigos a ganar recompensas',
    copyOption: 'copiar',
    copySuccess: 'Copia exitosa',
    inviteTitle: 'Número total de invitaciones',
    inviteLabel1: 'Número de personas invitadas:',
    inviteLabel2: 'Comisión total:',
    ruleTitle: 'Regla de invitación',
    ruleHint1: ' 1. Quien invita puede invitar a amigos a completar el registro compartiendo el enlace de promoción, y los amigos invitados se asociarán con usted;',
    ruleHint2: ' 2. Después de invitar a los usuarios a completar el pago, puedes obtener una parte de sus ganancias;',
    ruleHint3: ' 3. Solo se admiten recompensas de invitación de tercer nivel y la proporción de recompensa es: <%, >%, @%; ',

  },
  "appInviteUserRecordIndex": {
    navTitle: 'Invitar registro de usuario',
    recordTitle1: 'Número total de usuarios',
    recordTitle2: 'Nivel 1',
    recordTitle3: 'Nivel 2',
    recordTitle4: 'Nivel 3',
    tabLabel1: 'Todos',
    tabLabel2: 'Nivel 1',
    tabLabel3: 'Segundo nivel',
    tabLabel4: 'Nivel 3',
    listLabel1: 'Nivel:',
    listLabel2: 'Beneficio:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: 'Registro de ingresos por invitaciones',
    recordTitle1: 'Ingresos totales',
    recordTitle2: 'Nivel 1',
    recordTitle3: 'Nivel 2',
    recordTitle4: 'Nivel 3',
    tabLabel1: 'Todos',
    tabLabel2: 'Nivel 1',
    tabLabel3: 'Segundo nivel',
    tabLabel4: 'Nivel 3',
    listLabel1: 'Beneficio:',
    listLabel2: 'Proporción:',
  },
  "appWithdrawIndex": {
    navTitle: 'Retiro',
    currencyType1: 'moneda fiduciaria',
    currencyType2: 'moneda virtual',
    formLabel1: 'Tipo de moneda:',
    formPlace1: 'Seleccione el tipo de moneda',
    formLabel2: 'Seleccionar moneda:',
    formPlace2: 'Seleccione el tipo de moneda',
    formLabel3: 'Cantidad de retiro:',
    formPlace3: 'Ingrese el monto del retiro',
    formLabel4: 'Contraseña de retiro:',
    formPlace4: 'Por favor ingrese la contraseña de retiro:',
    balanceLabel: 'Saldo actual:',
    amountLabel: 'Llegada real:',
    optionsBtn: 'Enviar',
    dialogClose: 'Cancelar',
    dialogAffirm: 'Confirmar',
    currencySelectError: 'Seleccione el tipo de moneda',
    currencyError: 'Seleccione moneda',
    affirmDialogDialogTitle1: 'No has establecido una contraseña de pago. ¿Quieres configurarlo? ',
    affirmDialogDialogTitle2: 'No has configurado el canal de retiro, ¿quieres configurarlo? ',
    affirmDialogBtn2: 'Cancelar',
    affirmDialogBtn1: 'Confirmar',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Configuración del método de retiro',
    currencyType1: 'moneda fiduciaria',
    currencyType2: 'moneda virtual',
    formLabel1: 'Tipo de moneda:',
    formPlace1: 'Seleccione el tipo de moneda',
    formLabel2: 'Seleccionar moneda:',
    formPlace2: 'Seleccione el tipo de moneda',
    addressLabel: 'Dirección:',
    addressPlace: 'Ingrese la dirección de la moneda virtual',
    hintLabel: 'Consejo importante',
    hintValue: 'Solo transferir activos ¥',
    optionsBtn: 'Enviar',
    dialogClose: 'Cancelar',
    dialogAffirm: 'Confirmar',
    currencySelectError: 'Seleccione el tipo de moneda',
    currencyError: 'Seleccione moneda',
    currencyFail: 'Aún no está abierto',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'nombre real',
    formPlace1: 'Por favor ingresa tu nombre real',
    formLabel2: 'Correo electrónico',
    formPlace2: 'Por favor ingrese su correo electrónico',
    formLabel3: 'Número de teléfono móvil',
    formPlace3: 'Ingrese su número de teléfono móvil',
    formLabel4: 'tipo de PIX',
    formPlace4: 'Seleccione el tipo de PIX',
    formLabel5: 'cuenta PIX',
    formPlace5: 'Ingrese su número de cuenta PIX',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'País/Región',
    formPlace1: 'Ingrese el país/región',
    formLabel2: 'nombre',
    formPlace2: 'Por favor ingrese un nombre',
    formLabel3: 'Apellido',
    formPlace3: 'Por favor ingresa tu apellido',
    formLabel4: 'rápido',
    formPlace4: 'Por favor ingrese swift',
    formLabel5: 'Nombre del banco',
    formPlace5: 'Por favor ingrese el nombre del banco',
    formLabel6: 'iban',
    formPlace6: 'Por favor ingrese iban',
    formLabel7: 'Número de teléfono móvil',
    formPlace7: 'Por favor ingrese su número de teléfono móvil',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'País/Región',
    formPlace1: 'Ingrese el país/región',
    formLabel2: 'nombre',
    formPlace2: 'Por favor ingrese un nombre',
    formLabel3: 'Apellido',
    formPlace3: 'Por favor ingresa tu apellido',
    formLabel4: 'rápido',
    formPlace4: 'Por favor ingrese swift',
    formLabel5: 'ach',
    formPlace5: 'Por favor ingrese ach',
    formLabel6: 'Nombre del banco',
    formPlace6: 'Por favor ingrese el nombre del banco',
    formLabel7: 'Cuenta bancaria',
    formPlace7: 'Por favor ingrese la cuenta bancaria',
    formLabel8: 'Provincia o estado',
    formPlace8: 'Por favor ingrese la provincia o estado',
    formLabel9: 'Número de teléfono móvil',
    formPlace9: 'Ingrese su número de teléfono móvil',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'País/Región',
    formPlace1: 'Ingrese el país/región',
    formLabel2: 'nombre',
    formPlace2: 'Por favor ingrese un nombre',
    formLabel3: 'Apellido',
    formPlace3: 'Por favor ingresa tu apellido',
    formLabel4: 'Código bancario',
    formPlace4: 'Por favor ingrese el código bancario',
    formLabel5: 'Nombre del banco',
    formPlace5: 'Por favor ingrese el nombre del banco',
    formLabel6: 'Cuenta bancaria',
    formPlace6: 'Por favor ingrese la cuenta bancaria',
    formLabel7: 'Número de teléfono móvil',
    formPlace7: 'Por favor ingrese su número de teléfono móvil',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'País/Región',
    formPlace1: 'Ingrese el país/región',
    formLabel2: 'nombre',
    formPlace2: 'Por favor ingrese un nombre',
    formLabel3: 'Apellido',
    formPlace3: 'Por favor ingresa tu apellido',
    formLabel4: 'zengin',
    formPlace4: 'Por favor ingrese zengin',
    formLabel5: 'Nombre del banco',
    formPlace5: 'Por favor ingrese el nombre del banco',
    formLabel6: 'Cuenta bancaria',
    formPlace6: 'Por favor ingrese la cuenta bancaria',
    formLabel7: 'Provincia o estado',
    formPlace7: 'Por favor ingrese la provincia o estado',
    formLabel8: 'Número de teléfono móvil',
    formPlace8: 'Ingrese su número de teléfono móvil',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'País/Región',
    formPlace1: 'Ingrese el país/región',
    formLabel2: 'nombre',
    formPlace2: 'Por favor ingrese un nombre',
    formLabel3: 'Apellido',
    formPlace3: 'Por favor ingresa tu apellido',
    formLabel4: 'BSB',
    formPlace4: 'Por favor ingrese BSB',
    formLabel5: 'Nombre del banco',
    formPlace5: 'Por favor ingrese el nombre del banco',
    formLabel6: 'Cuenta bancaria',
    formPlace6: 'Por favor ingrese la cuenta bancaria',
    formLabel7: 'Provincia o estado',
    formPlace7: 'Por favor ingrese la provincia o estado',
    formLabel8: 'Número de teléfono móvil',
    formPlace8: 'Ingrese su número de teléfono móvil',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'nombre',
    formPlace1: 'Por favor ingrese un nombre',
    formLabel2: 'Apellido',
    formPlace2: 'Por favor ingrese su apellido',
    formLabel3: 'Nombre del banco',
    formPlace3: 'Por favor ingrese el nombre del banco',
    formLabel4: 'Cuenta bancaria',
    formPlace4: 'Ingrese la cuenta bancaria',
    formLabel5: 'Número de teléfono móvil',
    formPlace5: 'Ingrese su número de teléfono móvil',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Nombre del banco',
    formPlace1: 'Por favor ingrese el nombre del banco',
    formLabel2: 'Cuenta',
    formPlace2: 'Por favor ingrese su cuenta',
    formLabel3: 'cuenta clabe',
    formPlace3: 'Por favor ingresa tu cuenta clabe',
    formLabel4: 'Número de teléfono móvil',
    formPlace4: 'Ingrese su número de teléfono móvil',
  },
  'appStatisticsReportIndex': {
    navTitle: 'Informe operativo',
    cardLabel1: 'Ventas totales',
    cardLabel2: 'Monto total de la compra',
    cardLabel3: 'Beneficio total',
    cardLabel4: 'Número total de pedidos',
    listLabel1: 'Hora:',
    listLabel2: 'Orden:',
    listLabel3: 'Ventas:',
    listLabel4: 'Monto de la compra:',
    profitLabel: 'beneficio',
    searchDateTitle: 'Hora del documento',
    searchIntervalTitle: 'Tiempo de intervalo',
    searchStartTimePlace: 'Hora de inicio',
    searchEndTimePlace: 'Hora de finalización',
    searchOptions1: 'Restablecer',
    searchOptions2: 'Confirmar',
    searchDateAffirm: 'confirmar',
    searchDateClose: 'Cancelar',
    searchIntervalLabel1: 'Todos',
    searchIntervalLabel2: 'Hoy',
    searchIntervalLabel3: 'Ayer',
    searchIntervalLabel4: 'Esta semana',
    searchIntervalLabel5: 'Este mes',
    searchIntervalLabel6: 'Este año',
    searchIntervalOptions: 'Cancelar',
  },
  "appAmountRechargeIndex": {
    navTitle: 'Valor almacenado',
    currencyType1: 'moneda fiduciaria',
    currencyType2: 'moneda virtual',
    formLabel1: 'Tipo de moneda:',
    formPlace1: 'Seleccione el tipo de moneda',
    formLabel2: 'Seleccionar moneda:',
    formPlace2: 'Seleccione el tipo de moneda',
    formLabel3: 'Cantidad del valor almacenado:',
    formPlace3: 'Por favor ingrese la cantidad del valor almacenado',
    formLabel4: 'Método de recarga',
    formPlace4: 'Por favor seleccione el método de almacenamiento:',
    balanceLabel: 'Saldo actual:',
    amountLabel: 'Llegada real:',
    optionsBtn: 'Enviar',
    dialogClose: 'Cancelar',
    dialogAffirm: 'Confirmar',
    currencySelectError: 'Seleccione el tipo de moneda',
    currencyError: 'Seleccione moneda',
    successCopy: 'Copiar exitosamente',
    hintLabel: "Recordatorio importante:",
    hintValue: 'Sólo se permite la transferencia de activos en ¥',
    legalHint: '¡Póngase en contacto con el servicio al cliente!'
  },
  'appShopInfoIndex': {
    navTitle: 'Almacenar información',
    navEditText: 'Editar',
    formLabel1: 'Almacenar imagen',
    formLabel2: 'nombre de la tienda',
    formLabel3: 'Correo electrónico de inicio de sesión',
    formLabel4: 'código de invitación',
    formLabel5: 'Contraseña de inicio de sesión',
    formLabel6: 'Contraseña de pago',
    formLabel7: 'Mensaje de descripción',
    formLabel8: 'Guardar imagen de fondo',
    formLabel9: 'Imagen de fondo del mercado',
    formLabel10: 'Información del certificado',
    formHint1: 'Anverso del documento',
    formHint2: 'El reverso del certificado',
    fileFail: 'Por favor cargue imágenes y formatee',
    optionsBtn: 'Enviar',
  },
  'appShopInfoEdit': {
    navTitle: 'Editor de información de la tienda',
    formLabel1: 'Almacenar imagen',
    formLabel2: 'nombre de la tienda',
    formLabel3: 'Correo electrónico de inicio de sesión',
    formLabel4: 'código de invitación',
    formLabel7: 'Mensaje de descripción',
    formLabel8: 'Guardar imagen de fondo',
    formLabel9: 'Imagen de fondo del mercado',
    fileFail: 'Por favor cargue imágenes y formatee',
    optionsBtn: 'Enviar',
  },
  'appChangeLoginPass': {
    navTitle: 'Cambiar contraseña de inicio de sesión',
    formLabel1: 'Contraseña antigua',
    formLabel2: 'Nueva contraseña',
    formLabel3: 'Código de verificación',
    formHint1: 'Ingrese la contraseña anterior',
    formHint2: 'Ingrese una nueva contraseña',
    formHint3: 'Ingrese el código de verificación',
    formOption: 'Enviar',
    sendOption: 'Cuenta atrás:',
    optionsBtn: 'Confirmar',
  },
  'appChangePayPass': {
    navTitle: 'Cambiar contraseña de pago',
    formLabel1: 'Contraseña de pago',
    formLabel2: 'Código de verificación',
    formHint1: 'Ingrese la contraseña de pago',
    formHint2: 'Ingrese el código de verificación',
    formOption: 'Enviar',
    sendOption: 'Cuenta atrás:',
    optionsBtn: 'Confirmar',
  },
  "appFundingRecordsIndex": {
    navTitle: 'Registro de fondos',
    searchRecordsTitle: 'Tipo de fondo',
    searchDateTitle: 'Selección de hora',
    searchFundingLabel1: 'Todos los fondos',
    searchFundingLabel2: 'Tipo de depósito',
    searchFundingLabel3: 'Registro de retiro',
    searchOptions1: 'Restablecer',
    searchOptions2: 'Confirmar',
    searchDateAffirm: 'confirmar',
    searchDateClose: 'Cancelar',
    stareTimePlace: 'Hora de inicio',
    endTimePlace: 'Hora de finalización',
    typeAll: 'Todos',
  },
  "appProjectIndex": {
    navTitle: 'Lista de productos',
    searchPlace: 'Ingrese el nombre del producto que desea buscar',
    shelvesLabel: 'estantes superiores e inferiores',
    brandPlace: 'Por favor seleccione una marca',
    classPlace: 'Por favor seleccione una categoría',
    searchOptions1: "Restablecer",
    searchOptions2: 'Confirmar',
    shelvesTag1: "Todos",
    shelvesTag2: "en los estantes",
    shelvesTag3: "fuera de los estantes",
    salesLabel: 'Ventas:',
    dialogAffirm: 'Confirmar',
    dialogClose: 'Cancelar',
    listLabel: 'Estantes arriba y abajo:',
  },
  "appProjectLibraryIndex": {
    navTitle: "Biblioteca de productos",
    searchPlace: 'Ingrese el nombre del producto que desea buscar',
    brandPlace: 'Por favor seleccione una marca',
    classPlace: 'Por favor seleccione una categoría',
    searchOptions1: "Restablecer",
    searchOptions2: 'Confirmar',
    salesLabel: 'Ventas:',
    dialogAffirm: 'Confirmar',
    dialogClose: 'Cancelar',
    productPackageLabel1: 'paquete de productos',
    productPackageLabel2: '(añadir producto rápidamente)',

    selectAll: 'elige más',
    selectHint: 'solo admite hasta 50 productos',
    submitOptions: 'submit',
    dialogHint: 'está seguro de que el producto está en los estantes por lotes?',

  },
  'appProductPackageIndex': {
    navTitle: 'paquete de productos',
    listLabel1: 'estrellas recomendadas:',
    listLabel2: 'estado de sincronización:',
    listLabel3: 'número de productos:',
    optionsBtn: 'sincronizar',
    dialogHint: '¿ está confirmado el uso de este paquete de productos?',
    dialogOptions1: 'cancelar',
    dialogOptions2: 'confirmar',
    messageToast: 'enviar con éxito, los datos del producto están sincronizados...',
  },
  'appProductPackageList': {
    navTitle: 'detalles del paquete de productos',
    salesLabel: 'ventas:',
  },
  "appProductFirstIndex": {
    navTitle: 'Información del producto',
    nextStep: 'Siguiente paso',
    label1: 'Nombre del producto:',
    label2: 'Marca del producto:',
    label3: 'Categoría de producto:',
    label4: 'Precio mínimo de compra:',
    label5: 'Precio mínimo de venta:',
    label6: 'Precio mínimo de mercado:',
    label7: 'Imagen de portada:',
    label8: 'Imagen del producto:',

  },
  'appProductNextStepIndex': {
    navTitle: 'Especificaciones del producto',
    previous: 'paso anterior',
    navBtn: 'Enviar',
    label1: 'Precio de compra:',
    placeHint: 'Por favor ingrese',
    label2: 'Precio de venta:',
    label3: 'Precio de mercado:',
    specLabel: 'Atributo de especificación:',
    optionBtn: "Confirmar",
    validateMessage1: 'El precio original no puede ser inferior al precio de compra',
    validateMessage2: 'El precio original no puede ser inferior al precio de venta actual',
    validateMessage3: 'El precio de venta no puede ser inferior al precio de compra',
    validateMessage4: 'El precio de venta no puede ser mayor que el precio original',
    shelvesTitle: '¿Quieres cargar o descargar los estantes? ',
    shelvesOptions1: 'No',
    shelvesOptions2: 'Sí',
  },
  "appOrderIndex": {
    tagsLabel1: 'Todos',
    orderTotalNumber: 'Número total de pedido',
    listLabel1: 'Fecha del pedido:',
    listLabel2: 'Ventas totales:',
    listLabel3: 'Cantidad de producto:',
    listLabel4: 'Estado del pedido:',
    listLabel5: 'Estado de pago:',
    listHint: 'Comprar',
    searchOptionBtn: 'Buscar',
    searchPlace: 'Ingrese el contenido de la búsqueda',
    searchDateStartPlace: 'Hora de inicio',
    searchDateEndPlace: 'Hora de finalización',
    searchDateAffirm: 'confirmar',
    searchDateClose: 'Cancelar',
    searchTypePayTitle: 'Estado de pago',
    payTags1: 'Todos',
    payTags2: 'Pago pendiente',
    payTags3: 'Pagado',
    searchTypeOrderTitle: 'Estado del pedido',
    searchOptions1: 'Restablecer',
    searchOptions2: 'Confirmar',
    purDialogTitle: 'Comprar todo',
    purDialogLabel1: 'Monto de la compra:',
    purDialogLabel2: 'Cantidad de ventas:',
    purDialogLabel3: 'Cantidad de beneficio:',
    purOptions: "Confirmar",
    purRowTitle: 'Compra',
    purRowLabel1: 'Monto de la compra:',
    purRowLabel2: 'Cantidad de ventas:',
    purRowLabel3: 'Cantidad de beneficio:',
  },
  "appOrderDetailIndex": {
    navTitle: 'Detalles del pedido',
    detailInfoLabel1: 'Número de pedido:',
    detailInfoLabel2: 'Hora del pedido:',
    detailInfoLabel3: 'Método de pago:',
    detailInfoLabel4: 'Estado del pedido:',
    detailInfoLabel5: 'Estado de pago:',
    productLabel1: 'Información del producto',
    productAttributesLabel: 'Atributos del producto:',
    productAccountLabel1: 'Ventas:',
    productAccountLabel2: 'Monto de la compra:',
    countInfoLabel1: 'Ventas totales:',
    countInfoLabel2: 'Monto total de la compra:',
    countInfoLabel3: 'Beneficio total:',
    countInfoLabel4: 'Cantidad total:',
    countInfoLabel5: 'Relación de descuento:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Logística de pedidos',
    logisticsLabel: 'Pedido',
    listLabel1: "Nombre:",
    listLabel2: "Teléfono:",
    listLabel3: "Código postal:",
    listLabel4: "Dirección:",
  },
  "appOrderDetailRecord": {
    navTitle: 'Registro de pedido',
    logisticsLabel: 'Pedido',
    recordEnumLabel1: 'El cliente crea el pedido',
    recordEnumLabel2: 'Orden de pago del cliente',
    recordEnumLabel3: 'Almacenar orden de compra',
    recordEnumLabel4: 'El pedido comienza a enviarse',
    recordEnumLabel5: 'El usuario confirma la recepción',
    recordEnumLabel6: 'Llega el pago del sistema',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Actualización de versión, ¿confirmar? ',
    loadingTitle: 'Descargando...',
    systemUpgrade: 'Actualización del sistema',
    upgradeImmediately: 'Confirmar',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Cargando",
    finishedText: "Ninguno",
    loadingText: "Cargando",
    nullText: 'Aún no hay datos',
    loadingFinished: "Carga completa",
    listNullText: 'No más',
    pullIngText: 'Desplegar hacia abajo para actualizar',
    loosingText: 'Liberar para actualizar',
    pullingSuccess: 'Carga desplegable exitosa',
    webSocketSuccess: 'Tienes un mensaje nuevo',
    imLinkSuccess: '¡La conexión del servicio de mensajería instantánea fue exitosa! ',
    imNonsupport: 'Este navegador no soporta websocket',
    imLinkLoading: 'IM se está vinculando, por favor espere...',
    imLinkErrorLoading: 'Error en la conexión de mensajería instantánea, actualice la página o comuníquese con el desarrollador. ',
    dialogHint: 'Você já tem uma conta?',
    dialogClose: 'Registar',
    dialogAffirm: "Login",
  },
};
export default obj;
