import english from './languageModule/english'
import japanese from './languageModule/japanese'
import chineseTraditional from './languageModule/chineseTraditional'
import portuguese from './languageModule/portuguese'
import german from './languageModule/german'
import french from './languageModule/french'
import turkish from './languageModule/turkish'
import spanish from './languageModule/spanish'
import arabic from './languageModule/arabic'
import korean from './languageModule/korean'
import finland from './languageModule/finland'
import russian from './languageModule/russian'
import italian from './languageModule/italian'
import thai from './languageModule/thai'
import dutch from './languageModule/dutch'

const defaultLanguage = 'English'// 系统默认语言

/**
 * @description 获取语言文件
 * @param key 默认语言
 * @returns 当前语言文件
 */
function languageType (key) {
  if (key == 'English' || key == 'english') {
    return english
  }

  if (key == '日本語') {
    return japanese
  }

  if (key == 'Português') {
    return portuguese
  }

  if (key == '繁體中文') {
    return chineseTraditional
  }

  if (key == 'Français') {
    return french
  }

  if (key == 'Deutsch') {
    return german
  }

  if (key == 'Türk') {
    return turkish
  }

  if (key == 'Español') {
    return spanish
  }

  if (key == 'عربي') {
    return arabic
  }

  if (key == '한국인') {
    return korean
  }

  if (key == 'Suomi') {
    return finland
  }

  if (key == 'Русский') {
    return russian
  }

  if (key == 'italiano') {
    return italian
  }

  if (key == 'แบบไทย') {
    return thai
  }

  if (key == 'Nederlands') {
    return dutch
  }
}

/**
 * @description 获取设置语言包
 * @returns 获取设置默认语言
 */
function getLanguage () {
  let language = localStorage.getItem("languageType")
  if (!language) {
    localStorage.setItem('languageType', defaultLanguage)
    language = defaultLanguage
  }
  return language
}

/**
 * @description 获取语言包
 * @param {*} key 当前语言
 * @returns 当前页面语言
 */
function languageList (key) {
  let languageObj = {}
  let language = getLanguage()
  languageObj = languageType(language)

  if (key) {
    return languageObj[key]
  }
  let UrlPath = window.location.hash.slice(2)
  if (!UrlPath) {
    UrlPath = 'appHomeIndex'
  }
  if (UrlPath.includes("?")) {
    UrlPath = UrlPath.split("?")[0]
  }
  return languageObj[UrlPath]
}
export { languageList, languageType }