<template>
  <div class="orderDetail-warper" id="orderDetailID">
    <div class="page-header">
      <nav-tab>
        <template #navRight>
          <div class="nav-right-svg">
            <svg-icon
              icon-class="logistics-svg"
              @click="onChangeSvg('logistics')"
            ></svg-icon>
            <svg-icon
              icon-class="order-record"
              @click="onChangeSvg('record')"
            ></svg-icon>
          </div>
        </template>
      </nav-tab>
    </div>
    <div class="orderDetail-body">
      <div class="info-list">
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.detailInfoLabel1 }}</div>
          <div class="value">{{ orderProductObj.no }}</div>
        </div>
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.detailInfoLabel2 }}</div>
          <div class="value">{{ orderProductObj.createAt }}</div>
        </div>
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.detailInfoLabel3 }}</div>
          <div class="value">{{ orderProductObj.paymentMethodName }}</div>
        </div>
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.detailInfoLabel4 }}</div>
          <div class="value">{{ orderProductObj.stateName }}</div>
        </div>
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.detailInfoLabel5 }}</div>
          <div class="value">{{ orderProductObj.backAmountName }}</div>
        </div>
      </div>
      <div class="product-info-list">
        <div class="product-info-title">{{ language.productLabel1 }}</div>
        <template v-if="orderProductList.length">
          <div
            class="product-list flexbox"
            v-for="(item, index) in orderProductList"
            :key="index"
          >
            <div
              class="list-left imgFlex"
              @click="onChangePreview(item.imageIconUrl)"
            >
              <img v-lazy="item.imageIcon" />
            </div>
            <div class="list-right">
              <div class="name text2">{{ item.name }}</div>
              <div class="attributes-boxers flexbox">
                <div class="item-attributes-right">
                  <div
                    class="attributes-list flexbox"
                    v-for="(child, childIndex) in item.attributesValue"
                    :key="childIndex"
                  >
                    <p class="attributes-value flexbox">
                      <van-tag plain type="primary">
                        {{ child.productAttributesName }}
                      </van-tag>
                      <span class="value text-warper">
                        {{ child.productAttributesValueName }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="product-price">
                <span class="text-noText">
                  {{ language.productAccountLabel1 }}
                </span>
                <countTo
                  :startVal="0"
                  :endVal="item.totalUnitAmount"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </div>
              <div class="product-price">
                <span class="text-noText">
                  {{ language.productAccountLabel2 }}
                </span>
                <countTo
                  :startVal="0"
                  :endVal="item.totalPurchaseAmount"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </div>

              <div class="product-price sales-account flexbox">
                <span> {{ moneyIcon }}{{ item.totalProfitAmount }}</span
                ><countTo
                  :startVal="0"
                  :endVal="item.number"
                  :duration="1200"
                  :decimals="0"
                  :separator="','"
                  :prefix="'x'"
                ></countTo>
              </div>
            </div>
          </div>
        </template>
        <default-page v-else></default-page>
      </div>
      <div class="count-info-list">
        <div class="list-count flexbox">
          <span class="label">{{ language.countInfoLabel1 }}</span>
          <countTo
            class="value"
            :startVal="0"
            :endVal="orderProductObj.totalUnitAmount"
            :duration="1200"
            :decimals="2"
            :separator="','"
            :prefix="moneyIcon"
          ></countTo>
        </div>
        <div class="list-count flexbox">
          <span class="label">{{ language.countInfoLabel2 }}</span>
          <countTo
            class="value"
            :startVal="0"
            :endVal="orderProductObj.totalPurchaseAmount"
            :duration="1200"
            :decimals="2"
            :separator="','"
            :prefix="moneyIcon"
          ></countTo>
        </div>
        <div class="list-count flexbox">
          <span class="label">{{ language.countInfoLabel3 }}</span>
          <countTo
            class="value"
            :startVal="0"
            :endVal="orderProductObj.totalProfitAmount"
            :duration="1200"
            :decimals="2"
            :separator="','"
            :prefix="moneyIcon"
          ></countTo>
        </div>
        <div class="list-count flexbox">
          <span class="label">{{ language.countInfoLabel4 }}</span>
          <span class="value">
            <countTo
              class="value"
              :startVal="0"
              :endVal="orderProductObj.number"
              :duration="1200"
              :decimals="0"
              :separator="','"
            ></countTo
          ></span>
        </div>
        <div class="list-count flexbox">
          <span class="label">{{ language.countInfoLabel5 }}</span>
          <span class="value">
            <countTo
              class="value"
              :startVal="100"
              :endVal="orderProductObj.unitAmountDiscountRatio"
              :duration="1200"
              :decimals="0"
              :separator="','"
              :suffix="'%'"
            ></countTo
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import imgUrl from "@/utils/projectConfig.js";
import orderDetailApi from "@/api/orderDetailApi.js";
import { ImagePreview } from "vant";
export default {
  name: "appOrderDetailIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      thumbnail: this.$thumbnail,
      imgApi: imgUrl.imgUrl,
      orderProductObj: {
        totalUnitAmount: 0.0,
        actualTotalUnitAmount: 0.0,
        totalPurchaseAmount: 0.0,
        totalProfitAmount: 0.0,
        unitAmountDiscountRatio: 100,
        number: 0,
      },
      orderProductList: [],
      oldRoutePath: "",
      count: 0,
    };
  },
  watch: {
    $route(to, from) {
      this.oldRoutePath = from.path;
    },
  },
  activated() {
    let enumPath = ["/appOrderDetailLogistics", "/appOrderDetailRecord"];
    if (this.count == 1) {
      if (
        !enumPath.includes(this.oldRoutePath) &&
        this.$route.path == "/appOrderDetailIndex"
      ) {
        this.onLoad();
      }
    } else {
      this.count = 1;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("orderDetailID").style["height"] =
        seeHeight + "px";
    });
    this.count = 1;
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.getDetailData();
    },
    onChangePreview(url) {
      ImagePreview([url]);
    },
    async getDetailData() {
      const params = { id: this.$route.query.id };
      const { code, data } = await orderDetailApi.getOrderDetail_interface(
        params
      );
      if (code == 200) {
        data.logistic.orderNo = data.no;
        data.totalUnitAmount = Number((data.totalUnitAmount / 100).toFixed(2));
        data.actualTotalUnitAmount = Number(
          (data.actualTotalUnitAmount / 100).toFixed(2)
        );
        data.totalPurchaseAmount = Number(
          (data.totalPurchaseAmount / 100).toFixed(2)
        );
        data.totalProfitAmount = Number(
          (data.totalProfitAmount / 100).toFixed(2)
        );
        data.addressName = `${data.address.province} ${data.address.city} ${data.address.county} ${data.address.address}`;
        data.addressUserName = data.address.name;
        data.addressUzipName = data.address.uzip;
        data.paymentAtSlice = "";
        if (data.paymentAt) {
          data.paymentAtSlice = data.paymentAt.slice(5);
        }
        let orderProductSkuList = [];
        data.orderProduct.map((item, index) => {
          item.orderProductSku.map((record) => {
            record.name = item.name;
            record.imageIcon = this.imgApi + record.image + this.thumbnail;
            record.imageIconUrl = this.imgApi + record.image;
            record.profitAmount = (record.profitAmount / 100).toFixed(2);
            record.purchaseAmount = (record.purchaseAmount / 100).toFixed(2);
            record.totalProfitAmount = (record.totalProfitAmount / 100).toFixed(
              2
            );
            record.totalPurchaseAmount = Number(
              (record.totalPurchaseAmount / 100).toFixed(2)
            );
            record.totalUnitAmount = Number(
              (record.totalUnitAmount / 100).toFixed(2)
            );
            record.unitAmount = Number((record.unitAmount / 100).toFixed(2));
            orderProductSkuList.push(record);
          });
        });
        data.orderProduct.map((item) => {
          item.orderProductSku.map((record) => {
            data.orderProduct.push(record);
          });
        });
        this.orderProductObj = data;
        this.orderProductList = orderProductSkuList;
      }
    },
    onChangeSvg(key) {
      if (key == "logistics") {
        let obj = {
          address: this.orderProductObj.address,
          logistic: this.orderProductObj.logistic,
        };
        this.$router.push({
          path: "/appOrderDetailLogistics",
          query: {
            obj: JSON.stringify(obj),
          },
        });
      }
      if (key == "record") {
        let obj = {
          no: this.orderProductObj.no,
          createAt: this.orderProductObj.createAt,
          paymentAt: this.orderProductObj.paymentAt,
          purchaseAt: this.orderProductObj.purchaseAt,
          shipAt: this.orderProductObj.shipAt,
          receivingAt: this.orderProductObj.receivingAt,
          backAmountAt: this.orderProductObj.backAmountAt,
          state: this.orderProductObj.state,
        };
        this.$router.push({
          path: "/appOrderDetailRecord",
          query: { obj: JSON.stringify(obj) },
        });
      }
    },
  },
  components: {
    navTab,
  },
};
</script>

<style lang="scss" scoped>
.orderDetail-warper {
  width: 100%;
  height: 100vh;
  .page-header {
    ::v-deep .nav-right-svg {
      svg {
        cursor: pointer;
        width: 38px !important;
        height: 38px !important;
      }
      svg:nth-child(1) {
        margin-right: 12px;
      }
    }
  }
  .orderDetail-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: auto;
    padding: 30px;
    border-radius: 8px;
    .info-list {
      padding: 24px 0;
      background-color: #fff;
      .detail-info-boxers {
        font-size: 26px;
        padding: 14px 28px;
        color: #aaa;
        line-height: 1.5;
        justify-content: space-between;
        .label {
          text-align: left;
        }
        .value {
          text-align: right;
        }
      }
      .detail-info-boxers:nth-child(1) {
        .value {
          opacity: 0.8;
        }
      }
    }
    .product-info-list {
      margin-top: 40px;
      background-color: #fff;
      padding: 0px 30px;
      .product-info-title {
        padding: 24px 0;
        border-bottom: 1px solid #eee;
        font-size: 32px;
        color: $color1;
        font-weight: 700;
        line-height: 1.5;
      }
      .product-list {
        padding: 24px 0;
        border-radius: 8px;
        border-bottom: 1px solid #eee;
        .list-left {
          width: 160px;
          height: 160px;
          border-radius: 8px;
          cursor: pointer;
        }
        .list-right {
          width: calc(100% - 160px);
          padding-left: 20px;
          .name {
            font-size: 28px;
            color: $color1;
            font-weight: 700;
          }

          .attributes-boxers {
            margin-top: 20px;
            align-items: flex-start;
            .label {
              font-size: 24px;
              color: $color1;
            }
            .item-attributes-right {
              .attributes-list {
                margin-bottom: 12px;
                .attributes-value {
                  align-items: flex-start;
                  .van-tag {
                    border-radius: 6px;
                    font-size: 22px;
                    padding: 3px 6px;
                    margin-right: 12px;
                    line-height: 1;
                  }
                  .value {
                    font-size: 22px;
                    color: #aaa;
                    line-height: 1.5;
                  }
                }
              }
              .attributes-list:last-child {
                margin-bottom: 0;
              }
            }
          }
          .product-price {
            margin-top: 20px;
            font-size: 24px;
            color: #aaa;
            span:nth-child(1) {
              display: inline-block;
              color: $color1;
            }
            span:nth-child(2) {
              font-size: 22px;
            }
          }
          .sales-account {
            justify-content: space-between;
            span:nth-child(1) {
              color: $blur;
              font-weight: 700;
            }
            span:nth-child(2) {
              color: $blur;
            }
          }
        }
      }
    }
    .count-info-list {
      padding: 24px 0;
      border-radius: 8px;
      background-color: #fff;
      margin-top: 40px;
      .list-count {
        font-size: 26px;
        padding: 14px 28px;
        color: #aaa;
        line-height: 1.5;
        justify-content: space-between;
        .label {
          text-align: left;
        }
        .value {
          text-align: right;
        }
      }
    }
  }
}
</style>
