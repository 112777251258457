import service from '../required/axios'
export default {
  // 折扣列表获取
  getDiscountActivityList (data) {
    return service({
      url: '/activity.discount.discount_get',
      method: "get",
      params: data
    })
  },
  // 折扣数据设置
  setDiscountActivity(data) {
    return service({
      url: '/activity.discount.discount_set',
      method: "post",
      data: data
    })
  },
}