import service from '../required/axios'
export default {
  // 订单页数据
  getOrderDetail_interface (data) {
    return service({
      url: 'order.detail',
      method: "get",
      params: data
    })
  },
}