import Vue from 'vue'
import Vuex from 'vuex'
//需要使用插件一次
Vue.use(Vuex)
import createPersistedState from 'vuex-persistedstate';

const state = {}
const mutations = {}
const actions = {
  async web_getClass_list ({ commit }) {
    const res = await classIfyApi.getClassIfy_List()
    commit('webGetClassTree', res);
  },
}
const getters = {}
//对外暴露Store类的一个实例
export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  state,
  mutations,
  actions,
  getters
})