import service from '../required/axios'
export default {
  // 产品分类数据获取
  getProductDetail_interface (data) {
    return service({
      url: 'product.detail',
      method: "get",
      params: data
    })
  },
   // 产品 - 店铺产品增加 
   create_shop_product (data) {
    return service({
      url: 'product.create_shop_product',
      method: "post",
      data: data
    })
  },
  //产品详情更新
  change_shop_product (data) {
    return service({
      url: 'product.change_shop_product',
      method: "post",
      data: data
    })
  },
}