import Vue from 'vue'

import App from './App.vue'
import router from './router/index.js'
import '@/fonts/fonts.css'
import { environment } from '@/utils/constant'
Vue.prototype.$constant = environment;

import socketApi from "@/utils/websocket"
Vue.prototype.$socketApi = socketApi;//websocket挂在原型上
if (process.env.NODE_ENV !== 'development') {
  console.log = function () { }
  console.error = function () { }
}
import Debounce from "@/utils/directive"
Debounce(Vue)
import store from '@/store'
import 'lib-flexible/flexible'
// 引入
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)
import VueLazyload from 'vue-lazyload'
import '@/icons'
Vue.use(VueLazyload, {
  // 加载失败后加载的图片
  error: require('./assets/shop-default.png'),
  loading: require('./assets/shop-default.png'),
})
import '@/utils/vant'
import './utils/appback.js'
import './utils/version'
import countTo from 'vue-count-to';
import defaultPage from '@/layout/defaultNull.vue'
Vue.component('countTo', countTo)
Vue.component('defaultPage', defaultPage)
import { languageList, languageType } from '@/utils/language/language'
Vue.prototype.$language = languageList
Vue.prototype.$languageType = languageType
// 图片缩略
Vue.prototype.$thumbnail = '?x-oss-process=image/resize,w_100'

import globalCommon from './utils/global'
Vue.prototype.$globalCommon = globalCommon;
Vue.prototype.$permissionObj = globalCommon.$permissionObj;

// import { resetSetItem } from '@/utils/storangeWathch'
// Vue.prototype.$resetSetItem = resetSetItem;
Vue.prototype.$resetSetItem = function (type, key, data) {
  // 创建一个StorageEvent事件
  var newStorageEvent = document.createEvent('StorageEvent');
  const storage = {
    setItem: function (k, val) {
      if (type === "localStorage") {
        localStorage.setItem(k, val);
      } else {
        sessionStorage.setItem(k, val);
      }
      // 初始化创建的事件
      newStorageEvent.initStorageEvent(key, false, false, k, null, val, null, null);
      // 派发对象
      window.dispatchEvent(newStorageEvent);
    }
  }
  return storage.setItem(key, data);
}
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')