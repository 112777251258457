import service from '../required/axios'
export default {
  // 产品分类数据获取
  getCategory_interface (data) {
    return service({
      url: 'product.category',
      method: "post",
      data: data
    })
  },
  // 产品品牌数据获取
  getBrand_interface (data) {
    return service({
      url: 'product.brand',
      method: "get",
      data: data
    })
  },
  // 产品清单数据
  getProductList_interface (data) {
    return service({
      url: 'product.lists',
      method: "get",
      params: data
    })
  },
  // 产品库数据
  getProductLibrary_interface (data) {
    return service({
      url: 'product.library_list',
      method: "get",
      params: data
    })
  },
 
  // 获取基本信息
  getShopInfo_interface (data) {
    return service({
      url: 'shop.info',
      method: "post",
      data:data
    })
  },
  // 获取基本信息
  setChangeState_interface (data) {
    return service({
      url: 'product.change_shop_product_state',
      method: "post",
      data:data
    })
  },
  // 获取基本信息
  product_batch (data) {
    return service({
      url: 'product.create_shop_product_batch',
      method: "post",
      data:data
    })
  },
}