<template>
  <div class="project-page-warper" id="productID">
    <div class="page-header">
      <nav-tab :navLeftIcon="true">
        <template #navRight>
          <svg-icon
            icon-class="pushNav-svg"
            class="library-class"
            @click="onChangeRouterLibrary"
          ></svg-icon>
          <svg-icon
            icon-class="filter-svg"
            class="filter-class"
            @click="searchVisible = true"
          ></svg-icon>
        </template>
      </nav-tab>
    </div>
    <div class="project-page-body">
      <div class="project-list-boxers">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          :loading-text="loadingText"
          @load="onLoad"
        >
          <div class="list-item-boxers" v-if="projectDataList.length">
            <div
              class="list-item"
              v-for="(item, index) in projectDataList"
              :key="index"
            >
              <div class="item-images imgFlex" @click="onChangeAdd(item)">
                <img v-lazy="imgUrl + item.image + thumbnail" />
              </div>
              <div class="item-info">
                <div class="info-title text2" @click="onChangeAdd(item)">
                  {{ item.name }}
                </div>
                <div class="info-spec flexbox" @click="onChangeAdd(item)">
                  <span class="text2">{{ item.brand.name }}</span>
                  <span class="sales-span">
                    {{ language.salesLabel }}{{ item.sales }}
                  </span>
                </div>
                <div class="info-shelves flexbox">
                  <span class="label">{{ language.listLabel }}</span>
                  <van-switch
                    v-model="item.stateCheck"
                    inactive-color="#aaa"
                    active-color="#6d3580"
                    @change="onChangeState(item, index)"
                  />
                </div>
                <div class="info-price" @click="onChangeAdd(item)">
                  <countTo
                    :startVal="0"
                    :endVal="item.unitAmount"
                    :duration="1200"
                    :decimals="2"
                    :separator="','"
                    :prefix="moneyIcon"
                  ></countTo>
                </div>
              </div>
              <div class="item-svg" @click="onChangeAdd(item)">
                <svg-icon icon-class="more-svg"></svg-icon>
              </div>
            </div>
          </div>
          <default-page v-else></default-page>
        </van-list>
      </div>
    </div>
    <div class="footer-page">
      <footerCard></footerCard>
    </div>
    <template v-if="searchVisible">
      <van-popup
        v-model="searchVisible"
        position="top"
        @close="handlerClose"
        round
        class="popup-search"
      >
        <div class="search-popup-body">
          <div class="field-row">
            <van-field
              v-model="formObj.searchValue"
              :placeholder="language.searchPlace"
              clearable
            />
          </div>
          <div class="field-row flexbox">
            <van-field
              v-model="formObj.categoryName"
              :placeholder="language.classPlace"
              readonly
              right-icon="arrow-down"
              @click="onChangeSelect('class')"
            />
            <!-- <van-field
              v-model="formObj.brandName"
              :placeholder="language.brandPlace"
              readonly
              right-icon="arrow-down"
              @click="onChangeSelect('brand')"
            /> -->
          </div>
          <div class="field-row">
            <div class="field-label">{{ language.shelvesLabel }}</div>
            <div class="field-list flexbox">
              <div
                v-for="(item, index) in shelvesData"
                :key="index"
                @click="onChangeShelves(item, index)"
                :class="['list-item', shelvesActive == index && 'item-active']"
              >
                <span>{{ item.text }}</span>
              </div>
            </div>
          </div>
          <div class="search-options">
            <van-button @click="onChangeResult">
              {{ language.searchOptions1 }}
            </van-button>
            <van-button @click="handlerSubmitSearch">
              {{ language.searchOptions2 }}
            </van-button>
          </div>
        </div>
      </van-popup>
    </template>

    <van-popup
      v-if="classVisible"
      v-model="classVisible"
      position="bottom"
      class="van-popup-dialog"
    >
      <van-picker
        show-toolbar
        :confirm-button-text="language.dialogAffirm"
        :cancel-button-text="language.dialogClose"
        :columns="tmClassList"
        @confirm="onConfirmPicker"
        @cancel="onCancelPicker"
      />
    </van-popup>
  </div>
</template>

<script>
import footerCard from "@/layout/footerCard.vue";
import navTab from "@/layout/navTab";
import productApi from "@/api/productApi.js";
import imgApi from "@/utils/projectConfig";
export default {
  name: "appProjectIndex",
  data() {
    return {
      language: this.$language(),
      thumbnail: this.$thumbnail,
      imgUrl: imgApi.imgUrl,
      finishedText: this.$language("messageLanguage").finishedText,
      loadingText: this.$language("messageLanguage").loadingText,
      loading: false,
      finished: false,
      moneyIcon: localStorage.getItem("moneyIcon"),
      searchVisible: false,
      shelvesData: [], //上下架数据集
      shelvesActive: 0,
      brandData: [], //品牌数据集
      formObj: {
        searchValue: "",
        shelves: "",
        brand: "",
        brandName: "",
        category: [],
        categoryName: "",
        page: 1,
        size: 20,
        total: 50,
      },
      tmClassList: [],
      categoryList: [], //分类数据集
      classVisible: false,
      classOptionType: "",
      projectDataList: [],
    };
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("productID").style["height"] = seeHeight + "px";
    });
  },
  mounted() {
    this.init();
  },
  methods: {
    async onChangeState(record, index) {
      console.log(record.stateCheck);
      if (this.projectDataList[index].state === 1) {
        this.projectDataList[index].stateCheck = true;
      }
      if (this.projectDataList[index].state === 2) {
        this.projectDataList[index].stateCheck = false;
      }
      let params = {
        id: record.id,
      };
      const { code, message, data } = await productApi.setChangeState_interface(
        params
      );
      if (code == 200) {
        this.projectDataList[index].stateCheck =
          !this.projectDataList[index].stateCheck;
        if (this.projectDataList[index].stateCheck) {
          this.projectDataList[index].state = 1;
        }
        if (!this.projectDataList[index].stateCheck) {
          this.projectDataList[index].state = 2;
        }
        // this.handlerSubmitSearch()
        this.$toast.success(message);
        this.$forceUpdate();
      }
    },
    onChangeShelves(record, index) {
      this.shelvesActive = index;
    },
    handlerClose() {
      this.searchVisible = false;
    },
    onCancelPicker() {
      this.classVisible = false;
    },
    handlerSubmitSearch() {
      this.loading = true;
      this.finished = false;
      this.formObj.page = 1;
      this.projectDataList = [];
      this.searchVisible = false;
      this.getProductList();
    },
    onLoad() {
      this.getProductList();
    },

    // 产品列表
    async getProductList() {
      const listParams = {
        name: this.formObj.searchValue,
        brandId: this.formObj.brand,
        categoryId: this.formObj.category[this.formObj.category.length - 1],
        page: this.formObj.page,
        size: this.formObj.size,
        state: this.shelvesData[this.shelvesActive].value,
      };
      this.loading = true;
      const { code, data } = await productApi.getProductList_interface(
        listParams
      );
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.unitAmount = Number((item.unitAmount / 100).toFixed(2));
          if (item.state == 1) {
            item.stateCheck = true;
          }
          if (item.state == 2) {
            item.stateCheck = false;
          }
        });
        this.projectDataList = this.projectDataList.concat(data.list);
        this.formObj.total = data.total;
        if (this.projectDataList.length >= this.formObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.formObj.page++;
        }
      }
    },
    init() {
      this.shelvesData = [
        { value: 0, text: this.language.shelvesTag1 },
        { value: 1, text: this.language.shelvesTag2 },
        { value: 2, text: this.language.shelvesTag3 },
      ];
    },
    onChangeRouterLibrary() {
      this.$router.push({ path: "/appProjectLibraryIndex" });
    },
    // eslint-disable-next-line no-unused-vars
    onChangeAdd(record) {
      this.$router.push({
        path: "/appProductFirstIndex",
        query: { id: record.id, type: "list" },
      });
    },
    onChangeSelect(key) {
      this.classOptionType = key;
      if (key === "class") {
        if (this.categoryList.length) {
          this.tmClassList = JSON.parse(JSON.stringify(this.categoryList));
          this.classVisible = true;
        } else {
          this.getCategoryData();
        }
      }
      if (key === "brand") {
        if (this.brandData.length) {
          this.tmClassList = JSON.parse(JSON.stringify(this.brandData));
          this.classVisible = true;
        } else {
          this.getBrandData();
        }
      }
    },
    onConfirmPicker(e, val) {
      this.classVisible = false;
      if (this.classOptionType === "class") {
        const id1 = val.length >= 1 && this.tmClassList[val[0]].id;
        const id2 =
          val.length >= 2 && this.tmClassList[val[0]].child[val[1]].id;
        this.formObj.category = [id1, id2];
        this.formObj.categoryName = e.join("/");
      }
      if (this.classOptionType == "brand") {
        this.formObj.brand = e.id;
        this.formObj.brandName = e.name;
      }
    },
    async getBrandData() {
      const { code, data } = await productApi.getBrand_interface();
      if (code == 200) {
        data.list.map((item) => {
          item.value = item.id;
          item.text = item.name;
        });
        this.brandData = data.list;
        this.tmClassList = JSON.parse(JSON.stringify(this.brandData));
        this.classVisible = true;
      }
    },
    // 分类数据获取
    async getCategoryData() {
      const { code, data } = await productApi.getCategory_interface();
      if (code == 200) {
        noeTreeCall(data);
        this.categoryList = data;
        this.tmClassList = JSON.parse(JSON.stringify(this.categoryList));
        this.classVisible = true;
        function noeTreeCall(node) {
          for (const item of node) {
            item.value = item.id;
            item.text = item.name;
            item.children = item.child;
            if (item.children && item.children.length > 0) {
              noeTreeCall(item.children);
            }
          }
        }
      }
    },
    onChangeResult() {
      this.shelvesActive = 0;
      this.classOptionType = "";
      this.formObj.searchValue = "";
      this.formObj.shelves = "";
      this.formObj.brand = "";
      this.formObj.category = [];
      this.formObj.categoryName = "";
      this.formObj.brandName = "";
    },
  },
  components: { footerCard, navTab },
};
</script>

<style lang="scss" scoped>
.project-page-warper {
  width: 100%;
  height: 100vh;
  background: #eff2f6;

  .page-header {
    svg {
      width: 38px !important;
      height: 38px !important;
      color: $color3;
    }

    .library-class {
      width: 34px !important;
      height: 34px !important;
      margin-right: 12px;
    }
  }

  .project-page-body {
    width: 100%;
    height: calc(100% - 92px - 100px);
    padding: 30px;

    .project-list-boxers {
      width: 100%;
      height: 100%;

      .van-list {
        .list-item-boxers {
          .list-item {
            padding: 24px;
            display: flex;
            width: 100%;
            margin-bottom: 30px;
            background: #fff;
            border-radius: 8px;
            position: relative;

            .item-images {
              width: 160px;
              height: 160px;
              border-radius: 8px;
            }
          }

          .item-info {
            width: 65%;
            padding-left: 20px;

            .info-title {
              width: 100%;
              font-size: 28px;
              color: #333;
              line-height: 32px;
              font-weight: 700;
            }

            .info-spec {
              font-size: 24px;
              color: #999;
              line-height: 2;
              margin-top: 10px;
              span:nth-child(1) {
                max-width: 65%;
                line-height: 1.5;
                margin-right: 10px;
              }
              span:nth-child(2) {
                max-width: 35%;
              }
              .sales-span {
                margin-left: 20px;
              }
            }

            .info-shelves {
              .label {
                font-size: 24px;
                color: #999;
                line-height: 2;
                margin-right: 10px;
              }

              ::v-deep .van-switch {
                width: 76px;
                height: 36px;

                .van-switch__node {
                  width: 36px;
                  height: 36px;
                }
              }

              ::v-deep .van-switch--on {
                .van-switch__node {
                  transform: translateX(39px);
                }
              }
            }

            .info-price {
              margin-top: 10px;
              font-size: 24px;
              color: $blur;
              line-height: 24px;
            }
          }

          .item-svg {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 40px !important;
            height: 8px !important;
          }
        }
      }
    }
  }

  .van-popup-dialog {
    width: 100%;
    max-height: 75%;
    height: 45%;
    border-radius: 16px;

    ::v-deep .van-picker {
      height: 100%;

      .van-picker__toolbar {
        font-size: 32px;
        font-weight: 600;

        .van-picker__confirm {
          color: $blur;
        }
      }
    }
  }

  .popup-search {
    max-height: 80%;
    min-height: 28%;
    padding: 40px 30px;
    overflow-y: scroll;
    border-radius: 0 0 56px 56px;

    .search-popup-body {
      .field-row {
        margin-bottom: 30px;

        ::v-deep .van-cell {
          border: 1px solid $blur;
          border-radius: 42px;
          padding: 0 30px;

          .van-field__control {
            padding: 12px 0;
            font-size: 24px;
            color: $color3 !important;
          }
        }
      }

      .field-row:nth-child(2) {
        justify-content: space-between;
      }

      .field-row:nth-child(3) {
        .field-label {
          font-size: 28px;
          font-weight: 600;
          color: $color3;
          line-height: 1.5;
          margin-bottom: 20px;
        }

        .field-list {
          .list-item {
            display: inline-block;
            padding: 8px 18px;
            background: rgba(220, 220, 220, 0.6);
            color: $color1;
            font-size: 22px;
            line-height: 1.5;
            margin-left: 12px;
            border-radius: 6px;
            text-align: center;
          }

          .item-active {
            border: 1px solid $blur;
            background: $blur;
            color: #fff;
          }

          .list-item:last-child {
            margin-right: 0;
          }
        }
      }

      .search-options {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .van-button {
          width: 45%;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 28px;
          color: #fff;
          background: $blur;
          text-align: center;
          border-radius: 42px;
          border: 1px solid $blur;
        }

        .van-button:nth-child(1) {
          color: $blur;
          background: #fff;
          margin-right: 20px;
          border: 1px solid $blur;
        }
      }
    }
  }
}
</style>
