<template>
  <div class="page-navTabBar">
    <div class="navTab-boxers">
      <div class="back-icon">
        <van-icon
          name="arrow-left"
          v-if="!navLeftIcon"
          @click="$router.go(-1)"
        />
      </div>
      <div class="navTab-title">
        <p class="noText" v-if="navTitle">{{ navTitle }}</p>
        <p class="noText" v-else>{{ language.navTitle }}</p>
      </div>
      <div class="navTab-right">
        <slot name="navRight"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navTabBarIndex",
  props: {
    navLeftIcon: Boolean,
    navTitle: String,
  },
  data() {
    return {
      language: this.$language(),
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.page-navTabBar {
  max-width: 750px;
  margin: auto !important;
  width: 100%;
  height: 92px;
  border-bottom: 1px solid #eff2f6;
  background-color: #fff;
  position: fixed;
  top: 0;

  .navTab-boxers {
    width: 100%;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back-icon {
      min-width: 14%;
      height: 100%;
      display: flex;
      align-items: center; 
      padding: 0 32px;

      i {
        color: #1f2025;
        font-size: 36px;
      }
    }

    .navTab-title {
      max-width: 70%;
      color: #333333;
      font-size: 32px;
      p {
        width: 100%;
      }
    }

    .navTab-right {
      min-width: 14%;
      height: 100%;
      display: flex;
      align-items: center; 
      padding: 0 32px;
      cursor: pointer;
    }
  }
}
</style>
