<template>
  <div class="user-record-warper" id="userID">
    <div class="page-header">
      <nav-tab></nav-tab>
    </div>
    <div class="user-record-body">
      <div class="wull">
        <div class="record-header-card">
          <div class="row">
            <p class="value">
              <countTo
                class="value"
                :startVal="0"
                :endVal="inviteObj.sum"
                :duration="1200"
                :decimals="2"
                :separator="','"
                :prefix="moneyIcon"
              ></countTo>
            </p>
            <p class="label">{{ language.recordTitle1 }}</p>
          </div>
          <div class="row content flexbox">
            <div class="list-item">
              <p class="value">
                <countTo
                  class="value"
                  :startVal="0"
                  :endVal="inviteObj.one"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </p>
              <p class="label">{{ language.recordTitle2 }}</p>
            </div>
            <div class="list-item">
              <p class="value">
                <countTo
                  class="value"
                  :startVal="0"
                  :endVal="inviteObj.two"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </p>
              <p class="label">{{ language.recordTitle3 }}</p>
            </div>
            <div class="list-item">
              <p class="value">
                <countTo
                  class="value"
                  :startVal="0"
                  :endVal="inviteObj.three"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </p>
              <p class="label">{{ language.recordTitle4 }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="record-tags-boxers">
        <van-tabs
          v-model="tabsActiveIndex"
          swipe-threshold="3"
          :ellipsis="false"
          @change="onChangeClick"
        >
          <van-tab
            v-for="(item, index) in tabsData"
            :key="index"
            :title="item.label"
          ></van-tab>
        </van-tabs>
        <div class="record-van-list">
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finishedText"
            :loading-text="loadingText"
            @load="onLoad"
          >
            <template v-if="recordsListData.length">
              <div
                class="record-list"
                v-for="(item, index) in recordsListData"
                :key="index"
              >
                <div class="list-left imgFlex">
                  <img v-lazy="item.image" />
                </div>
                <div class="list-right">
                  <div class="item-row">
                    <span class="name noText">{{ item.name }}</span>
                  </div>
                  <div class="item-row">
                    <span class="timeDate">{{ item.createAt }}</span>
                  </div>
                  <div class="item-row">
                    <span class="label">{{ language.listLabel1 }}</span>
                    <countTo
                      class="value"
                      :startVal="0"
                      :endVal="item.amount"
                      :duration="1200"
                      :decimals="2"
                      :separator="','"
                      :prefix="moneyIcon"
                    ></countTo>
                  </div>
                  <div class="item-row">
                    <span class="label">{{ language.listLabel2 }}</span>
                    <countTo
                      class="value"
                      :startVal="0"
                      :endVal="item.ratio"
                      :duration="1200"
                      :separator="','"
                      :suffix="'%'"
                    ></countTo>
                  </div>
                </div>
              </div>
            </template>
            <default-page v-else></default-page>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import imgApi from "@/utils/projectConfig";
import inviteIndex from "@/api/inviteIndexApi.js";
export default {
  name: "appInviteUserRecordIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      thumbnail: this.$thumbnail,
      imgApi: imgApi.imgUrl,
      finishedText: this.$language("messageLanguage").finishedText,
      loadingText: this.$language("messageLanguage").loadingText,
      loading: false,
      finished: false,
      tabsData: [],
      tabsActiveIndex: 0,
      recordsListData: [],
      searchObj: {
        total: 0,
        page: 1,
        size: 20,
        level: 0,
      },
      inviteObj: {
        sum: 0,
        one: 0,
        two: 0,
        three: 0,
      },
    };
  },
  components: {
    navTab,
  },
  created() {
    this.init();
  },
  mounted() {
    this.getSumCount();
  },
  methods: {
    onChangeClick(e) {
      this.tabsActiveIndex = e;
      this.finished = false;
      this.loading = false;
      this.recordsListData = [];
      this.searchObj.total = 0
      this.getFundingRecordsList();
    },
    async getSumCount() {
      const _loginInfo = JSON.parse(localStorage.getItem("loginInfo_"));
      const params = {
        account: _loginInfo.account,
        level: 0,
      };
      const { code, data } = await inviteIndex.getInviteSumData(params);
      if (code === 200) {
        data.sum = Number((data.sum / 100).toFixed(2));
        data.one = Number((data.one / 100).toFixed(2));
        data.two = Number((data.two / 100).toFixed(2));
        data.three = Number((data.three / 100).toFixed(2));
        this.inviteObj = data;
      }
    },
    onLoad() {
      this.getFundingRecordsList();
    },
    async getFundingRecordsList() {
      this.loading = true;
      const params = {
        level: Number(this.tabsActiveIndex),
        page: this.searchObj.page,
        size: this.searchObj.size,
      };
      const { code, data } = await inviteIndex.getIncomeLists(params);
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.amount = Number((item.amount / 100).toFixed(2));
        });
        this.recordsListData = this.recordsListData.concat(data.list);
        this.searchObj.total = data.total;
        if (this.recordsListData.length >= this.searchObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.searchObj.page++;
        }
      }
    },
    init() {
      this.tabsData = [
        { value: 1, label: this.language.tabLabel1 },
        { value: 2, label: this.language.tabLabel2 },
        { value: 3, label: this.language.tabLabel3 },
        { value: 4, label: this.language.tabLabel4 },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.user-record-warper {
  width: 100%;
  height: 100vh;
  .user-record-body {
    width: 100%;
    height: calc(100% - 92px);
    // overflow-y: scroll;
    .wull {
      padding: 30px;
    }
    .record-header-card {
      width: 100%;
      background: linear-gradient(270deg, #aa88ff 0%, #6d3580 100%);
      padding: 30px;
      border-radius: 8px;
      .row {
        justify-content: center;
        line-height: 1.5;
        text-align: center;
        .value {
          font-size: 24px;
          color: #fff;
        }
        .label {
          margin-top: 12px;
          font-size: 28px;
          color: #fff;
        }
      }
      .content {
        display: flex;
        align-items: center;
        margin-top: 40px;
        justify-content: space-around;
        .list-item {
          flex: 1;
          .label {
            font-size: 26px;
          }
        }
      }
    }
    .record-tags-boxers {
      width: 100%;
      height: calc(100% - 346px);
      // background: #fff;
      ::v-deep .van-tabs {
        .van-tabs__nav {
          padding: 0 30px 20px 30px;
          background: #eff2f6;
        }
        .van-tab {
          color: #aaa;
        }
        .van-tab--active {
          color: $blur;
          font-size: 600;
        }
        .van-tabs__line {
          background: $blur;
        }
      }
    }
    .record-van-list {
      height: calc(100% - 93px);
      overflow-y: scroll;
      ::v-deep .van-list {
        padding: 30px;
        .record-list {
          display: flex;
          padding: 30px 24px;
          border-radius: 8px;
          background: #fff;
          margin-bottom: 30px;

          .list-left {
            width: 120px;
            height: 120px;
            border-radius: 8px;
            background: #eff2f6;
          }
          .list-right {
            padding-left: 20px;
            width: calc(100% - 120px);
            .item-row {
              width: 100%;
              margin-top: 10px;
              line-height: 1.5;
              .name {
                font-size: 26px;
                font-weight: 600;
                color: #000;
                display: inline-block;
                width: 100%;
              }
              .timeDate {
                font-size: 24px;
                color: #aaa;
              }
              .label {
                font-size: 24px;
                color: $color3;
              }
              .value {
                font-size: 22px;
                color: $blur;
              }
            }
            .item-row:nth-child(1) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
</style>
