import service from '../required/axios'
export default {
  // 获取筛选类型
  getSystemConfigList () {
    return service({
      url: '/system.config',
      method: "get",
    })
  },
  // 获取筛选类型
  getRecordList (data) {
    return service({
      url: '/assets.record_list',
      method: "get",
      params: data
    })
  },
}