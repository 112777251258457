<template>
  <div class="appInvite-warper" id="appInviteID">
    <div class="page-header">
      <nav-tab></nav-tab>
    </div>
    <div class="invite-body">
      <div class="body-title">{{ language.headerTitle }}</div>
      <div class="qrCode-card">
        <div class="qr-code-boxers flexbox">
          <div class="qrCode" ref="qrCodeUrl"></div>
        </div>
        <div class="qrCode-options flexbox">
          <svg-icon
            icon-class="copy-svg"
            v-clipboard:copy="qrCode"
            v-clipboard:success="onCopy"
          ></svg-icon>
          <span class="text-warper">
            {{ qrCode }}
          </span>
        </div>
      </div>
      <div class="invite-count-card">
        <!-- <div class="count-title">{{ language.inviteTitle }}</div> -->
        <div class="count-list">
          <div class="list-row flexbox" @click="onChangeRouter('user')">
            <span class="label">{{ language.inviteLabel1 }}</span>
            <p>
              <countTo
                class="value"
                :startVal="0"
                :endVal="inviteObj.sumNumber"
                :duration="1200"
                :separator="','"
              ></countTo>
              <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
            </p>
          </div>
          <div class="list-row flexbox" @click="onChangeRouter('income')">
            <span class="label">{{ language.inviteLabel2 }}</span>
            <p>
              <countTo
                class="value"
                :startVal="0"
                :endVal="inviteObj.sum"
                :duration="1200"
                :decimals="2"
                :separator="','"
                :prefix="moneyIcon"
              ></countTo>
              <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
            </p>
          </div>
        </div>
      </div>
      <div class="ruleHint-card">
        <div class="ruleHint-title">{{ language.ruleTitle }}</div>
        <div class="ruleHint-body">
          <p class="hint">{{ language.ruleHint1 }}</p>
          <p class="hint">{{ language.ruleHint2 }}</p>
          <p class="hint">{{ activityHint3 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import QRCode from "qrcodejs2";
import inviteIndex from "@/api/inviteIndexApi.js";
export default {
  name: "appInviteIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      qrCode: "",
      activityHint3: "",
      inviteObj: { sumNumber: 0, sum: 0.0 },
    };
  },
  components: { navTab },
  created() {
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("appInviteID").style["height"] = seeHeight + "px";
    });
  },
  methods: {
    init() {
      this.qrCode = JSON.parse(localStorage.getItem("loginInfo_"))?.inviteUrl;
      this.$nextTick(() => {
        this.bindQRCode();
      });
      const _configInfo = JSON.parse(localStorage.getItem("configInfo_"));
      this.activityHint3 = this.language.ruleHint3.replace(
        /</g,
        _configInfo.hierarchyOneInt
      );
      this.activityHint3 = this.activityHint3.replace(
        />/g,
        _configInfo.hierarchyTwoInt
      );
      this.activityHint3 = this.activityHint3.replace(
        /@/g,
        _configInfo.hierarchyThreeInt
      );
      this.getSumCount();
    },
    async getSumCount() {
      const _loginInfo = JSON.parse(localStorage.getItem("loginInfo_"));
      const params = {
        account: _loginInfo.account,
        level: 0,
      };
      const { code, data } = await inviteIndex.getInviteSumData(params);
      if (code === 200) {
        data.sum = Number((data.sum / 100).toFixed(2));

        this.inviteObj = data;
      }
    },
    onChangeRouter(key) {
      if (key === "user") {
        this.$router.push({ path: "/appInviteUserRecordIndex" });
      }
      if (key === "income") {
        this.$router.push({ path: "/appInviteIncomeRecordIndex" });
      }
    },
    onCopy() {
      this.$toast.success(this.language.copySuccess);
    },
    bindQRCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.qrCode,
        width: 120,
        height: 120,
        colorDark: "#333333", //二维码颜色
        colorLight: "#FFFF", //二维码背景色
        correctLevel: QRCode.CorrectLevel.H, //容错率，L/M/H
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appInvite-warper {
  width: 100%;
  height: 100vh;
  .invite-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    .body-title {
      font-size: 38px;
      font-weight: 600;
      line-height: 1.5;
      color: $blur;
      margin-bottom: 30px;
      text-align: center;
    }
    .qrCode-card {
      margin-bottom: 30px;
      padding: 24px 30px;
      background: #fff;
      border-radius: 8px;
      .qr-code-boxers {
        justify-content: center;
        overflow: hidden;
        .qrCode {
          padding: 20px;
          border-radius: 8px;
          border: 1px solid #f0f0f0;
        }
      }

      .qrCode-options {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          cursor: pointer;
          width: 42px !important;
          height: 42px !important;
          margin-right: 12px;
        }
        span {
          font-size: 24px;
          text-align: left;
          color: $color1;
          border-radius: 16px;
        }
      }
    }
    .invite-count-card {
      margin-bottom: 30px;
      background: #fff;
      border-radius: 8px;
      padding: 0px 30px;
      .count-title {
        font-size: 32px;
        font-weight: 600;
        color: $color3;
        line-height: 1.5;
        padding: 20px 0;
        border-bottom: 1px solid #f0f0f0;
        text-align: center;
      }
      .count-list {
        .list-row {
          padding: 24px 0;
          justify-content: space-between;
          border-bottom: 1px solid #f0f0f0;
          font-size: 28px;
          color: $color3;
          font-weight: 500;
          line-height: 1.5;
          p {
            display: flex;
            align-items: center;
          }
          .value {
            font-size: 24px;
            color: $blur;
            margin-right: 12px;
          }
        }
      }
    }
    .ruleHint-card {
      background: #fff;
      border-radius: 8px;
      padding: 0px 30px;
      .ruleHint-title {
        font-size: 32px;
        font-weight: 600;
        color: $color3;
        line-height: 1.5;
        padding: 20px 0;
        border-bottom: 1px solid #f0f0f0;
        text-align: center;
      }
      .ruleHint-body {
        padding: 30px 0;
        color: $color1;
        font-size: 24px;
        line-height: 1.5;
        text-align: left;
        p {
          margin-bottom: 12px;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
