<template>
  <div class="appLogin-page-warper" id="appLoginId">
    <div class="appLogin-header-naver">
      <span class="nav-title">{{ language.navTitle }}</span>
      <div class="set-language">
        <languageCard />
      </div>
    </div>
    <div class="login-nav">
      <div class="logo-img flexbox">
        <img src="../../assets/logo.png" alt="" />
      </div>
      <div class="logo-title">{{ language.projectName }}</div>
    </div>
    <div class="login-form">
      <van-form @submit="onSubmit" class="form-warper">
        <div class="field-item">
          <p class="label">{{ language.formLabel1 }}</p>
          <van-field
            v-model="formObj.account"
            :placeholder="language.placeholder"
            clearable
          />
        </div>
        <div class="field-item">
          <p class="label">{{ language.formLabel2 }}</p>
          <van-field
            v-model="formObj.password"
            :type="passType"
            clearable
            :placeholder="language.placeholder"
          >
            <template #right-icon>
              <svg-icon
                :icon-class="passIcon"
                @click="onChangePassType"
              ></svg-icon>
            </template>
          </van-field>
        </div>

        <div class="form-hint">
          <p>{{ language.hint }}</p>
          <div class="form-hint-info flexbox">
            <div class="hint-left" @click="onClickRegister">
              <span>{{ language.clickRegister }}</span>
            </div>
            <div class="hint-right">
              <span @click="$router.push({ path: '/appForgetPassIndex' })">
                {{ language.forgetPass }}
              </span>
            </div>
          </div>
        </div>
        <div class="form-option">
          <van-button type="info" native-type="submit">{{
            language.optionBtn
          }}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import languageCard from "@/layout/language";
import loginApi from "@/api/loginApi";
export default {
  name: "appLoginIndex",
  components: { languageCard },
  data() {
    return {
      language: this.$language(),
      formObj: {
        account: "",
        password: "",
      },
      passType: "password",
      passIcon: "eye-hide",
    };
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("appLoginId").style["height"] = seeHeight + "px";
    });
    const toKen_ = localStorage.getItem("authenticationToKen_");
    if (toKen_) {
      this.$router.push({ path: "/appHomeIndex" });
    }
  },
  mounted() {},
  methods: {
    onClickRegister() {
      this.$globalCommon.$openParamUrl(this.$globalCommon.$merchantRegisterUrl);
    },
    onChangePassType() {
      if (this.passType === "password") {
        this.passIcon = "eye-show";
        this.passType = "text";
        return false;
      }
      if (this.passType === "text") {
        this.passIcon = "eye-hide";
        this.passType = "password";
      }
    },
    onSubmit() {
      if (!this.formObj.account || !this.formObj.password) {
        this.$toast({
          type: "fail",
          message: this.language.validateError,
          duration: 800,
        });
        return false;
      }
      const params = {
        ...this.formObj,
        tiktokData: this.$route.query.data,
      };
      loginApi.toPathLogin_interface(params).then(({ code, data, message }) => {
        if (code === 200) {
          let loginInfo = JSON.stringify(data.account);
          // this.$store.commit("getUserInfo", res.data.account);
          localStorage.setItem("loginInfo_", loginInfo);
          localStorage.setItem("configInfo_", JSON.stringify(data.config));
          localStorage.setItem("authenticationToKen_", data.token);
          localStorage.setItem("moneyIcon", data.config.currencySymbol);
          this.$resetSetItem(
            "authenticationToKen_",
            "authenticationToKen_",
            data.token
          );
          this.$toast.success(message);
          setTimeout(() => {
            this.$router.push({ path: "/appHomeIndex" });
          }, 600);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appLogin-page-warper {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/homeBg.jpg");
  background-size: 100% 100%;

  .appLogin-header-naver {
    width: 100%;
    height: 120px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-title {
      font-size: 48px;
      font-weight: 700;
      color: #fff;
    }
  }

  .login-nav {
    padding-top: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logo-img {
      width: 144px;
      height: 144px;
      justify-content: center;
      overflow: hidden;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .logo-title {
      font-size: 48px;
      font-weight: 700;
      margin-top: 16px;
      color: #fff;
    }
  }

  .login-form {
    padding: 120px 46px 0 46px;

    .form-warper {
      .field-item {
        .label {
          color: #fff;
          font-size: 24px;
          line-height: 1.5;
          margin-bottom: 20px;
        }

        .van-cell {
          height: 88px;
          background-color: transparent;
          border-radius: 12px;
          border: 1px solid #fff;
          padding: 0;
          color: #fff !important;

          ::v-deep .van-field__body {
            height: 88px;
            padding: 20px;
            color: #fff !important;
          }

          ::v-deep .van-cell__value--alone {
            color: #fff !important;
          }

          ::v-deep .van-field__control {
            line-height: 2;
            background-color: transparent !important;
            color: #fff !important;
          }

          ::v-deep .van-icon {
            color: #fff !important;
          }

          ::v-deep .van-field__right-icon {
            svg {
              width: 32px !important;
              height: 32px !important;
              color: #fff;
            }
          }
        }
      }

      .field-item:nth-child(1) {
        margin-bottom: 40px;
      }

      .form-hint {
        margin-top: 20px;
        line-height: 1.5;
        font-size: 24px;
        p {
          color: #fff;
        }
        .form-hint-info {
          justify-content: space-between;

          .hint-left {
            width: 49%;
            color: #fff;

            span {
              color: #1d91ff;
              cursor: pointer;
            }
          }

          .hint-right {
            width: 49%;
            color: #fff;
            text-align: right;
            span {
              color: #1d91ff;
              cursor: pointer;
            }
          }
        }
      }

      .form-option {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 88px;

        .van-button {
          width: 100%;
          height: 100%;
          background-color: #1d91ff;
          color: #fff;
          border-color: #1d91ff;
        }

        .van-button:active {
          color: #fff;
          background-color: #1d91ff;
          border-color: #1d91ff;
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.van-field__control {
  color: #fff !important;
}

input {
  caret-color: #fff;
}
</style>
