// 泰语
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'ข้อความ',
    IMErrorMessage: 'การเชื่อมต่อ IM ล้มเหลว คลิกเพื่อลองอีกครั้ง! ',
  },
  "appWebSocketDetails": {
    sendOption: 'ส่ง',
    send: 'ส่ง',
    fileFail: 'กรุณาอัพโหลดและจัดรูปแบบรูปภาพ',
    IMportError: 'ไม่ได้เชื่อมต่อในขณะนี้ โปรดลองอีกครั้ง! ',
    IMErrorMessage: 'การเชื่อมต่อ IM ล้มเหลว โปรดลองอีกครั้ง! ',
    retryBtn: 'ลองอีกครั้ง',
  },
  "appLoginIndex": {
    navTitle: 'เข้าสู่ระบบ',
    projectName: 'จัดการร้านค้า',
    formLabel1: 'อีเมล',
    formLabel2: 'รหัสผ่าน',
    placeholder: 'กรุณาป้อน',
    hint: "หากคุณไม่มีบัญชี",
    clickRegister: 'คลิกเพื่อลงทะเบียน',
    forgetPass: 'ลืมรหัสผ่าน',
    optionBtn: 'เข้าสู่ระบบ',
    validateError: 'กรุณากรอกข้อมูลให้ครบถ้วน!',
  },
  "appForgetPassIndex": {
    navTitle: 'ลืมรหัสผ่าน',
    formLabel1: 'กล่องจดหมายเข้าสู่ระบบ',
    formLabel2: 'รหัสยืนยัน',
    formLabel3: 'รหัสผ่านใหม่',
    formLabel4: 'ยืนยันรหัสผ่าน',
    formHint1: 'กรุณากรอกอีเมลสำหรับเข้าสู่ระบบของคุณ',
    formHint2: 'กรุณากรอกรหัสผ่านใหม่',
    formHint3: 'กรุณากรอกรหัสยืนยัน',
    formHint4: 'กรุณาใส่รหัสผ่านของคุณสองครั้ง',
    formOption: 'ส่ง',
    sendOption: 'นับถอยหลัง:',
    optionsBtn: 'ยืนยัน',
  },
  'footer': {
    home: "เก็บ",
    project: 'ผลิตภัณฑ์',
    info: 'ข้อความ',
    order: 'คำสั่ง',
    user: 'ของฉัน',
  },
  'setDrawer': {
    label1: 'การตั้งค่าหลายภาษา',
    label2: 'การลงทะเบียนร้านค้า',
    label3: 'เข้าสู่ระบบร้านค้า',
    label4: 'ฝ่ายบริการลูกค้า',
    label5: 'ดาวน์โหลดแอปพลิเคชัน Android',
    label6: 'ดาวน์โหลดแอปพลิเคชัน iOS',
    optionsBtn: 'ยกเลิก',
  },
  'appHomeIndex': {
    accountLabel1: 'การขาย',
    accountLabel2: 'การจราจร',
    cardLabel1: 'ภาพรวมข้อมูล',
    cardLabel2: 'รายงานข้อมูล',
    cardLabel3: 'ภาพรวมจำนวนเงิน',
    ech1Title: 'ยอดขายรวม',
    ech2Title: 'จำนวนคำสั่งซื้อทั้งหมด',
    ech3Title: 'ปริมาณการเข้าชมร้านค้า',
    echBuoy1Title: 'ยอดขายรวม',
    echBuoy2Title: 'จำนวนคำสั่งซื้อทั้งหมด',
    echBuoy3Title: 'ปริมาณการเข้าชมร้านค้า',
    dataCardTitle1: 'ยอดขาย',
    dataCardTitle2: 'การจราจร',
    dataCardTitle3: 'จำนวนคำสั่งซื้อทั้งหมด',
    dataCardTitle4: 'ยอดค้างชำระทั้งหมด',
    dataCardDayTags: 'วันนี้',
    dataYesterTags: "ข้อมูลเมื่อวาน",
    dataYesterTags1: "จำนวนคำสั่งซื้อที่ไม่ได้ตรวจสอบ",
    cardMenusLabel1: 'ภาษี',
    cardMenusLabel2: 'มูลค่าที่เก็บไว้',
    cardMenusLabel3: 'การถอนเงิน',
    cardMenusLabel4: 'แชร์คำเชิญ',
    cardMenusLabel5: 'รายการผลิตภัณฑ์',
    cardMenusLabel6: 'รายการสั่งซื้อ',
    cardMenusLabel7: 'รายงานธุรกิจ',
    taxPopupTitle: 'ขณะนี้จำเป็นต้องจ่ายภาษี:',
    popupBtn: 'ไปจ่ายภาษี',
    cardStateLabel1: 'ยอดคงเหลือ',
    cardStateLabel2: 'การถอนเงิน',
    cardStateLabel3: 'รายได้',
    cardStateLabel4: 'ทีม',
    cardSellHotTitle: 'สินค้าขายดี 10 อันดับแรก',
    salesLabel: 'ยอดขาย:',
  },
  'appSetLanguage': {
    navTitle: 'การตั้งค่าหลายภาษา',
    affirm: 'ยืนยัน'
  },
  "appServiceIndex": {
    navTitle: 'ฝ่ายบริการลูกค้า',
    customerService: 'บริการลูกค้าพิเศษ',
    helloTitle: 'สวัสดี',
    welcomeTitle: 'ฉันเป็นของคุณ< สามารถติดต่อฉันได้ตามช่องทางต่อไปนี้',
    iconInformation: 'โปรดเลือกซอฟต์แวร์การสื่อสารเพื่อรับคำปรึกษา',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: 'สมดุล',
    blanceOption1: 'ถอนออก',
    blanceOption2: 'มูลค่าร้านค้า',
    incomeLabel1: 'รายได้สะสม',
    incomeLabel2: 'ยอดถอนสะสม',
    listLabel1: 'ข้อมูลร้านค้า',
    listLabel2: 'การตั้งค่าวิธีการถอนเงิน',
    listLabel3: 'บันทึกกองทุน',
    listLabel4: 'รายงานธุรกิจ',
    listLabel5: 'กิจกรรมส่วนลด',
    listLabel7: 'เงินกู้',
    listLabel8: 'ไปห้างสรรพสินค้า',
    listLabel9: 'ดาวน์โหลดใบสมัคร',
    listLabelLast: 'ออก',
  },
  'downloadAppIndex': {
    navTitle: 'ดาวน์โหลด',
    downloadAndroidApp: "ดาวน์โหลด Android",
    downloadIosApp: "ดาวน์โหลด IOS",
  },
  "appDiscountActivity": {
    navTitle: 'กิจกรรมส่วนลด',
    popupLabel1: 'จำนวน',
    popupPlace1: 'กรุณากรอกจำนวนเงิน',
    popupLabel2: 'ส่วนลด',
    popupPlace2: 'กรุณากรอกส่วนลด',
    popupOption: 'ยืนยัน',
    listLabel1: 'จำนวนเงินที่ต้องการ:',
    listLabel2: 'อัตราส่วนส่วนลด:',
    listLabel3: 'ส่วนลดเวลา:',
    dialogTitle: "ยืนยันว่าจะลบหรือไม่!",
    dialogAffirm: 'ยืนยัน',
    dialogClose: "ยกเลิก",
  },
  "appInviteIndex": {
    navTitle: 'เชิญกิจกรรมใหม่',
    headerTitle: 'ชวนเพื่อนมารับรางวัล',
    copyOption: 'คัดลอก',
    copySuccess: 'คัดลอกสำเร็จ',
    inviteTitle: 'จำนวนคำเชิญทั้งหมด',
    inviteLabel1: 'จำนวนผู้ได้รับเชิญ:',
    inviteLabel2: 'ค่าคอมมิชชันทั้งหมด:',
    ruleTitle: 'กฎการเชิญ',
    ruleHint1: ' 1. ผู้เชิญสามารถเชิญเพื่อนให้ลงทะเบียนให้เสร็จสิ้นได้โดยการแชร์ลิงก์โปรโมชัน และเพื่อนที่ได้รับเชิญจะเชื่อมโยงกับคุณ',
    ruleHint2: ' 2. หลังจากเชิญผู้ใช้ให้ชำระเงินให้เสร็จสิ้น คุณจะได้รับส่วนแบ่งกำไรของพวกเขา',
    ruleHint3: ' 3. รองรับเฉพาะรางวัลคำเชิญระดับที่สามเท่านั้น และอัตราส่วนรางวัลคือ: <%, >%, @%;'
  },
  "appInviteUserRecordIndex": {
    navTitle: 'เชิญบันทึกผู้ใช้',
    recordTitle1: 'จำนวนผู้ใช้ทั้งหมด',
    recordTitle2: 'ระดับ 1',
    recordTitle3: 'ระดับ 2',
    recordTitle4: 'ระดับ 3',
    tabLabel1: 'ทั้งหมด',
    tabLabel2: 'ระดับ 1',
    tabLabel3: 'ระดับที่สอง',
    tabLabel4: 'ระดับ 3',
    listLabel1: 'ระดับ:',
    listLabel2: 'กำไร:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: 'บันทึกรายได้คำเชิญ',
    recordTitle1: 'รายได้รวม',
    recordTitle2: 'ระดับ 1',
    recordTitle3: 'ระดับ 2',
    recordTitle4: 'ระดับ 3',
    tabLabel1: 'ทั้งหมด',
    tabLabel2: 'ระดับ 1',
    tabLabel3: 'ระดับที่สอง',
    tabLabel4: 'ระดับ 3',
    listLabel1: 'กำไร:',
    listLabel2: 'สัดส่วน:',
  },
  "appWithdrawIndex": {
    navTitle: 'การถอนเงิน',
    currencyType1: 'สกุลเงินคำสั่ง',
    currencyType2: 'สกุลเงินเสมือน',
    formLabel1: 'ประเภทสกุลเงิน:',
    formPlace1: 'โปรดเลือกประเภทสกุลเงิน',
    formLabel2: 'เลือกสกุลเงิน:',
    formPlace2: 'โปรดเลือกประเภทสกุลเงิน',
    formLabel3: 'จำนวนเงินที่ถอน:',
    formPlace3: 'กรุณากรอกจำนวนเงินที่ถอน',
    formLabel4: 'รหัสผ่านการถอน:',
    formPlace4: 'กรุณากรอกรหัสผ่านการถอน:',
    balanceLabel: 'ยอดคงเหลือปัจจุบัน:',
    amountLabel: 'มาถึงจริง:',
    optionsBtn: 'ส่ง',
    dialogClose: 'ยกเลิก',
    dialogAffirm: 'ยืนยัน',
    currencySelectError: 'โปรดเลือกประเภทสกุลเงิน',
    currencyError: 'โปรดเลือกสกุลเงิน',
    affirmDialogDialogTitle1: 'คุณยังไม่ได้ตั้งรหัสผ่านการชำระเงิน\' คุณต้องการตั้งค่าหรือไม่?',
    affirmDialogDialogTitle2: 'คุณยังไม่ได้ตั้งค่าช่องทางการถอน คุณต้องการตั้งค่าหรือไม่?',
    affirmDialogBtn2: 'ยกเลิก',
    affirmDialogBtn1: 'ยืนยัน',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'การตั้งค่าวิธีการถอนเงิน',
    currencyType1: 'สกุลเงินคำสั่ง',
    currencyType2: 'สกุลเงินเสมือน',
    formLabel1: 'ประเภทสกุลเงิน:',
    formPlace1: 'โปรดเลือกประเภทสกุลเงิน',
    formLabel2: 'เลือกสกุลเงิน:',
    formPlace2: 'โปรดเลือกประเภทสกุลเงิน',
    addressLabel: 'ที่อยู่:',
    addressPlace: 'กรุณากรอกที่อยู่สกุลเงินเสมือน',
    hintLabel: 'เคล็ดลับสำคัญ',
    hintValue: 'โอนสินทรัพย์ ¥ เท่านั้น',
    optionsBtn: 'ส่ง',
    dialogClose: 'ยกเลิก',
    dialogAffirm: 'ยืนยัน',
    currencySelectError: 'โปรดเลือกประเภทสกุลเงิน',
    currencyError: 'โปรดเลือกสกุลเงิน',
    currencyFail: 'ยังไม่เปิด',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'ชื่อจริง',
    formPlace1: 'กรุณากรอกชื่อจริงของคุณ',
    formLabel2: 'อีเมล',
    formPlace2: 'กรุณากรอกอีเมลของคุณ',
    formLabel3: 'หมายเลขโทรศัพท์มือถือ',
    formPlace3: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
    formLabel4: 'ประเภท PIX',
    formPlace4: 'โปรดเลือกประเภท PIX',
    formLabel5: 'บัญชี PIX',
    formPlace5: 'กรุณากรอกหมายเลขบัญชี PIX ของคุณ',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'ประเทศ/ภูมิภาค',
    formPlace1: 'กรุณาระบุประเทศ/ภูมิภาค',
    formLabel2: 'ชื่อ',
    formPlace2: 'กรุณากรอกชื่อ',
    formLabel3: 'นามสกุล',
    formPlace3: 'กรุณากรอกนามสกุลของคุณ',
    formLabel4: 'รวดเร็ว',
    formPlace4: 'กรุณากรอกอย่างรวดเร็ว',
    formLabel5: 'ชื่อธนาคาร',
    formPlace5: 'กรุณากรอกชื่อธนาคาร',
    formLabel6: 'iban',
    formPlace6: 'กรุณาระบุ iban',
    formLabel7: 'หมายเลขโทรศัพท์มือถือ',
    formPlace7: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'ประเทศ/ภูมิภาค',
    formPlace1: 'กรุณาระบุประเทศ/ภูมิภาค',
    formLabel2: 'ชื่อ',
    formPlace2: 'กรุณากรอกชื่อ',
    formLabel3: 'นามสกุล',
    formPlace3: 'กรุณากรอกนามสกุลของคุณ',
    formLabel4: 'รวดเร็ว',
    formPlace4: 'กรุณากรอกอย่างรวดเร็ว',
    formLabel5: 'ach',
    formPlace5: 'กรุณาระบุ ach',
    formLabel6: 'ชื่อธนาคาร',
    formPlace6: 'กรุณากรอกชื่อธนาคาร',
    formLabel7: 'บัญชีธนาคาร',
    formPlace7: 'กรุณากรอกบัญชีธนาคาร',
    formLabel8: 'จังหวัดหรือรัฐ',
    formPlace8: 'กรุณาระบุจังหวัดหรือรัฐ',
    formLabel9: 'หมายเลขโทรศัพท์มือถือ',
    formPlace9: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'ประเทศ/ภูมิภาค',
    formPlace1: 'กรุณาระบุประเทศ/ภูมิภาค',
    formLabel2: 'ชื่อ',
    formPlace2: 'กรุณากรอกชื่อ',
    formLabel3: 'นามสกุล',
    formPlace3: 'กรุณากรอกนามสกุลของคุณ',
    formLabel4: 'รหัสธนาคาร',
    formPlace4: 'กรุณากรอกรหัสธนาคาร',
    formLabel5: 'ชื่อธนาคาร',
    formPlace5: 'กรุณากรอกชื่อธนาคาร',
    formLabel6: 'บัญชีธนาคาร',
    formPlace6: 'กรุณากรอกบัญชีธนาคาร',
    formLabel7: 'หมายเลขโทรศัพท์มือถือ',
    formPlace7: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'ประเทศ/ภูมิภาค',
    formPlace1: 'กรุณาระบุประเทศ/ภูมิภาค',
    formLabel2: 'ชื่อ',
    formPlace2: 'กรุณากรอกชื่อ',
    formLabel3: 'นามสกุล',
    formPlace3: 'กรุณากรอกนามสกุลของคุณ',
    formLabel4: 'เซงจิ้น',
    formPlace4: 'กรุณาระบุ zengin',
    formLabel5: 'ชื่อธนาคาร',
    formPlace5: 'กรุณากรอกชื่อธนาคาร',
    formLabel6: 'บัญชีธนาคาร',
    formPlace6: 'กรุณากรอกบัญชีธนาคาร',
    formLabel7: 'จังหวัดหรือรัฐ',
    formPlace7: 'กรุณาระบุจังหวัดหรือรัฐ',
    formLabel8: 'หมายเลขโทรศัพท์มือถือ',
    formPlace8: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'ประเทศ/ภูมิภาค',
    formPlace1: 'กรุณาระบุประเทศ/ภูมิภาค',
    formLabel2: 'ชื่อ',
    formPlace2: 'กรุณากรอกชื่อ',
    formLabel3: 'นามสกุล',
    formPlace3: 'กรุณากรอกนามสกุลของคุณ',
    formLabel4: 'BSB',
    formPlace4: 'กรุณาระบุ BSB',
    formLabel5: 'ชื่อธนาคาร',
    formPlace5: 'กรุณากรอกชื่อธนาคาร',
    formLabel6: 'บัญชีธนาคาร',
    formPlace6: 'กรุณากรอกบัญชีธนาคาร',
    formLabel7: 'จังหวัดหรือรัฐ',
    formPlace7: 'กรุณาระบุจังหวัดหรือรัฐ',
    formLabel8: 'หมายเลขโทรศัพท์มือถือ',
    formPlace8: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'ชื่อ',
    formPlace1: 'กรุณากรอกชื่อ',
    formLabel2: 'นามสกุล',
    formPlace2: 'กรุณากรอกนามสกุลของคุณ',
    formLabel3: 'ชื่อธนาคาร',
    formPlace3: 'กรุณากรอกชื่อธนาคาร',
    formLabel4: 'บัญชีธนาคาร',
    formPlace4: 'กรุณากรอกบัญชีธนาคาร',
    formLabel5: 'หมายเลขโทรศัพท์มือถือ',
    formPlace5: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'ชื่อธนาคาร',
    formPlace1: 'กรุณากรอกชื่อธนาคาร',
    formLabel2: 'บัญชี',
    formPlace2: 'กรุณาระบุบัญชีของคุณ',
    formLabel3: 'บัญชี clabe',
    formPlace3: 'กรุณากรอกบัญชี clabe ของคุณ',
    formLabel4: 'หมายเลขโทรศัพท์มือถือ',
    formPlace4: 'กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ',
  },
  'appStatisticsReportIndex': {
    navTitle: 'รายงานการดำเนินงาน',
    cardLabel1: 'ยอดขายรวม',
    cardLabel2: 'ยอดซื้อทั้งหมด',
    cardLabel3: 'กำไรทั้งหมด',
    cardLabel4: 'จำนวนคำสั่งซื้อทั้งหมด',
    listLabel1: 'เวลา:',
    listLabel2: 'ลำดับ:',
    listLabel3: 'ยอดขาย:',
    listLabel4: 'จำนวนการซื้อ:',
    profitLabel: 'กำไร',
    searchDateTitle: 'เวลาเอกสาร',
    searchIntervalTitle: 'ช่วงเวลา',
    searchStartTimePlace: 'เวลาเริ่มต้น',
    searchEndTimePlace: 'เวลาสิ้นสุด',
    searchOptions1: 'รีเซ็ต',
    searchOptions2: 'ยืนยัน',
    searchDateAffirm: 'ยืนยัน',
    searchDateClose: 'ยกเลิก',
    searchIntervalLabel1: 'ทั้งหมด',
    searchIntervalLabel2: 'วันนี้',
    searchIntervalLabel3: 'เมื่อวาน',
    searchIntervalLabel4: 'สัปดาห์นี้',
    searchIntervalLabel5: 'เดือนนี้',
    searchIntervalLabel6: 'ปีนี้',
    searchIntervalOptions: 'ยกเลิก',
  },
  "appAmountRechargeIndex": {
    navTitle: 'มูลค่าที่เก็บไว้',
    currencyType1: 'สกุลเงินคำสั่ง',
    currencyType2: 'สกุลเงินเสมือน',
    formLabel1: 'ประเภทสกุลเงิน:',
    formPlace1: 'โปรดเลือกประเภทสกุลเงิน',
    formLabel2: 'เลือกสกุลเงิน:',
    formPlace2: 'โปรดเลือกประเภทสกุลเงิน',
    formLabel3: 'จำนวนมูลค่าที่เก็บไว้:',
    formPlace3: 'กรุณากรอกจำนวนมูลค่าที่เก็บไว้',
    formLabel4: 'วิธีการเติมเงิน',
    formPlace4: 'โปรดเลือกวิธีการจัดเก็บ:',
    balanceLabel: 'ยอดคงเหลือปัจจุบัน:',
    amountLabel: 'มาถึงจริง:',
    optionsBtn: 'ส่ง',
    dialogClose: 'ยกเลิก',
    dialogAffirm: 'ยืนยัน',
    currencySelectError: 'โปรดเลือกประเภทสกุลเงิน',
    currencyError: 'โปรดเลือกสกุลเงิน',
    successCopy: 'คัดลอกสำเร็จ',
    hintLabel: "คำเตือนที่สำคัญ:",
    hintValue: 'อนุญาตให้โอนสินทรัพย์ ¥ เท่านั้น',
    legalHint: 'กรุณาติดต่อฝ่ายบริการลูกค้า!'
  },
  'appShopInfoIndex': {
    navTitle: 'ข้อมูลร้านค้า',
    navEditText: 'แก้ไข',
    formLabel1: 'จัดเก็บรูปภาพ',
    formLabel2: 'ชื่อร้านค้า',
    formLabel3: 'อีเมลสำหรับเข้าสู่ระบบ',
    formLabel4: 'รหัสคำเชิญ',
    formLabel5: 'รหัสผ่านเข้าสู่ระบบ',
    formLabel6: 'รหัสผ่านการชำระเงิน',
    formLabel7: 'ข้อความคำอธิบาย',
    formLabel8: 'บันทึกภาพพื้นหลัง',
    formLabel9: 'ภาพพื้นหลังของตลาด',
    formLabel10: 'ข้อมูลใบรับรอง',
    formHint1: 'ด้านหน้าเอกสาร',
    formHint2: 'ด้านหลังของใบรับรอง',
    fileFail: 'กรุณาอัพโหลดรูปภาพและรูปแบบ',
    optionsBtn: 'ส่ง',
  },
  'appShopInfoEdit': {
    navTitle: 'Редактор информации о магазине',
    formLabel1: 'จัดเก็บรูปภาพ',
    formLabel2: 'ชื่อร้านค้า',
    formLabel3: 'อีเมลสำหรับเข้าสู่ระบบ',
    formLabel4: 'รหัสคำเชิญ',
    formLabel7: 'ข้อความคำอธิบาย',
    formLabel8: 'บันทึกภาพพื้นหลัง',
    formLabel9: 'ภาพพื้นหลังของตลาด',
    fileFail: 'กรุณาอัพโหลดรูปภาพและรูปแบบ',
    optionsBtn: 'ส่ง',
  },
  'appChangeLoginPass': {
    navTitle: 'เปลี่ยนรหัสผ่านเข้าสู่ระบบ',
    formLabel1: 'รหัสผ่านเก่า',
    formLabel2: 'รหัสผ่านใหม่',
    formLabel3: 'รหัสยืนยัน',
    formHint1: 'กรุณากรอกรหัสผ่านเก่า',
    formHint2: 'กรุณากรอกรหัสผ่านใหม่',
    formHint3: 'กรุณากรอกรหัสยืนยัน',
    formOption: 'ส่ง',
    sendOption: 'นับถอยหลัง:',
    optionsBtn: 'ยืนยัน',
  },
  'appChangePayPass': {
    navTitle: 'เปลี่ยนรหัสผ่านการชำระเงิน',
    formLabel1: 'รหัสผ่านการชำระเงิน',
    formLabel2: 'รหัสยืนยัน',
    formHint1: 'กรุณากรอกรหัสผ่านการชำระเงิน',
    formHint2: 'กรุณากรอกรหัสยืนยัน',
    formOption: 'ส่ง',
    sendOption: 'นับถอยหลัง:',
    optionsBtn: 'ยืนยัน',
  },
  "appFundingRecordsIndex": {
    navTitle: 'บันทึกกองทุน',
    searchRecordsTitle: 'ประเภทกองทุน',
    searchDateTitle: 'การเลือกเวลา',
    searchFundingLabel1: 'เงินทุนทั้งหมด',
    searchFundingLabel2: 'ประเภทเงินฝาก',
    searchFundingLabel3: 'บันทึกการถอนเงิน',
    searchOptions1: 'รีเซ็ต',
    searchOptions2: 'ยืนยัน',
    searchDateAffirm: 'ยืนยัน',
    searchDateClose: 'ยกเลิก',
    stareTimePlace: 'เวลาเริ่มต้น',
    endTimePlace: 'เวลาสิ้นสุด',
    typeAll: 'ทั้งหมด',
  },
  "appProjectIndex": {
    navTitle: 'รายการผลิตภัณฑ์',
    searchPlace: 'กรุณาระบุชื่อผลิตภัณฑ์ที่คุณต้องการค้นหา',
    shelvesLabel: 'ชั้นวางบนและล่าง',
    brandPlace: 'โปรดเลือกแบรนด์',
    classPlace: 'กรุณาเลือกหมวดหมู่',
    searchOptions1: "รีเซ็ต",
    searchOptions2: 'ยืนยัน',
    shelvesTag1: "ทั้งหมด",
    shelvesTag2: "บนชั้นวาง",
    shelvesTag3: "ปิดชั้นวาง",
    salesLabel: 'ยอดขาย:',
    dialogAffirm: 'ยืนยัน',
    dialogClose: 'ยกเลิก',
    listLabel: 'ชั้นวางขึ้นและลง:',
  },
  "appProjectLibraryIndex": {
    navTitle: "คลังผลิตภัณฑ์",
    searchPlace: 'กรุณาระบุชื่อผลิตภัณฑ์ที่คุณต้องการค้นหา',
    brandPlace: 'โปรดเลือกแบรนด์',
    classPlace: 'กรุณาเลือกหมวดหมู่',
    searchOptions1: "รีเซ็ต",
    searchOptions2: 'ยืนยัน',
    salesLabel: 'ยอดขาย:',
    dialogAffirm: 'ยืนยัน',
    dialogClose: 'ยกเลิก',
    productPackageLabel1: 'แพ็คสินค้า',
    productPackageLabel2: '(เพิ่มผลิตภัณฑ์อย่างรวดเร็ว)',

    selectAll: 'หลายตัวเลือก',
    selectHint: 'รองรับสินค้าสูงสุด 50 รายการเท่านั้น',
    submitOptions: 'ส่ง',
    dialogHint: 'แน่ใจหรือไม่ว่าผลิตภัณฑ์จะถูกวางบนชั้นวางจำนวนมาก?',

  },
  'appProductPackageIndex': {
    navTitle: 'แพ็คสินค้า',
    listLabel1: 'ดาวแนะนำ:',
    listLabel2: 'สถานะการซิงโครไนซ์:',
    listLabel3: 'ปริมาณผลิตภัณฑ์: ',
    optionsBtn: 'ซิงค์',
    dialogHint: 'ยืนยันการใช้แพ็คเกจผลิตภัณฑ์นี้หรือไม่',
    dialogOptions1: 'ยกเลิก',
    dialogOptions2: 'ยืนยัน',
    messageToast: 'ส่งเรียบร้อยแล้ว, ซิงค์ข้อมูลผลิตภัณฑ์...',
  },
  'appProductPackageList': {
    navTitle: 'รายละเอียดแพ็คเกจผลิตภัณฑ์',
    salesLabel: 'ฝ่ายขาย:',
  },
  "appProductFirstIndex": {
    navTitle: 'ข้อมูลผลิตภัณฑ์',
    nextStep: 'ขั้นตอนถัดไป',
    label1: 'ชื่อผลิตภัณฑ์:',
    label2: 'แบรนด์สินค้า:',
    label3: 'หมวดหมู่ผลิตภัณฑ์:',
    label4: 'ราคาซื้อขั้นต่ำ:',
    label5: 'ราคาขายขั้นต่ำ:',
    label6: 'ราคาตลาดขั้นต่ำ:',
    label7: 'ภาพหน้าปก:',
    label8: 'รูปภาพสินค้า:',

  },
  'appProductNextStepIndex': {
    navTitle: 'ข้อมูลจำเพาะของผลิตภัณฑ์',
    previous: 'ขั้นตอนก่อนหน้า',
    navBtn: 'ส่ง',
    label1: 'ราคาซื้อ:',
    placeHint: 'กรุณากรอก',
    label2: 'ราคาขาย:',
    label3: 'ราคาตลาด:',
    specLabel: 'แอตทริบิวต์ข้อมูลจำเพาะ:',
    optionBtn: "ยืนยัน",
    validateMessage1: 'ราคาเดิมต้องไม่ต่ำกว่าราคาซื้อ',
    validateMessage2: 'ราคาเดิมต้องไม่ต่ำกว่าราคาขายปัจจุบัน',
    validateMessage3: 'ราคาขายต้องไม่ต่ำกว่าราคาซื้อ',
    validateMessage4: 'ราคาขายต้องไม่สูงกว่าราคาเดิม',
    shelvesTitle: 'คุณต้องการโหลดหรือยกเลิกการโหลดชั้นวาง?',
    shelvesOptions1: 'ไม่',
    shelvesOptions2: 'ใช่'
  },
  "appOrderIndex": {
    tagsLabel1: 'ทั้งหมด',
    orderTotalNumber: 'หมายเลขคำสั่งซื้อทั้งหมด',
    listLabel1: 'วันที่สั่งซื้อ:',
    listLabel2: 'ยอดขายรวม:',
    listLabel3: 'ปริมาณผลิตภัณฑ์:',
    listLabel4: 'สถานะคำสั่งซื้อ:',
    listLabel5: 'สถานะการชำระเงิน:',
    listHint: 'ซื้อ',
    searchOptionBtn: 'ค้นหา',
    searchPlace: 'กรุณากรอกเนื้อหาการค้นหา',
    searchDateStartPlace: 'เวลาเริ่มต้น',
    searchDateEndPlace: 'เวลาสิ้นสุด',
    searchDateAffirm: 'ยืนยัน',
    searchDateClose: 'ยกเลิก',
    searchTypePayTitle: 'สถานะการชำระเงิน',
    payTags1: 'ทั้งหมด',
    payTags2: 'รอการชำระเงิน',
    payTags3: 'จ่ายแล้ว',
    searchTypeOrderTitle: 'สถานะคำสั่งซื้อ',
    searchOptions1: 'รีเซ็ต',
    searchOptions2: 'ยืนยัน',
    purDialogTitle: 'ซื้อทั้งหมด',
    purDialogLabel1: 'จำนวนการซื้อ:',
    purDialogLabel2: 'ยอดขาย:',
    purDialogLabel3: 'จำนวนกำไร:',
    purOptions: "ยืนยัน",
    purRowTitle: 'การซื้อ',
    purRowLabel1: 'จำนวนการซื้อ:',
    purRowLabel2: 'ยอดขาย:',
    purRowLabel3: 'จำนวนกำไร:',
  },
  "appOrderDetailIndex": {
    navTitle: 'รายละเอียดการสั่งซื้อ',
    detailInfoLabel1: 'หมายเลขคำสั่งซื้อ:',
    detailInfoLabel2: 'เวลาสั่งซื้อ:',
    detailInfoLabel3: 'วิธีการชำระเงิน:',
    detailInfoLabel4: 'สถานะคำสั่งซื้อ:',
    detailInfoLabel5: 'สถานะการชำระเงิน:',
    productLabel1: 'ข้อมูลผลิตภัณฑ์',
    productAttributesLabel: 'คุณลักษณะของผลิตภัณฑ์:',
    productAccountLabel1: 'ยอดขาย:',
    productAccountLabel2: 'จำนวนการซื้อ:',
    countInfoLabel1: 'ยอดขายรวม:',
    countInfoLabel2: 'ยอดซื้อทั้งหมด:',
    countInfoLabel3: 'กำไรทั้งหมด:',
    countInfoLabel4: 'ปริมาณทั้งหมด:',
    countInfoLabel5: 'อัตราส่วนส่วนลด:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'สั่งซื้อโลจิสติกส์',
    logisticsLabel: 'สั่งซื้อ',
    listLabel1: "ชื่อ:",
    listLabel2: "โทรศัพท์:",
    listLabel3: "รหัสไปรษณีย์:",
    listLabel4: "ที่อยู่:",
  },
  "appOrderDetailRecord": {
    navTitle: 'บันทึกการสั่งซื้อ',
    logisticsLabel: 'สั่งซื้อ',
    recordEnumLabel1: 'ลูกค้าสร้างคำสั่งซื้อ',
    recordEnumLabel2: 'ใบสั่งการชำระเงินของลูกค้า',
    recordEnumLabel3: 'เก็บใบสั่งซื้อ',
    recordEnumLabel4: 'คำสั่งซื้อเริ่มจัดส่ง',
    recordEnumLabel5: 'ผู้ใช้ยืนยันการรับ',
    recordEnumLabel6: 'การชำระคืนของระบบมาถึง',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'อัปเกรดเวอร์ชัน โปรดยืนยันหรือไม่?',
    loadingTitle: 'กำลังดาวน์โหลด...',
    systemUpgrade: 'อัปเกรดระบบ',
    upgradeImmediately: 'ยืนยัน'
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "กำลังโหลด",
    finishedText: "ไม่มี",
    loadingText: "กำลังโหลด",
    nullText: 'ยังไม่มีข้อมูล',
    loadingFinished: "กำลังโหลดเสร็จสมบูรณ์",
    listNullText: 'ไม่มีอีกแล้ว',
    pullIngText: 'ดึงลงเพื่อรีเฟรช',
    loosingText: 'ปล่อยเพื่อรีเฟรช',
    pullingSuccess: 'การโหลดแบบดึงลงสำเร็จ',
    webSocketSuccess: 'คุณมีข้อความใหม่',
    imLinkSuccess: 'การเชื่อมต่อบริการ IM สำเร็จ!',
    imNonsupport: 'เบราว์เซอร์นี้ไม่รองรับ websocket',
    imLinkLoading: 'IM กำลังเชื่อมโยง โปรดรอสักครู่...',
    imLinkErrorLoading: 'การเชื่อมต่อ IM ล้มเหลว โปรดรีเฟรชหน้าหรือติดต่อผู้พัฒนา!',
    dialogHint: 'คุณมีหมายเลขบัญชีแล้วหรือยัง',
    dialogClose: 'ลงทะเบียน',
    dialogAffirm: 'เข้าสู่ระบบ',
  },
};
export default obj;
