<template>
  <div class="downloadApp-warper" id="downloadApp">
    <div class="page-header">
      <navTab :navTitle="language.navTitle" class="service-header"></navTab>
    </div>
    <div class="setting_content">
      <div class="content_itemBoxWarper">
        <div
          class="content_item"
          @click="getChangeRouter('downloadAndroidApp')"
        >
          <span>{{ language.downloadAndroidApp }}</span>
          <van-icon name="arrow" />
        </div>
      </div>

      <div class="content_itemBoxWarper">
        <div class="content_item" @click="getChangeRouter('downloadIosApp')">
          <span>{{ language.downloadIosApp }}</span>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultApi from "@/utils/projectConfig";
import navTab from "@/layout/navTab.vue";
import downloadAppApi from "@/api/downloadApp.js";
export default {
  name: "downloadAppIndex",
  data() {
    return {
      language: this.$language(),
      URL: defaultApi,
    };
  },

  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("downloadApp").style["height"] = seeHeight + "px";
    });
  },
  mounted() {},
  methods: {
    async getChangeRouter(key) {
      const { data, code } = await downloadAppApi.downloadApi();
      if (code == 200) {
        if (key == "downloadAndroidApp") {
          window.location.href = data.android;
        }
        if (key == "downloadIosApp") {
          window.location.href = data.ios;
        }
      }
    },
  },
  components: {
    navTab,
  },
};
</script>
<style lang="scss" scoped>
.downloadApp-warper {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;

  .setting_content {
    width: 100%;
    height: calc(100vh - 98px);

    .content_itemBoxWarper {
      width: 100%;
      background: #fff;

      .content_item {
        width: 100%;
        padding: 0 28px;
        height: 103px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed #dfdede;
        font-size: 28px;

        div:nth-child(1) {
          width: 30%;
        }

        .rightIcon {
          width: 14px;
          height: 24px;
          margin-left: 15px;
        }
      }

      .management_item {
        border-bottom: 0;
      }
    }
  }
}
</style>
<style lang="scss"></style>
