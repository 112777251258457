import service from '../required/axios'
export default {
  // 登陆
  toPathLogin_interface (data) {
    return service({
      url: 'auth.login',
      method: "post",
      data: data,
    })
  },

  // 邮箱发送验证吗
  sendEmailCode_interface (data) {
    return service({
      url: 'common.send_email_verify_code',
      method: "POST",
      data: data
    })
  },
  forgotPass_interface (data) {
    return service({
      url: 'auth.forgot_password',
      method: "POST",
      data: data
    })
  },
}