<template>
  <div class="shop-info-warper" id="shopInfo">
    <div class="page-header">
      <nav-tab>
        <template #navRight>
          <span @click="$router.push({ path: '/appShopInfoEdit' })">
            {{ language.navEditText }}
          </span>
        </template>
      </nav-tab>
    </div>
    <div class="shop-info-body">
      <div class="body-list-boxers">
        <div class="list-item flexbox">
          <div class="item-left">{{ language.formLabel1 }}</div>
          <div class="item-right flexbox">
            <div class="imgFlex phopo-images">
              <img v-lazy="getImgUrl(shopInfo.image)" />
            </div>
          </div>
        </div>
        <div class="list-item flexbox">
          <div class="item-left">{{ language.formLabel2 }}</div>
          <div class="item-right flexbox">
            <van-field v-if="editIsShow" v-model="shopInfo.name"></van-field>
            <span v-else class="text2"> {{ shopInfo.name }}</span>
          </div>
        </div>
        <div class="list-item flexbox">
          <div class="item-left">{{ language.formLabel7 }}</div>
          <div class="item-right flexbox">
            <van-field
              v-if="editIsShow"
              v-model="shopInfo.introduce"
            ></van-field>
            <span v-else class="text2"> {{ shopInfo.introduce }}</span>
          </div>
        </div>
        <div class="list-item flexbox">
          <div class="item-left">{{ language.formLabel3 }}</div>
          <div class="item-right flexbox">
            <van-field v-if="editIsShow" v-model="shopInfo.account"></van-field>
            <span v-else> {{ shopInfo.account }}</span>
          </div>
        </div>
        <div class="list-item flexbox">
          <div class="item-left">{{ language.formLabel4 }}</div>
          <div class="item-right flexbox">
            <span>{{ shopInfo.inviteCode }}</span>
          </div>
        </div>
        <div
          class="list-item flexbox"
          @click="$router.push({ path: '/appChangeLoginPass' })"
        >
          <div class="item-left">{{ language.formLabel5 }}</div>
          <div class="item-right flexbox">
            <svg-icon icon-class="app-arrow"></svg-icon>
          </div>
        </div>
        <div
          class="list-item flexbox"
          @click="$router.push({ path: '/appChangePayPass' })"
        >
          <div class="item-left">{{ language.formLabel6 }}</div>
          <div class="item-right flexbox">
            <svg-icon icon-class="app-arrow"></svg-icon>
          </div>
        </div>
        <div class="list-item-certificate">
          <div class="item-label">
            <span>{{ language.formLabel8 }}</span>
          </div>
          <div class="certificate-img-box flexbox">
            <div class="image-item imgFlex">
              <van-uploader
                :after-read="marketUpload"
                :before-read="beforeRead"
                :disabled="!editIsShow"
                class="upload-images"
              >
                <div class="images imgFlex">
                  <img v-lazy="getImgUrl(shopInfo.marketImage)" />
                </div>
                <!-- <van-icon name="plus" v-else /> -->
              </van-uploader>
            </div>
          </div>
        </div>
        <div class="list-item-certificate">
          <div class="item-label">
            <span>{{ language.formLabel9 }}</span>
          </div>
          <div class="certificate-img-box flexbox">
            <div class="image-item imgFlex">
              <van-uploader
                :after-read="backgroundUpload"
                :before-read="beforeRead"
                :disabled="!editIsShow"
                class="upload-images"
              >
                <div class="images imgFlex">
                  <img v-lazy="getImgUrl(shopInfo.backgroundImage)" />
                </div>
              </van-uploader>
            </div>
          </div>
        </div>
        <div class="list-item-certificate">
          <div class="item-label">
            <span>{{ language.formLabel10 }}</span>
          </div>
          <div class="certificate-img-box flexbox">
            <div class="image-item imgFlex">
              <!-- <van-uploader
                :after-read="certificateUpload"
                :before-read="beforeRead"
                class="upload-images"
              >
                <div class="images imgFlex" v-if="shopInfo.certificateLeft">
                  <img v-lazy="getImgUrl(shopInfo.certificateLeft)" />
                </div>
                <van-icon name="plus" v-else />
              </van-uploader> -->
              <img v-lazy="getImgUrl(shopInfo.certFrontImage)" />
            </div>
            <div class="image-item imgFlex">
              <img v-lazy="getImgUrl(shopInfo.certReverseImage)" />
              <!-- <van-uploader
                :after-read="certificateUpload"
                :before-read="beforeRead"
                class="upload-images"
              >
                <div class="images imgFlex" v-if="shopInfo.certificateRight">
                  <img v-lazy="getImgUrl(shopInfo.certificateRight)" />
                </div>
                <van-icon name="plus" v-else />
              </van-uploader> -->
            </div>
          </div>
        </div>
        <div class="list-item-options" v-if="editIsShow">
          <van-button @click="onSubmit">{{ language.optionsBtn }}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import userApi from "@/api/userApi.js";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "appShopInfoIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      imgUrl: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      lazyImg: require("../../../assets/shopIcon.png"),
      shopImg: "",
      certificateLeft: "",
      certificateRight: "",
      shopInfo: {},
      editIsShow: false,
    };
  },
  components: {
    navTab,
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("shopInfo").style["height"] = seeHeight + "px";
    });
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getShopInfo();
    },
    async onSubmit() {
      let params = this.shopInfo;
      const { code, message } = await userApi.setStore_info(params);
      if (code === 200) {
        this.editIsShow = false;
        this.$toast.success(message);
      }
    },
    logoUpload(file) {
      this.uploadEnclosure(file, "logoUpload");
    },
    marketUpload(file) {
      this.uploadEnclosure(file, "marketUpload");
    },
    backgroundUpload(file) {
      this.uploadEnclosure(file, "backgroundUpload");
    },
    certificateUpload() {
      return false;
    },
    // 上传图片
    async uploadEnclosure(file, key) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("type", "shopImage");
      userApi.importUpload(formData).then(({ code, data }) => {
        if (code == 200) {
          if (key === "logoUpload") {
            this.shopInfo.image = data.url;
          }
          if (key === "marketUpload") {
            this.shopInfo.marketImage = data.url;
          }
          if (key === "backgroundUpload") {
            this.shopInfo.backgroundImage = data.url;
          }
        }
      });
    },
    beforeRead(file) {
      let title;
      const typeEnum = [
        "image/jpg",
        "image/JPG",
        "image/png",
        "image/PNG",
        "image/JPEG",
        "image/jpeg",
      ];
      let isShow = false;
      if (!typeEnum.includes(file.type)) {
        isShow = true;
      }
      if (isShow) {
        title = this.language.fileFail.replace(/&/g, "jpg,png,jpeg");
        this.$toast({
          message: title,
          type: "fail",
          duration: 1800,
        });
        return false;
      } else {
        return true;
      }
    },
    async getShopInfo() {
      const params = {
        cert: true,
      };
      const { code, data } = await userApi.getShopInfoPort(params);
      if (code == 200) {
        this.shopInfo = data;
        this.shopImg = data.image;
        this.certificateLeft = data.certFrontImage;
        this.certificateRight = data.certReverseImage;
      }
    },
    getImgUrl(url) {
      if (url) {
        return this.imgUrl + url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-info-warper {
  width: 100%;
  height: 100vh;
  .page-header {
    ::v-deep .navTab-right {
      font-size: 26px;
      color: $blur;
    }
  }
  .shop-info-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;

    .body-list-boxers {
      background-color: #fff;
      .list-item {
        padding: 20px 32px;
        justify-content: space-between;
        border-bottom: 1px solid #eff2f6;
        background-color: #fff;

        .item-left {
          max-width: 55%;
          font-size: 30px;
          line-height: 1.5;
          color: #000;
          font-weight: 400;
          display: inline-block;
          white-space: wrap;
          word-break: break-all;
        }

        .item-right {
          max-width: 65%;
          font-size: 24px;
          color: #969799;
          ::v-deep .van-cell {
            border-radius: 8px;
            border: 1px solid #e2e1e1;
            color: #969799;
            padding: 10px 20px;
            .van-cell__value {
              .van-field__body {
                .van-field__control {
                  color: #969799 !important;
                  text-align: right;
                }
              }
            }
          }
          .phopo-images {
            width: 120px;
            height: 120px; 
            border-radius: 50%;
            background-color: #fff;
          }
          

          svg {
            width: 32px !important;
            height: 32px !important;
            color: #969799;
            margin-left: 6px;
          }
        }
      }

      .list-item:last-child {
        border: none;
      }

      .list-item-certificate {
        padding: 0px 32px 20px 32px;
        background-color: #fff;

        .item-label {
          padding: 20px 0;
          border-bottom: 1px solid #eff2f6;
          font-size: 30px;
          line-height: 1.5;
          color: #000;
          font-weight: 400;
          // opacity: 0.9;
        }

        .certificate-img-box {
          margin-top: 30px;
          .image-item {
            margin-right: 24px;
          }

          .upload-images {
            width: 240px;
            height: 180px;
            background-color: #fff;

            ::v-deep .van-uploader__wrapper {
              width: 100%;
              height: 100%;
              .van-uploader__input-wrapper {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                i {
                  font-size: 66px;
                  font-weight: 500;
                }
                .images {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          .image-item {
            width: 240px;
            height: 180px;
            border-radius: 6px;
            margin-right: 20px;
            background-color: #fff;
          }
        }
      }
      .list-item-options {
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        ::v-deep .van-button {
          width: 80%;
          font-size: 26px;
          color: #fff;
          background-color: $blur;
          border-radius: 8px;
          padding: 15px 32px;
        }
      }
    }
  }
  .upload-images {
    ::v-deep .van-uploader__wrapper--disabled {
      opacity: 1 !important;
    }
  }
}
</style>
