import Vue from 'vue'
import {
  PullRefresh,
  IndexBar,
  IndexAnchor,
  Stepper,
  Uploader,
  Sticky,
  Grid,
  GridItem,
  Swipe,
  Button,
  Icon,
  Field,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Cell,
  CellGroup,
  Calendar,
  DatetimePicker,
  Popup,
  Form,
  Checkbox,
  CheckboxGroup,
  Toast,
  List,
  Loading,
  DropdownMenu,
  DropdownItem,
  Search,
  SwipeCell,
  Card,
  Sidebar,
  SidebarItem,
  Image,
  Rate,
  Tag,
  Picker,
  Switch
} from "vant";
import 'vant/lib/index.css'
Vue.use(PullRefresh);
Vue.use(Switch);
Vue.use(Picker);
Vue.use(Tag);
Vue.use(Rate);
Vue.use(IndexBar);
Vue.use(Image);
Vue.use(IndexAnchor);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Toast);
Vue.use(Card);
Vue.use(SwipeCell);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Stepper);
Vue.use(Sticky);
Vue.use(DropdownMenu);
Vue.use(Uploader);
Vue.use(Search);
Vue.use(DropdownItem);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(List);
Vue.use(CheckboxGroup);
Vue.use(Cell);
Vue.use(Form);
Vue.use(CellGroup)
Vue.use(Calendar);
Vue.use(Field);
Vue.use(Button)
Vue.use(Field)
Vue.use(Icon);
Vue.use(GridItem);
Vue.use(Grid);
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(DatetimePicker)
Vue.use(Popup)


Vue.use(Checkbox);
Vue.use(CheckboxGroup);