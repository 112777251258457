var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-edit-warper"},[_c('van-popup',{style:({ height: '75%' }),attrs:{"position":"bottom","round":""},on:{"close":function($event){_vm.$emit('handlerClose', (_vm.editVisibleIsShow = false))},"click-overlay":function($event){_vm.$emit('handlerClose', (_vm.editVisibleIsShow = false))}},model:{value:(_vm.editVisibleIsShow),callback:function ($$v) {_vm.editVisibleIsShow=$$v},expression:"editVisibleIsShow"}},[_c('div',{staticClass:"edit-body"},[_c('div',{staticClass:"popup-edit-body"},[(_vm.productEditObj.skuImagesList)?_c('van-swipe',{ref:"swipeRefs",staticClass:"my-swipe",attrs:{"autoplay":5000},on:{"change":_vm.onChangeSwipe},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"custom-indicator-boxers flexbox"},[(_vm.current >= 1)?_c('div',{staticClass:"custom-indicator-left",on:{"click":function($event){return _vm.onChangeClickSwipe('left')}}},[_c('van-icon',{attrs:{"name":"arrow-left"}})],1):_vm._e(),(
                  _vm.productEditObj.skuImagesList.length &&
                  _vm.current + 1 !== _vm.productEditObj.skuImagesList.length
                )?_c('div',{staticClass:"custom-indicator-right",on:{"click":function($event){return _vm.onChangeClickSwipe('right')}}},[_c('van-icon',{attrs:{"name":"arrow"}})],1):_vm._e()])]},proxy:true}],null,false,3181684038)},_vm._l((_vm.productEditObj.skuImagesList),function(item,skuIndex){return _c('van-swipe-item',{key:skuIndex},[_c('div',{staticClass:"swipe-img imgFlex"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}]})])])}),1):_vm._e(),_c('div',{staticClass:"body-form-boxers"},[_c('div',{staticClass:"form-item-list flexbox"},[_c('div',{staticClass:"form-label"},[_c('span',[_vm._v(_vm._s(_vm.language.specLabel))])]),_c('div',{staticClass:"form-value"},_vm._l((_vm.productEditObj.attributes),function(item,index){return _c('div',{key:index,staticClass:"attributes-list flexbox"},[_c('van-tag',{staticClass:"tag-label",attrs:{"plain":"","type":"primary"}},[_vm._v(" "+_vm._s(item.productAttributesName)+" ")]),_c('span',{staticClass:"tag-value"},[_vm._v(" "+_vm._s(item.productAttributesValueName)+" ")])],1)}),0)]),_c('div',{staticClass:"form-item-list flexbox"},[_c('div',{staticClass:"form-label"},[_c('span',[_vm._v(_vm._s(_vm.language.label1))])]),_c('div',{staticClass:"form-value"},[_c('van-field',{attrs:{"disabled":"","placeholder":_vm.language.placeHint,"type":"number"},model:{value:(_vm.productEditObj.purchaseAmount),callback:function ($$v) {_vm.$set(_vm.productEditObj, "purchaseAmount", $$v)},expression:"productEditObj.purchaseAmount"}})],1)]),_c('div',{staticClass:"form-item-list flexbox"},[_c('div',{staticClass:"form-label"},[_c('span',[_vm._v(_vm._s(_vm.language.label2))])]),_c('div',{staticClass:"form-value"},[_c('van-field',{attrs:{"placeholder":_vm.language.placeHint,"type":"number"},on:{"blur":function($event){return _vm.onChangeAmount(
                    _vm.productEditObj.unitAmount,
                    _vm.productEditObj,
                    'unitAmount'
                  )}},model:{value:(_vm.productEditObj.unitAmount),callback:function ($$v) {_vm.$set(_vm.productEditObj, "unitAmount", $$v)},expression:"productEditObj.unitAmount"}})],1)]),_c('div',{staticClass:"form-item-list flexbox"},[_c('div',{staticClass:"form-label"},[_c('span',[_vm._v(_vm._s(_vm.language.label3))])]),_c('div',{staticClass:"form-value"},[_c('van-field',{attrs:{"placeholder":_vm.language.placeHint,"type":"number"},on:{"blur":function($event){return _vm.onChangeAmount(
                    _vm.productEditObj.originalAmount,
                    _vm.productEditObj,
                    'originalAmount'
                  )}},model:{value:(_vm.productEditObj.originalAmount),callback:function ($$v) {_vm.$set(_vm.productEditObj, "originalAmount", $$v)},expression:"productEditObj.originalAmount"}})],1)])])],1),_c('div',{staticClass:"popup-options"},[_c('van-button',{on:{"click":_vm.handlerSubmit}},[_vm._v(_vm._s(_vm.language.optionBtn))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }