<template>
  <div class="set-withdrawal-warper" id="withdrawalID">
    <div class="page-header">
      <nav-tab></nav-tab>
    </div>
    <div class="setWithdrawal-body">
      <div class="setWithdrawal-field-list">
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel1 }}</p>
          <van-field
            v-model="formObj.currencyType"
            :placeholder="language.formPlace1"
            readonly
            right-icon="arrow-down"
            @click="onClickLine('type')"
          />
        </div>
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel2 }}</p>
          <van-field
            v-model="formObj.currencyKindType"
            :placeholder="language.formPlace2"
            readonly
            right-icon="arrow-down"
            @click="onClickLine('kind')"
          />
        </div>
        <template v-if="addressIsShow()">
          <div class="field-list address-field">
            <p class="label flexbox">{{ language.addressLabel }}</p>
            <van-field
              v-model="formObj.address"
              :placeholder="language.addressPlace"
              type="textarea"
              autosize
              @input="onChangeAddress($event)"
            />
          </div>
          <div class="field-list hint-address" v-if="hintValue">
            <p class="label flexbox">{{ language.hintLabel }}</p>
            <div class="hintValue">{{ hintValue }}</div>
          </div>
        </template>
        <template v-if="formObj.currencyTypeCode == 1">
          <BRLCard
            v-if="formObj.currencyKindCode === 'BRL'"
            :fieldObj="formCurrencyObj"
            @onClickLine="onClickLine"
            @onChangeCard="onChangeCard"
          />
          <EURCard
            v-if="formObj.currencyKindCode === 'EUR'"
            :fieldObj="formCurrencyObj"
            @onChangeCard="onChangeCard"
          />
          <USDCard
            v-if="formObj.currencyKindCode === 'USD'"
            :fieldObj="formCurrencyObj"
            @onChangeCard="onChangeCard"
          />
          <GBPCard
            v-if="formObj.currencyKindCode === 'GBP'"
            :fieldObj="formCurrencyObj"
            @onChangeCard="onChangeCard"
          />
          <JPYCard
            v-if="formObj.currencyKindCode === 'JPY'"
            :fieldObj="formCurrencyObj"
            @onChangeCard="onChangeCard"
          />
          <AUDCard
            v-if="formObj.currencyKindCode === 'AUD'"
            :fieldObj="formCurrencyObj"
            @onChangeCard="onChangeCard"
          />
          <KRWCard
            v-if="formObj.currencyKindCode === 'KRW'"
            :fieldObj="formCurrencyObj"
            @onChangeCard="onChangeCard"
          />
          <MXNCard
            v-if="formObj.currencyKindCode === 'MXN'"
            :fieldObj="formCurrencyObj"
            @onChangeCard="onChangeCard"
          />
        </template>
      </div>
      <div class="setWithdrawal-options" v-if="optionIsShow()">
        <van-button @click="onChangeSUbmit">{{
          language.optionsBtn
        }}</van-button>
      </div>
    </div>
    <van-popup
      v-model="popupVisibleIsShow"
      position="bottom"
      class="currency-popup"
    >
      <div class="currency-popup-body">
        <van-picker
          show-toolbar
          :confirm-button-text="language.dialogAffirm"
          :cancel-button-text="language.dialogClose"
          :columns="popupDataList"
          @confirm="onConfirmPicker"
          @cancel="onCancelPicker"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import navTab from "@/layout/navTab";
import setWithdrawalApi from "@/api/setWithdrawalApi";
import {
  setWithdrawForm,
  BRLForm,
  EURForm,
  GBPForm,
  JPYForm,
  KRWForm,
  MXNForm,
  USDForm,
  AUDForm,
} from "./class";
import BRLCard from "./component/BRL-card.vue";
import EURCard from "./component/EUR-card.vue";
import USDCard from "./component/USD-card.vue";
import GBPCard from "./component/GBP-card.vue";
import JPYCard from "./component/JPY-card.vue";
import AUDCard from "./component/AUD-card.vue";
import KRWCard from "./component/KRW-card.vue";
import MXNCard from "./component/MXN-card.vue";
export default {
  name: "appSetWithdrawalIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      popupDataList: [],
      popupVisibleIsShow: false,
      lineType: "",
      currencyTypeList: [], //货币类型
      currencyFailList: [], //法定货币
      currencyVirtualList: [], //虚拟货币
      PIXDataList: [],
      formObj: new setWithdrawForm(),
      hintValue: "",
      addressReadonly: true,
      formCurrencyObj: {},
      bankDataList: [],
    };
  },
  created() { 
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("withdrawalID").style["height"] =
        seeHeight + "px";
    });
  },
  methods: {
    onChangeAddress(e) {
      this.formObj.address = e;
      this.$forceUpdate();
    },
    onClickLine(key) {
      this.lineType = key;
      if (key == "type") {
        this.onLoad();
        this.popupVisibleIsShow = true;
      }
      if (key == "kind") {
        if (!this.formObj.currencyType) {
          this.$toast({
            type: "fail",
            message: this.language.currencySelectError,
            duration: 1200,
          });
          return false;
        }
        if (this.formObj.currencyTypeCode === 1) {
          this.getCurrencyFiatList();
        }
        if (this.formObj.currencyTypeCode === 2) {
          this.getCurrencyVirtualList();
        }
      }
      if (key === "PIX") {
        this.getPIXDataList();
      }
    },
    onConfirmPicker(val, e) {
      if (this.lineType === "type") {
        this.hintValue = "";
        this.formObj = new setWithdrawForm();
        this.formObj.currencyType = this.popupDataList[e].text;
        this.formObj.currencyTypeCode = this.popupDataList[e].value;
        this.popupVisibleIsShow = false;
      }
      if (this.lineType === "kind") {
        if (this.formObj.currencyTypeCode === 1) {
          this.hintValue = "";
          this.formObj.currencyKindType = this.popupDataList[e].text;
          this.formObj.currencyKindCode = this.popupDataList[e].code;
          this.formObj.currencyKindTypeCode = this.popupDataList[e].value;
          let enumType = ["TRY", "PHP"];
          if (enumType.includes(this.formObj.currencyKindCode)) {
            this.$toast({
              type: "fail",
              message: this.language.currencyFail,
              duration: 1200,
            });
          } else {
            this.formObjCallBack();
            this.getByFiatForm();
          }
        }
        if (this.formObj.currencyTypeCode === 2) {
          this.formObj.address = null;
          let typeName = this.popupDataList[e].text;
          this.formObj.currencyKindType = this.popupDataList[e].text;
          this.formObj.currencyKindTypeCode = this.popupDataList[e].value;
          this.hintValue = this.language.hintValue.replace(/¥/, typeName);
          this.getAddressVirtualList();
        }
        this.popupVisibleIsShow = false;
      }
      if (this.lineType === "PIX") {
        this.formCurrencyObj.PIXValue = this.popupDataList[e].value;
        this.$set(this.formCurrencyObj, "pixType", this.popupDataList[e].value);
        this.popupVisibleIsShow = false;
      }
    },
    // 获取法定货币
    async getCurrencyFiatList() {
      if (this.currencyFailList.length) {
        this.popupDataList = this.currencyFailList;
        this.popupVisibleIsShow = true;
        return false;
      }
      const { code, data } = await setWithdrawalApi.getCurrencyFiatList();
      if (code == 200) {
        data.map((item) => {
          item.text = `${item.code}(${item.symbol})`;
          item.value = item.id;
        });
        this.currencyFailList = data;
        this.popupDataList = data;
        this.popupVisibleIsShow = true;
      }
    },
    getPIXDataList() {
      if (this.bankDataList.length) {
        this.popupDataList = this.bankDataList;
        this.popupVisibleIsShow = true;
        return false;
      }
      this.formCurrencyObj.bankList.map((item) => {
        item.text = item.name;
        item.value = item.id;
      });
      this.bankDataList = this.formCurrencyObj.bankList;
      this.popupDataList = this.formCurrencyObj.bankList;
      this.popupVisibleIsShow = true;
    },
    // 获取虚拟货币
    async getCurrencyVirtualList() {
      if (this.currencyVirtualList.length) {
        this.popupDataList = this.currencyVirtualList;
        this.popupVisibleIsShow = true;
        return false;
      }
      const { code, data } = await setWithdrawalApi.getCurrencyVirtualList();
      if (code == 200) {
        data.map((item) => {
          item.text = item.code;
          item.value = item.id;
        });
        this.currencyVirtualList = data;
        this.popupDataList = data;
        this.popupVisibleIsShow = true;
      }
    },
    // 获取法定货币列表数据
    async getByFiatForm() {
      const params = {
        fiatId: this.formObj.currencyKindTypeCode,
      };
      const { code, data } = await setWithdrawalApi.getByFiat(params);
      if (code === 200) {
        this.formCurrencyObj = data;
      }
    },
    // 获取虚拟货币地址数据
    async getAddressVirtualList() {
      console.log(this.formObj, "this.formObj");
      const params = {
        chainId: this.formObj.currencyKindTypeCode,
      };
      const { code, data } = await setWithdrawalApi.getCurrencyVirtual(params);
      if (code == 200) {
        this.formObj.address = data.address;
        this.$forceUpdate();
      }
    },
    onCancelPicker() {
      this.lineType = null;
      this.popupVisibleIsShow = false;
    },
    onChangeCard(obj) {
      this.formCurrencyObj = obj;
    },
    // 提交setCurrencyVirtual
    onChangeSUbmit() {
      if (this.formObj.currencyTypeCode == 1) {
        this.submitFail();
      }
      if (this.formObj.currencyTypeCode == 2) {
        this.submitVirtual();
      }
    },
    async submitVirtual() {
      const params = {
        chainId: this.formObj.currencyKindTypeCode,
        address: this.formObj.address,
      };
      const { code, message } = await setWithdrawalApi.setCurrencyVirtual(
        params
      );
      if (code == 200) {
        this.$toast({
          type: "success",
          message: message,
          duration: 1200,
        });
      }
    },
    async submitFail() {
      let url;
      if (this.formObj.currencyKindCode == "BRL") url = "brl";
      if (this.formObj.currencyKindCode == "EUR") url = "eur";
      if (this.formObj.currencyKindCode == "USD") url = "usd";
      if (this.formObj.currencyKindCode == "GBP") url = "gbp";
      if (this.formObj.currencyKindCode == "JPY") url = "jpy";
      if (this.formObj.currencyKindCode == "AUD") url = "aud";
      if (this.formObj.currencyKindCode == "KRW") url = "krw";
      if (this.formObj.currencyKindCode == "MXN") url = "mxn";
      const tmFormObj = JSON.parse(JSON.stringify(this.formCurrencyObj));
      delete tmFormObj.bankList;
      const params = {
        formObj: tmFormObj,
        url: url,
      };
      const { code, message } = await setWithdrawalApi.setCurrencyFiat(params);
      if (code == 200) {
        this.$toast({
          type: "success",
          message: message,
          duration: 1200,
        });
      }
    },
    optionIsShow() {
      let isShow = false;
      if (this.formObj.currencyTypeCode) {
        if (
          this.formObj.currencyTypeCode == 2 &&
          this.formObj.currencyKindType
        ) {
          isShow = true;
        }
        const enumType = [
          "BRL",
          "EUR",
          "GBP",
          "JPY",
          "KRW",
          "MXN",
          "AUD",
          "USD",
        ];
        if (
          this.formObj.currencyTypeCode == 1 &&
          enumType.includes(this.formObj.currencyKindCode)
        ) {
          isShow = true;
        }
      }
      return isShow;
    },
    addressIsShow() {
      let isShow = false;
      if (this.formObj.currencyTypeCode == 2 && this.formObj.currencyKindType) {
        isShow = true;
      }
      return isShow;
    },
    versionCall() {
      let isShow = true;
      if (!this.formObj.currencyType) {
        this.$toast({
          type: "fail",
          message: this.language.currencySelectError,
          duration: 1200,
        });
        isShow = false;
        return false;
      }
      if (!this.formObj.currencyKindType) {
        this.$toast({
          type: "fail",
          message: this.language.currencyError,
          duration: 1200,
        });
        isShow = false;
        return false;
      }
      return isShow;
    },
    formObjCallBack() {
      if (this.formObj.currencyKindCode == "BRL") {
        this.formCurrencyObj = new BRLForm();
      }
      if (this.formObj.currencyKindCode == "EUR") {
        this.formCurrencyObj = new EURForm();
      }
      if (this.formObj.currencyKindCode == "GBP") {
        this.formCurrencyObj = new GBPForm();
      }
      if (this.formObj.currencyKindCode == "JPY") {
        this.formCurrencyObj = new JPYForm();
      }
      if (this.formObj.currencyKindCode == "KRW") {
        this.formCurrencyObj = new KRWForm();
      }
      if (this.formObj.currencyKindCode == "MXN") {
        this.formCurrencyObj = new MXNForm();
      }
      if (this.formObj.currencyKindCode == "USD") {
        this.formCurrencyObj = new USDForm();
      }
      if (this.formObj.currencyKindCode == "AUD") {
        this.formCurrencyObj = new AUDForm();
      }
    },
    onLoad() {
      this.popupDataList = [];
      this.currencyTypeList = [
        { value: 1, text: this.language.currencyType1 },
        { value: 2, text: this.language.currencyType2 },
      ];
      this.popupDataList = this.currencyTypeList;
    },
  },

  components: {
    navTab,
    BRLCard,
    EURCard,
    USDCard,
    GBPCard,
    JPYCard,
    AUDCard,
    KRWCard,
    MXNCard,
  },
};
</script>

<style lang="scss" scoped>
.set-withdrawal-warper {
  width: 100%;
  height: 100vh;
  .page-header {
    border-color: 1px solid currentColor;
  }
  .setWithdrawal-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    background-color: #fff;
    .setWithdrawal-field-list {
      width: 100%;
      padding: 0 24px;
      border-radius: 8px;
      .field-list {
        margin-bottom: 30px;
        .label {
          font-size: 24px;
          color: $color3;
          margin-bottom: 20px;
        }
        .label::before {
          content: "*";
          display: block;
          color: red;
          margin-right: 4px;
        }
        ::v-deep .van-cell {
          border: 1px solid rgb(238, 238, 238);
          box-sizing: border-box;
          height: 88px;
          border-radius: 8px;
          padding: 20px 32px;
          .van-cell__value {
            .van-field__control {
              display: block;
              box-sizing: border-box;
              width: 100%;
              min-width: 0;
              margin: 0;
              padding: 0;
              font-size: 24px;
              color: #323233 !important;
              line-height: inherit;
              text-align: left;
              background-color: transparent;
              border: 0;
              resize: none;
              -webkit-user-select: auto;
              -moz-user-select: auto;
              user-select: auto;
            }
          }
        }
      }
      .address-field {
        ::v-deep .van-cell {
          height: auto;
          min-height: 88%;
          .van-field__control {
            line-height: 1.5 !important;
          }
        }
      }
      .hint-address {
        .label::before {
          display: none;
        }
        .label {
          font-size: 28px;
          font-weight: 600;
          color: #aaa !important;
        }
        .hintValue {
          font-size: 28px;
          font-weight: 600;
          color: #aaa !important;
        }
      }
    }
    .setWithdrawal-options {
      margin-top: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .van-button {
        width: 80%;
        border-radius: 16px;
        background-color: $blur;
        color: #fff;
        font-size: 28px;
        border: 1px solid $blur;
      }
    }
  }
  .currency-popup {
    padding: 20px 30px 40px 30px;
    overflow-y: scroll;
    max-height: 50%;
    min-height: 25%;
    .currency-popup-body {
      height: 100%;
      ::v-deep .van-picker {
        height: 100%;
        .van-picker__toolbar {
          font-size: 32px;
          .van-picker__confirm {
            color: $blur;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
input {
  caret-color: #323233;
}
input:-webkit-autofill {
  background: transparent;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #aaa !important;
}
</style>
