<template>
  <div class="setLanguage-page-warper" id="setLanguageID">
    <div class="page-header">
      <navTabBar></navTabBar>
    </div>
    <div class="setLanguage-page-conation">
      <div class="language-list">
        <div
          class="list-item flexbox"
          v-for="(item, index) in languageVersion"
          :key="index"
          @click="onChangeLanguage(item)"
        >
          <div class="item-left flexbox">
            <div class="item-flag-icon imgFlex">
              <img :src="item.flagIcon" />
            </div>
            <span class="title">{{ item.state }}</span>
          </div>
          <van-icon name="success" v-if="item.optionType" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navTabBar from "@/layout/navTab.vue";
export default {
  name: "appSetLanguageIndex",
  data() {
    return {
      language: this.$language("languageVersion"),
      languageVersion: [],
      activeObj: {
        flagIcon: "",
        value: "",
      },
      countNum: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("setLanguageID").style["height"] =
        seeHeight + "px";
    });
    this.languageVersion = [];
    this.onLoad();
  },
  methods: {
    onLoad() {
      for (let key of this.language) {
        let obj = {};
        obj[key] = key;
        obj["state"] = key;
        obj.optionType = false;
        if (key === "한국인") {
          obj["flagIcon"] = require(`../../assets/flag/Korean.png`);
        } else {
          obj["flagIcon"] = require(`../../assets/flag/${key}.png`);
        }
        this.languageVersion.push(obj);
      }
      this.contrastCallBack();
    },
    contrastCallBack() {
      const language = localStorage.getItem("languageType");
      this.languageVersion.map((item) => {
        item.optionType = false;
        if (item.state === language) {
          item.optionType = true;
          this.activeObj = {
            flagIcon: item.flagIcon,
            value: item.state,
          };
        }
      });
    },
    onChangeLanguage(record) {
      localStorage.setItem("languageType", record.state);
      this.contrastCallBack();
      this.countNum += 1;
      this.$resetSetItem("_countNum", "_countNum", this.countNum);
      this.$router.go(-1);
    },
  },
  components: { navTabBar },
};
</script>

<style lang="scss" scoped>
.setLanguage-page-warper {
  width: 100%;
  height: 100vh;
  .setLanguage-page-conation {
    height: calc(100% - 92px);
    background: #fff;
    .language-list {
      height: 100%;
      overflow-y: scroll;
      .list-item {
        justify-content: space-between;
        padding: 44px 36px;
        border-bottom: 1px solid #e5e7ed;
        font-weight: 400;
        font-size: 36px;
        color: #000;
        .item-left {
          font-size: 26px;
          .item-flag-icon {
            width: 48px;
            height: 48px;
            margin-right: 24px;
          }
        }

        i {
          color: $blur;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
