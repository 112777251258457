<template>
  <div class="footer-card flexbox">
    <div
      class="footer-list"
      v-for="(item, index) in footerList"
      :key="index"
      @click="
        {
          $router.push({ path: item.path }), onClickRouter(item);
        }
      "
    >
      <div class="icon">
        <div
          :class="['message-count', messageCount < 10 && 'dot-count']"
          v-if="messageCount && item.path === '/appWebSocketList'"
        >
          <span v-if="messageCount > 99">99 + </span>
          <span class="dot" v-else>{{ messageCount }}</span>
        </div>
        <svg-icon :icon-class="item.icon"></svg-icon>
      </div>
      <div :class="['label', item.path === $route.path && 'label-active']">
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import websocketApi from "@/api/websocketApi";
export default {
  name: "footerCardIndex",
  data() {
    return {
      language: this.$language("footer"),
      footerList: [],
      messageCount: 0,
    };
  },
  watch: {
    $route: {
      handler() {
        this.iconCallBack();
      },
    },
  },
  mounted() {
    this.unreadMessageGet();
    this.localStorageListener();
    this.onLoad();
  },
  methods: {
    localStorageListener() {
      window.addEventListener("IM_COUNT", (val) => {
        if (sessionStorage.getItem("IM_COUNT")) {
          let count = JSON.parse(sessionStorage.getItem("IM_COUNT"));
          this.messageCount = Number(count);
        }
      });
    },
    onLoad() {
      this.footerList = [
        {
          svgIcon: "footerHome",
          icon: "footerHome1",
          name: this.language.home,
          path: "/appHomeIndex",
        },
        {
          svgIcon: "footerProject",
          icon: "footerProject1",
          name: this.language.project,
          path: "/appProjectIndex",
        },
        {
          svgIcon: "footerOrder",
          icon: "footerOrder1",
          name: this.language.order,
          path: "/appOrderIndex",
        },
        {
          svgIcon: "footerInfo",
          icon: "footerInfo1",
          name: this.language.info,
          path: "/appWebSocketList",
        },
        {
          svgIcon: "footerUser",
          icon: "footerUser1",
          name: this.language.user,
          path: "/appUserIndex",
        },
      ];
      this.iconCallBack();
    },
    onClickRouter(record) {
      console.log("click");
      record.icon = record.svgIcon + "2";
      this.iconCallBack();
    },
    iconCallBack() {
      this.footerList.map((item) => {
        if (this.$route.path !== item.path) {
          item.icon = item.svgIcon + "1";
        }
        if (this.$route.path === item.path) {
          item.icon = item.svgIcon + "2";
        }
      });
      this.$forceUpdate();
    },
    // 未读消息广播设置
    async unreadMessageGet() {
      const { data, code } = await websocketApi.getUnreadMessage();
      if (code == 200) {
        this.unreadNumber = Number(data.number);
        this.$resetSetItem("IM_COUNT", "IM_COUNT", this.unreadNumber);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-card {
  width: 100%;
  height: 100px;
  background-color: #fff;

  .footer-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
      width: 40px;
      height: 40px;
      position: relative;
      .message-count {
        position: absolute;
        top: -12px;
        right: -25px;

        span:nth-child(1) {
          background: red;
          font-size: 16px;
          padding: 4px 8px;
          border-radius: 32px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
        }
        .dot {
          border-radius: 50%;
        }
      }
      .dot-count {
        right: -18px;
      }
      svg {
        width: 40px !important;
        height: 40px !important;
      }
    }

    .label {
      margin-top: 3px;
      font-size: 24px;
      color: #646566;
    }
    .label-active {
      color: $blur;
    }
  }
}
</style>
