<template>
  <div class="field-list-boxers">
    <div class="field-list">
      <p class="label flexbox">{{ language.formLabel1 }}</p>
      <van-field
        v-model="formObj.bankName"
        :placeholder="language.formPlace1"
        @input="onChangeCard"
      />
    </div>
    <div class="field-list">
      <p class="label flexbox">{{ language.formLabel2 }}</p>
      <van-field
        v-model="formObj.account"
        :placeholder="language.formPlace2"
        @input="onChangeCard"
      />
    </div>
    <div class="field-list">
      <p class="label flexbox">{{ language.formLabel3 }}</p>
      <van-field
        v-model="formObj.clabe"
        :placeholder="language.formPlace3"
        @input="onChangeCard"
      />
    </div>
    <div class="field-list">
      <p class="label flexbox">{{ language.formLabel4 }}</p>
      <van-field
        v-model="formObj.mobilePhone"
        :placeholder="language.formPlace4"
        @input="onChangeCard"
      />
    </div>
  </div>
</template>

<script>
import { MXNForm } from "../class.js";
export default {
  name: "setWithdrawFieldMXNCard",
  props: {
    fieldObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      language: this.$language("mxnLanguage"),
      formObj: new MXNForm(),
    };
  },
  mounted() {
    this.formObj = this.fieldObj;
  },
  methods: {
    onChangeCard() {
      this.$emit("onChangeCard", this.formObj);
    },
  },
  watch: {
    fieldObj: {
      handler(newVal, oldValue) {
        console.log(newVal, oldValue);
        this.formObj = { ...newVal };
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.field-list-boxers {
  .field-list {
    margin-bottom: 30px;
    .label {
      font-size: 24px;
      color: $color3;
      margin-bottom: 20px;
    }
    ::v-deep .van-cell {
      border: 1px solid rgb(238, 238, 238);
      box-sizing: border-box;
      height: 88px;
      border-radius: 8px;
      padding: 20px 32px;
      .van-cell__value {
        .van-field__control {
          display: block;
          box-sizing: border-box;
          width: 100%;
          min-width: 0;
          margin: 0;
          padding: 0;
          font-size: 24px;
          color: #323233 !important;
          line-height: inherit;
          text-align: left;
          background-color: transparent;
          border: 0;
          resize: none;
          -webkit-user-select: auto;
          -moz-user-select: auto;
          user-select: auto;
        }
      }
    }
  }
}
</style>
