<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="product-edit-warper" id="productEdit">
    <div class="page-header">
      <navTab>
        <template #navRight>
          <span class="nav-btn" @click="onChangeSubmit">
            {{ language.navBtn }}
          </span>
        </template>
      </navTab>
    </div>
    <div class="product-edit-body">
      <div
        class="product-list-item flexbox"
        v-for="(item, index) in productNextStepObj"
        :key="index"
      >
        <div
          class="item-images imgFlex"
          @click="onChangePreview(item.imagesUrl)"
        >
          <img v-lazy="item.imagesIcon" />
        </div>

        <div class="item-attributes-list" @click="onChangeSetProduct(item)">
          <div
            class="attributes-item flexbox"
            v-for="(child, childIndex) in item.attributes"
            :key="childIndex"
          >
            <van-tag class="tag-label" plain type="primary">
              {{ child.productAttributesName }}
            </van-tag>
            <div class="tag-item">
              <span class="tag-value">
                {{ child.productAttributesValueName }}
              </span>
            </div>
          </div>
          <div class="item-account">
            <countTo
              :startVal="0"
              :endVal="Number(item.unitAmount)"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </div>
        </div>
        <div class="item-svg" @click="onChangeSetProduct(item)">
          <svg-icon icon-class="setting-svg"></svg-icon>
        </div>
      </div>
    </div>
    <popupCard
      v-if="editVisible"
      :visible="editVisible"
      :setProductObj="setProductObj"
      @handlerClose="handlerClose"
      @handlerSubmit="handlerSubmit"
    ></popupCard>
    <van-popup v-model="shelvesVisible" class="affirm-popup">
      <div class="popup-body">
        <div class="body-title">
          <van-icon name="warning" />
          <span>{{ language.shelvesTitle }}</span>
        </div>
        <div class="body-footer">
          <span @click="onChangeShelvesClose()">
            {{ language.shelvesOptions1 }}
          </span>
          <span @click="onChangeShelvesAffirm()">
            {{ language.shelvesOptions2 }}
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import productDetailApi from "@/api/productDetail";
import navTab from "@/layout/navTab.vue";
import { ImagePreview } from "vant";
import imgUrl from "@/utils/projectConfig.js";
import popupCard from "./popup-dialog.vue";
export default {
  name: "appEditProductIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      thumbnail: this.$thumbnail,
      imageApi: imgUrl.imgUrl,
      productNextStepObj: {},
      editVisible: false,
      setProductObj: {},
      shelvesVisible: false,
    };
  },

  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("productEdit").style["height"] = seeHeight + "px";
    });
  },
  mounted() {
    this.getProductNextStepObj();
  },
  methods: {
    handlerClose() {
      this.setProductObj = {};
      this.editVisible = false;
    },
    onChangeShelvesClose() {
      this.setSubmitPort(2);
      this.shelvesVisible = false;
    },
    onChangeShelvesAffirm() {
      this.setSubmitPort(1);
      this.shelvesVisible = false;
    },
    onChangeSubmit() {
      if (this.$route.query.type === "add") {
        this.shelvesVisible = true;
      }
      if (this.$route.query.type === "list") {
        this.setSubmitPort(1);
      }
    },
    handlerSubmit() {
      this.shelvesVisible = false;
    },
    async setSubmitPort(state) {
      let skuList = [],
        params = {},
        port;
      this.productNextStepObj.map((item) => {
        skuList.push({
          id: item.id,
          originalAmount: Number(item.originalAmount) * 100,
          unitAmount: Number(item.unitAmount) * 100,
        });
      });
      if (this.$route.query.type === "add") {
        params = {
          state: state,
          productId: this.$route.query.id,
          sku: skuList,
        };
        port = productDetailApi.create_shop_product(params);
      }
      if (this.$route.query.type === "list") {
        params = {
          state: state,
          id: Number(this.$route.query.id),
          sku: skuList,
        };
        port = productDetailApi.change_shop_product(params);
      }

      const { code, data, message } = await port;
      if (code === 200) {
        this.editVisible = false;
        this.$toast.success(message);
        this.$router.push({ path: "/appProjectIndex" });
      } else {
        if (Object.keys(data).length > 0) {
          this.$toast.fail(`第${data.sort + 1}行,${message}`);
        } else {
          this.$toast.fail(message);
        }
      }
    },
    onChangeRouter() {
      this.$router.push({
        path: "/appProductFirstIndex",
      });
    },
    onChangeSetProduct(record) {
      this.setProductObj = record;
      this.editVisible = true;
    },
    onChangePreview(url) {
      ImagePreview([url]);
    },
    async getProductNextStepObj() {
      let params = { productId: this.$route.query.id };
      if (this.$route.query.type == "list") {
        params = {
          shopProductId: this.$route.query.id,
        };
      }
      const { code, data } = await productDetailApi.getProductDetail_interface(
        params
      );
      if (code == 200) {
        this.productNextStepObj = data.sku;
        this.productNextStepObj.map((item) => {
          item.originalAmount = parseFloat(item.originalAmount / 100).toFixed(
            2
          );
          item.purchaseAmount = parseFloat(item.purchaseAmount / 100).toFixed(
            2
          );
          item.unitAmount = parseFloat(item.unitAmount / 100).toFixed(2);
          item.imagesIcon = this.imageApi + item.image + this.thumbnail;
          item.imagesUrl = this.imageApi + item.image;
          item.skuImagesList = [];
          item.skuImage.map((record) => {
            record.imagesUrl = this.imageApi + record.imageUrl;
            record.imagesIcon =
              this.imageApi + record.imageUrl + this.thumbnail;
            item.skuImagesList.push(record.imagesUrl);
          });
          item.attributes = [];
          for (let key in item.skuAttributesValue) {
            item.attributes.push(item.skuAttributesValue[key]);
          }
        });
        // this.setProductObj = this.productNextStepObj[0];
      }
    },
  },
  components: { navTab, popupCard },
};
</script>

<style lang="scss" scoped>
.product-edit-warper {
  width: 100%;
  height: 100vh;

  .page-header {
    .nav-btn {
      padding: 8px 20px;
      background: $blur;
      color: #fff;
      font-size: 20px;
      line-height: 1.5;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }

    .nav-btn:active {
      opacity: 0.7;
    }
  }

  .product-edit-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow: auto;
    padding: 30px;

    .product-list-item {
      margin-bottom: 30px;
      padding: 24px 30px;
      background-color: #fff;
      border-radius: 8px;
      position: relative;

      .item-images {
        width: 160px;
        height: 160px;
        border-radius: 6px;
      }

      .item-attributes-list {
        width: calc(100% - 160px - 40px);
        padding: 0 30px;

        .item-account {
          font-size: 24px;
          line-height: 28px;
          color: $blur;
          margin-top: 20px;
        }

        .attributes-item {
          margin-bottom: 12px;
          align-items: flex-start;

          .tag-label {
            font-size: 22px;
            line-height: 1.5;
            margin-right: 12px;
            padding: 3px 10px;
          }

          .tag-item {
            .tag-value {
              font-size: 22px;
              color: $color9;
              margin-right: 8px;
              line-height: 1.5;
            }
          }
        }
      }

      .item-svg {
        position: absolute;
        top: 24px;
        right: 30px;

        svg {
          width: 26px !important;
          height: 26px !important;
        }
      }
    }
  }

  .affirm-popup {
    width: 420px;
    border-radius: 8px;
    padding: 40px  ;

    .popup-body {
      .body-title {
        display: flex;
        align-items: center;

        i {
          font-size: 52px;
          color: #f90;
        }

        span {
          margin-left: 12px;
          font-size: 32px;
          font-weight: 600;
          color: $blur;
        }
      }

      .body-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
     

        span {
          text-align: center;
          width: 38%;
          display: inline-block;
          font-size: 24px;
          font-weight: 600;
          color: $blur;
          padding: 12px 32px;
          background: #fff;
          border: 1px solid $blur;
          border-radius: 8px;
        }

        span:nth-child(2) {
          background: $blur;
          border-color: $blur;
          color: #fff;
        }
      }
    }
  }
}
</style>
