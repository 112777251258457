<template>
  <!-- 过渡页 -->
  <div class="loading_wrap">
    <div class="loader-box">
      <div class="loader-card">
        <div class="ele-animation">
          <img src="../assets/ICON.png" alt="" />
        </div>
      </div>
      <div class="lable">{{ messageLanguage.loadingTitle }}...</div>
    </div>
    <van-popup v-model="legalIsShow" class="legal-popup">
      <div class="legal-body">
        <p class="title">{{ messageLanguage.dialogHint }}</p>
        <div class="options">
          <span @click="registerChange">
            {{ messageLanguage.dialogClose }}
          </span>
          <span @click="loaginChange">
            {{ messageLanguage.dialogAffirm }}
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getParams } from "@/utils/publicMethods";
let Base64 = require("js-base64").Base64;
import transitionApi from "@/api/transitionApi";
export default {
  name: "transitionPageIndex",
  data() {
    return {
      messageLanguage: this.$language("messageLanguage"),
      decodedStr: {},
      loginObj: {},
      routeEnum: {
        goodsList: "/appProjectIndex",
        shopCenter: "/appHomeIndex",
        orderList: "/appOrderIndex",
      },
      strBase64: "",
      loadingSuccessCount: 1,
      legalIsShow: false,
    };
  },
  created() {
    // const ser = 'ewogICJjdXN0b21JRCIgOiAiQHVzZXI1MTQ2MDg0NzciLAogICJhdmF0YXIiIDogImh0dHBzOlwvXC9wMTYtc2lnbi1zZy50aWt0b2tjZG4uY29tXC90b3MtYWxpc2ctYXZ0LTAwNjhcLzczODI0OTYzMTEwMTE5NjY5OTN%20YzVfMzAweDMwMC53ZWJwP2xrM3M9YTVkNDgwNzgmbm9uY2U9MTQzNjEmcmVmcmVzaF90b2tlbj1kN2NlNDBjNTJmMTljYTc1ODkyY2ExYWRkYTVjZmJjNiZ4LWV4cGlyZXM9MTcxOTE5ODAwMCZ4LXNpZ25hdHVyZT1JWDNROXQlMkZ5M1pGSndnelUyRU80ZnRpSnd2QSUzRCZzaHA9YTVkNDgwNzgmc2hjcD0yNDcyYTZjNiIsCiAgImxhbmciIDogInpoIiwKICAibmlja25hbWUiIDogInVzZXI1MTQ2MDg0NzciLAogICJ0aW1lem9uZSIgOiAiR01UKzA4OjAwIiwKICAidGlrdG9rX2lkIiA6ICI3MzgyNDk1MTc1OTE2NjY3OTA5IiwKICAidmVyc2lvbiIgOiAidjAuMC4zIiwKICAidGltZXN0YW1wIiA6IDE3MTkwMjU0MTY4MjksCiAgInJvdXRlIiA6ICJnb29kc0xpc3QiCn0='
    // let decodedStr = Base64.decode(decodeURIComponent(ser));
    // console.log(decodedStr)
    // // let params = `?language=${localStorage.getItem("languageType")}&base64=${ser}&code=2100`
    // // this.$globalCommon.$openUrl(this.$globalCommon.$mallHost + params + imgUrlApi.shopRegisterWebUrl)
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.linkBase64();
    },
    linkBase64() {
      const Router_1002 = sessionStorage.getItem("ROUTER_1002");
      const BASE64 = sessionStorage.getItem("BASE64_STR");
      let decodedStr;
      console.log(getParams("data"));
      if (
        (getParams("data") !== "null" || getParams("data") !== "undefined") &&
        getParams("data")
      ) {
        this.strBase64 = getParams("data");
      } else if (
        (Router_1002 || BASE64) &&
        Router_1002 !== "null" &&
        BASE64 &&
        BASE64 !== "null" &&
        BASE64 !== "undefined"
      ) {
        this.strBase64 = sessionStorage.getItem("BASE64_STR");
      }
      try {
        if (this.strBase64) {
          this.$resetSetItem("tikTok_data", "tikTok_data", this.strBase64);
          sessionStorage.setItem("BASE64_STR", this.strBase64);
          decodedStr = Base64.decode(decodeURIComponent(this.strBase64));
          this.decodedStr = JSON.stringify(JSON.parse(decodedStr));
          sessionStorage.setItem("BASE64", decodedStr);
        } else {
          this.decodedStr = { route: "/appHomeIndex" };
        }
      } catch {
        this.decodedStr = { route: "/appHomeIndex" };
      }
      this.flowLinkCall();
    },
    flowLinkCall() {
      let authentication = localStorage.getItem("authentication");
      if (authentication) {
        if (
          Object.keys(this.decodedStr).length === 1 &&
          this.decodedStr.route === "/appHomeIndex"
        ) {
          setTimeout(() => {
            this.$emit("tiTokClose");
          }, 800);
          return false;
        }
        const router = this.decodedStr?.route;
        if (router) {
          this.$router.push({ path: this.routeEnum[router] });
          this.loadingSuccessCount += 1;
          setTimeout(() => {
            this.$resetSetItem(
              "loadingSuccessCount",
              "loadingSuccessCount",
              this.loadingSuccessCount
            );
          }, 2000);
        }
      } else {
        if (this.strBase64) {
          this.getTiTokCertification();
        } else {
          this.$router.push({
            path: "/appHomeIndex",
          });
          setTimeout(() => {
            this.$emit("tiTokClose");
          }, 800);
        }
      }
    },
    getTiTokCertification() {
      let params = { data: decodeURIComponent(this.strBase64) };
      transitionApi.certificationTiTok(params).then((res) => {
        const { code, data } = res;
        if (code == 200) {
          this.loginObj = data;
          const Router_1002 = sessionStorage.getItem("ROUTER_1002");
          setTimeout(() => {
            if (data.isLogin) {
              let inviteUrl =
                this.$globalCommon.$mallHost +
                "?code=" +
                data.account.inviteCode;
              let account = { ...data.account, inviteUrl: inviteUrl };
              localStorage.setItem("account", JSON.stringify(account));
              localStorage.setItem("authenticationToKen_", data.token);
              localStorage.setItem("loginInfo_", JSON.stringify(account));
              this.$resetSetItem(
                "localStorage",
                "authenticationToKen_",
                data.token
              );
              sessionStorage.removeItem("ROUTER_1002");
              if (!Router_1002) {
                const router = this.decodedStr?.route;
                if (router) {
                  this.$router.push({
                    path: this.routeEnum[router],
                    query: { data: this.strBase64 },
                  });
                  setTimeout(() => {
                    this.$emit("tiTokClose");
                  }, 800);
                } else {
                  this.$router.push({
                    path: "/appHomeIndex",
                    query: { data: this.strBase64 },
                  });
                  setTimeout(() => {
                    this.$emit("tiTokClose");
                  }, 800);
                }
              } else {
                this.$emit("tiTokClose");
              }
            } else {
              this.legalIsShow = true
            }
          }, 2500);
        }
      });
    },
    registerChange() {
      this.legalIsShow = false;
      this.$globalCommon.$openParamUrl(
        this.$globalCommon.$merchantRegisterUrl,
        { base64: decodeURIComponent(this.strBase64) }
      );
    },
    loaginChange() {
      this.legalIsShow = false;
      this.$router.push({
        path: "/appLoginIndex",
        query: { data: this.strBase64 },
      });
      setTimeout(() => {
        this.$emit("tiTokClose");
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading_wrap {
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.loader-box {
  width: 100%;
  height: 100vh;
  border-radius: 0.3125rem;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-1.875rem);
}

.loader-card {
  width: 320px;
  height: 320px;
  overflow: hidden;
  display: block;
  border-radius: 50%;
  border: 0.1875rem solid #295fd4;
  position: relative;
  margin: 0.9375rem auto;
  z-index: 1;
}

img {
  animation: animationFrames-c2dc4248 4s linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: initial;
  image-rendering: pixelated;
  object-fit: cover;
  transform: translate(32.5rem, 0.9375rem);
}

.lable {
  transform: translateX(0.2375rem);
  font-family: Gotham, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 60px;
  font-weight: 600;
  color: #295fd4;
}

@keyframes animationFrames-c2dc4248 {
  0% {
    transform: translate(-12.5rem, 1.1375rem);
  }

  100% {
    transform: translate(4.5rem, 1.175rem);
  }
}
.legal-popup {
  border-radius: 8px;
  padding: 40px 30px;
  width: 420px;

  .legal-body {
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      color: $color3;
      line-height: 1.5;
    }

    .options {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        text-align: center;
        background: $blur;
        color: #fff;
        border-radius: 8px;
        font-size: 26px;
        width: 45%;
        padding: 20px 32px;
      }
      span:nth-child(1) {
        color: $blur;
        background: #fff;
        border: 1px solid $blur;
      }
    }
  }
}
</style>
