import Vue from 'vue'
import { Toast } from "vant";
import { Dialog } from 'vant';
import router from 'vue-router'
import routers from '@/router/index'
import axios from 'axios'
import baseUrl from '@/utils/projectConfig'
import { loadingNode, loadingClear } from '@/layout/loading-custom/loadingjs'
import { languageList } from '@/utils/language/language'
import { getParams } from "@/utils/publicMethods";
Vue.use(router)

const service = axios.create({
  baseURL: baseUrl.baseUrl,
  timeout: 5000 * 2 // 仅为这个请求设置超时时间为5秒
})
Vue.use(router)

let messageLanguage, dialogLanguage, loadingCount = 0, error1002 = sessionStorage.getItem('error1002') || 0, error1002Num = 0
Vue.nextTick(() => {
  languageGet()
})
window.addEventListener("_countNum", (val) => {
  languageGet()
});
onLoad('init')


// request拦截器==>对请求参数做处理
service.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem('authenticationToKen_')
  config.headers['Accept-Language'] = baseUrl.lang[window.localStorage.getItem('languageType')]
  loadingCount += 1
  if (loadingCount === 1) {
    loadingNode(2500, messageLanguage + '...')
  }
  return config
}, error => { // 请求错误处理
  Promise.reject(error)
})


// respone拦截器==>对响应做处理
service.interceptors.response.use(
  response => {
    // 成功请求到数据
    // 这里根据后端提供的数据进行对应的处理 
    if (response.data.code === 200) {
      loadingCount--
      if (loadingCount == 0) {
        loadingClear(0)
        Toast.clear()
      }
    } else if (response.data.code == 1002) {
      loadingCount = 0

      Toast.fail({
        forbidClick: true,
        duration: 1200,
        message: response.data.message
      })
      onLoad('1002')
      setTimeout(() => {
        if (loadingCount == 0) {
          loadingClear(0)
          Toast.clear()
        }
        const tikTokIsShow = sessionStorage.getItem("tikTok_data")
        if (!tikTokIsShow && !getParams('data')) {
          setTimeout(() => {
            routers.push({
              path: '/appLoginIndex',
            })
          }, 100)
        } else {
          Vue.prototype.$resetSetItem('error1002', "error1002", error1002Num);
        }
      }, 1500)
    } else if (response.data.code == 500) {
      dialogCall(response.data.message)
    } else {
      loadingCount -= 1
      if (loadingCount == 0) {
        loadingClear(0)
        Toast.clear()
      }
      Toast.fail({
        message: response.data.message,
        forbidClick: true,
      })
    }
    return response.data
  },
  error => {
    loadingCount--
    if (loadingCount == 0) {
      loadingClear(0)
      Toast.clear()
    }
    const text = JSON.parse(JSON.stringify(error))
    let errText
    if (text.code === 'ECONNABORTED') {
      errText = "The request has timed out. Click Refresh to try again!"
    } else { // 处理中止错误
      errText = "Service abnormality, click refresh to try again!"
    }
    dialogCall(errText)
    return Promise.reject(error)
  }
)

function dialogCall (message) {
  Dialog.confirm({
    className: 'axios-dialog-class',
    message: message,
    showCancelButton: false,
    confirmButtonText: dialogLanguage,
    beforeClose
  })
}
function beforeClose (action, done) {
  if (action === 'confirm') {
    setTimeout(() => {
      location.reload();
    }, 1000)
  } else {
    done();
  }
}

function onLoad (options) {
  if (options == 'init') {
    Vue.nextTick(() => {
      languageGet()
    })
    window.addEventListener("_countNum", (val) => {
      languageGet()
    });
    tikTok_Call()
    window.addEventListener("error1002", (val) => {
      tikTok_Call()
    });
  }
  if (options == '1002') {
    if (sessionStorage.getItem("tikTok_data") || getParams('data')) {
      error1002Num += 1
    }
    localStorage.removeItem("authenticationToKen_")
    localStorage.removeItem("configInfo_")
    localStorage.removeItem("loginInfo_")
    localStorage.removeItem("moneyIcon")
    sessionStorage.removeItem("authenticationToKen_")

  }
}
function languageGet () {
  messageLanguage = languageList('messageLanguage').loadingTitle
  dialogLanguage = languageList('configLanguage').upgradeImmediately
}
function tikTok_Call () {
  if (sessionStorage.getItem("tikTok_data") || getParams('data')) {
    error1002Num = Number(error1002)
    error1002Num += error1002Num
  }
}
export default service