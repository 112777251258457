<template>
  <div class="appLanguage-card flexbox">
    <div class="language-flag imgFlex2" @click="setLanguage">
      <img :src="languageObj.imgIcon" />
    </div>
    <div class="arrow-img" @click="setLanguage">
      <img src="../assets/arrow_down.png" />
    </div>
  </div>
</template>
<script>
export default {
  name: "appLanguageCard",
  data() {
    return {
      language: this.$language(),
      languageType: this.$languageType(),
      languageObj: {},
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      let language = localStorage.getItem("languageType");
      let languageIcon; 
      if (language === "한국인") {
        languageIcon = require(`../assets/flag/Korean.png`);
      } else {
        languageIcon = require(`../assets/flag/${language}.png`);
      }
      this.languageObj = {
        imgIcon: languageIcon,
        value: language,
      };
    },
    setLanguage() {
      this.$router.push({ path: "/appSetLanguage" });
    },
  },
};
</script>

<style lang="scss" scoped>
.appLanguage-card {
  display: flex;
  align-items: center;

  .language-flag {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #fff;
  }

  .arrow-img {
    width: 20px;
    height: 20px;
    margin-left: 12px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
