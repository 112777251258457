// 芬兰语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'Viesti',
    IMErrorMessage: 'Pikaviestiyhteys epäonnistui, yritä uudelleen napsauttamalla! ',
  },
  "appWebSocketDetails": {
    sendOption: 'Lähetä',
    send: 'lähetä',
    fileFail: 'Lähetä ja muotoile kuvat',
    IMportError: ' Ei yhteyttä tällä hetkellä, yritä uudelleen! ',
    IMErrorMessage: 'Pikaviestiyhteys epäonnistui, yritä uudelleen! ',
    retryBtn: 'Yritä uudelleen',
  },
  "appLoginIndex": {
    navTitle: 'Kirjaudu',
    projectName: 'Hallitse kauppaa',
    formLabel1: 'Sähköposti',
    formLabel2: 'salasana',
    placeholder: 'Syötä',
    hint: "Jos sinulla ei ole tiliä",
    clickRegister: 'Rekisteröidy napsauttamalla',
    forgetPass: 'unohdin salasana',
    optionBtn: 'Kirjaudu',
    validateError: 'Täydennä syöttö! ',
  },
  "appForgetPassIndex": {
    navTitle: "Unohdin salasana",
    formLabel1: 'Kirjautumispostilaatikko',
    formLabel2: 'Vahvistuskoodi',
    formLabel3: 'Uusi salasana',
    formLabel4: 'Vahvista salasana',
    formHint1: "Anna kirjautumissähköpostiosoitteesi",
    formHint2: 'Anna uusi salasana',
    formHint3: "Anna vahvistuskoodi",
    formHint4: "Anna salasanasi kahdesti",
    formOption: 'Lähetä',
    sendOption: 'Countdown:',
    optionsBtn: 'Vahvista',
  },
  'footer': {
    home: "Kauppa",
    project: "Tuote",
    info: "Viesti",
    order: "Tilaa",
    user: "Minun",
  },
  'setDrawer': {
    label1: "Monikieliasetukset",
    label2: 'Rekisteröityminen myymälään',
    label3: 'Kirjautuminen myymälään',
    label4: 'asiakaspalvelu',
    label5: 'Lataa Android-sovellus',
    label6: 'Lataa ios-sovellus',
    optionsBtn: 'Peruuta',
  },
  'appHomeIndex': {
    accountLabel1: 'Myynti',
    accountLabel2: 'liikenne',
    cardLabel1: 'Tietojen yleiskatsaus',
    cardLabel2: 'Dataraportti',
    cardLabel3: "Summan yleiskatsaus",
    ech1Title: 'Kokonaismyynti',
    ech2Title: 'Tilausten kokonaismäärä',
    ech3Title: 'Kaupan liikenne',
    echBuoy1Title: 'Kokonaismyynti',
    echBuoy2Title: "Tilausten kokonaismäärä",
    echBuoy3Title: 'Kaupan liikenne',
    dataCardTitle1: 'Myynti',
    dataCardTitle2: 'Traffic',
    dataCardTitle3: 'Tilausten kokonaismäärä',
    dataCardTitle4: 'Erääntynyt kokonaissumma',
    dataCardDayTags: "Tänään",
    dataYesterTags: "Eilen tiedot",
    dataYesterTags1: "Tarkistamattomien tilausten määrä",
    cardMenusLabel1: 'Tax',
    cardMenusLabel2: "Tallennettu arvo",
    cardMenusLabel3: 'Peruutus',
    cardMenusLabel4: 'Jaa kutsu',
    cardMenusLabel5: 'Tuoteluettelo',
    cardMenusLabel6: 'Tilausluettelo',
    cardMenusLabel7: 'Liikeraportti',
    taxPopupTitle: "Täytyy tällä hetkellä maksaa veroja:",
    popupBtn: "Mene ja maksa verot",
    cardStateLabel1: 'Balance',
    cardStateLabel2: 'Peruutus',
    cardStateLabel3: 'Tulot',
    cardStateLabel4: 'Team',
    cardSellHotTitle: "10 suosituinta tuotetta",
    salesLabel: 'Sales:',
  },
  'appSetLanguage': {
    navTitle: 'Monikieliset asetukset',
    affirm: 'vahvista',
  },
  "appServiceIndex": {
    navTitle: 'Asiakaspalvelu',
    customerService: "Yksinomaista asiakaspalvelua",
    helloTitle: "Hei",
    welcomeTitle: 'Olen sinun<, voit ottaa minuun yhteyttä seuraavien kanavien kautta',
    iconInformation: 'Valitse viestintäohjelmisto konsultaatiota varten',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: 'Balance',
    blanceOption1: 'Poista',
    blanceOption2: 'Store value',
    incomeLabel1: 'Kumulatiivinen tulo',
    incomeLabel2: 'Kumulatiiviset nostot',
    listLabel1: 'Kaupan tiedot',
    listLabel2: 'Nostotavan asetukset',
    listLabel3: 'Fund Record',
    listLabel4: 'Business Report',
    listLabel5: "Alennustoiminta",
    listLabel7: "Laina",
    listLabel8: "Mene ostoskeskukseen",
    listLabel9: 'Lataa sovellus',
    listLabelLast: 'Poistu',
  },
  'downloadAppIndex': {
    navTitle: 'Lataa',
    downloadAndroidApp: "Android-lataus",
    downloadIosApp: "IOS-lataus",
  },
  "appDiscountActivity": {
    navTitle: "Alennustoiminta",
    popupLabel1: 'Summa',
    popupPlace1: "Anna summa",
    popupLabel2: "Alennus",
    popupPlace2: "Anna alennus",
    popupOption: 'Vahvista',
    listLabel1: 'Tarvittava summa:',
    listLabel2: 'Alennussuhde:',
    listLabel3: 'Alennusaika:',
    dialogTitle: "Vahvista, poistetaanko!",
    dialogAffirm: 'Vahvista',
    dialogClose: "Peruuta",
  },
  "appInviteIndex": {
    navTitle: "Kutsu uusia aktiviteetteja",
    headerTitle: "Kutsu ystäviä ansaitsemaan palkintoja",
    copyOption: 'copy',
    copySuccess: 'Kopiointi onnistui',
    inviteTitle: 'Kutsujen kokonaismäärä',
    inviteLabel1: 'Kutsuttujen ihmisten määrä:',
    inviteLabel2: 'Kokonaispalkkio:',
    ruleTitle: "Kutsusääntö",
    ruleHint1: ' 1. Kutsuja voi kutsua ystäviä viimeistelemään rekisteröinti jakamalla promootiolinkin, jolloin kutsutut ystävät yhdistetään sinuun;',
    ruleHint2: ' 2. Kun olet kutsunut käyttäjiä suorittamaan maksun, voit saada osuuden heidän voitoistaan;',
    ruleHint3: ' 3. Vain kolmannen tason kutsupalkintoja tuetaan, ja palkkiosuhde on: <%, >%, @%; ',
  },
  "appInviteUserRecordIndex": {
    navTitle: "Kutsu käyttäjätietue",
    recordTitle1: "Käyttäjien kokonaismäärä",
    recordTitle2: "Taso 1",
    recordTitle3: "Taso 2",
    recordTitle4: "Taso 3",
    tabLabel1: "Kaikki",
    tabLabel2: 'Taso 1',
    tabLabel3: 'Toinen taso',
    tabLabel4: "Taso 3",
    listLabel1: 'Taso:',
    listLabel2: 'Tuotto:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: "Kutsutulotietue",
    recordTitle1: 'Kokonaistulot',
    recordTitle2: "Taso 1",
    recordTitle3: "Taso 2",
    recordTitle4: "Taso 3",
    tabLabel1: "Kaikki",
    tabLabel2: 'Taso 1',
    tabLabel3: 'Toinen taso',
    tabLabel4: "Taso 3",
    listLabel1: 'Tuotto:',
    listLabel2: 'Proportion:',
  },
  "appWithdrawIndex": {
    navTitle: 'Peruuttaminen',
    currencyType1: 'Fiatin valuutta',
    currencyType2: "Virtuaalivaluutta",
    formLabel1: 'Valuuttatyyppi:',
    formPlace1: 'Valitse valuuttatyyppi',
    formLabel2: 'Valitse valuutta:',
    formPlace2: 'Valitse valuuttatyyppi',
    formLabel3: 'Nostosumma:',
    formPlace3: "Syötä nostosumma",
    formLabel4: 'Poistan salasana:',
    formPlace4: 'Anna noston salasana:',
    balanceLabel: 'Nykyinen saldo:',
    amountLabel: 'Todellinen saapuminen:',
    optionsBtn: 'Lähetä',
    dialogClose: 'Peruuta',
    dialogAffirm: 'Vahvista',
    currencySelectError: 'Valitse valuuttatyyppi',
    currencyError: 'Valitse valuutta',
    affirmDialogDialogTitle1: 'Et ole asettanut maksusalasanaa. Haluatko määrittää sen? ',
    affirmDialogDialogTitle2: 'Et ole asettanut nostokanavaa, haluatko määrittää sen? ',
    affirmDialogBtn2: 'Peruuta',
    affirmDialogBtn1: 'Vahvista',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Peruutustavan asetus',
    currencyType1: 'Fiatin valuutta',
    currencyType2: "Virtuaalivaluutta",
    formLabel1: 'Valuuttatyyppi:',
    formPlace1: 'Valitse valuuttatyyppi',
    formLabel2: 'Valitse valuutta:',
    formPlace2: 'Valitse valuuttatyyppi',
    addressLabel: 'Osoite:',
    addressPlace: "Syötä virtuaalivaluutan osoite",
    hintLabel: 'Tärkeä vinkki',
    hintValue: 'Siirrä vain ¥ omaisuutta',
    optionsBtn: 'Lähetä',
    dialogClose: 'Peruuta',
    dialogAffirm: 'Vahvista',
    currencySelectError: 'Valitse valuuttatyyppi',
    currencyError: 'Valitse valuutta',
    currencyFail: 'Ei vielä auki',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'oikea nimi',
    formPlace1: "Anna oikea nimesi",
    formLabel2: 'Sähköposti',
    formPlace2: "Anna sähköpostiosoitteesi",
    formLabel3: 'Matkapuhelinnumero',
    formPlace3: "Anna matkapuhelinnumerosi",
    formLabel4: 'PIX-tyyppi',
    formPlace4: 'Valitse PIX-tyyppi',
    formLabel5: 'PIX-tili',
    formPlace5: "Syötä PIX-tilinumerosi",
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'Maa/alue',
    formPlace1: "Anna maa/alue",
    formLabel2: 'nimi',
    formPlace2: "Anna nimi",
    formLabel3: 'Sukunimi',
    formPlace3: "Anna sukunimesi",
    formLabel4: 'swift',
    formPlace4: "Syötä nopeasti",
    formLabel5: 'Pankin nimi',
    formPlace5: "Anna pankin nimi",
    formLabel6: 'iban',
    formPlace6: "Syötä iban",
    formLabel7: 'Matkapuhelinnumero',
    formPlace7: "Anna matkapuhelinnumerosi",
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'Maa/alue',
    formPlace1: "Anna maa/alue",
    formLabel2: 'nimi',
    formPlace2: "Anna nimi",
    formLabel3: 'Sukunimi',
    formPlace3: "Anna sukunimesi",
    formLabel4: 'swift',
    formPlace4: "Syötä nopeasti",
    formLabel5: 'ach',
    formPlace5: "Syötä ach",
    formLabel6: 'Pankin nimi',
    formPlace6: "Anna pankin nimi",
    formLabel7: 'Pankkitili',
    formPlace7: "Syötä pankkitili",
    formLabel8: 'Province tai osavaltio',
    formPlace8: "Syötä maakunta tai osavaltio",
    formLabel9: 'Matkapuhelinnumero',
    formPlace9: "Anna matkapuhelinnumerosi",
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'Maa/alue',
    formPlace1: "Anna maa/alue",
    formLabel2: 'nimi',
    formPlace2: "Anna nimi",
    formLabel3: 'Sukunimi',
    formPlace3: "Anna sukunimesi",
    formLabel4: 'Pankin koodi',
    formPlace4: "Anna pankin koodi",
    formLabel5: 'Pankin nimi',
    formPlace5: "Anna pankin nimi",
    formLabel6: 'Pankkitili',
    formPlace6: "Syötä pankkitili",
    formLabel7: 'Matkapuhelinnumero',
    formPlace7: "Anna matkapuhelinnumerosi",
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'Maa/alue',
    formPlace1: "Anna maa/alue",
    formLabel2: 'nimi',
    formPlace2: "Anna nimi",
    formLabel3: 'Sukunimi',
    formPlace3: "Anna sukunimesi",
    formLabel4: 'zengin',
    formPlace4: "Syötä zengin",
    formLabel5: 'Pankin nimi',
    formPlace5: "Anna pankin nimi",
    formLabel6: 'Pankkitili',
    formPlace6: "Syötä pankkitili",
    formLabel7: 'Province tai osavaltio',
    formPlace7: "Syötä maakunta tai osavaltio",
    formLabel8: 'Matkapuhelinnumero',
    formPlace8: "Anna matkapuhelinnumerosi",
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'Maa/alue',
    formPlace1: "Anna maa/alue",
    formLabel2: 'nimi',
    formPlace2: "Anna nimi",
    formLabel3: 'Sukunimi',
    formPlace3: "Anna sukunimesi",
    formLabel4: 'BSB',
    formPlace4: "Syötä BSB",
    formLabel5: 'Pankin nimi',
    formPlace5: "Anna pankin nimi",
    formLabel6: 'Pankkitili',
    formPlace6: "Syötä pankkitili",
    formLabel7: 'Province tai osavaltio',
    formPlace7: "Syötä maakunta tai osavaltio",
    formLabel8: 'Matkapuhelinnumero',
    formPlace8: "Anna matkapuhelinnumerosi",
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'nimi',
    formPlace1: "Anna nimi",
    formLabel2: 'Sukunimi',
    formPlace2: "Anna sukunimesi",
    formLabel3: 'Pankin nimi',
    formPlace3: "Anna pankin nimi",
    formLabel4: 'Pankkitili',
    formPlace4: "Syötä pankkitili",
    formLabel5: 'Matkapuhelinnumero',
    formPlace5: "Anna matkapuhelinnumerosi",
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Pankin nimi',
    formPlace1: "Anna pankin nimi",
    formLabel2: 'Account',
    formPlace2: "Syötä tilisi",
    formLabel3: 'clabe account',
    formPlace3: "Syötä clabe-tilisi",
    formLabel4: 'Matkapuhelinnumero',
    formPlace4: "Anna matkapuhelinnumerosi",
  },
  'appStatisticsReportIndex': {
    navTitle: 'Toimintaraportti',
    cardLabel1: 'Kokonaismyynti',
    cardLabel2: 'Ostosten kokonaissumma',
    cardLabel3: 'Kokonaisvoitto',
    cardLabel4: 'Tilausten kokonaismäärä',
    listLabel1: 'Aika:',
    listLabel2: 'Order:',
    listLabel3: 'Myynti:',
    listLabel4: 'Ostosumma:',
    profitLabel: 'voitto',
    searchDateTitle: 'Asiakirjan aika',
    searchIntervalTitle: 'Interval time',
    searchStartTimePlace: 'Aloitusaika',
    searchEndTimePlace: 'Päättymisaika',
    searchOptions1: 'Nollaa',
    searchOptions2: 'Vahvista',
    searchDateAffirm: 'confirm',
    searchDateClose: 'Cancel',
    searchIntervalLabel1: 'Kaikki',
    searchIntervalLabel2: 'Tänään',
    searchIntervalLabel3: 'Eilen',
    searchIntervalLabel4: 'Tällä viikolla',
    searchIntervalLabel5: 'Tässä kuussa',
    searchIntervalLabel6: 'Tänä vuonna',
    searchIntervalOptions: 'Cancel',
  },
  "appAmountRechargeIndex": {
    navTitle: "Tallennettu arvo",
    currencyType1: 'Fiatin valuutta',
    currencyType2: "Virtuaalivaluutta",
    formLabel1: 'Valuuttatyyppi:',
    formPlace1: 'Valitse valuuttatyyppi',
    formLabel2: 'Valitse valuutta:',
    formPlace2: 'Valitse valuuttatyyppi',
    formLabel3: 'Tallennetun arvon määrä:',
    formPlace3: "Anna tallennetun arvon summa",
    formLabel4: 'Recharge method',
    formPlace4: 'Valitse tallennustapa:',
    balanceLabel: 'Nykyinen saldo:',
    amountLabel: 'Todellinen saapuminen:',
    optionsBtn: 'Lähetä',
    dialogClose: 'Peruuta',
    dialogAffirm: 'Vahvista',
    currencySelectError: 'Valitse valuuttatyyppi',
    currencyError: 'Valitse valuutta',
    successCopy: 'Kopioi onnistuneesti',
    hintLabel: "Tärkeä muistutus:",
    hintValue: "Vain ¥ omaisuuden siirto on sallittu",
    legalHint: 'Ota yhteyttä asiakaspalveluun!'
  },
  'appShopInfoIndex': {
    navTitle: 'Kaupan tiedot',
    navEditText: 'Muokkaa',
    formLabel1: 'Store image',
    formLabel2: 'myymälän nimi',
    formLabel3: 'Kirjautumissähköposti',
    formLabel4: 'kutsukoodi',
    formLabel5: 'Kirjautumissalasana',
    formLabel6: 'Maksun salasana',
    formLabel7: 'Description message',
    formLabel8: 'Tallenna taustakuva',
    formLabel9: 'Markkinoiden taustakuva',
    formLabel10: "Varmenteen tiedot",
    formHint1: 'Asiakirjan etupuoli',
    formHint2: "Varmenteen kääntöpuoli",
    fileFail: 'Lähetä kuvat ja muotoile',
    optionsBtn: 'Lähetä',
  },
  'appShopInfoEdit': {
    navTitle: 'Tallennustietojen editori',
    formLabel1: 'Store image',
    formLabel2: 'myymälän nimi',
    formLabel3: 'Kirjautumissähköposti',
    formLabel4: 'kutsukoodi',
    formLabel7: 'Description message',
    formLabel8: 'Tallenna taustakuva',
    formLabel9: 'Markkinoiden taustakuva',
    fileFail: 'Lähetä kuvat ja muotoile',
    optionsBtn: 'Lähetä',
  },
  'appChangeLoginPass': {
    navTitle: 'Vaihda kirjautumissalasana',
    formLabel1: 'Vanha salasana',
    formLabel2: 'Uusi salasana',
    formLabel3: 'Vahvistuskoodi',
    formHint1: 'Anna vanha salasana',
    formHint2: 'Anna uusi salasana',
    formHint3: "Anna vahvistuskoodi",
    formOption: 'Lähetä',
    sendOption: 'Countdown:',
    optionsBtn: 'Vahvista',
  },
  'appChangePayPass': {
    navTitle: 'Vaihda maksun salasana',
    formLabel1: 'Maksun salasana',
    formLabel2: 'Vahvistuskoodi',
    formHint1: 'Anna maksun salasana',
    formHint2: "Anna vahvistuskoodi",
    formOption: 'Lähetä',
    sendOption: 'Countdown:',
    optionsBtn: 'Vahvista',
  },
  "appFundingRecordsIndex": {
    navTitle: 'Fund Record',
    searchRecordsTitle: 'Rahaston tyyppi',
    searchDateTitle: 'Ajan valinta',
    searchFundingLabel1: "Kaikki varat",
    searchFundingLabel2: "Talletustyyppi",
    searchFundingLabel3: "Nostotietue",
    searchOptions1: 'Nollaa',
    searchOptions2: 'Vahvista',
    searchDateAffirm: 'confirm',
    searchDateClose: 'Cancel',
    stareTimePlace: "Aloitusaika",
    endTimePlace: "Päättymisaika",
    typeAll: 'All',
  },
  "appProjectIndex": {
    navTitle: 'Tuoteluettelo',
    searchPlace: "Syötä tuotteen nimi, jota haluat etsiä",
    shelvesLabel: 'ylempi ja alahylly',
    brandPlace: "Valitse tuotemerkki",
    classPlace: 'Valitse luokka',
    searchOptions1: "Palauta",
    searchOptions2: 'Vahvista',
    shelvesTag1: "Kaikki",
    shelvesTag2: "hyllyillä",
    shelvesTag3: "off shelves",
    salesLabel: 'Sales:',
    dialogAffirm: 'Vahvista',
    dialogClose: 'Peruuta',
    listLabel: 'Hyllyt ylös ja alas:',
  },
  "appProjectLibraryIndex": {
    navTitle: "Tuotekirjasto",
    searchPlace: "Syötä tuotteen nimi, jota haluat etsiä",
    brandPlace: "Valitse tuotemerkki",
    classPlace: 'Valitse luokka',
    searchOptions1: "Palauta",
    searchOptions2: 'Vahvista',
    salesLabel: 'Sales:',
    dialogAffirm: 'Vahvista',
    dialogClose: 'Peruuta',
    productPackageLabel1: "Product Package",
    productPackageLabel2: "(Quickly Add Product)",

    selectAll: 'Multiple Choice',
    selectHint: 'Only supports up to 50 products',
    submitOptions: 'Submit',
    dialogHint: 'Are you sure to batch list this product?',
  },
  'appProductPackageIndex': {
    navTitle: 'Product Package',
    listLabel1: 'Recommended star rating:',
    listLabel2: 'Synchronization status:',
    listLabel3: 'Product Quantity:',
    optionsBtn: 'Sync',
    dialogHint: 'Are you sure you want to use this product package?',
    dialogOptions1: 'Cancel',
    dialogOptions2: 'Confirm',
    messageToast: 'Submission successful, product information synchronization in progress...',
  },
  'appProductPackageList': {
    navTitle: "Product Package Details",
    salesLabel: "Sales:",
  },
  "appProductFirstIndex": {
    navTitle: 'Tuotetiedot',
    nextStep: 'Seuraava vaihe',
    label1: 'Tuotteen nimi:',
    label2: 'Tuotteen merkki:',
    label3: 'Tuoteluokka:',
    label4: 'Vähimmäisostohinta:',
    label5: 'Vähimmäismyyntihinta:',
    label6: 'Minimi markkinahinta:',
    label7: 'Kansikuva:',
    label8: 'Tuotekuva:',
  },
  'appProductNextStepIndex': {
    navTitle: 'Tuotteen tiedot',
    previous: 'edellinen vaihe',
    navBtn: 'Lähetä',
    label1: 'Ostohinta:',
    placeHint: 'Syötä',
    label2: 'Myyntihinta:',
    label3: 'Markkinahinta:',
    specLabel: 'Specification attribuutti:',
    optionBtn: "Vahvista",
    validateMessage1: "Alkuperäinen hinta ei voi olla ostohintaa alhaisempi",
    validateMessage2: 'Alkuperäinen hinta ei voi olla alempi kuin nykyinen myyntihinta',
    validateMessage3: "Myyntihinta ei voi olla ostohintaa alhaisempi",
    validateMessage4: "Myyntihinta ei voi olla suurempi kuin alkuperäinen hinta",
    shelvesTitle: 'Haluatko ladata tai purkaa hyllyt? ',
    shelvesOptions1: "Ei",
    shelvesOptions2: 'Kyllä',
  },
  "appOrderIndex": {
    tagsLabel1: "Kaikki",
    orderTotalNumber: 'Total order number',
    listLabel1: 'Tilauksen päivämäärä:',
    listLabel2: 'Kokonaismyynti:',
    listLabel3: 'Tuotteen määrä:',
    listLabel4: 'Tilauksen tila:',
    listLabel5: 'Maksun tila:',
    listHint: 'Osta',
    searchOptionBtn: 'Haku',
    searchPlace: "Syötä haun sisältö",
    searchDateStartPlace: 'Aloitusaika',
    searchDateEndPlace: 'End time',
    searchDateAffirm: 'confirm',
    searchDateClose: 'Cancel',
    searchTypePayTitle: 'Maksun tila',
    payTags1: "Kaikki",
    payTags2: 'Odottaa maksua',
    payTags3: 'Maksettu',
    searchTypeOrderTitle: 'Tilauksen tila',
    searchOptions1: 'Nollaa',
    searchOptions2: 'Vahvista',
    purDialogTitle: "Osta kaikki",
    purDialogLabel1: 'Ostosumma:',
    purDialogLabel2: 'Myynnin määrä:',
    purDialogLabel3: 'Tuoton määrä:',
    purOptions: "Vahvista",
    purRowTitle: 'Osta',
    purRowLabel1: 'Ostosumma:',
    purRowLabel2: 'Myynnin määrä:',
    purRowLabel3: 'Tuottojen määrä:',
  },
  "appOrderDetailIndex": {
    navTitle: 'Tilauksen tiedot',
    detailInfoLabel1: 'Tilausnumero:',
    detailInfoLabel2: 'Tilausaika:',
    detailInfoLabel3: 'Maksutapa:',
    detailInfoLabel4: 'Tilauksen tila:',
    detailInfoLabel5: 'Maksun tila:',
    productLabel1: "Tuotetiedot",
    productAttributesLabel: 'Tuoteattribuutit:',
    productAccountLabel1: 'Myynti:',
    productAccountLabel2: 'Ostosumma:',
    countInfoLabel1: 'Kokonaismyynti:',
    countInfoLabel2: 'Ostoksen kokonaissumma:',
    countInfoLabel3: 'Kokonaistuotto:',
    countInfoLabel4: 'Kokonaismäärä:',
    countInfoLabel5: 'Alennussuhde:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Tilaa logistiikka',
    logisticsLabel: 'Tilaa',
    listLabel1: "Nimi:",
    listLabel2: "Puhelin:",
    listLabel3: "Postinumero:",
    listLabel4: "Osoite:"
  },
  "appOrderDetailRecord": {
    navTitle: 'Tilaustietue',
    logisticsLabel: 'Tilaa',
    recordEnumLabel1: 'Asiakas luo tilauksen',
    recordEnumLabel2: 'Asiakkaan maksumääräys',
    recordEnumLabel3: 'Kaupan ostotilaus',
    recordEnumLabel4: 'Tilauksen toimitus alkaa',
    recordEnumLabel5: 'Käyttäjä vahvistaa vastaanottamisen',
    recordEnumLabel6: "Järjestelmän takaisinmaksu saapuu",
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Version päivitys, vahvista? ',
    loadingTitle: 'Ladataan...',
    systemUpgrade: "Järjestelmän päivitys",
    upgradeImmediately: 'Vahvista',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Ladataan", // Ladataan
    finishedText: "Ei mitään", //Ei mitään
    loadingText: "Ladataan", // Ladataan
    nullText: "Ei vielä tietoja",
    loadingFinished: "Lataus valmis",
    listNullText: "Ei enää",
    pullIngText: 'Vedä alas päivittääksesi',
    loosingText: 'Release to refresh',
    pullingSuccess: 'Pull-down loading onnistui',
    webSocketSuccess: "Sinulla on uusi viesti",
    imLinkSuccess: 'Pikaviestipalveluyhteys onnistui! ',
    imNonsupport: "Tämä selain ei tue websocketia",
    imLinkLoading: 'Pikaviesti linkittää, odota...',
    imLinkErrorLoading: 'Pikaviestintäyhteys epäonnistui, päivitä sivu tai ota yhteyttä kehittäjään! ',
    dialogHint: "Onko sinulla jo tili?",
    dialogClose: "Register",
    dialogAffirm: "Login",
  },
};
export default obj;
