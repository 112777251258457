<template>
  <div class="shopInfo-edit-warper" id="shopInfoEdit">
    <div class="page-header">
      <nav-tab> </nav-tab>
    </div>
    <div class="shopInfo-edit-body">
      <div class="form-warper">
        <div class="filed-list">
          <p class="label flexbox">{{ language.formLabel2 }}</p>
          <div class="list-form flexbox">
            <van-field
              v-model="shopInfo.name"
              :placeholder="language.formLabel2"
            />
          </div>
        </div>
        <div class="filed-list">
          <p class="label flexbox">{{ language.formLabel7 }}</p>
          <div class="list-form flexbox textarea-from">
            <van-field
              v-model="shopInfo.introduce"
              :placeholder="language.formLabel7"
              type="textarea"
              rows="2"
              autosize
            />
          </div>
        </div>
        <div class="filed-list">
          <p class="label flexbox">{{ language.formLabel1 }}</p>
          <div class="list-form flexbox">
            <van-uploader
              :after-read="logoUpload"
              :before-read="beforeRead"
              class="upload-images"
            >
              <div class="images imgFlex">
                <img v-lazy="getImgUrl(shopInfo.image)" />
              </div>
            </van-uploader>
          </div>
        </div>
        <div class="filed-list">
          <p class="label flexbox">{{ language.formLabel8 }}</p>
          <div class="list-form flexbox">
            <van-uploader
              :after-read="marketUpload"
              :before-read="beforeRead"
              class="upload-images"
            >
              <div class="images imgFlex">
                <img v-lazy="getImgUrl(shopInfo.marketImage)" />
              </div>
            </van-uploader>
          </div>
        </div>
        <div class="filed-list">
          <p class="label flexbox">{{ language.formLabel9 }}</p>
          <div class="list-form flexbox">
            <van-uploader
              :after-read="backgroundUpload"
              :before-read="beforeRead"
              class="upload-images"
            >
              <div class="images imgFlex">
                <img v-lazy="getImgUrl(shopInfo.backgroundImage)" />
              </div>
            </van-uploader>
          </div>
        </div>
        <div class="filed-options">
          <van-button class="submit-btn" @click="onSubmit">
            {{ language.optionsBtn }}
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import userApi from "@/api/userApi.js";
import imgApi from "@/utils/projectConfig.js";
export default {
  name: "shopInfoEditIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      imgUrl: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      lazyImg: require("../../../assets/shopIcon.png"),
      shopInfo: {},
      certificateLeft: "",
      certificateRight: "",
    };
  },
  components: {
    navTab,
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("shopInfoEdit").style["height"] =
        seeHeight + "px";
    });
    this.getShopInfo();
  },
  methods: {
    async onSubmit() {
      let params = this.shopInfo;
      const { code, message } = await userApi.setStore_info(params);
      if (code === 200) {
        this.$toast.success(message);
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }
    },
    logoUpload(file) {
      this.uploadEnclosure(file, "logoUpload");
    },
    marketUpload(file) {
      this.uploadEnclosure(file, "marketUpload");
    },
    backgroundUpload(file) {
      this.uploadEnclosure(file, "backgroundUpload");
    },
    certificateUpload() {
      return false;
    },
    // 上传图片
    async uploadEnclosure(file, key) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("type", "shopImage");
      userApi.importUpload(formData).then(({ code, data }) => {
        if (code == 200) {
          if (key === "logoUpload") {
            this.shopInfo.image = data.url;
            let logoObj_ = JSON.parse(localStorage.getItem("loginInfo_"));
            logoObj_.image = data.url;
            localStorage.setItem("loginInfo_", JSON.stringify(logoObj_));
          }
          if (key === "marketUpload") {
            this.shopInfo.marketImage = data.url;
          }
          if (key === "backgroundUpload") {
            this.shopInfo.backgroundImage = data.url;
          }
        }
      });
    },
    async getShopInfo() {
      const params = {
        cert: true,
      };
      const { code, data } = await userApi.getShopInfoPort(params);
      if (code == 200) {
        this.shopInfo = data;
        this.shopImg = data.image;
        this.certificateLeft = data.certFrontImage;
        this.certificateRight = data.certReverseImage;
      }
    },
    beforeRead(file) {
      let title;
      const typeEnum = [
        "image/jpg",
        "image/JPG",
        "image/png",
        "image/PNG",
        "image/JPEG",
        "image/jpeg",
      ];
      let isShow = false;
      if (!typeEnum.includes(file.type)) {
        isShow = true;
      }
      if (isShow) {
        title = this.language.fileFail.replace(/&/g, "jpg,png,jpeg");
        this.$toast({
          message: title,
          type: "fail",
          duration: 1800,
        });
        return false;
      } else {
        return true;
      }
    },
    getImgUrl(url) {
      if (url) {
        return this.imgUrl + url;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shopInfo-edit-warper {
  width: 100%;
  height: 100vh;
  .shopInfo-edit-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    background-color: #fff;
    .form-warper {
      padding: 30px;
      width: 100%;
      // height: calc(100% - 110px);
      overflow-y: scroll;
      .filed-list {
        margin-bottom: 30px;

        .label {
          font-size: 24px;
          color: $color3;
          margin-bottom: 20px;
        }
        .list-form {
          .upload-images {
            width: 240px;
            height: 180px;

            background-color: #fff;
            ::v-deep .van-uploader__wrapper {
              width: 100%;
              height: 100%;
              // border-radius: 50%;
              .van-uploader__input-wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                // border-radius: 50%;
                overflow: hidden;
                i {
                  font-size: 42px;
                  font-weight: 600;
                }
                .images {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          ::v-deep .van-cell {
            border: 1px solid rgb(238, 238, 238);
            box-sizing: border-box;
            height: 88px;
            border-radius: 8px;
            padding: 20px 32px;

            .van-cell__value {
              .van-field__control {
                display: block;
                box-sizing: border-box;
                width: 100%;
                min-width: 0;
                margin: 0;
                padding: 0;
                font-size: 24px;
                color: #323233 !important;
                line-height: inherit;
                text-align: left;
                background-color: transparent;
                border: 0;
                resize: none;
                -webkit-user-select: auto;
                -moz-user-select: auto;
                user-select: auto;
              }
            }
          }
        }
        .textarea-from {
          ::v-deep .van-cell {
            height: auto;
            max-height: 420px;
          }
        }
      }
      .filed-options {
        // position: fixed;
        // bottom: 30px;
        // left: 0;
        width: 100%;
        margin-top: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 750px;
        margin: auto !important;
        background-color: #fff;
        .submit-btn {
          width: 80%;
          color: #fff;
          background: $blur;
          font-size: 26px;
          border-radius: 12px;
          border: 1px solid $blur;
        }
      }
    }
  }
}
</style>
