<template>
  <div class="user-page-warper" id="userPageId">
    <div class="user-contain">
      <div class="user-header-boxers">
        <div class="user-info-boxers flexbox">
          <div class="user-img imgFlex" v-lazy-container="{ selector: 'img' }">
            <img
              :data-error="require('../../assets/shop-default.png')"
              :data-loading="require('../../assets/shop-default.png')"
              :data-src="getImgUrl()"
            />
          </div>
          <div class="user-info">
            <div class="title text1">{{ shopInfoObj.account }}</div>
            <div class="leaver-logo flexbox">
              <div
                v-for="(svgItem, svgIndex) in 5"
                :key="svgIndex"
                :class="[
                  svgIndex + 1 <= stareLever && 'active-leaver',
                  'leaver-item',
                ]"
              >
                <svg-icon icon-class="leaver-svg"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-body">
        <div class="user-blance-boxers">
          <div class="blance-info">
            <div class="value flexbox">
              <span>{{ blanceValue }}</span>
              <p class="svg-icon flexbox">
                <svg-icon
                  :icon-class="blanceSvg"
                  @click="onChangeBlance"
                ></svg-icon>
                <svg-icon icon-class="refresh-svg" @click="onLoad"></svg-icon>
              </p>
            </div>
            <p class="label">{{ language.blanceLabel }}</p>
          </div>
          <div class="blance-options flexbox">
            <van-button @click="onChangeLine('withdraw')">
              {{ language.blanceOption1 }}
            </van-button>
            <van-button @click="onChangeLine('recharge')">
              {{ language.blanceOption2 }}
            </van-button>
          </div>
        </div>
        <div class="body-income-boxers flexbox">
          <div class="income-left flexbox">
            <div class="svg-icon flexbox">
              <div class="svg-boxers flexbox">
                <svg-icon icon-class="income-svg1"></svg-icon>
              </div>
            </div>
            <div class="income-value flexbox">
              <p class="title">{{ language.incomeLabel1 }}</p>
              <p class="value">
                <span>{{ moneyIcon }}</span>
                <span>{{ shopInfoObj.profit }}</span>
              </p>
            </div>
          </div>
          <div class="income-right flexbox">
            <div class="svg-icon flexbox">
              <div class="svg-boxers flexbox">
                <svg-icon icon-class="income-svg2"></svg-icon>
              </div>
            </div>
            <div class="income-value flexbox">
              <p class="title">{{ language.incomeLabel2 }}</p>
              <p class="value">
                <span>{{ moneyIcon }}</span>
                <span>{{ shopInfoObj.withdrawal }}</span>
              </p>
            </div>
          </div>
        </div>
<!--        <div class="body-share-bg">-->
<!--          <div class="img-share-bg imgFlex">-->
<!--            <img-->
<!--              src="../../assets/dev/userIcon1.png"-->
<!--              @click="onChangeLine('inviteEvent')"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
        <div class="body-list-boxers">
          <div class="list-conation-boxers">
            <div class="list-item" @click="onChangeLine('shopInfo')">
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-shop"
                  ></svg-icon>
                  <span>{{ language.listLabel1 }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
            <div class="list-item" @click="onChangeLine('withdrawSet')">
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-withdraw"
                  ></svg-icon>
                  <span>{{ language.listLabel2 }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
          </div>

          <div class="list-conation-boxers">
            <div class="list-item" @click="onChangeLine('fundingRecords')">
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-funds"
                  ></svg-icon>
                  <span>{{ language.listLabel3 }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
            <div class="list-item" @click="onChangeLine('report')">
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-report"
                  ></svg-icon>
                  <span>{{ language.listLabel4 }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
          </div>
          <div class="list-conation-boxers">
            <div class="list-item" @click="onChangeLine('activity')">
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-discount"
                  ></svg-icon>
                  <span>{{ language.listLabel5 }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
          </div>
          <div class="list-conation-boxers">
            <div class="list-item" @click="onChangeLine('loan')">
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-loan"
                  ></svg-icon>
                  <span>{{ language.listLabel7 }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
            <div class="list-item" @click="onChangeLine('toMall')">
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-mall"
                  ></svg-icon>
                  <span>{{ language.listLabel8 }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
            <div
              class="list-item"
              @click="onChangeLine('download')"
              v-if="$permissionObj.downloadApp"
            >
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-application"
                  ></svg-icon>
                  <span>{{ language.listLabel9 }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
          </div>
          <div class="list-conation-boxers" v-if="$permissionObj.outBtn">
            <div class="list-item" @click="onChangeLine('over')">
              <div class="item flexbox">
                <div class="left flexbox">
                  <svg-icon
                    width="20px"
                    height="20px"
                    icon-class="user-out"
                  ></svg-icon>
                  <span>{{ language.listLabelLast }}</span>
                </div>
                <svg-icon icon-class="app-arrow" class="arrow"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-page">
      <footerCard></footerCard>
    </div>
  </div>
</template>

<script>
import footerCard from "@/layout/footerCard.vue";
import userApi from "@/api/userApi.js";
import imgApi from "@/utils/projectConfig";
export default {
  name: "appUserIndex",
  data() {
    return {
      moneyIcon: localStorage.getItem("moneyIcon"),
      thumbnail: this.$thumbnail,
      imgApi: imgApi.imgUrl,
      language: this.$language(),
      blanceSvg: "eye-show",
      userImg: "",
      stareLever: 0,
      blanceValue: 0,
      shopInfoObj: {
        balance: "0.00",
        profit: "0.00",
        withdrawal: "0.00",
      },
    };
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("userPageId").style["height"] = seeHeight + "px";
    });
    this.onLoad();
  },
  mounted() {},
  methods: {
    getImgUrl() {
      if (this.shopInfoObj.image) {
        return this.imgApi + this.shopInfoObj.image + this.thumbnail;
      }
    },
    onLoad() {
      this.getShopInfo();
    },
    async getShopInfo() {
      const params = {
        cert: true,
      };
      const { code, data } = await userApi.getShopInfoPort(params);
      if (code == 200) {
        data.balance = parseFloat(data.balance / 100).toFixed(2);
        data.profit = parseFloat(data.profit / 100).toFixed(2);
        data.withdrawal = parseFloat(data.withdrawal / 100).toFixed(
          2
        );
        this.shopInfoObj = data;
        this.stareLever = data.star;
        this.blanceValue = this.moneyIcon + this.shopInfoObj.balance;
      }
    },
    onChangeBlance() {
      if (this.blanceSvg === "eye-show") {
        this.blanceSvg = "eye-hide";
        this.blanceValue = "******";
        return false;
      } else {
        this.blanceSvg = "eye-show";
        this.blanceValue = this.moneyIcon + this.shopInfoObj.balance;
      }
    },
    onChangeLine(key) {
      if (key === "inviteEvent") {
        this.$router.push({ path: "/appInviteIndex" });
        return false;
      }
      if (key === "withdraw") {
        this.$router.push({ path: "/appWithdrawIndex" });
        return false;
      }
      if (key === "recharge") {
        this.$router.push({ path: "/appAmountRechargeIndex" });
        return false;
      }
      if (key === "shopInfo") {
        this.$router.push({ path: "/appShopInfoIndex" });
        return false;
      }
      if (key === "withdrawSet") {
        // 提现方式设置
        this.$router.push({ path: "/appSetWithdrawalIndex" });
        return false;
      }
      if (key === "fundingRecords") {
        //资金记录
        this.$router.push({ path: "/appFundingRecordsIndex" });
        return false;
      }
      if (key === "report") {
        //报表
        this.$router.push({ path: "/appStatisticsReportIndex" });
        return false;
      }
      if (key === "activity") {
        //活动
        this.$router.push({ path: "/appDiscountActivity" });
        return false;
      }
      if (key === "loan") {
        //贷款
        this.$globalCommon.$openParamUrl(this.$globalCommon.$loanHost, {
          shopId: this.shopInfoObj.id,
        });
        return false;
      }
      if (key === "toMall") {
        //前往商城
        this.$globalCommon.$openParamUrl(this.$globalCommon.$mallHost);
        return false;
      }
      if (key === "download") {
        //下载应用程序
        this.$router.push({ path: "/downloadAppIndex" });
        return false;
      }
      if (key === "over") {
        localStorage.clear();
        sessionStorage.clear();
        this.$socketApi.closeWebSocket();
        this.$router.push({ path: "/appLoginIndex" });
      }
    },
  },
  components: {
    footerCard,
  },
};
</script>

<style lang="scss" scoped>
.user-page-warper {
  width: 100%;
  height: 100%;

  .user-contain {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: scroll;

    .user-header-boxers {
      width: 100%;
      height: 360px;
      background-color: $blur;
      padding-top: 100px;

      .user-info-boxers {
        padding: 0 30px;

        .user-img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          margin-right: 30px;
          background-color: #fff;
        }

        .user-info {
          color: #fff;

          .title {
            font-weight: 700;
            font-size: 36px;
            line-height: 1;
          }

          .leaver-logo {
            margin-top: 10px;

            .leaver-item {
              margin-right: 4px;
              color: #cdcdcd !important;
              margin-right: 6px;
            }

            .active-leaver {
              color: #ffd700 !important;
            }
          }
        }
      }
    }

    .user-body {
      padding: 0 30px;

      .user-blance-boxers {
        width: 100%;
        background-color: #fff;
        margin-top: -70px;
        border-radius: 12px;

        .blance-info {
          padding: 20px 32px;

          .value {
            line-height: 1.5;

            span {
              display: inline-block;
              color: #323233;
              font-size: 40px;
              font-weight: 700;
              margin-right: 24px;
            }

            .svg-icon {
              svg:nth-child(1) {
                color: #ccc;
                width: 36px !important;
                height: 36px !important;
                margin-right: 15px;
              }

              svg:nth-child(2) {
                color: #000;
                width: 28px !important;
                height: 28px !important;
              }
            }
          }

          .label {
            font-size: 24px;
            padding-top: 10px;
            line-height: 1.5;
            color: #323233;
          }
        }

        .blance-options {
          margin-top: 30px;
          padding: 20px 32px;
          display: flex;
          align-items: center;
          justify-content: space-around;

          ::v-deep .van-button {
            width: 40%;
            border-radius: 8px;
            min-height: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: $blur;
            background-color: #fff;
            font-size: 24px;
            color: $blur;
          }

          .van-button:nth-child(2) {
            color: #fff;
            background-color: $blur;
          }
        }
      }

      .body-income-boxers {
        width: 100%;
        align-items: flex-start;
        margin-top: 20px;
        background-color: #fff;
        border-radius: 12px;
        padding: 30px;
        justify-content: space-between;

        .income-left,
        .income-right {
          width: 50%;
          height: 100%;
          align-items: flex-start;

          .svg-icon {
            height: 100%;

            .svg-boxers {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              background-color: $blur;
              justify-content: center;
            }

            svg {
              width: 30px !important;
              height: 30px !important;
            }
          }

          .income-value {
            padding-left: 20px;
            width: calc(100% - 80px);
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .title {
              width: 100%;
              color: #323233;
              font-size: 28px;
              display: inline-block;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }

            .value {
              margin-top: 15px;
              color: #000;
              font-size: 30px;
              font-weight: 600;
              width: 100%;

              span {
                width: 100%;
              }
            }
          }
        }
      }

      .body-share-bg {
        width: 100%;
        margin-top: 20px;
        border-radius: 12px;

        .img-share-bg {
          width: 100%;
          height: 192px;
          border-radius: 12px;
        }
      }

      .body-list-boxers {
        margin-top: 20px;

        .list-conation-boxers {
          border-radius: 12px;
          background: #fff;
          margin-bottom: 20px;

          .list-item {
            padding: 0px 32px;

            .item {
              padding: 20px 0;
              border-bottom: 1px solid #f0f0f0;
              justify-content: space-between;
              .left {
                svg {
                  width: 36px !important;
                  height: 36px !important;
                }
              }
            }

            span {
              color: #333;
              font-size: 28px;
              line-height: 1.5;
              margin-left: 20px;
            }
          }

          .arrow {
            color: #969799;
          }
        }
      }
    }
  }
}
</style>
