<template>
  <div id="app">
    <transitionCard v-if="transitionIsShow" @tiTokClose="tiTokClose" />
    <template v-else>
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
      </transition>
      <transition name="fade-transform" mode="out-in">
        <router-view v-if="!$route.meta.keepAlive"></router-view> </transition
    ></template>
  </div>
</template>
<script>
import websocketApi from "@/api/websocketApi";
import transitionCard from "@/layout/transition-index.vue";
import { getParams } from "@/utils/publicMethods";
export default {
  name: "App",
  components: { transitionCard },
  data() {
    return {
      language: this.$language("messageLanguage"),
      unreadNumber: "",
      countNumber: 0,
      imIsShow: false,
      transitionIsShow: false,
    };
  },

  updated() {
    if (localStorage.getItem("authenticationToKen_") && !this.imIsShow) {
      this.imIsShow = true;
      this.$socketApi.initWebSocket(this.getSocketResult);
    }
  },
  created() {
    localStorage.setItem("moneyIcon", "$");
    this.transitionCall();
    this.usuallyParamsUrlCall();

    if (localStorage.getItem("authenticationToKen_") && !this.imIsShow) {
      this.imIsShow = true;
      this.$socketApi.initWebSocket(this.getSocketResult);
    }
  },
  methods: {
    usuallyParamsUrlCall() {
      let code = getParams("code");
      let language = getParams("language");
      language = decodeURI(language);
      if (language && language !== "null" && language !== "undefined") {
        localStorage.setItem("languageType", language);
      }
      let query = this.$route.query;
      if (Object.values(query).length > 0 && query.code) {
        localStorage.setItem("code", query.code);
      } else if (code == 0 || code) {
        localStorage.setItem("code", code);
      }
      this.$forceUpdate();
    },
    tiTokClose() {
      this.transitionIsShow = false;
    },
    transitionCall() {
      if (this.$globalCommon.$environment == "tiktok") {
        this.transitionIsShow = true;
        const _tikTok = sessionStorage.getItem("tikTok_data");
        const data = getParams("data");
        if (!data && !_tikTok) {
          alert("Illegal request!");
          return false;
        }
        this.$resetSetItem("tikTok_data", "tikTok_data", data || _tikTok);
        window.addEventListener("error1002", (val) => {
          this.transitionIsShow = true;
        });
        window.addEventListener("loadingSuccessCount", (val) => {
          this.transitionIsShow = false;
        });
      }
    },
    callBack(val) {
      this.isShow = false;
      if (
        !localStorage.getItem("authenticationToKen_") ||
        (localStorage.getItem("authenticationToKen_") &&
          val.path == "/appLoginIndex")
      ) {
        return false;
      }
      this.isShow = true;
      this.$resetSetItem("websocket", "websocket", JSON.stringify({}));
    },
    getSocketResult(data) {
      console.log(data);
      if (data.type === "register") {
        let params = {
          clientId: data.content,
        };
        this.getRegisterWays(params);
      }
      if (data.type === "text" || data.type === "image") {
        this.unreadMessageGet();
        this.$resetSetItem("websocket", "websocket", JSON.stringify(data));
      }
      this.$resetSetItem("IM_LINK_ERROR", "IM_LINK_ERROR", true);
    },
    // 用户第一次登陆时调用的接口
    async getRegisterWays(params) {
      const { data, code } = await websocketApi.getRegisterPort(params);
      console.log(data, code);
      this.$resetSetItem("IM_LINK_ERROR", "IM_LINK_ERROR", true);
      this.unreadMessageGet();
    },

    // 未读消息广播设置
    async unreadMessageGet() {
      const { data, code } = await websocketApi.getUnreadMessage();
      if (code == 200) {
        this.unreadNumber = Number(data.number);
        this.$resetSetItem("IM_COUNT", "IM_COUNT", this.unreadNumber);
      }
    },
  },
  watch: {
    $route: {
      handler(val, oldValue) {
        console.log(oldValue);
        if (val.path == "/appLoginIndex") {
          this.imIsShow = false;
        }
        this.callBack(val);
      },
      // 深度观察监听
      deep: true,
    },
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
}

body {
  max-width: 750px;
  margin: auto !important;
  background-color: #eff2f6;
}

.van-tabs__wrap {
  max-width: 750px;
  margin: auto !important;
}
.van-popup {
  max-width: 750px;
  margin: auto !important;
}
::-webkit-scrollbar {
  display: none;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.4s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translate(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

input:-webkit-autofill {
  background: transparent;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
</style>
