<template>
  <div class="withdraw-page-warper" id="withdrawPageId">
    <div class="page-header">
      <nav-tab></nav-tab>
    </div>
    <div class="withdraw-body">
      <div class="withdraw-field-list">
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel1 }}</p>
          <van-field
            v-model="formObj.currencySelectType"
            :placeholder="language.formPlace1"
            readonly
            right-icon="arrow-down"
            @click="onClickSelect('select')"
          />
        </div>
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel2 }}</p>
          <van-field
            v-model="formObj.currencyType"
            :placeholder="language.formPlace2"
            readonly
            right-icon="arrow-down"
            @click="onClickSelect('currency')"
          />
        </div>
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel3 }}</p>
          <van-field
            ref="amountInput"
            v-model="formObj.withdrawAmount"
            type="number"
            :placeholder="language.formPlace3"
            :readonly="amountReadonly"
            @click="onClickInput('amount')"
            @keyup.native="onChangeInput('amount')"
          />
        </div>
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel4 }}</p>
          <van-field
            ref="passInput"
            :type="inputType"
            v-model="formObj.withdrawPass"
            :placeholder="language.formPlace4"
            :readonly="passReadonly"
            @click="onClickInput('pass')"
          />
        </div>
        <div class="field-amount-list">
          <p class="amount-list">
            <span>{{ language.balanceLabel }}</span>
            <countTo
              :startVal="0"
              :endVal="shopInfoObj.balance"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </p>
          <p class="amount-list" v-if="balanceRate">
            <span>{{ language.amountLabel }}</span>
            <span>{{ balanceRate }} </span>
          </p>
        </div>
      </div>
      <div class="withdraw-options" v-if="formObj.withdrawAmount">
        <van-button @click="onChangeSubmit">
          {{ language.optionsBtn }}
        </van-button>
      </div>
    </div>
    <van-popup
      v-model="currencyVisibleIsShow"
      position="bottom"
      class="currency-popup"
    >
      <div class="currency-popup-body">
        <van-picker
          show-toolbar
          :confirm-button-text="language.dialogAffirm"
          :cancel-button-text="language.dialogClose"
          :columns="currencyVirtualFailList"
          @confirm="onConfirmPicker"
          @cancel="onCancelPicker"
        />
      </div>
    </van-popup>
    <van-popup v-model="submitVisible" class="submit-pass-popup">
      <div class="popup-body">
        <div class="popup-title">
          <van-icon name="warning" />
          <span>{{ submitVisibleTitle }}</span>
        </div>
        <div class="popup-footer">
          <van-button @click="submitVisible = false">{{
            language.affirmDialogBtn2
          }}</van-button>
          <van-button @click="submitVisibleChange">{{
            language.affirmDialogBtn1
          }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import navTab from "@/layout/navTab";
import withdrawApi from "@/api/withdrawApi";
export default {
  name: "appWithdrawIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      shopInfoObj: { balance: 0.0 },
      formObj: {
        currencySelectType: "",
        currencySelectCode: "",
        currencyType: "",
        currencyTypeCode: null,
        withdrawAmount: "",
        withdrawPass: "",
      },
      currencySelectList: [],
      currencyVirtualFailList: [],
      currencyVirtualList: [],
      currencyFailList: [],
      selectState: "",
      currencyVisibleIsShow: false,
      amountReadonly: true,
      passReadonly: true,
      inputType: "text",
      lastTime: new Date(),
      balanceRate: "",
      submitVisible: false,
      submitVisibleTitle: "",
      submitVisiblePath: "",
    };
  },
  watch: {
    "formObj.currencyType"() {
      this.inputType = "password";
    },
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("withdrawPageId").style["height"] =
        seeHeight + "px";
    });
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onChangeInput(key) {
      setTimeout(() => {
        this.throttle();
      }, 500);
    },
    throttle() {
      //函数执行时的时间
      let now = new Date();
      if (now - this.lastTime > 1000) {
        this.getCountPort();
        //调用成功，上一次调用时间值修改
        this.lastTime = now;
      }
    },
    onClickInput(key) {
      let isShow = this.versionCall();
      if (key == "amount" && isShow) {
        this.amountReadonly = false;
        this.$nextTick(() => {
          this.$refs.amountInput.focus();
        });
      }
      if (key == "pass" && isShow) {
        this.passReadonly = false;
        this.$nextTick(() => {
          this.$refs.passInput.focus();
        });
      }
    },
    async getCountPort() {
      let type,
        chainId = 0,
        id;
      console.log(this.formObj.currencySelectCode);
      if (this.formObj.currencySelectCode == 1) {
        chainId = this.formObj.currencyTypeCode[0];
        id = this.formObj.currencyTypeCode[1];
        type = 2;
      } else {
        id = this.formObj.currencyTypeCode;
        type = 1;
      }
      const params = {
        amount: Number(this.formObj.withdrawAmount) * 100,
        chainId: chainId,
        id: id,
        type: type,
        useType: 2,
      };
      const { code, data } = await withdrawApi.getExchangeRate(params);
      if (code == 200) {
        const { currencyType, withdrawAmount } = this.formObj;
        const { exchangeRateAmount } = data;
        this.balanceRate = `${withdrawAmount}${this.moneyIcon} = ${(
          exchangeRateAmount / 100
        ).toFixed(2)} ${currencyType}`;
      }
    },
    onConfirmPicker(data, val) {
      console.log(data, val);
      if (this.selectState == "select") {
        this.balanceRate = "";
        this.formObj = {
          currencySelectType: "",
          currencySelectCode: "",
          currencyType: "",
          currencyTypeCode: null,
          withdrawAmount: "",
          withdrawPass: "",
        };
        this.formObj.currencySelectType = data;
        this.formObj.currencySelectCode = val;
      }
      if (this.selectState == "currency") {
        if (this.formObj.currencySelectCode === 0) {
          this.formObj.currencyType = this.currencyVirtualFailList[val].code;
          this.formObj.currencyTypeCode = this.currencyVirtualFailList[val].id;
        }
        if (this.formObj.currencySelectCode === 1) {
          const currencyList = [];
          const currencyListCode = [];
          this.currencyVirtualFailList.map((item, index) => {
            if (index == val[0]) {
              currencyList.push(item.text);
              currencyListCode.push(item.value);
              item.children.map((record, recordIndex) => {
                if (recordIndex == val[1]) {
                  currencyList.push(record.text);
                  currencyListCode.push(record.value);
                }
              });
            }
          });
          console.log(currencyListCode, val[1], "currencyListCode");
          this.formObj.currencyType = currencyList.join("/");
          this.formObj.currencyTypeCode = currencyListCode;
        }
      }
      this.currencyVisibleIsShow = false;
      console.log(this.formObj.currencySelectCode);
    },
    onCancelPicker() {
      this.currencyVisibleIsShow = false;
    },
    async onChangeSubmit() {
      let type,
        chainId = 0,
        id;
      if (this.formObj.currencySelectCode == 1) {
        chainId = this.formObj.currencyTypeCode[0];
        id = this.formObj.currencyTypeCode[1];
        type = 2;
      } else {
        id = this.formObj.currencyTypeCode;
        type = 1;
      }
      const params = {
        amount: Number(this.formObj.withdrawAmount) * 100,
        chainId: chainId,
        id: id,
        type: type,
        useType: 2,
        payPassword: this.formObj.withdrawPass,
      };
      const { code, message } = await withdrawApi.set_withdrawal(params);
      if (code == 200) {
        this.$toast.success(message);
        setTimeout(() => {
          this.$router.go(-1);
        }, 500);
      } else if (code == 2000007) {
        //支付密码未设置
        this.submitVisibleTitle = this.language.affirmDialogDialogTitle1;
        this.submitVisible = true;
        this.submitVisiblePath = "/appChangePayPass";
      } else if (code == 3000009) {
        // 提款方式未设置
        this.submitVisibleTitle = this.language.affirmDialogDialogTitle2;
        this.submitVisible = true;

        this.submitVisiblePath = "/appSetWithdrawalIndex";
      } else {
        this.$toast.fail(message);
      }
    },
    submitVisibleChange() {
      this.$router.push({ path: this.submitVisiblePath });
    },
    onClickSelect(key) {
      this.selectState = null;
      this.selectState = key;
      this.currencyVirtualFailList = [];
      if (key == "select") {
        this.currencyVirtualFailList = [
          this.language.currencyType1,
          this.language.currencyType2,
        ];
        this.currencyVisibleIsShow = true;
        return false;
      }
      if (key == "currency") {
        if (!this.formObj.currencySelectType) {
          this.$toast({
            type: "fail",
            message: this.language.currencySelectError,
            duration: 1200,
          });
          return false;
        }
        if (this.formObj.currencySelectCode === 0) {
          this.getCurrencyFiatList();
        }
        if (this.formObj.currencySelectCode == 1) {
          this.getCurrencyVirtualList();
        }
      }
    },
    // 获取法定货币
    async getCurrencyFiatList() {
      if (this.currencyFailList.length) {
        this.currencyVirtualFailList = this.currencyFailList;
        this.currencyVisibleIsShow = true;
        return false;
      }
      const { code, data, message } = await withdrawApi.getCurrencyFiatList();
      if (code == 200) {
        data.map((item) => {
          item.text = item.code;
          item.value = item.id;
        });
        this.currencyFailList = data;
        this.currencyVirtualFailList = data;
        this.currencyVisibleIsShow = true;
      }
    },
    // 获取虚拟货币
    async getCurrencyVirtualList() {
      if (this.currencyVirtualList.length) {
        this.currencyVirtualFailList = this.currencyVirtualList;
        this.currencyVisibleIsShow = true;
        return false;
      }
      const { code, data } = await withdrawApi.getCurrencyVirtualList();
      if (code == 200) {
        data.map((item) => {
          item.text = item.code;
          item.value = item.id;
          item.children = item.tokens;
          item.children.map((child) => {
            child.text = child.name;
            child.value = child.id;
          });
        });
        this.currencyVirtualList = data;
        this.currencyVirtualFailList = data;
        this.currencyVisibleIsShow = true;
      }
    },
    onLoad() {
      this.currencyVirtualFailList = [
        { value: 0, text: this.language.currencyType1 },
        { value: 1, text: this.language.currencyType2 },
      ];
      this.getShopData();
    },
    async getShopData() {
      const params = { cert: true };
      const { code, data } = await withdrawApi.getShopInfoPort(params);
      if (code == 200) {
        data.balance = Number((data.balance / 100).toFixed(2));
        this.shopInfoObj = data;
      }
    },
    versionCall() {
      let isShow = true;
      if (!this.formObj.currencySelectType) {
        this.$toast({
          type: "fail",
          message: this.language.currencySelectError,
          duration: 1200,
        });
        isShow = false;
        return false;
      }
      if (!this.formObj.currencyType) {
        this.$toast({
          type: "fail",
          message: this.language.currencyError,
          duration: 1200,
        });
        isShow = false;
        return false;
      }
      return isShow;
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.withdraw-page-warper {
  width: 100%;
  height: 100vh;

  .page-header {
    border-color: 1px solid currentColor;
  }

  .withdraw-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    background-color: #fff;

    .withdraw-field-list {
      width: 100%;
      padding: 0 24px;
      border-radius: 8px;

      .field-list {
        margin-bottom: 30px;

        .label {
          font-size: 24px;
          color: $color3;
          margin-bottom: 20px;
        }

        .label::before {
          content: "*";
          display: block;
          color: red;
          margin-right: 4px;
        }

        ::v-deep .van-cell {
          border: 1px solid rgb(238, 238, 238);
          box-sizing: border-box;
          height: 88px;
          border-radius: 8px;
          padding: 20px 32px;

          .van-cell__value {
            .van-field__control {
              display: block;
              box-sizing: border-box;
              width: 100%;
              min-width: 0;
              margin: 0;
              padding: 0;
              font-size: 24px;
              color: #323233 !important;
              line-height: inherit;
              text-align: left;
              background-color: transparent;
              border: 0;
              resize: none;
              -webkit-user-select: auto;
              -moz-user-select: auto;
              user-select: auto;
              // -webkit-text-fill-color: #aaa;
            }
          }
        }
      }

      .field-amount-list {
        margin-top: 44px;

        .amount-list {
          margin-top: 12px;
          font-size: 24px;
          color: #999;
          line-height: 1.5;
        }

        .amount-list:nth-child(1) {
          color: $blur;
        }
      }
    }

    .withdraw-options {
      margin-top: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .van-button {
        width: 80%;
        border-radius: 16px;
        background-color: $blur;
        color: #fff;
        font-size: 28px;
        border: 1px solid $blur;
      }
    }
  }

  .currency-popup {
    padding: 20px 30px 40px 30px;
    overflow-y: scroll;
    max-height: 50%;
    min-height: 25%;

    .currency-popup-body {
      height: 100%;

      ::v-deep .van-picker {
        height: 100%;

        .van-picker__toolbar {
          font-size: 32px;

          .van-picker__confirm {
            color: $blur;
          }
        }
      }
    }
  }

  .submit-pass-popup {
    width: 520px;
    border-radius: 8px;
    padding: 40px 30px;

    .popup-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .popup-title {
        width: 80%;
        font-size: 32px;
        color: #323233;
        font-weight: 600;
        line-height: 1.5;
        text-align: left;
        margin-bottom: 40px;

        i {
          color: $blur;
          margin-right: 12px;
          font-weight: 400;
        }
      }

      .popup-footer {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ::v-deep .van-button {
          width: 45%;
          padding: 16px 20px;
          height: auto;
          border-radius: 8px;
          font-size: 24px;
          color: #fff;
          border: 1px solid $blur;
          background: $blur;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .van-button:nth-child(1) {
          background-color: #fff;
          color: $blur;
        }
      }
    }
  }
}
</style>
<style lang="scss">
input {
  caret-color: #323233;
}

input:-webkit-autofill {
  background: transparent;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #aaa !important;
}
</style>
