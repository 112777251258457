<template>
  <!-- 资金记录页 -->
  <div class="fundingRecords-warper" id="fundingRecords">
    <div class="page-header">
      <nav-tab>
        <template #navRight>
          <svg-icon
            icon-class="filter-svg"
            class="filter-svg"
            @click="searchIsShow = true"
          ></svg-icon>
        </template>
      </nav-tab>
    </div>
    <div class="fundingRecords-body">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        :loading-text="loadingText"
        @load="onLoad"
      >
        <div class="records-list-boxers" v-if="recordsListData.length">
          <div
            class="records-list flexbox"
            v-for="(item, index) in recordsListData"
            :key="index"
          >
            <div class="records-state">
              <img :src="getUrlItem(item.typeCalculation)" alt="" />
            </div>
            <div class="record-info">
              <p class="info-line flexbox">
                <span class="label">{{ item.typeLang }}</span>
                <span :class="['value', amountClassCall(item.typeCalculation)]">
                  {{ item.typeCalculation }}
                  {{ moneyIcon }}{{ item.amountMoney }}
                </span>
              </p>
              <p class="info-line flexbox">
                <span class="label time">{{ item.createAt }}</span>
                <span class="value">
                  {{ moneyIcon }}{{ item.balanceMoney }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <default-page v-else></default-page>
      </van-list>
    </div>
    <van-popup
      v-if="searchIsShow"
      v-model="searchIsShow"
      position="top"
      @close="handlerClose"
      round
      class="popup-search"
    >
      <div class="search-dialog">
        <div class="search-date-title">
          {{ language.searchDateTitle }}
        </div>
        <div class="search-date-field flexbox">
          <van-field
            v-model="searchObj.startTime"
            readonly
            :placeholder="language.stareTimePlace"
            @click="onClickTime('start')"
          ></van-field>
          <span>——</span>
          <van-field
            readonly
            v-model="searchObj.endTime"
            :placeholder="language.endTimePlace"
            @click="onClickTime('end')"
          ></van-field>
        </div>
        <div class="search-funding-select">
          <div class="title">{{ language.searchRecordsTitle }}</div>
          <div class="list flexbox">
            <div
              :class="['list-item', activeIndex == item.id && 'item-active']"
              v-for="(item, index) in fundingSelectList"
              :key="index"
              @click="onChangeTag(item, index)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="search-options">
          <van-button @click="onChangeResult">
            {{ language.searchOptions1 }}
          </van-button>
          <van-button @click="handlerSubmitSearch">
            {{ language.searchOptions2 }}
          </van-button>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="dateIsShow"
      position="bottom"
      round
      :style="{ height: '38%' }"
    >
      <van-datetime-picker
        class="date-picker"
        :show-toolbar="true"
        :confirm-button-text="language.searchDateAffirm"
        :cancel-button-text="language.searchDateClose"
        v-model="searchObj.currDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="affirmTime"
        @cancel="onChangeTimeCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import navTab from "@/layout/navTab";
import moment from "moment-timezone";
import { timeZone } from "@/utils/timeZone";
import { formatDate } from "@/utils/index";
import fundingRecordApi from "@/api/fundingRecordApi.js";
export default {
  name: "appFundingRecordsIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      finishedText: this.$language("messageLanguage").finishedText,
      loadingText: this.$language("messageLanguage").loadingText,
      loading: false,
      finished: false,
      searchObj: {
        total: 0,
        page: 1,
        size: 20,

        currDate: [],
        startTime: "",
        endTime: "",
      },
      activeIndex: "",
      recordsListData: [],
      searchIsShow: false,
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(2100, 12, 31),
      dateIsShow: false,
      timeZone: timeZone,
      timeTypeClick: "",
      fundingSelectList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("fundingRecords").style["height"] =
        seeHeight + "px";
    });
    this.onChangeResult();
    this.getSystemConfigData();
  },
  methods: {
    moment,
    onChangeTag(record, index) {
      this.activeIndex = record.id;
    },
    onChangeResult() {
      this.searchObj.startTime = "";
      this.searchObj.endTime = "";
      this.activeIndex = 0;
    },
    onClickTime(key) {
      let currDate = moment(new Date()).tz(this.timeZone).format("YYYY-MM-DD");
      this.searchObj.currDate = new Date(currDate);
      if (key == "start") {
        if (this.searchObj.startTime) {
          this.searchObj.currDate = new Date(this.searchObj.startTime);
        }
      }
      if (key == "end") {
        if (this.searchObj.endTime) {
          this.searchObj.currDate = new Date(this.searchObj.endTime);
        }
      }
      this.timeTypeClick = key;
      this.dateIsShow = true;
    },
    affirmTime(date) {
      const time = formatDate(date, "yyyy-MM-dd") + " " + "23:59:59";
      if (this.timeTypeClick == "start") {
        this.searchObj.startTime = moment(time)
          .tz(this.timeZone)
          .format("YYYY-MM-DD");
      }
      if (this.timeTypeClick == "end") {
        this.searchObj.endTime = moment(time)
          .tz(this.timeZone)
          .format("YYYY-MM-DD");
      }
      this.dateIsShow = false;
    },
    async getSystemConfigData() {
      const { code, data } = await fundingRecordApi.getSystemConfigList();
      if (code == 200) {
        this.fundingSelectList = [{ id: "", label: this.language.typeAll }];
        for (const key in data.assetsLang) {
          this.fundingSelectList.push({ id: key, label: data.assetsLang[key] });
        }
      }
    },
    onChangeTimeCancel() {
      this.timeTypeClick = "";
      this.dateIsShow = false;
    },
    handlerClose() {
      this.searchIsShow = false;
    },
    handlerSubmitSearch() {
      this.searchIsShow = false;
      this.searchObj.page = 1;
      this.recordsListData = [];
      this.finished = false;
      this.getFundingRecordsList();
    },
    onLoad() {
      this.getFundingRecordsList();
    },
    async getFundingRecordsList() {
      this.loading = true;
      const params = {
        type: this.activeIndex,
        startTime: this.searchObj.startTime,
        endTime: this.searchObj.endTime,
        page: this.searchObj.page,
        size: this.searchObj.size,
      };
      const { code, data } = await fundingRecordApi.getRecordList(params);
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.amountMoney = (item.amount / 100).toFixed(2);
          item.balanceMoney = (item.balance / 100).toFixed(2);
        });
        this.recordsListData = this.recordsListData.concat(data.list);
        this.searchObj.total = data.total;
        if (this.recordsListData.length >= this.searchObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.searchObj.page++;
        }
      }
    },
    getUrlItem(type) {
      let state;
      if (type == "+") {
        state = 1;
      }
      if (type == "-") {
        state = 2;
      }
      return require(`../../assets/fundingIcon${state}.png`);
    },
    amountClassCall(type) {
      if (type == "+") {
        return "push";
      }
      if (type == "-") {
        return "pull";
      }
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.fundingRecords-warper {
  width: 100%;
  height: 100vh;
  .page-header {
    .filter-svg {
      width: 32px !important;
      height: 32px !important;
    }
  }
  .fundingRecords-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    .van-list {
      .records-list-boxers {
        height: 100%;
        padding: 24px 30px;
        background-color: #fff;
        border-radius: 8px;
      }
      .records-list {
        border-bottom: 1px solid #eee;
        margin-bottom: 30px;
        padding-bottom: 24px;
        .records-state {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .record-info {
          width: calc(100% - 40px);
          padding-left: 20px;
          .info-line {
            justify-content: space-between;
            margin-bottom: 12px;
            .label {
              font-size: 28px;
              color: $color3;
              font-weight: 600;
              line-height: 1.5;
            }
            .value {
              font-size: 24px;
              color: #999;
              line-height: 1.5;
            }
            .time {
              color: #999;
              font-weight: 400;
              font-size: 24px;
            }
            .push {
              color: #ff3e3e;
            }
            .pull {
              color: #0ecb81;
            }
          }
          .info-line:last-child {
            margin-bottom: 0;
          }
        }
      }
      .records-list:last-child {
        margin-bottom: 0;
      }
    }
  }
  .popup-search {
    max-height: 80%;
    min-height: 28%;
    padding: 40px 30px;
    overflow-y: scroll;
    border-radius: 0 0 56px 56px;
    .search-dialog {
      .search-date-title {
        font-size: 28px;
        font-weight: 600;
        line-height: 2;
        color: $color1;
        margin-bottom: 20px;
      }
      .search-date-field {
        margin-bottom: 30px;
        ::v-deep .van-cell {
          border: 1px solid $blur;
          border-radius: 42px;
          padding: 0 12px 0 20px;
          .van-field__control {
            padding: 12px 0;
            font-size: 24px;
            color: $color1 !important;
          }
          .van-button {
            padding: 10px 30px;
            background-color: $blur;
            color: #fff;
            font-size: 24px;
            border-radius: 42px;
            border: 1px solid $blur;
          }
        }
      }
      .search-funding-select {
        .title {
          font-size: 28px;
          font-weight: 600;
          line-height: 2;
          color: $color1;
          margin-bottom: 20px;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          .list-item {
            display: inline-block;
            padding: 8px 14px;
            background: rgba(220, 220, 220, 0.6);
            color: $color1;
            font-size: 22px;
            line-height: 1.5;
            margin-left: 15px;
            margin-bottom: 20px;
            border-radius: 6px;
            text-align: left;
            cursor: pointer;
          }
          .item-active {
            border: 1px solid $blur;
            background: $blur;
            color: #fff;
          }
        }
      }
      .search-options {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .van-button {
          width: 45%;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 28px;
          color: #fff;
          background: $blur;
          text-align: center;
          border-radius: 42px;
          border: 1px solid $blur;
        }
        .van-button:nth-child(1) {
          color: $blur;
          background: #fff;
          margin-right: 20px;
          border: 1px solid $blur;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.date-picker {
  .van-picker__cancel {
    font-size: 32px;
    font-weight: 500;
  }
  .van-picker__confirm {
    font-size: 32px;
    color: $blur;
    font-weight: 500;
  }
}
</style>
