import Vue from 'vue'
import VueRouter from 'vue-router'
import login from "@/views/loginIndex/loginIndex.vue"
import forgetPass from "@/views/forgetPass/forget-pass-index.vue"
import home from "@/views/homeIndex/homeIndex.vue"
import setLanguage from "@/views/setLanguage/setLanguage-index.vue"
import userIndex from "@/views/userIndex/user-index.vue"
import inviteIndex from '@/views/inviteIndex/invite-index.vue'
import inviteIncomeRecord from '@/views/inviteRecord/income-record-index.vue'
import inviteUserRecord from '@/views/inviteRecord/user-record-index.vue'
import withdrawIndex from "@/views/withdrawIndex/withdraw-index.vue"
import setWithdrawal from "@/views/setWithdrawal/setWithdrawal-index.vue"
import rechargeIndex from "@/views/amountRecharge/amountRecharge-index.vue"
import statisticsReport from "@/views/statisticsReport/statisticsReport-index.vue"
import shopInfo from "@/views/componentPage/shopInfo/shop-info-index.vue"
import shopInfoEdit from "@/views/componentPage/shopInfo/shopInfo-edit.vue"
import discountActivity from "@/views/activityPage/discount-activity.vue"
import projectIndex from "@/views/projectIndex/project-index.vue"
import changeLoginPass from "@/views/componentPage/changePass/login-pass.vue"
import changeLoginPayPass from "@/views/componentPage/changePass/pay-pass.vue"
import fundingRecords from "@/views/fundingRecords/fundingRecords.vue"
import productLibrary from "@/views/productLibrary/product-library.vue"
import productPackage from "@/views/productPackage/productPackage-index.vue"
import productPackageList from "@/views/productPackage/productPackage-list.vue"
import firstStep from "@/views/componentPage/productEdit/first-step-product.vue"
import nextStep from "@/views/componentPage/productEdit/next-step-product.vue"
import orderIndex from "@/views/orderIndex/order-index.vue"
import orderDetail from "@/views/orderDetail/orderDetail.vue"
import orderDetailLogisticsIndex from "@/views/orderDetail/component/logistics-index.vue"
import orderDetailRecordsIndex from "@/views/orderDetail/component/record-index.vue"
// import appService from "@/views/servicePage/service-page.vue"
import webSocketList from '@/views/webSocket/webSocket-list.vue'
import webSocketDetail from '@/views/webSocket/component/webSocket-detail.vue'
import downloadApp from '@/views/downloadApp/downloadApp-index.vue'
Vue.use(VueRouter)
let routes = [
  {
    path: "/",
    name: "appHomeIndex",
    redirect: "/appHomeIndex",
  },

  {
    path: "/appLoginIndex",
    name: "appLoginIndex",
    component: login,
    meta: { title: '登陆', keepAlive: false },
  },
  {
    path: "/appForgetPassIndex",
    name: "appForgetPassIndex",
    component: forgetPass,
    meta: { title: '注册', keepAlive: false },
  },
  {
    path: "/appHomeIndex",
    name: "appHomeIndex",
    component: home,
    meta: { title: '首页', keepAlive: false },
  },
  {
    path: "/appSetLanguage",
    name: "appSetLanguageIndex",
    component: setLanguage,
    meta: { title: '语言包设置', keepAlive: false },
  },
  {
    path: "/appUserIndex",
    name: "appUserIndex",
    component: userIndex,
    meta: { title: '我的', keepAlive: false },
  },
  {
    path: "/downloadAppIndex",
    name: "downloadAppIndex",
    component: downloadApp,
    meta: { title: '我的', keepAlive: false },
  },
  {
    path: "/appFundingRecordsIndex",
    name: "appFundingRecordsIndex",
    component: fundingRecords,
    meta: { title: '资金记录页', keepAlive: false },
  },
  {
    path: "/appInviteIndex",
    name: "appInviteIndex",
    component: inviteIndex,
    meta: { title: '邀新活动', keepAlive: false },
  },
  {
    path: "/appInviteUserRecordIndex",
    name: "appInviteUserRecordIndex",
    component: inviteUserRecord,
    meta: { title: '邀请用户记录', keepAlive: false },
  },
  {
    path: "/appInviteIncomeRecordIndex",
    name: "appInviteIncomeRecordIndex",
    component: inviteIncomeRecord,
    meta: { title: '邀请收益记录', keepAlive: false },
  },
  {
    path: "/appDiscountActivity",
    name: "appDiscountActivity",
    component: discountActivity,
    meta: { title: '折扣活动', keepAlive: false },
  },
  {
    path: "/appWithdrawIndex",
    name: "appWithdrawIndex",
    component: withdrawIndex,
    meta: { title: '提现', keepAlive: false },
  },
  {
    path: "/appAmountRechargeIndex",
    name: "appAmountRechargeIndex",
    component: rechargeIndex,
    meta: { title: '充值', keepAlive: false },
  },
  {
    path: "/appSetWithdrawalIndex",
    name: "appSetWithdrawalIndex",
    component: setWithdrawal,
    meta: { title: '提现设置', keepAlive: false },
  },
  {
    path: "/appStatisticsReportIndex",
    name: "appStatisticsReportIndex",
    component: statisticsReport,
    meta: { title: '经营报表', keepAlive: false },
  },
  {
    path: "/appShopInfoIndex",
    name: "appShopInfoIndex",
    component: shopInfo,
    meta: { title: '店铺信息', keepAlive: false },
  },
  {
    path: "/appShopInfoEdit",
    name: "appShopInfoEdit",
    component: shopInfoEdit,
    meta: { title: '店铺信息编辑', keepAlive: false },
  },
  {
    path: "/appChangeLoginPass",
    name: "appChangeLoginPassIndex",
    component: changeLoginPass,
    meta: { title: '设置登陆密码', keepAlive: false },
  },
  {
    path: "/appChangePayPass",
    name: "appChangePayPassIndex",
    component: changeLoginPayPass,
    meta: { title: '设置支付密码', keepAlive: false },
  },

  {
    path: "/appProjectIndex",
    name: "appProjectIndex",
    component: projectIndex,
    meta: { title: '产品页', keepAlive: false },
  },
  {
    path: "/appProjectLibraryIndex",
    name: "appProjectLibraryIndex",
    component: productLibrary,
    meta: { title: '产品库', keepAlive: false },
  },
  {
    path: "/appProductPackageIndex",
    name: "appProductPackageIndex",
    component: productPackage,
    meta: { title: '添加产品包', keepAlive: false },
  },
  {
    path: "/appProductPackageList",
    name: "appProductPackageList",
    component: productPackageList,
    meta: { title: '产品包列表', keepAlive: false },
  },
  {
    path: "/appProductFirstIndex",
    name: "appProductFirstIndex",
    component: firstStep,
    meta: { title: '产品资料', keepAlive: false },
  },
  {
    path: "/appProductNextStepIndex",
    name: "appProductNextStepIndex",
    component: nextStep,
    meta: { title: '添加产品', keepAlive: false },
  },
  {
    path: "/appOrderIndex",
    name: "appOrderIndex",
    component: orderIndex,
    meta: { title: '订单清单', keepAlive: false },
  },
  {
    path: "/appOrderDetailIndex",
    name: "appOrderDetailIndex",
    component: orderDetail,
    meta: { title: '订单详情', keepAlive: true },
  },
  {
    path: "/appOrderDetailLogistics",
    name: "appOrderDetailLogisticsIndex",
    component: orderDetailLogisticsIndex,
    meta: { title: '订单物流', keepAlive: false },
  },
  {
    path: "/appOrderDetailRecord",
    name: "appOrderDetailRecordIndex",
    component: orderDetailRecordsIndex,
    meta: { title: '订单记录', keepAlive: false },
  },
  {
    path: "/appWebSocketList",
    name: "appWebSocketListIndex",
    component: webSocketList,
    meta: { title: '消息用户列表', keepAlive: false },
  },
  {
    path: "/appWebSocketDetails",
    name: "appWebSocketDetailsIndex",
    component: webSocketDetail,
    meta: { title: '消息中心', keepAlive: false },
  },
  // {
  //   path: "/appServiceIndex",
  //   name: "appServiceIndex",
  //   component: appService,
  //   meta: { title: '消息中心', keepAlive: false },
  // },
]
const router = new VueRouter({
  // base: "/dev",
  // mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) { //如果savedPosition存在，滚动条会自动跳到记录的值的地方
      return savedPosition
    } else {
      return { x: 0, y: 0 }//savedPosition也是一个记录x轴和y轴位置的对象
    }
  },
  routes: routes
})

router.beforeEach((to, from, next) => {
  // document.body.scrollTop = 0
  // document.documentElement.scrollTop = 0
  next()
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router