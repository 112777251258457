import service from '@/required/axios'
import { permissionCall } from './operation-permission'
import { environment, domainMapEnvironment, environmentMapMallDomain } from './constant'
// 默认环境
const environmentDefault = environment.web

// 获取并设置当前环境
const $environment = getEnvironment();

// 按钮展示权限
const $permissionObj = permissionCall($environment)

// 基础域名
let domain = ''
// 设置基础域名
getDomain();

// 商城域名
const $mallHost = 'https://' + environmentMapMallDomain[$environment] + domain;
// 店家注册页
const $merchantRegisterUrl = $mallHost + '#/appMerchantSettlement'
// 贷款域名
const $loanHost = 'https://loan.' + domain;


/**
 * 跳转参数链接
 *
 * @param requestDomain
 * @param params
 * @returns {boolean}
 */
function $openParamUrl (requestDomain, params = {}) {

  // 语言参数拼接
  params.language = window.localStorage.getItem('languageType');

  // code码参数拼接
  params.code = window.localStorage.getItem('code')

  // 参数拼接
  params = Object.keys(params).map(key => {
    if (params[key] !== undefined && params[key] !== '' && params[key] !== null) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }
  }).join('&')

  $openUrl(requestDomain + '?' + params);

  return true;
}

/**
 * 跳转链接
 *
 * @param url
 * @returns {boolean}
 */
function $openUrl (url) {

  if ($environment === 'android') {
    plus.runtime.openURL(url)
    return true;
  }

  window.location.href = url

  return true;
}

/**
 * 获取当前环境
 *
 * @returns {*}
 */
function getEnvironment () {
  // 环境信息
  let env;

  // 请求地址前缀
  const protocol = window.location.protocol


  if (protocol == 'http:' || protocol == 'https:') {

    // 获取二级域名
    const urlDomain = window.location.href.split(".")[0].split("//")[1];

    // 匹配二级域名
    env = domainMapEnvironment[urlDomain] ?? environmentDefault;

  } else {

    // 安卓模式
    env = environment.android
  }
  console.log(env)
  return env;
}

/**
 * 获取域名
 *
 * @returns {Promise<*>}
 */
async function getDomain () {

  if ($environment === environment.android) {
    const { data, code } = await service({
      url: 'system.version',
      method: "get",
    })
    if (code == 200) {
      domain = data.domain;
    }
  } else {
    domain = window.location.hostname.split('.').slice(-2).join('.');
  }

  return domain
}


export default {
  $environment,
  $openParamUrl,
  $openUrl,
  $mallHost,
  $loanHost,
  $merchantRegisterUrl,
  $permissionObj
}