<template>
  <div class="product-page-warper" id="productID">
    <div class="page-header flexbox">
      <navTab> </navTab>
    </div>
    <div class="add-product-boxers">
      <div class="boxers">
        <p>
          <span>{{ $route.query.name }}</span>
        </p>
      </div>
    </div>
    <div class="product-library-body">
      <div class="library-list-boxers">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          :loading-text="loadingText"
          @load="onLoad"
        >
          <div class="list-item-boxers" v-if="projectDataList.length">
            <div
              :class="['list-item', item.sync && 'list-item-disabled']"
              v-for="(item, index) in projectDataList"
              :key="index"
            >
              <div class="item-images imgFlex">
                <img v-lazy="imgUrl + item.image + thumbnail" />
              </div>
              <div class="item-info">
                <div class="info-title text2">{{ item.name }}</div>
                <div class="info-spec flexbox">
                   
                  {{ language.salesLabel }}{{ item.sales }}
                </div>

                <div class="info-price">
                  <countTo
                    :startVal="0"
                    :endVal="item.unitAmount"
                    :duration="1200"
                    :decimals="2"
                    :separator="','"
                    :prefix="moneyIcon"
                  ></countTo>
                </div>
              </div>
            </div>
          </div>
          <default-page v-else></default-page>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import productPackageApi from "@/api/productPackageApi.js";
import imgApi from "@/utils/projectConfig";
export default {
  name: "appProjectIndex",
  data() {
    return {
      language: this.$language(),
      thumbnail: this.$thumbnail,
      imgUrl: imgApi.imgUrl,
      finishedText: this.$language("messageLanguage").finishedText,
      loadingText: this.$language("messageLanguage").loadingText,
      loading: false,
      finished: false,
      moneyIcon: localStorage.getItem("moneyIcon"),
      formObj: {
        searchValue: "",
        shelves: "",
        brand: "",
        brandName: "",
        category: [],
        categoryName: "",
        page: 1,
        size: 20,
        total: 50,
      },
      projectDataList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("productID").style["height"] = seeHeight + "px";
    });
  },
  methods: {
    onChangeShelves(record, index) {
      this.shelvesActive = index;
    },
    handlerClose() {
      this.searchVisible = false;
    },
    onCancelPicker() {
      this.classVisible = false;
    },
    handlerSubmitSearch() {
      this.loading = true;
      this.finished = false;
      this.formObj.page = 1;
      this.projectDataList = [];
      this.searchVisible = false;
      this.getProductList();
    },
    onLoad() {
      this.getProductList();
    },
    // 产品列表
    async getProductList() {
      let categoryId;
      if (this.formObj.category) {
        categoryId = this.formObj.category[this.formObj.category.length - 1];
      }
      const params = {
        productPackageId: this.$route.query.id,
        page: this.formObj.page,
        size: this.formObj.size,
      };
      this.loading = true;
      const { code, data } = await productPackageApi.getProductPackageList(
        params
      );
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.unitAmount = Number((item.unitAmount / 100).toFixed(2));
        });
        this.projectDataList = this.projectDataList.concat(data.list);
        this.formObj.total = data.total;
        if (this.projectDataList.length >= this.formObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.formObj.page++;
        }
      }
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.product-page-warper {
  width: 100%;
  height: 100vh;
  background: #eff2f6;

  .page-header {
    svg {
      width: 38px !important;
      height: 38px !important;
      color: $color3;
    }
  }
  .add-product-boxers {
    width: 100%;
    height: 100px;
    margin-top: 30px;
    padding: 0 30px;
    .boxers {
      height: 100%;
      background: #fff;
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      font-size: 28px;
      color: #333;
      font-weight: 700;
      line-height: 1.5;
    }
  }
  .product-library-body {
    width: 100%;
    height: calc(100% - 92px - 130px);
    padding: 30px;

    .library-list-boxers {
      width: 100%;
      height: 100%;
      overflow: auto;

      .van-list {
        .list-item-boxers {
          .list-item {
            padding: 24px;
            display: flex;
            width: 100%;
            margin-bottom: 40px;
            background: #fff;
            border-radius: 8px;
            position: relative;

            .item-images {
              width: 160px;
              height: 160px;
              border-radius: 6px;
            }
          }

          .list-item-disabled {
            opacity: 0.5;
          }

          .item-info {
            width: 65%;
            padding-left: 20px;

            .info-title {
              width: 100%;
              font-size: 28px;
              color: #333;
              line-height: 32px;
              font-weight: 700;
            }

            .info-spec {
              font-size: 24px;
              color: #999;
              line-height: 2;
              margin-top: 10px;

              span {
                margin-right: 10px;
              }
            }

            .info-price {
              margin-top: 10px;
              font-size: 24px;
              color: $blur;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
