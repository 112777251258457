import service from '../required/axios'
import { languageList } from "@/utils/language/language";
const configLanguage = languageList('configLanguage');
document.addEventListener('plusready', function () {
  // 获取最新版本号、下载链接
  service({
    method: 'get',
    url: 'system.version',
  }).then((versionData) => {
    if (versionData.code == 200) {
      const appVersion = (plus.runtime.version).split('.').join('')
      if (versionData.data.androidVersion > appVersion) {
        plus.nativeUI.confirm(
          configLanguage.upgradePrompt,
          function (event) {
            if (event.index == 0) {
              plus.nativeUI.showWaiting(`${configLanguage.loadingTitle}`);
              plus.downloader.createDownload(versionData.data.android, {}, function (d, status) {
                if (status == 200) {
                  plus.runtime.install(d.filename)
                }
                plus.nativeUI.closeWaiting();
              }).start();
            }
          },
          `${configLanguage.systemUpgrade}`, [`${configLanguage.upgradeImmediately}`]
        );
      }
    }
  })
});