<template>
  <div class="webSocketDetail-warper" id="webSocketDetail">
    <div class="page-header">
      <nav-tab :navTitle="navTitle"></nav-tab>
    </div>
    <div class="info-body">
      <div class="loading-warper" v-if="isLoading">
        <van-loading color="#6d3580" />
      </div>
      <div class="info-boxers">
        <div id="chatRoomRefs" @scroll="pageScroll">
          <div
            class="info-list"
            v-for="(item, index) in imInfoDataList"
            :key="index"
            :ref="`scrollRef${item.id}`"
          >
            <div class="info-time" v-if="setTimeCallBack(item.createAt, index)">
              {{ item.createAt }}
            </div>
            <div class="info-item-boxers">
              <div :class="['info-item flexbox', setItemClass(item)]">
                <div class="info-item-image imgFlex">
                  <img
                    v-if="item.clientType === 'SHOP'"
                    v-lazy="imagePath + item.userIcon + thumbnail"
                  />
                  <span
                    v-else
                    class="avatar"
                    :style="`background:${extractColorByName(navTitle)}`"
                  >
                    {{ elAvatarSlice(navTitle) }}
                  </span>
                </div>
                <div class="info-item-content">
                  <div class="title text-warper" v-if="item.type == 'text'">
                    <span>{{ item.content }}</span>
                  </div>
                  <div class="images imgFlex" v-else>
                    <img
                      v-lazy="imagePath + item.content + thumbnail"
                      @click="onChangePreview(imagePath + item.content)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-page">
      <div class="info-field flexbox">
        <van-field v-model="chatTextValue" type="text" clearable />
        <van-button
          size="small"
          type="primary"
          class="chat-send"
          @click="onChangeSend"
          v-if="chatTextValue"
        >
          {{ language.sendOption }}
        </van-button>
        <div class="addo" v-else-if="!IM_LINK_ERROR">
          <van-icon name="add-o" @click="clickUpload" />
        </div>
        <van-uploader :after-read="afterRead" :before-read="beforeRead" v-else>
          <van-icon name="add-o" />
        </van-uploader>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab";
import { ImagePreview } from "vant";
import wsImageUrl from "@/utils/projectConfig";
import websocketApi from "@/api/websocketApi";
export default {
  name: "appWebSocketDetail",
  data() {
    return {
      language: this.$language(),
      messageLanguage: this.$language("messageLanguage"),
      isLoading: false,
      navTitle: "",
      acceptType: ".jpg,.jpeg,.png",
      imagePath: wsImageUrl.wsImageUrl,
      thumbnail: this.$thumbnail,
      imTextValue: "",
      imInfoDataList: [],
      chatTextValue: "",
      pageObj: {
        total: 0,
        size: 20,
        page: 1,
      },
      offHeightID: null,
      IM_LINK_ERROR: false,
    };
  },
  beforeDestroy() {
    //销毁的生命周期
    window.removeEventListener("websocket", this.localStorage);
    window.removeEventListener("IM_LINK_ERROR", this.localStorage2);
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("webSocketDetail").style["height"] =
        seeHeight + "px";
    });
    this.navTitle = this.$route.query.name;
    this.init();
  },
  methods: {
    init() {
      this.ERRORTypeCall();
      this.getHistoryMessageData();
      this.readMessageWays();
      window.addEventListener("IM_LINK_ERROR", this.localStorage2);
      window.addEventListener("websocket", this.localStorage);
    },
    localStorage() {
      let self = this;
      let webImData = JSON.parse(sessionStorage.getItem("websocket"));
      self.getSocketResult(webImData);
    },
    localStorage2() {
      let isShow = sessionStorage.getItem("IM_LINK_ERROR");
      if (isShow == "true") {
        this.IM_LINK_ERROR = true;
      } else {
        this.IM_LINK_ERROR = false;
      }
    },
    getSocketResult(data) {
      if (Object.keys(this.$route.query).length > 0) {
        this.readMessageWays();
        if (
          data.memberId == this.$route.query.id &&
          (data.type == "image" || data.type == "text")
        ) {
          const userIcon = JSON.parse(localStorage.getItem("loginInfo_")).image;
          this.imInfoDataList.push({
            ...data,
            userIcon: userIcon,
          });
          this.scrollPageBottom();
        }
      }
    },
    async getHistoryMessageData(historyID) {
      const params = {
        memberId: this.$route.query.id,
        id: historyID,
        size: this.pageObj.size,
      };
      const { code, data } = await websocketApi.getHistoryMessage(params);
      if (code == 200) {
        data.map((item) => {
          item.userIcon = JSON.parse(localStorage.getItem("loginInfo_"))?.image;
          this.imInfoDataList.unshift(item);
        });
        this.total = data.length;
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
        if (!historyID) {
          this.scrollPageBottom();
        } else {
          this.scrollPageBottom("clientHeight");
        }
      }
    },
    pageScroll(e) {
      let scrollTop = e.target.scrollTop;
      if (scrollTop == 0) {
        this.isLoading = true;
        this.offHeightID = this.imInfoDataList[0].id;
        this.getHistoryMessageData(this.offHeightID);
      }
    },
    setTimeCallBack(time, index) {
      if (!index) return false;
      let itemTime = +new Date(this.imInfoDataList[index - 1].updateAt);
      if (+new Date(time) - itemTime < 300000) {
        return false;
      }
      return true;
    },

    // 点击发送按钮
    onChangeSend() {
      if (!this.IM_LINK_ERROR) {
        this.$toast.fail(this.language.IMportError);
        return false;
      }
      this.sendMessageWays(this.chatTextValue, "text");
    },

    // 发送消息接口
    async sendMessageWays(content, key) {
      let paramsObj = {
        memberId: this.$route.query.id,
        type: key,
        content: content,
      };
      const { data, code } = await websocketApi.sendMessagePort(paramsObj);
      if (code == 200) {
        const userIcon = JSON.parse(localStorage.getItem("loginInfo_")).image;
        this.imInfoDataList.push({ ...data, userIcon: userIcon });
        this.chatTextValue = "";
        this.scrollPageBottom();
      }
    },
    clickUpload(e) {
      if (!this.IM_LINK_ERROR) {
        this.$toast.fail(this.language.IMportError);
        return false;
      }
    },
    // 图片上传
    async afterRead(file) {
      if (!this.IM_LINK_ERROR) {
        this.$toast.fail(this.language.IMportError);
        return false;
      }
      this.imageCallBackFile(file, "image");
    },
    async imageCallBackFile(file, type) {
      let formData = new FormData();
      formData.append("file", file.file);
      const { code, data } = await websocketApi.updateImagesPort(formData);
      if (code == 200) {
        this.sendMessageWays(data.url, type);
      }
    },
    // 发送消息后页面自动滚动到最底部
    scrollPageBottom(key) {
      if (document.getElementById("chatRoomRefs")) {
        if (key === "clientHeight") {
          setTimeout(() => {
            this.$refs["scrollRef" + this.offHeightID][0].scrollIntoView({
              block: "end",
              inline: "end",
            });
          }, 500);
        } else {
          setTimeout(() => {
            document.getElementById("chatRoomRefs").scrollTop =
              document.getElementById("chatRoomRefs").scrollHeight;
          }, 500);
        }
      }
    },
    // 消息已读
    async readMessageWays() {
      const { code, data } = await websocketApi.getReadMessage({
        memberId: this.$route.query.id,
      });
    },
    //   点击图片预览
    onChangePreview(img) {
      ImagePreview([img]);
    },
    // 图片上传限制
    beforeRead(file) {
      let title;
      const typeEnum = [
        "image/jpg",
        "image/JPG",
        "image/png",
        "image/PNG",
        "image/JPEG",
        "image/jpeg",
      ];
      let isShow = false;
      if (!typeEnum.includes(file.type)) {
        isShow = true;
      }
      if (isShow) {
        title = this.language.fileFail.replace(/&/g, "jpg,png,jpeg");
        this.$toast({
          message: title,
          type: "fail",
          duration: 1800,
        });
        return false;
      } else {
        return true;
      }
    },
    getThumbnail(img) {
      return img + this.thumbnail;
    },
    setItemClass(item) {
      let className = "";
      if (item.clientType === "ADMIN") {
        className = "client-list-item";
      }
      if (item.clientType === "SHOP") {
        className = "user-list-item";
      }

      return className;
    },
    // 用户名称截取作为头像
    elAvatarSlice(name) {
      if (name) {
        return name.slice(0, 1);
      }
    },
    /**
     * 根据名字提取颜色
     * @param name 名字
     */
    extractColorByName(name) {
      if (name) {
        var temp = [];
        temp.push("#");
        for (let index = 0; index < name.length; index++) {
          temp.push(parseInt(name[index].charCodeAt(0), 10).toString(16));
        }
        return temp.slice(0, 5).join("").slice(0, 4);
      }
    },
    ERRORTypeCall() {
      let isShow = sessionStorage.getItem("IM_LINK_ERROR");
      if (isShow == "true") {
        this.IM_LINK_ERROR = true;
      } else {
        this.IM_LINK_ERROR = false;
      }
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.webSocketDetail-warper {
  width: 100%;
  height: 100vh;

  .info-body {
    width: 100%;
    height: calc(100% - 92px - 105px);
    background: rgb(241 239 239);
    position: relative;

    .loading-warper {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 10px;
      left: 0%;
      width: 100%;
      height: 100%;
    }

    .info-boxers {
      width: 100%;
      height: 100%;
      padding: 15px 30px;
      overflow-y: scroll;

      .info-list {
        margin-bottom: 30px;

        .info-time {
          text-align: center;
          padding: 20px 0;
          color: rgb(130, 128, 130);
          font-size: 20px;
        }

        .info-item-boxers {
          .info-item {
            align-items: flex-start;

            .info-item-image {
              width: 80px;
              height: 80px;
              border-radius: 8px;

              .avatar {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 32px;
                line-height: inherit;
                font-weight: 600;
                color: #fff;
              }
            }

            .info-item-content {
              width: 60%;
              margin-left: 20px;
              margin-top: 20px;

              .title {
                padding: 20px;
                border-radius: 8px;
                font-size: 24px;
                line-height: 1.5;
                color: rgb(19, 19, 20);
                background: #fff;
              }

              .images {
                max-height: 350px;
                min-height: 80px;
                max-width: 60%;
                min-width: 35%;
                border-radius: 8px;
              }
            }
          }

          .user-list-item {
            flex-direction: row-reverse;

            .info-item-image {
              background: #fff;
            }

            .info-item-content {
              margin-right: 20px;
              margin-top: 20px;
              display: flex;
              justify-content: flex-end;

              .title {
                background: rgb(134, 236, 89);
              }

              .images {
                max-height: 350px;
                min-height: 80px;
                max-width: 60%;
                min-width: 35%;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;

                img {
                  width: 100%;
                  object-fit: cover;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer-page {
    height: 105px;
    border-top: 1px solid rgb(224, 223, 223);

    .info-field {
      background: rgb(245, 245, 245);
      height: 100%;
      padding: 12px 0 12px 20px;
      justify-content: space-between;

      ::v-deep .van-cell {
        height: 100%;
        padding: 20px 20px;
        border-radius: 8px;

        .van-cell__value {
          height: 100%;

          .van-field__body {
            height: 100%;

            .van-field__control {
              background-color: #fff;
              height: 100%;
              font-size: 26px;
              line-height: 1.5;
              color: $color3 !important;
            }
          }
        }
      }

      .van-button {
        min-width: 120px;
        margin: 0 15px;
        font-size: 28px;
        color: #fff;
        font-weight: 400;
      }

      .addo {
        margin: 0 15px;
        width: 49px;
        height: 49px;

        .van-icon {
          font-size: 46px;
          font-weight: 600;
        }
      }

      .van-uploader {
        margin: 0 15px;
        width: 49px;
        height: 49px;

        .van-uploader__wrapper {
          .van-uploader__input-wrapper {
            .van-icon {
              font-size: 46px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

#chatRoomRefs {
  height: 100%;
  // padding: 15px 0 15px 0;
  overflow-y: scroll;
}
</style>
<style>
input:-webkit-autofill {
  background: transparent;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
}
</style>
