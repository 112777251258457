
import service from '@/required/axios'
export default {
  // 获取基本信息
  getShopInfoPort () {
    return service({
      url: "/shop.info",
      method: "post",
    });
  },
  // 法定货币列表获取
  getCurrencyFiatList (data) {
    return service({
      url: '/finance.get_currency_fiat_list',
      method: "get",
      params: data
    })
  },
  // 虚拟货币列表获取
  getCurrencyVirtualList (data) {
    return service({
      url: '/finance.get_currency_virtual_list',
      method: "get",
      params: data
    })
  },
  // 汇率获取
  getExchangeRate (data) {
    return service({
      url: '/finance.get_exchange_rate',
      method: "post",
      data: data
    })
  },
  // 提现申请
  set_withdrawal (data) {
    return service({
      url: 'finance.withdrawal',
      method: "post",
      data: data
    })
  },
}