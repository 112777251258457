import service from '../required/axios'
export default {
  // 法定货币列表获取
  getCurrencyFiatList (data) {
    return service({
      url: 'finance.get_currency_fiat_list',
      method: "get",
      params: data
    })
  },
   // 虚拟货币列表获取
   getCurrencyVirtualList (data) {
    return service({
      url: 'finance.get_currency_virtual_list',
      method: "get",
      params: data
    })
  },
  // 法定获取数据获取
   getByFiat (data) {
    return service({
      url: 'receivingAccount.get_by_fiat',
      method: "get",
      params: data
    })
  },
  // 法定数据设置
   setCurrencyFiat (data) {
    return service({
      url: `receivingAccount.set_${data.url}_by_fiat`,
      method: "get",
      params: data.formObj
    })
  },
  // 虚拟货币数据获取
   getCurrencyVirtual (data) {
    return service({
      url: `receivingAccount.get_by_virtual`,
      method: "get",
      params: data
    })
  },
  // 虚拟货币数据设置
   setCurrencyVirtual (data) {
    return service({
      url: `receivingAccount.set_by_virtual`,
      method: "post",
      data: data
    })
  },
}