<template>
  <div class="webSocket-warper" id="webSocketID">
    <div class="nav-header-page">
      <div class="page-header">
        <nav-tab :navLeftIcon="true"></nav-tab>
      </div>
    </div>
    <div class="webSocket-body">
      <div class="info-error" v-if="!IM_LINK_ERROR" @click="onChangeRetry">
        <van-icon name="warning" /><span>{{ language.IMErrorMessage }}</span>
      </div>
      <div :class="[!IM_LINK_ERROR ? 'user-list-error' : 'user-list-body']">
        <div class="user-info-list" v-if="serviceData.length">
          <div
            :class="['info-list', activeIndex == index && 'info-active']"
            v-for="(item, index) in serviceData"
            :key="index"
            @click="onChangeRow(item, index)"
          >
            <div class="list-row flexbox">
              <div class="list-row-left">
                <div class="list-row-left-icon imgFlex">
                  <div class="icon-img imgFlex">
                    <img :src="item.imageIcon" v-if="item.imageIcon" />
                    <span
                      class="avatar"
                      :style="`background:${extractColorByName(item.name)}`"
                    >
                      {{ elAvatarSlice(item.name) }}
                    </span>
                  </div>
                </div>
                <div
                  :class="[
                    'count-number',
                    item.shopUnreadNumber > 99 && 'numberCount',
                  ]"
                  v-if="item.shopUnreadNumber"
                >
                  <span v-if="item.shopUnreadNumber > 99" class="span1"
                    >99+</span
                  >
                  <span v-if="item.shopUnreadNumber <= 99" class="span2">
                    {{ item.shopUnreadNumber }}
                  </span>
                </div>
              </div>
              <div class="list-row-right">
                <div class="list-row-right-text">
                  <span class="name text-warper">{{ item.name }}</span>
                  <span class="time">{{ item.createAt }}</span>
                </div>
                <div class="list-row-right-content noText"></div>
              </div>
            </div>
          </div>
        </div>
        <default-page v-else></default-page>
      </div>
    </div>
    <div class="footer-boxers">
      <div class="footer-page">
        <footerCard></footerCard>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab";
import footerCard from "@/layout/footerCard.vue";
import websocketApi from "@/api/websocketApi";
import wsImageUrl from "@/utils/projectConfig";
export default {
  name: "appWebSocketListIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      imagePath: wsImageUrl.wsImageUrl,
      activeIndex: null,
      serviceData: [],
      IM_LINK_ERROR: true,
      USER_ID: "",
      localStorage: "",
    };
  },
  components: { navTab, footerCard },
  beforeDestroy() {
    //销毁的生命周期
    window.removeEventListener("websocket", this.localStorageListener);
    window.removeEventListener("IM_LINK_ERROR", this.localStorage2);
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("webSocketID").style["height"] = seeHeight + 100 + "px";
    });
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.ERRORTypeCall();
      window.addEventListener("IM_LINK_ERROR", this.localStorage2);
      window.addEventListener("websocket", this.localStorageListener);
      this.getUserListDataPort();
    },
    localStorageListener() {
      let self = this;
      let webImData = JSON.parse(sessionStorage.getItem("websocket"));
      self.init(webImData);
    },
    localStorage2() {
      const isShow = sessionStorage.getItem("IM_LINK_ERROR");
      if (isShow == "true") {
        this.IM_LINK_ERROR = true;
      }
      if (isShow === "close") { 
        this.IM_LINK_ERROR = false;
      }
    },
    onChangeRetry() {
      this.$socketApi.closeWebSocket();
      window.removeEventListener("websocket", this.localStorageListener);
      this.IM_LINK_ERROR = true;
      setTimeout(() => {
        this.$socketApi.initWebSocket(this.init);
      }, 800);
    },
    init(data) {
      if (data.type == "register") {
        let params = {
          clientId: data.content,
        };
        this.getRegisterWays(params);
      }
      if (data.type == "text" || data.type == "image") {
        if (data.memberId == this.USER_ID) {
          this.serviceData.push({ ...data });
          let serviceDataList = [];
          this.serviceData.map((item, index) => {
            if (item.id == data.memberId) {
              item.shopUnreadNumber += 1;
              item.updateAt = data.updateAt;
              serviceDataList.push(item);
              this.serviceData.splice(index, 1);
            }
          });
          this.serviceData.unshift(serviceDataList[0]);
        }
      }
    },
    // 获取用户列表
    async getUserListDataPort(key) {
      let params = { forceReload: false };
      const { data, code } = await websocketApi.getUserList(params);
      if (code == 200) {
        this.serviceData = data;
        this.serviceData.map((item, index) => {
          item.key = index + 1;
        });
        this.USER_ID = this.activeIndex
          ? this.serviceData[this.activeIndex].id
          : "";
      }
    },
    onChangeRow(record, index) {
      this.activeIndex = index;
      setTimeout(() => {
        this.$router.push({ path: "/appWebSocketDetails", query: record });
      }, 300);
    },
    // 用户第一次登陆时调用的接口
    async getRegisterWays(params) {
      const { data, code } = await websocketApi.getRegisterPort(params);
      this.$resetSetItem("IM_LINK_ERROR", "IM_LINK_ERROR", true);
      this.getUserListDataPort();
    },
    getThumbnail(img, key) {
      return this.imagePath + img + "?x-oss-process=image/resize,w_250";
    },
    // 用户名称截取作为头像
    elAvatarSlice(name) {
      if (name) {
        return name.slice(0, 1);
      }
    },
    /**
     * 根据名字提取颜色
     * @param name 名字
     */
    extractColorByName(name) {
      if (name) {
        let temp = [];
        temp.push("#");
        for (let index = 0; index < name.length; index++) {
          temp.push(parseInt(name[index].charCodeAt(0), 10).toString(16));
        }
        return temp.slice(0, 5).join("").slice(0, 4);
      }
    },
    ERRORTypeCall() {
      let isShow = sessionStorage.getItem("IM_LINK_ERROR");
      if (isShow == "true") {
        this.IM_LINK_ERROR = true;
      } else {
        this.IM_LINK_ERROR = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.webSocket-warper {
  width: 100%;
  height: 100vh;

  .nav-header-page {
    height: 92px;
  }

  .webSocket-body {
    width: 100%;
    height: calc(100% - 92px - 100px);

    .info-error {
      cursor: pointer;
      display: flex;
      align-items: center;
      background: rgb(253, 237, 238, 0.8);
      color: #fff;
      font-size: 24px;
      line-height: 1.5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 30px;

      .van-icon {
        font-size: 32px;
        color: #fd4f53;
        margin-right: 24px;
      }

      span {
        color: #7b6b6c;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .user-list-error {
      height: calc(100% - 96px);
    }

    .user-list-body {
      height: 100%;
      background: #fff;
    }

    .user-info-list {
      height: 100%;
      background: #fff;
      overflow-y: scroll;

      .info-list {
        padding: 20px 30px;
        border-bottom: 1px solid #eff2f6;

        .list-row {
          align-items: flex-start;

          .list-row-left {
            position: relative;

            .list-row-left-icon {
              background: rgb(214, 216, 218);
              width: 90px;
              height: 90px;
              border-radius: 8px;

              .icon-img {
                width: 100%;
                height: 100%;
              }
            }

            .count-number {
              position: absolute;
              top: -10px;
              left: 75px;
              overflow: hidden;

              span {
                border-radius: 50%;
                background: #f63945;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: #fff;
                font-weight: 400;
              }

              .span1 {
                width: 40px;
                height: 41px;
              }
            }

            .numberCount {
              top: -16px;
              left: 65px;
            }

            span {
              display: inline-block;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              font-size: 32px;
              font-weight: 600;
            }
          }

          .list-row-right {
            width: calc(100% - 90px);
            padding-left: 20px;

            .list-row-right-text {
              width: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;

              .name {
                width: 55%;
                display: inline-block;
                font-size: 28px;
                color: $color3;
                line-height: 1.5;
              }

              .time {
                font-size: 22px;
                line-height: 1.5;
                color: rgb(183, 184, 186);
              }
            }

            .list-row-right-content {
              width: 55%;
              line-height: 1.5;
              margin-top: 16px;
              font-size: 22px;
              color: rgb(183, 184, 186);
            }
          }
        }
      }

      .info-active:active {
        background: rgb(233, 233, 233);
      }
    }
  }

  .footer-boxers {
    height: 100px;
  }
}
</style>
