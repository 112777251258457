<template>
  <div class="product-edit-warper">
    <van-popup
      v-model="editVisibleIsShow"
      position="bottom"
      round
      :style="{ height: '75%' }"
      @close="$emit('handlerClose', (editVisibleIsShow = false))"
      @click-overlay="$emit('handlerClose', (editVisibleIsShow = false))"
    >
      <div class="edit-body">
        <div class="popup-edit-body">
          <van-swipe
            v-if="productEditObj.skuImagesList"
            class="my-swipe"
            ref="swipeRefs"
            :autoplay="5000"
            @change="onChangeSwipe"
          >
            <van-swipe-item
              v-for="(item, skuIndex) in productEditObj.skuImagesList"
              :key="skuIndex"
            >
              <div class="swipe-img imgFlex">
                <img v-lazy="item" />
              </div>
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator-boxers flexbox">
                <div
                  v-if="current >= 1"
                  class="custom-indicator-left"
                  @click="onChangeClickSwipe('left')"
                >
                  <van-icon name="arrow-left" />
                </div>
                <div
                  v-if="
                    productEditObj.skuImagesList.length &&
                    current + 1 !== productEditObj.skuImagesList.length
                  "
                  class="custom-indicator-right"
                  @click="onChangeClickSwipe('right')"
                >
                  <van-icon name="arrow" />
                </div>
              </div>
            </template>
          </van-swipe>
          <div class="body-form-boxers">
            <div class="form-item-list flexbox">
              <div class="form-label">
                <span>{{ language.specLabel }}</span>
              </div>
              <div class="form-value">
                <div
                  class="attributes-list flexbox"
                  v-for="(item, index) in productEditObj.attributes"
                  :key="index"
                >
                  <van-tag class="tag-label" plain type="primary">
                    {{ item.productAttributesName }}
                  </van-tag>
                  <span class="tag-value">
                    {{ item.productAttributesValueName }}
                  </span>
                </div>
              </div>
            </div>
            <div class="form-item-list flexbox">
              <div class="form-label">
                <span>{{ language.label1 }}</span>
              </div>
              <div class="form-value">
                <van-field
                  disabled
                  v-model="productEditObj.purchaseAmount"
                  :placeholder="language.placeHint"
                  type="number"
                />
              </div>
            </div>
            <div class="form-item-list flexbox">
              <div class="form-label">
                <span>{{ language.label2 }}</span>
              </div>
              <div class="form-value">
                <van-field
                  v-model="productEditObj.unitAmount"
                  :placeholder="language.placeHint"
                  type="number"
                  @blur="
                    onChangeAmount(
                      productEditObj.unitAmount,
                      productEditObj,
                      'unitAmount'
                    )
                  "
                />
              </div>
            </div>
            <div class="form-item-list flexbox">
              <div class="form-label">
                <span>{{ language.label3 }}</span>
              </div>
              <div class="form-value">
                <van-field
                  v-model="productEditObj.originalAmount"
                  :placeholder="language.placeHint"
                  type="number"
                  @blur="
                    onChangeAmount(
                      productEditObj.originalAmount,
                      productEditObj,
                      'originalAmount'
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="popup-options">
          <van-button @click="handlerSubmit">{{
            language.optionBtn
          }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { Toast } from "vant";
export default {
  name: "appProductPopupDialogIndex",
  props: {
    visible: { type: Boolean, default: false },
    setProductObj: { type: Object, default: () => {} },
  },
  data() {
    return {
      language: this.$language("appProductNextStepIndex"),
      moneyIcon: localStorage.getItem("moneyIcon"),
      thumbnail: this.$thumbnail,
      editVisibleIsShow: false,
      productEditObj: {},
      current: 0,
      visibleError: true,
    };
  },
  mounted() {
    this.editVisibleIsShow = this.visible;
    this.productEditObj = this.setProductObj;
  },
  methods: {
    onChangeAmount(val, row, key) {
      this.visibleError = true;
      if (val === "") {
        val = 0;
      }
      row[key] = parseFloat(val).toFixed(2);
      if (key === "originalAmount") {
        if (parseFloat(val) < parseFloat(row.purchaseAmount)) {
          Toast.fail(this.language.validateMessage1);
          row.originalAmount = 0;
          this.visibleError = false;
          return false;
        }
        if (parseFloat(val) < parseFloat(row.unitAmount)) {
          Toast.fail(this.language.validateMessage2);
          row.originalAmount = 0;
          this.visibleError = false;
          return false;
        }
      }
      if (key === "unitAmount") {
        if (parseFloat(val) < parseFloat(row.purchaseAmount)) {
          Toast.fail(this.language.validateMessage3);
          row.unitAmount = 0;
          this.visibleError = false;
          return false;
        }
        if (parseFloat(val) > parseFloat(row.originalAmount)) {
          Toast.fail(this.language.validateMessage4);
          row.unitAmount = 0;
          this.visibleError = false;
          return false;
        }
      }
    },
    onChangeClickSwipe(key) {
      if (key === "left") {
        this.$refs.swipeRefs.prev();
      } else {
        this.$refs.swipeRefs.next();
      }
    },

    onChangeSwipe(e) {
      this.current = e;
    },
    handlerSubmit() {
      if (this.visibleError) {
        setTimeout(() => {
          this.editVisibleIsShow = false;
          this.$emit("handlerSubmit", this.productEditObj);
        }, 500);
      }
    },
    onChangeRouter() {
      this.$router.push({ path: "/appProductEditIndex" });
    },

    onChangePreview(record) {
      ImagePreview([record.imgIcon]);
    },
  },
};
</script>

<style lang="scss" scoped>
.product-edit-warper {
  width: 100%;
  .van-popup {
    background: #fff;
    padding: 30px 0;
    .edit-body {
      width: 100%;
      height: 100%;
      padding: 0 30px;
      overflow-y: auto;
      .popup-edit-body {
        background-color: #fff;
        padding: 24px 30px;
        .my-swipe {
          background: #eff2f6;
          margin-bottom: 30px;
          .van-swipe-item {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .swipe-img {
            width: 260px;
            height: 260px;
            border-radius: 6px;
          }
        }
        .body-form-boxers {
          .form-item-list:nth-child(1) {
            align-items: flex-start;
          }
          .form-item-list {
            margin-bottom: 30px;
            padding: 20px 0;
            .form-label {
              width: 30%;
              font-size: 28px;
              font-weight: 600;
              color: $color3;
              line-height: 1.5;
              span {
                display: inline-block;
                white-space: wrap;
                word-break: break-all;
              }
            }
            .form-value {
              width: 70%;
              padding-left: 20px;
              font-size: 24px;
              font-weight: 600;
              color: $color1;
              opacity: 0.7;
              line-height: 1.5;
              .attributes-list {
                align-items: flex-start;
                margin-bottom: 12px;
                .tag-label {
                  font-size: 22px;
                  line-height: 1.5;
                  margin-right: 12px;
                  padding: 3px 10px;
                }
                .tag-value {
                  font-size: 22px;
                  color: $color9;
                  margin-right: 8px;
                  line-height: 1.5;
                }
              }
              .attributes-list:last-child {
                margin-bottom: 0;
              }
              ::v-deep .van-cell {
                width: calc(100%);
                padding: 0;
                background-color: #fff;
                color: $color1;
                font-size: 24px;
                border: 1px solid #d1d1d1;
                border-radius: 8px;
                padding: 0 20px;
                opacity: 0.9;
                .van-cell__value {
                  .van-field__control {
                    padding: 20px 0;
                    height: 72px;
                    font-size: 24px;
                    line-height: 1.5;
                    -webkit-text-fill-color: $color1;
                  }

                  .van-field__control:disabled {
                    color: $color1;
                    cursor: not-allowed;
                    opacity: 0.8;
                    -webkit-text-fill-color: $color1;
                  }
                }
              }
            }
          }
          .form-item-list:last-child {
            margin-bottom: 0;
          }
        }
      }
      .popup-options {
        margin-top: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .van-button {
          background: $blur;
          color: #fff;
          font-size: 30px;
          border-radius: 12px;
          padding: 24px 30px;
          width: 80%;
        }
      }
    }
  }
  .custom-indicator-boxers {
    justify-content: space-between;
    font-size: 30px;

    .custom-indicator-left {
      position: absolute;
      top: calc(50% - (28px / 2));
      left: 10%;
      color: #fff;
      width: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(72, 71, 72, 0.5);
      border: 1px solid $colore;
      border-radius: 50%;
    }
    .custom-indicator-right {
      position: absolute;
      top: calc(50% - (28px / 2));
      right: 10%;
      color: #fff;
      width: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(72, 71, 72, 0.5);
      border: 1px solid $colore;
    }
  }
}
</style>
<style lang="scss">
input {
  caret-color: $color1;
}
</style>
