import service from '../required/axios'
export default {
  // 产品包列表
  getProductPackagePort (data) {
    return service({
      url: '/product.productPackage.product_package_list',
      method: "get",
      params: data
    })
  },
  // 产品包查看列表
  getProductPackageList (data) {
    return service({
      url: '/product.productPackage.product_package_product_list',
      method: "get",
      params: data
    })
  },
  // 产品包使用
  useProductPackage (data) {
    return service({
      url: '/product.productPackage.product_package_use',
      method: "get",
      params: data
    })
  },
}