import { render, staticRenderFns } from "./EUR-card.vue?vue&type=template&id=b3a6fa84&scoped=true"
import script from "./EUR-card.vue?vue&type=script&lang=js"
export * from "./EUR-card.vue?vue&type=script&lang=js"
import style0 from "./EUR-card.vue?vue&type=style&index=0&id=b3a6fa84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3a6fa84",
  null
  
)

export default component.exports