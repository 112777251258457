<template>
  <div class="productPackage-warper" id="productPackageId">
    <div class="page-header flexbox">
      <navTab> </navTab>
    </div>
    <div class="productPackage-body">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        :loading-text="loadingText"
        @load="onLoad"
      >
        <template v-if="productPackageList.length">
          <div
            class="list-item"
            v-for="(item, index) in productPackageList"
            :key="index"
            @click="onChangeView(item)"
          >
            <div class="list-boxers">
              <div class="list-title flexbox">
                <p class="list-left orderNo">
                  <span class="text2">{{ item.name }}</span>
                </p>
              </div>
              <div class="list-title flexbox">
                <p class="list-left flexbox">
                  <span class="label">{{ language.listLabel3 }}</span>
                </p>
                <p class="list-right">
                  <span>{{ item.number }}</span>
                </p>
              </div>

              <div class="list-title flexbox">
                <p class="list-left flexbox">
                  <span class="label">{{ language.listLabel2 }}</span>
                </p>
                <p class="list-right">
                  <span>{{ item.syncStateText }}</span>
                </p>
              </div>
              <div class="list-title flexbox">
                <p class="list-left flexbox">
                  <span class="label">{{ language.listLabel1 }}</span>
                </p>
                <div class="leaver-logo flexbox">
                  <div
                    v-for="(svgItem, svgIndex) in 5"
                    :key="svgIndex"
                    :class="[
                      svgIndex + 1 <= item.star && 'active-leaver',
                      'leaver-item',
                    ]"
                  >
                    <svg-icon icon-class="leaver-svg"></svg-icon>
                  </div>
                </div>
              </div>
              <div
                class="list-options flexbox"
                v-if="item.syncState === 0"
                @click.stop="productPackageUse(item)"
              >
                <span>{{ language.optionsBtn }}</span>
              </div>
            </div>
          </div>
        </template>
        <default-page v-else></default-page>
      </van-list>
    </div>
    <van-popup v-model="productPackageUseIsShow" class="affirm-popup">
      <div class="popup-body">
        <div class="body-title">
          <van-icon name="warning" />
          <span>{{ language.dialogHint }}</span>
        </div>
        <div class="body-footer">
          <span @click="onChangeClose()">
            {{ language.dialogOptions1 }}
          </span>
          <span @click="onChangeAffirm()">
            {{ language.dialogOptions2 }}
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import productPackageApi from "@/api/productPackageApi.js";
export default {
  name: "appProductPackageIndex",
  data() {
    return {
      moneyIcon: localStorage.getItem("moneyIcon"),
      language: this.$language(),
      finishedText: this.$language("messageLanguage").finishedText,
      loadingText: this.$language("messageLanguage").loadingText,
      loading: false,
      finished: false,
      formObj: {
        page: 1,
        total: 0,
        size: 10,
      },
      productPackageList: [],
      productPackageUseObj: {},
      productPackageUseIsShow: false,
    };
  },
  components: { navTab },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("productPackageId").style["height"] =
        seeHeight + "px";
    });
  },
  mounted() {},
  methods: {
    productPackageUse(row) {
      this.productPackageUseObj = row;
      this.productPackageUseIsShow = true;
    },
    async onChangeAffirm() {
      let params = { productPackageId: this.productPackageUseObj.id };
      const { code, data } = await productPackageApi.useProductPackage(params);
      this.productPackageUseIsShow = false;
      if (code == 200) {
        setTimeout(() => {
          setTimeout(() => {
            this.onLoad();
          }, 1500);
          this.$toast({
            message: this.language.messageToast,
            type: "success",
            duration: 1600,
          });
        }, 300);
      }
    },
    onChangeClose() {
      this.productPackageUseObj = {};
      this.productPackageUseIsShow = false;
    },
    onChangeView(row) {
      this.$router.push({
        path: "/appProductPackageList",
        query: { name: row.name, id: row.id },
      });
    },
    onLoad() {
      this.formObj.page = 1;
      this.formObj.total = 0;
      this.productPackageList = [];
      this.getProductPackage();
    },
    async getProductPackage() {
      let params = {
        page: this.formObj.page,
        size: this.formObj.size,
      };
      const { code, data } = await productPackageApi.getProductPackagePort(
        params
      );
      if (code === 200) {
        this.loading = false;

        this.productPackageList = this.productPackageList.concat(data.list);
        this.formObj.total = data.total;
        if (this.productPackageList.length >= this.formObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.formObj.page++;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.productPackage-warper {
  width: 100%;
  height: 100vh;
  .productPackage-body {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding: 30px;
    .van-list {
      .list-item {
        padding: 24px 30px;
        background: #fff;
        border-radius: 8px;
        margin-bottom: 30px;

        .list-boxers {
          .list-title {
            margin-bottom: 16px;
            line-height: 1.5;

            .list-left {
              color: #666;
              margin-right: 4px;
              .label {
                font-size: 28px;
              }
            }

            .orderNo {
              font-size: 28px;
              color: $color1;
              font-weight: 700;
            }

            .list-right {
              span {
                font-size: 26px;
                color: #666;
              }
            }
            .leaver-logo { 
              .leaver-item {
                margin-right: 4px;
                color: #cdcdcd !important;
                margin-right: 6px;
              }
              .active-leaver {
                color: #ffd700 !important;
              }
            }
          }
          .list-title:nth-child(1),
          .list-title:nth-child(4) {
            padding-bottom: 15px;
            border-bottom: 1px solid $colore;
          }

          .list-options {
            justify-content: flex-end;
            padding-bottom: 0;
            span {
              font-size: 26px;
              color: #fff;
              background: $blur;
              border-radius: 8px;
              padding: 12px 24px;
            }
          }
        }
      }
    }
  }
  .affirm-popup {
    width: 420px;
    border-radius: 8px;
    padding: 40px;

    .popup-body {
      .body-title {
        display: flex;
        align-items: center;

        i {
          font-size: 52px;
          color: #f90;
        }

        span {
          margin-left: 12px;
          font-size: 32px;
          font-weight: 600;
          color: $blur;
        }
      }

      .body-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        span {
          text-align: center;
          width: 38%;
          display: inline-block;
          font-size: 24px;
          font-weight: 600;
          color: $blur;
          padding: 12px 32px;
          background: #fff;
          border: 1px solid $blur;
          border-radius: 8px;
        }

        span:nth-child(2) {
          background: $blur;
          border-color: $blur;
          color: #fff;
        }
      }
    }
  }
}
</style>
