<template>
  <div class="product-firstStep-warper" id="firstStep">
    <div class="page-header">
      <navTab>
        <template #navRight>
          <span class="nav-btn" @click="onChangeRouter">
            {{ language.nextStep }}
          </span>
        </template>
      </navTab>
    </div>
    <div class="product-firstStep-body">
      <div class="firstStep-form-boxers">
        <div class="firstStep-form-list flexbox">
          <div class="form-label">
            <span>{{ language.label1 }}</span>
          </div>
          <div class="form-value text4">
            <span>{{ productObj.name }}</span>
          </div>
        </div>
        <div class="firstStep-form-list flexbox">
          <div class="form-label">
            <span>{{ language.label2 }}</span>
          </div>
          <div class="form-value">
            <span>{{ productObj.brandName }}</span>
          </div>
        </div>
        <div class="firstStep-form-list flexbox">
          <div class="form-label">
            <span>{{ language.label3 }}</span>
          </div>
          <div class="form-value">
            <span>{{ productObj.categoryName }}</span>
          </div>
        </div>
        <div class="firstStep-form-list flexbox">
          <div class="form-label">
            <span>{{ language.label4 }}</span>
          </div>
          <div class="form-value">
            <countTo
              :startVal="0"
              :endVal="productObj.purchaseAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </div>
        </div>
        <div class="firstStep-form-list flexbox">
          <div class="form-label">
            <span>{{ language.label5 }}</span>
          </div>
          <div class="form-value">
            <countTo
              :startVal="0"
              :endVal="productObj.unitAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </div>
        </div>
        <div class="firstStep-form-list flexbox">
          <div class="form-label">
            <span>{{ language.label6 }}</span>
          </div>
          <div class="form-value">
            <countTo
              :startVal="0"
              :endVal="productObj.originalAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </div>
        </div>
        <div class="firstStep-form-list flexbox">
          <div class="form-label">
            <span>{{ language.label7 }}</span>
          </div>
          <div class="form-value">
            <div
              class="value-img-list imgFlex"
              @click="onChangePreview({}, 'bg')"
            >
              <img v-lazy="imgPath + productObj.image + thumbnail" />
            </div>
          </div>
        </div>
        <div class="firstStep-form-list flexbox">
          <div class="form-label">
            <span>{{ language.label8 }}</span>
          </div>
          <div class="form-value">
            <div class="product-img-list flexbox">
              <div
                class="item-images imgFlex"
                v-for="(item, index) in productObj.colorImage"
                :key="index"
                @click="onChangePreview(item)"
              >
                <img v-lazy="imgPath + item.image + thumbnail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import { ImagePreview } from "vant";
import productDetailApi from "@/api/productDetail";
import imgApi from "@/utils/projectConfig";
export default {
  name: "appProductFirstIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      imgPath: imgApi.imgUrl,
      thumbnail: this.$thumbnail,
      productObj: {
        purchaseAmount: 0.0,
        unitAmount: 0.0,
        originalAmount: 0.0,
      },
    };
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("firstStep").style["height"] =
        seeHeight + "px";
    });
  },
  mounted() {
    this.getProductMaterial();
  },
  methods: {
    onChangeRouter() {
      this.$router.push({
        path: "/appProductNextStepIndex",
        query: this.$route.query,
      });
    },
    // 产品资料
    async getProductMaterial() {
      let params = { productId: this.$route.query.id };
      if (this.$route.query.type == "list") {
        params = {
          shopProductId: this.$route.query.id,
        };
      }

      const { code, data } = await productDetailApi.getProductDetail_interface(
        params
      );
      if (code == 200) {
        data.categoryName = [];
        data["category"].map((item) => {
          data.categoryName.unshift(item.name);
        });
        data.brandName = data.brand.name;
        data.categoryName = data.categoryName.join("/");
        data.purchaseAmount = Number((data.purchaseAmount / 100).toFixed(2));
        data.unitAmount = Number((data.unitAmount / 100).toFixed(2));
        data.originalAmount = Number((data.originalAmount / 100).toFixed(2));
        this.productObj = data;
      }
    },
    onChangePreview(record, key) {
      if (key) {
        ImagePreview([this.imgPath + this.productObj.image]);
      } else {
        ImagePreview([this.imgPath + record.image]);
      }
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.product-firstStep-warper {
  width: 100%;
  height: 100vh;
  .page-header {
    .nav-btn {
      color: $blur;
      cursor: pointer;
    }
  }
  .product-firstStep-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    .firstStep-form-boxers {
      width: 100%;
      padding: 24px 30px;
      background-color: #fff;
      border-radius: 16px;
      .firstStep-form-list {
        align-items: flex-start;
        margin-bottom: 40px;
        .form-label {
          width: 40%;
          font-size: 28px;
          font-weight: 600;
          color: $color3;
          line-height: 1.5;
          span {
            display: inline-block;
            white-space: wrap;
            word-break: break-all;
          }
        }
        .form-value {
          width: 60%;
          padding-left: 20px;
          font-size: 24px;
          font-weight: 600;
          color: $color1;
          opacity: 0.7;
          line-height: 1.5;
          .value-img-list {
            width: 160px;
            height: 160px;
            border-radius: 8px;
            border: 1px solid $colore;
          }
          .product-img-list {
            width: 100%;
            overflow-x: scroll;
            .item-images {
              width: 160px;
              height: 160px;
              min-width: 160px;
              border-radius: 8px;
              border: 1px solid $colore;
              margin-right: 30px;
            }
            .item-images:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
