import Vue from 'vue'
import axios from 'axios'
import baseUrl from '@/utils/projectConfig'
import router from 'vue-router'
Vue.use(router)
import { Toast } from "vant";
import { getParams } from "@/utils/publicMethods";
let error1002 = sessionStorage.getItem('error1002') || 0, error1002Num = 0

onLoad('init')

const ServerRequestUrl = baseUrl.baseUrlWebStocket
// 创建axios实例
const service = axios.create({
  baseURL: ServerRequestUrl
})
// request拦截器==>对请求参数做处理
service.interceptors.request.use(config => {
  config.headers.Authorization = window.localStorage.getItem('authenticationToKen_')
  return config
}, error => { // 请求错误处理
  Promise.reject(error)
})
// respone拦截器==>对响应做处理
service.interceptors.response.use(
  response => {
    if (response.data.code === 200) {

    } else if (response.data.code == 1002) {
      Toast.fail({
        forbidClick: true,
        duration: 2500,
        message: response.data.message
      })
      localStorage.removeItem("authenticationToKen_")
      const tikTokIsShow = sessionStorage.getItem("tikTok_data")
      if (tikTokIsShow || getParams('data')) {
        error1002 += 1
        setTimeout(() => {
          Vue.prototype.$resetSetItem('error1002', "error1002", error1002Num);
        }, 1500)
      }
    } else {
      Toast.fail({
        forbidClick: true,
        duration: 2500,
        message: response.data.message
      })
    }
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

function onLoad (options) {
  if (options == 'init') {
    window.addEventListener("error1002", (val) => {
      tikTok_Call()
    });
  }
  if (options == '1002') {
    if (sessionStorage.getItem("tikTok_data") || getParams('data')) {
      error1002 += 1
    }
    localStorage.removeItem("authenticationToKen_")
    localStorage.removeItem("configInfo_")
    localStorage.removeItem("loginInfo_")
    localStorage.removeItem("moneyIcon")
    sessionStorage.removeItem("authenticationToKen_")

  }
}
function tikTok_Call () {
  if (sessionStorage.getItem("tikTok_data") || getParams('data')) {
    error1002Num = Number(error1002)
    error1002Num += error1002Num
  }
}
export default service