<template>
  <div class="forgetPass-warper" id="forgetPass">
    <div class="page-header">
      <navTab></navTab>
    </div>
    <div class="forgetPass-body">
      <van-form>
        <van-field
          v-model="formObj.account"
          :label="language.formLabel1"
          :placeholder="language.formHint1"
        />
        <van-field
          v-model="formObj.code"
          :label="language.formLabel2"
          :placeholder="language.formHint2"
        >
          <template #button>
            <van-button
              size="small"
              :disabled="timer < 60"
              @click="sendEmailPort"
            >
              {{ sendOptions }}
            </van-button>
          </template>
        </van-field>

        <van-field
          v-model="formObj.oldPass"
          type="password"
          :label="language.formLabel3"
          :placeholder="language.formHint3"
        />
        <van-field
          v-model="formObj.newPass"
          type="password"
          :label="language.formLabel4"
          :placeholder="language.formHint4"
        />
        <div class="form-option">
          <van-button
            block
            type="info"
            native-type="submit"
            @click="onSubmitCall"
          >
            {{ language.optionsBtn }}
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import loginApi from "@/api/loginApi.js";
export default {
  name: "appChangeLoginPassIndex",
  data() {
    return {
      language: this.$language(),
      formObj: {
        oldPass: "",
        newPass: "",
        code: "",
        account: "",
      },
      sendOptions: "",
      timer: 60,
      sendCodeSetTimer: "",
    };
  },
  components: { navTab },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("forgetPass").style["height"] = seeHeight + "px";
    });
  },
  mounted() {
    // sendOption
    this.sendOptions = this.language.formOption;
  },
  methods: {
    onSubmitCall() {
      this.onSubmit();
    },
    async onSubmit() {
      let params = {
        account: this.formObj.account,
        verificationCode: this.formObj.code,
        password: this.formObj.oldPass,
        affirmPass: this.formObj.newPass,
      };
      const { code, data, message } = await loginApi.forgotPass_interface(
        params
      );
      if (code == 200) {
        this.$toast({ type: "success", message: message, duration: 2000 });
        setTimeout(() => {
          this.$router.push("/appLoginIndex");
        }, 1000);
      } else {
        this.$toast({ type: "fail", message: message, duration: 2000 });
      }
    },
    // 发送邮箱验证码
    async sendEmailPort() {
      let params = {
        account: this.formObj.account,
        type: 4,
      };
      this.timer = 59;
      const { code, message } = await loginApi.sendEmailCode_interface(params);
      if (code === 200) {
        this.sendOptions = this.language.sendOption + this.timer + "s";
        this.sendCodeSetTimer = setInterval(() => {
          if (this.timer > 0) {
            this.timer -= 1;
            this.sendOptions = this.language.sendOption + this.timer + "s";
          }
          if (this.timer <= 0) {
            this.timer = 60;
            this.sendOptions = this.language.formOption;
            clearInterval(this.sendCodeSetTimer);
          }
        }, 1000);
        this.$toast({ type: "success", message: message, duration: 2000 });
        return false;
      } else {
        this.$toast({ type: "fail", message: message, duration: 2000 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.forgetPass-warper {
  width: 100%;
  height: 100vh;
  .forgetPass-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    ::v-deep .van-form {
      .van-cell {
        padding: 20px;
        .van-field__label {
          width: 20%;
          font-size: 26px;
          font-weight: 600;
        }
        .van-cell__value {
          width: 80%;

          .van-field__control {
            line-height: 1.5;
            text-align: left;
            color: #000 !important;
          }
          .van-button {
            background-color: $blur;
            color: #fff;
            font-size: 24px;
            border-radius: 8px;
          }
        }
      }
      .form-option {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .van-button {
          width: 80%;
          height: 80px;
          background: $blur;
          color: #fff;
          border-radius: 8px;
          font-size: 24px;
          border-color: $blur;
        }
        .van-button:active {
          box-shadow: 0 0 5px 0 $blur;
        }
      }
    }
  }
}
</style>
<style>
.forgetPass-warper input:-webkit-autofill {
  background: transparent;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
}
</style>
