<template>
  <div class="statisticsReport-warper" id="statisticsReportID">
    <div class="page-header">
      <nav-tab>
        <template #navRight>
          <svg-icon
            icon-class="filter-svg"
            class="filter-svg"
            @click="onClickFilter"
          ></svg-icon>
        </template>
      </nav-tab>
    </div>
    <div class="statisticsReport-body">
      <div class="report-card flexbox">
        <div class="card-list">
          <p class="title">{{ language.cardLabel1 }}</p>
          <p class="value">
            <countTo
              :startVal="0"
              class="text-warper"
              :endVal="reportTotalObj.totalUnitAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </p>
        </div>
        <div class="card-list">
          <p class="title">{{ language.cardLabel2 }}</p>
          <p class="value">
            <countTo
              :startVal="0"
              class="text-warper"
              :endVal="reportTotalObj.totalPurchaseAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </p>
        </div>
        <div class="card-list">
          <p class="title">{{ language.cardLabel3 }}</p>
          <p class="value">
            <countTo
              :startVal="0"
              class="text-warper"
              :endVal="reportTotalObj.totalProfitAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo>
          </p>
        </div>
        <div class="card-list">
          <p class="title">{{ language.cardLabel4 }}</p>
          <p class="value">
            <countTo
              :startVal="0"
              class="text-warper"
              :endVal="reportTotalObj.totalOrder"
              :duration="1200"
              :separator="','"
            ></countTo>
          </p>
        </div>
      </div>

      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        :loading-text="loadingText"
        @load="onLoad"
      >
        <div class="report-list-boxers" v-if="reportListData.length">
          <div
            class="records-list"
            v-for="(item, index) in reportListData"
            :key="index"
          >
            <div class="list-line time-list flexbox">
              <div class="left flexbox">
                <svg-icon icon-class="report-timeIcon"></svg-icon>
                <div class="label">
                  {{ language.listLabel1 }}
                </div>
                <div class="value">{{ item.dateString }}</div>
              </div>
              <div class="right">
                {{ language.profitLabel }}
                <countTo
                  :startVal="0"
                  class="text-warper"
                  :endVal="item.profitAmountMoney"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </div>
            </div>
            <div class="list-line flexbox">
              <svg-icon icon-class="report-orderIcon"></svg-icon>
              <div class="label">
                {{ language.listLabel2 }}
              </div>
              <div class="value">
                <countTo
                  :startVal="0"
                  class="text-warper"
                  :endVal="item.order"
                  :duration="1200"
                  :separator="','"
                ></countTo>
              </div>
            </div>
            <div class="list-line flexbox">
              <svg-icon icon-class="report-salesIcon"></svg-icon>
              <div class="label">{{ language.listLabel3 }}</div>
              <div class="value">
                <countTo
                  :startVal="0"
                  class="text-warper"
                  :endVal="item.unitAmount"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </div>
            </div>
            <div class="list-line flexbox">
              <svg-icon icon-class="report-purIcon"></svg-icon>
              <div class="label">{{ language.listLabel4 }}</div>
              <div class="value">
                <countTo
                  :startVal="0"
                  class="text-warper"
                  :endVal="item.purchaseAmountMoney"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </div>
            </div>
          </div>
        </div>
        <default-page v-else></default-page>
      </van-list>
    </div>
    <van-popup
      v-if="searchIsShow"
      v-model="searchIsShow"
      position="top"
      @close="handlerClose"
      round
      class="popup-search"
    >
      <div class="search-dialog">
        <div class="search-date-title">
          {{ language.searchDateTitle }}
        </div>
        <div class="search-date-field flexbox">
          <van-field
            v-model="searchObj.startTime"
            readonly
            :placeholder="language.searchStartTimePlace"
            @click="onClickTime('start')"
          ></van-field>
          <span>——</span>
          <van-field
            readonly
            v-model="searchObj.endTime"
            :placeholder="language.searchEndTimePlace"
            @click="onClickTime('end')"
          ></van-field>
        </div>
        <div class="search-funding-select">
          <div class="title">{{ language.searchIntervalTitle }}</div>
          <div class="list flexbox">
            <div
              :class="[
                'list-item',
                searchObj.activeIndex == index && 'item-active',
              ]"
              v-for="(item, index) in intervalTimeList"
              :key="index"
              @click="onChangeTag(item, index)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="search-options">
          <van-button @click="onChangeResult">
            {{ language.searchOptions1 }}
          </van-button>
          <van-button @click="handlerSubmitSearch">
            {{ language.searchOptions2 }}
          </van-button>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="dateIsShow"
      position="bottom"
      round
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        class="date-picker"
        :show-toolbar="true"
        :confirm-button-text="language.searchDateAffirm"
        :cancel-button-text="language.searchDateClose"
        v-model="searchObj.currDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="affirmTime"
        @cancel="onChangeTimeCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import staticsReportApi from "@/api/staticsReportApi.js";
import moment from "moment-timezone";
import { timeZone } from "@/utils/timeZone";
import { formatDate } from "@/utils/index";
export default {
  name: "appStatisticsReportIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      finishedText: this.$language("messageLanguage").finishedText,
      loadingText: this.$language("messageLanguage").loadingText,
      searchIsShow: false,
      loading: false,
      finished: false,
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(2100, 12, 31),
      dateIsShow: false,
      timeZone: timeZone,
      timeTypeClick: "",
      intervalTimeList: [],

      searchObj: {
        total: 0,
        page: 1,
        size: 20,
        activeIndex: 0,
        currDate: [],
        startTime: "",
        endTime: "",
      },
      reportTotalObj: {
        totalProfitAmount: 0,
        totalPurchaseAmount: 0,
        totalUnitAmount: 0,
        totalOrder: 0,
      },
      //报表总量
      reportListData: [],
    };
  }, 
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("statisticsReportID").style["height"] =
        seeHeight + "px";
    });
    this.onChangeResult();
    this.init();
  },
  mounted() {},
  methods: {
    onClickFilter() {
      this.searchIsShow = true;
    },
    init() {
      this.intervalTimeList = [
        { value: "all", label: this.language.searchIntervalLabel1 },
        { value: "today", label: this.language.searchIntervalLabel2 },
        { value: "yesterday", label: this.language.searchIntervalLabel3 },
        { value: "week", label: this.language.searchIntervalLabel4 },
        { value: "month", label: this.language.searchIntervalLabel5 },
        { value: "year", label: this.language.searchIntervalLabel6 },
      ];
      this.getReportTotalPort();
    },
    async getReportTotalPort() {
      const { code, data } = await staticsReportApi.getReportData();
      if (code == 200) {
        data.totalProfitAmount = Number(
          (data.totalProfitAmount / 100).toFixed(2)
        );
        data.totalPurchaseAmount = Number(
          (data.totalPurchaseAmount / 100).toFixed(2)
        );
        data.totalUnitAmount = Number((data.totalUnitAmount / 100).toFixed(2));
        this.reportTotalObj = data;
      }
    },
    handlerSubmitSearch() {
      this.searchIsShow = false;
      this.reportListData = [];
      this.searchObj.page = 1;
      this.searchObj.total = 0;
      this.finished = false;
      this.getReportListPort();
    },
    handlerClose() {
      this.searchIsShow = false;
    },
    onLoad() {
      this.getReportListPort();
    },
    async getReportListPort() {
      this.loading = true;
      let timeRange = this.intervalTimeList[this.searchObj.activeIndex].value;
      if (timeRange == "all") {
        timeRange = "";
      }
      const params = {
        startTime: this.searchObj.startTime,
        endTime: this.searchObj.endTime,
        page: this.searchObj.page,
        size: this.searchObj.size,
        timeRangeName: timeRange,
      };
      const { code, data } = await staticsReportApi.getReportListPort(params);
      if (code == 200) {
        this.loading = false;
        data.list.map((item) => {
          item.unitAmount = Number((item.unitAmount / 100).toFixed(2));
          item.purchaseAmountMoney = Number(
            (item.purchaseAmount / 100).toFixed(2)
          );
          item.profitAmountMoney = Number((item.profitAmount / 100).toFixed(2));
        });
        this.reportListData = this.reportListData.concat(data.list);
        this.searchObj.total = data.total;
        if (this.reportListData.length >= this.searchObj.total) {
          this.finished = true;
        } else {
          this.finished = false;
          this.searchObj.page++;
        }
      }
    },
    moment,
    onChangeTag(record, index) {
      this.searchObj.activeIndex = index;
    },
    onChangeResult() {
      this.searchObj.startTime = "";
      this.searchObj.endTime = "";
      this.searchObj.activeIndex = 0;
    },
    onClickTime(key) {
      this.timeTypeClick = key;
      let currDate = moment(new Date()).tz(this.timeZone).format("YYYY-MM-DD");
      this.searchObj.currDate = new Date(currDate);
      if (key == "start") {
        if (this.searchObj.startTime) {
          this.searchObj.currDate = new Date(this.searchObj.startTime);
        }
      }
      if (key == "end") {
        if (this.searchObj.endTime) {
          this.searchObj.currDate = new Date(this.searchObj.endTime);
        }
      }
      this.dateIsShow = true;
    },
    affirmTime(date) {
      const time = formatDate(date, "yyyy-MM-dd") + " " + "23:59:59";
      if (this.timeTypeClick == "start") {
        this.searchObj.startTime = moment(time)
          .tz(this.timeZone)
          .format("YYYY-MM-DD");
      }
      if (this.timeTypeClick == "end") {
        this.searchObj.endTime = moment(time)
          .tz(this.timeZone)
          .format("YYYY-MM-DD");
      }
      this.dateIsShow = false;
    },
    onChangeTimeCancel() {
      this.timeTypeClick = "";
      this.dateIsShow = false;
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.statisticsReport-warper {
  width: 100%;
  height: 100vh;
  .page-header {
    .filter-svg {
      width: 32px !important;
      height: 32px !important;
    }
  }
  .statisticsReport-body {
    width: 100%;
    padding: 30px;
    .report-card {
      flex-wrap: wrap;
      .card-list {
        width: calc(((100% - 30px) / 2));
        margin-right: 30px;
        margin-bottom: 30px;
        border-radius: 8px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-content: space-between;

        .title {
          text-align: left;
          font-size: 28px;
          color: #fff;
        }
        .value {
          margin-top: 40px;
          text-align: right;
          font-size: 40px;
          font-weight: 700;
          color: #fff;
        }
      }
      .card-list:nth-child(1) {
        background-size: 100% 100%;
        background-image: url("../../assets/icon1.png");
      }
      .card-list:nth-child(2) {
        background-size: 100% 100%;
        background-image: url("../../assets/icon2.png");
      }
      .card-list:nth-child(3) {
        background-size: 100% 100%;
        background-image: url("../../assets/icon4.png");
      }
      .card-list:nth-child(4) {
        background-size: 100% 100%;
        background-image: url("../../assets/icon5.png");
      }
      .card-list:nth-child(2n) {
        margin-right: 0;
      }
    }
    .report-list-boxers {
      .records-list {
        margin-bottom: 30px;
        padding: 10px 30px;
        background-color: #fff;
        border-radius: 8px;
        svg {
          width: 32px !important;
          height: 32px !important;
          margin-right: 20px;
        }
        .list-time {
          text-align: right;
          color: #333333;
          font-size: 24px;
          line-height: 28px;
          padding: 15px 0;
          border-bottom: 1px solid #eee;
        }
        .list-line {
          padding: 12px 0;
          .label {
            color: #333333;
            padding-right: 10px;
            font-size: 28px;
          }
          .value {
            color: #000;
            font-size: 26px;
          }
        }
        .time-list {
          justify-content: space-between;
          .right {
            font-size: 26px;
            color: $blur;
          }
        }
      }
    }
  }
  .popup-search {
    max-height: 80%;
    min-height: 28%;
    padding: 40px 30px;
    overflow-y: scroll;
    border-radius: 0 0 56px 56px;
    .popup-dialog-body {
      height: 100%;
      padding: 40px 30px;
    }
  }
  .search-dialog {
    .search-date-title {
      font-size: 28px;
      font-weight: 600;
      line-height: 2;
      color: $color1;
      margin-bottom: 20px;
    }
    .search-date-field {
      margin-bottom: 30px;
      ::v-deep .van-cell {
        border: 1px solid $blur;
        border-radius: 42px;
        padding: 0 12px 0 20px;
        .van-field__control {
          padding: 12px 0;
          font-size: 24px;
          color: $color1 !important;
        }
        .van-button {
          padding: 10px 30px;
          background-color: $blur;
          color: #fff;
          font-size: 24px;
          border-radius: 42px;
          border: 1px solid $blur;
        }
      }
    }
    .search-funding-select {
      .title {
        font-size: 28px;
        font-weight: 600;
        line-height: 2;
        color: $color1;
        margin-bottom: 20px;
      }
      .list {
        flex-wrap: wrap;
        .list-item {
          display: inline-block;
          padding: 8px 14px;
          background: rgba(220, 220, 220, 0.6);
          color: $color1;
          font-size: 22px;
          line-height: 1.5;
          margin-left: 12px;
          border-radius: 6px;
          text-align: left;
          margin-bottom: 15px;
        }
        .item-active {
          border: 1px solid $blur;
          background: $blur;
          color: #fff;
        }
      }
    }
    .search-options {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .van-button {
        width: 45%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        color: #fff;
        background: $blur;
        text-align: center;
        border-radius: 42px;
        border: 1px solid $blur;
      }
      .van-button:nth-child(1) {
        color: $blur;
        background: #fff;
        margin-right: 20px;
        border: 1px solid $blur;
      }
    }
  }
}
</style>
<style lang="scss">
.date-picker {
  .van-picker__cancel {
    font-size: 32px;
    font-weight: 500;
  }
  .van-picker__confirm {
    font-size: 32px;
    color: $blur;
    font-weight: 500;
  }
}
</style>
