import service from '../required/axios'
export default {
  // 报表数据获取
  getReportData (data) {
    return service({
      url: '/report.index',
      method: "get",
      params: data
    })
  },
  // 报表列表数据获取
  getReportListPort (data) {
    return service({
      url: '/report.lists',
      method: "get",
      params: data
    })
  }
}