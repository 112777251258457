
import service from '@/required/websotrcketAxios'
export default {
  // 图片上传
  updateImagesPort (form) {
    return service({
      url: "/upload",
      method: "post",
      data: form,
    });
  },
  // 注册、认证
  getRegisterPort (form) {
    return service({
      url: "/register",
      method: "post",
      data: form
    });
  },
  // 用户新增
  generate_memberPort (form) {
    return service({
      url: "/generate_member",
      method: "post",
      data: form
    });
  },
  // 发送消息接口
  sendMessagePort (form) {
    return service({
      url: "/send_message",
      method: "post",
      data: form,
    });
  },
  // 获取未读消息数
  getUnreadMessage (form) {
    return service({
      url: "/get_unread_message_number",
      method: "post",
      data: form,
    });
  },
  // 获取历史消息
  getHistoryMessage (form) {
    return service({
      url: "/message_history",
      method: "get",
      params: { ...form }
    });
  },
  // 消息已读
  getReadMessage (form) {
    return service({
      url: "/read_message",
      method: "post",
      data: form,
    });
  },
  // 获取用户列表
  getUserList (form) {
    return service({
      url: "/member_list",
      method: "get",
      params: {
        forceReload: form.forceReload
      },
    });
  },
  // 获取用户列表
  removeMessage (form) {
    return service({
      url: "/delete_message",
      method: "post",
      data: form
    });
  },
}

