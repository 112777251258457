// 土耳其
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'Mesaj',
    IMErrorMessage: 'IM bağlantısı başarısız oldu, tekrar denemek için tıklayın! ',
  },
  "appWebSocketDetails": {
    sendOption: 'Gönder',
    send: 'gönder',
    fileFail: 'Lütfen resimleri yükleyin ve biçimlendirin',
    IMportError: 'Şu anda bağlantı kurulamadı, lütfen tekrar deneyin! ',
    IMErrorMessage: 'IM bağlantısı başarısız oldu, lütfen tekrar deneyin! ',
    retryBtn: 'Yeniden dene',
  },
  "appLoginIndex": {
    navTitle: 'Giriş Yap',
    projectName: 'Mağazayı yönet',
    formLabel1: 'E-posta',
    formLabel2: 'şifre',
    placeholder: 'Lütfen girin',
    hint: "Hesabınız yoksa ",
    clickRegister: 'Kaydolmak için tıklayın',
    forgetPass: 'şifremi unuttum',
    optionBtn: 'Giriş Yap',
    validateError: 'Lütfen girişi tamamlayın! ',
  },
  "appForgetPassIndex": {
    navTitle: 'Şifremi unuttum',
    formLabel1: 'Giriş posta kutusu',
    formLabel2: 'Doğrulama kodu',
    formLabel3: 'Yeni şifre',
    formLabel4: 'Şifreyi onayla',
    formHint1: 'Lütfen giriş e-postanızı girin',
    formHint2: 'Lütfen yeni bir şifre girin',
    formHint3: 'Lütfen doğrulama kodunu girin',
    formHint4: 'Lütfen şifrenizi iki kez girin',
    formOption: 'Gönder',
    sendOption: 'Geri sayım:',
    optionsBtn: 'Onayla',
  },
  'footer': {
    home: "Mağaza",
    project: 'Ürün',
    info: 'Mesaj',
    order: 'Sipariş',
    user: 'Benim',
  },
  'setDrawer': {
    label1: 'Çoklu dil ayarları',
    label2: 'Mağaza kaydı',
    label3: 'Mağaza girişi',
    label4: 'Müşteri Hizmetleri',
    label5: 'Android uygulamasını indir',
    label6: 'ios uygulamasını indir',
    optionsBtn: 'İptal',
  },
  'appHomeIndex': {
    accountLabel1: 'Satış',
    accountLabel2: 'trafik',
    cardLabel1: 'Verilere genel bakış',
    cardLabel2: 'Veri raporu',
    cardLabel3: 'Tutar genel görünümü',
    ech1Title: 'Toplam satışlar',
    ech2Title: 'Toplam sipariş sayısı',
    ech3Title: 'Mağaza trafiği',
    echBuoy1Title: 'Toplam satışlar',
    echBuoy2Title: 'Toplam sipariş sayısı',
    echBuoy3Title: 'Mağaza trafiği',
    dataCardTitle1: 'Satış',
    dataCardTitle2: 'Trafik',
    dataCardTitle3: 'Toplam sipariş sayısı',
    dataCardTitle4: 'Toplam ödenmemiş tutar',
    dataCardDayTags: 'Bugün',
    dataYesterTags: "Dünün verileri",
    dataYesterTags1: "Kontrol edilmeyen siparişlerin miktarı",
    cardMenusLabel1: 'Vergi',
    cardMenusLabel2: 'Saklanan değer',
    cardMenusLabel3: 'Para Çekme',
    cardMenusLabel4: 'Daveti paylaş',
    cardMenusLabel5: 'Ürün Listesi',
    cardMenusLabel6: 'Sipariş listesi',
    cardMenusLabel7: 'İş Raporu',
    taxPopupTitle: 'Şu anda vergi ödemeniz gerekiyor:',
    popupBtn: 'Git ve vergilerini öde',
    cardStateLabel1: 'Bakiye',
    cardStateLabel2: 'Para Çekme',
    cardStateLabel3: 'Gelir',
    cardStateLabel4: 'Takım',
    cardSellHotTitle: 'En çok satan 10 ürün',
    salesLabel: 'Satış:',
  },
  'appSetLanguage': {
    navTitle: 'Çoklu dil ayarları',
    affirm: 'onayla',
  },
  "appServiceIndex": {
    navTitle: 'Müşteri Hizmetleri',
    customerService: 'Özel müşteri hizmetleri',
    helloTitle: 'Merhaba',
    welcomeTitle: 'Ben seninim<, bana aşağıdaki kanallardan ulaşabilirsiniz',
    iconInformation: 'Lütfen danışmak için iletişim yazılımını seçin',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: 'Denge',
    blanceOption1: 'Çek',
    blanceOption2: 'Değeri sakla',
    incomeLabel1: 'Kümülatif gelir',
    incomeLabel2: 'Toplam çekimler',
    listLabel1: 'Bilgileri sakla',
    listLabel2: 'Para çekme yöntemi ayarları',
    listLabel3: 'Fon Kaydı',
    listLabel4: 'İş Raporu',
    listLabel5: 'İndirim etkinliği',
    listLabel7: 'Kredi',
    listLabel8: 'Alışveriş merkezine git',
    listLabel9: 'Uygulamayı indir',
    listLabelLast: 'Çıkış',
  },
  'downloadAppIndex': {
    navTitle: 'İndir',
    downloadAndroidApp: "Android İndir",
    downloadIosApp: "IOS İndir",
  },
  "appDiscountActivity": {
    navTitle: 'İndirim etkinliği',
    popupLabel1: 'Tutar',
    popupPlace1: 'Lütfen tutarı girin',
    popupLabel2: 'İndirim',
    popupPlace2: 'Lütfen indirimi girin',
    popupOption: 'Onayla',
    listLabel1: 'Gerekli miktar:',
    listLabel2: 'İndirim oranı:',
    listLabel3: 'İndirim süresi:',
    dialogTitle: "Silinip silinmeyeceğini onaylayın!",
    dialogAffirm: 'Onayla',
    dialogClose: "İptal",
  },
  "appInviteIndex": {
    navTitle: 'Yeni etkinlikleri davet et',
    headerTitle: 'Arkadaşlarınızı ödül kazanmaya davet edin',
    copyOption: 'kopyala',
    copySuccess: 'Kopyalama başarılı',
    inviteTitle: 'Toplam davet sayısı',
    inviteLabel1: 'Davet edilen kişi sayısı:',
    inviteLabel2: 'Toplam komisyon:',
    ruleTitle: 'Davetiye Kuralı',
    ruleHint1: ' 1. Davet eden, promosyon bağlantısını paylaşarak arkadaşlarını kaydı tamamlamaya davet edebilir ve davet edilen arkadaşlar sizinle ilişkilendirilir;',
    ruleHint2: ' 2. Kullanıcıları ödemeyi tamamlamaya davet ettikten sonra kârlarından pay alabilirsiniz;',
    ruleHint3: ' 3. Yalnızca üçüncü seviye davet ödülleri desteklenir ve ödül oranı şu şekildedir: <%, >%, @%; ',
  },
  "appInviteUserRecordIndex": {
    navTitle: 'Kullanıcı kaydını davet et',
    recordTitle1: 'Toplam kullanıcı sayısı',
    recordTitle2: 'Seviye 1',
    recordTitle3: 'Düzey 2',
    recordTitle4: 'Seviye 3',
    tabLabel1: 'Tümü',
    tabLabel2: 'Seviye 1',
    tabLabel3: 'İkinci düzey',
    tabLabel4: 'Seviye 3',
    listLabel1: 'Seviye:',
    listLabel2: 'Kar:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: 'Davetiye geliri kaydı',
    recordTitle1: 'Toplam gelir',
    recordTitle2: 'Seviye 1',
    recordTitle3: 'Düzey 2',
    recordTitle4: 'Seviye 3',
    tabLabel1: 'Tümü',
    tabLabel2: 'Seviye 1',
    tabLabel3: 'İkinci düzey',
    tabLabel4: 'Seviye 3',
    listLabel1: 'Kar:',
    listLabel2: 'Oran:',
  },
  "appWithdrawIndex": {
    navTitle: 'Para Çekme',
    currencyType1: 'Fiat para birimi',
    currencyType2: 'Sanal para birimi',
    formLabel1: 'Para birimi türü:',
    formPlace1: 'Lütfen para birimi türünü seçin',
    formLabel2: 'Para birimini seçin:',
    formPlace2: 'Lütfen para birimi türünü seçin',
    formLabel3: 'Çekim tutarı:',
    formPlace3: 'Lütfen çekim tutarını giriniz',
    formLabel4: 'Para çekme şifresi:',
    formPlace4: 'Lütfen para çekme şifresini girin:',
    balanceLabel: 'Geçerli bakiye:',
    amountLabel: 'Gerçek varış:',
    optionsBtn: 'Gönder',
    dialogClose: 'İptal',
    dialogAffirm: 'Onayla',
    currencySelectError: 'Lütfen para birimi türünü seçin',
    currencyError: 'Lütfen para birimini seçin',
    affirmDialogDialogTitle1: 'Bir ödeme şifresi belirlemediniz. Ayarlamak istiyor musunuz? ',
    affirmDialogDialogTitle2: 'Para çekme kanalını ayarlamadınız, ayarlamak istiyor musunuz? ',
    affirmDialogBtn2: 'İptal',
    affirmDialogBtn1: 'Onayla',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Para çekme yöntemi ayarı',
    currencyType1: 'Fiat para birimi',
    currencyType2: 'Sanal para birimi',
    formLabel1: 'Para birimi türü:',
    formPlace1: 'Lütfen para birimi türünü seçin',
    formLabel2: 'Para birimini seçin:',
    formPlace2: 'Lütfen para birimi türünü seçin',
    addressLabel: 'Adres:',
    addressPlace: 'Lütfen sanal para birimi adresini girin',
    hintLabel: 'Önemli İpucu',
    hintValue: 'Yalnızca ¥ varlık aktarın',
    optionsBtn: 'Gönder',
    dialogClose: 'İptal',
    dialogAffirm: 'Onayla',
    currencySelectError: 'Lütfen para birimi türünü seçin',
    currencyError: 'Lütfen para birimini seçin',
    currencyFail: 'Henüz açık değil',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'gerçek ad',
    formPlace1: 'Lütfen gerçek adınızı girin',
    formLabel2: 'E-posta',
    formPlace2: 'Lütfen e-posta adresinizi girin',
    formLabel3: 'Cep telefonu numarası',
    formPlace3: 'Lütfen cep telefonu numaranızı girin',
    formLabel4: 'PIX türü',
    formPlace4: 'Lütfen PIX türünü seçin',
    formLabel5: 'PIX hesabı',
    formPlace5: 'Lütfen PIX hesap numaranızı girin',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'Ülke/Bölge',
    formPlace1: 'Lütfen ülkeyi/bölgeyi girin',
    formLabel2: 'isim',
    formPlace2: 'Lütfen bir ad girin',
    formLabel3: 'Soyadı',
    formPlace3: 'Lütfen soyadınızı girin',
    formLabel4: 'hızlı',
    formPlace4: 'Lütfen hızlı girin',
    formLabel5: 'Banka adı',
    formPlace5: 'Lütfen banka adını girin',
    formLabel6: 'iban',
    formPlace6: 'Lütfen iban\'ı girin',
    formLabel7: 'Cep telefonu numarası',
    formPlace7: 'Lütfen cep telefonu numaranızı giriniz',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'Ülke/Bölge',
    formPlace1: 'Lütfen ülkeyi/bölgeyi girin',
    formLabel2: 'isim',
    formPlace2: 'Lütfen bir ad girin',
    formLabel3: 'Soyadı',
    formPlace3: 'Lütfen soyadınızı girin',
    formLabel4: 'hızlı',
    formPlace4: 'Lütfen hızlı girin',
    formLabel5: 'her şey',
    formPlace5: 'Lütfen her birini girin',
    formLabel6: 'Banka adı',
    formPlace6: 'Lütfen banka adını girin',
    formLabel7: 'Banka Hesabı',
    formPlace7: 'Lütfen banka hesabını girin',
    formLabel8: 'İl veya eyalet',
    formPlace8: 'Lütfen ili veya eyaleti girin',
    formLabel9: 'Cep telefonu numarası',
    formPlace9: 'Lütfen cep telefonu numaranızı girin',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'Ülke/Bölge',
    formPlace1: 'Lütfen ülkeyi/bölgeyi girin',
    formLabel2: 'isim',
    formPlace2: 'Lütfen bir ad girin',
    formLabel3: 'Soyadı',
    formPlace3: 'Lütfen soyadınızı girin',
    formLabel4: 'Banka kodu',
    formPlace4: 'Lütfen banka kodunu girin',
    formLabel5: 'Banka adı',
    formPlace5: 'Lütfen banka adını girin',
    formLabel6: 'Banka Hesabı',
    formPlace6: 'Lütfen banka hesabını girin',
    formLabel7: 'Cep telefonu numarası',
    formPlace7: 'Lütfen cep telefonu numaranızı giriniz',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'Ülke/Bölge',
    formPlace1: 'Lütfen ülkeyi/bölgeyi girin',
    formLabel2: 'isim',
    formPlace2: 'Lütfen bir ad girin',
    formLabel3: 'Soyadı',
    formPlace3: 'Lütfen soyadınızı girin',
    formLabel4: 'zengin',
    formPlace4: 'Lütfen zengin girin',
    formLabel5: 'Banka adı',
    formPlace5: 'Lütfen banka adını girin',
    formLabel6: 'Banka Hesabı',
    formPlace6: 'Lütfen banka hesabını girin',
    formLabel7: 'İl veya eyalet',
    formPlace7: 'Lütfen ili veya eyaleti girin',
    formLabel8: 'Cep telefonu numarası',
    formPlace8: 'Lütfen cep telefonu numaranızı girin',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'Ülke/Bölge',
    formPlace1: 'Lütfen ülkeyi/bölgeyi girin',
    formLabel2: 'isim',
    formPlace2: 'Lütfen bir ad girin',
    formLabel3: 'Soyadı',
    formPlace3: 'Lütfen soyadınızı girin',
    formLabel4: 'BSB',
    formPlace4: 'Lütfen BSB\'yi girin',
    formLabel5: 'Banka adı',
    formPlace5: 'Lütfen banka adını girin',
    formLabel6: 'Banka Hesabı',
    formPlace6: 'Lütfen banka hesabını girin',
    formLabel7: 'İl veya eyalet',
    formPlace7: 'Lütfen ili veya eyaleti girin',
    formLabel8: 'Cep telefonu numarası',
    formPlace8: 'Lütfen cep telefonu numaranızı girin',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'isim',
    formPlace1: 'Lütfen bir ad girin',
    formLabel2: 'Soyadı',
    formPlace2: 'Lütfen soyadınızı girin',
    formLabel3: 'Banka adı',
    formPlace3: 'Lütfen banka adını girin',
    formLabel4: 'Banka Hesabı',
    formPlace4: 'Lütfen banka hesabını girin',
    formLabel5: 'Cep telefonu numarası',
    formPlace5: 'Lütfen cep telefonu numaranızı girin',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Banka adı',
    formPlace1: 'Lütfen banka adını girin',
    formLabel2: 'Hesap',
    formPlace2: 'Lütfen hesabınızı girin',
    formLabel3: 'clabe hesabı',
    formPlace3: 'Lütfen claba hesabınızı girin',
    formLabel4: 'Cep telefonu numarası',
    formPlace4: 'Lütfen cep telefonu numaranızı girin',
  },
  'appStatisticsReportIndex': {
    navTitle: 'İşletme Raporu',
    cardLabel1: 'Toplam satışlar',
    cardLabel2: 'Toplam satın alma tutarı',
    cardLabel3: 'Toplam kâr',
    cardLabel4: 'Toplam sipariş sayısı',
    listLabel1: 'Zaman:',
    listLabel2: 'Sipariş:',
    listLabel3: 'Satışlar:',
    listLabel4: 'Satın alma tutarı:',
    profitLabel: 'kâr',
    searchDateTitle: 'Belge zamanı',
    searchIntervalTitle: 'Aralık süresi',
    searchStartTimePlace: 'Başlangıç ​​zamanı',
    searchEndTimePlace: 'Bitiş zamanı',
    searchOptions1: 'Sıfırla',
    searchOptions2: 'Onayla',
    searchDateAffirm: 'onayla',
    searchDateClose: 'İptal',
    searchIntervalLabel1: 'Tümü',
    searchIntervalLabel2: 'Bugün',
    searchIntervalLabel3: 'Dün',
    searchIntervalLabel4: 'Bu hafta',
    searchIntervalLabel5: 'Bu ay',
    searchIntervalLabel6: 'Bu yıl',
    searchIntervalOptions: 'İptal',
  },
  "appAmountRechargeIndex": {
    navTitle: 'Saklanan değer',
    currencyType1: 'Fiat para birimi',
    currencyType2: 'Sanal para birimi',
    formLabel1: 'Para birimi türü:',
    formPlace1: 'Lütfen para birimi türünü seçin',
    formLabel2: 'Para birimini seçin:',
    formPlace2: 'Lütfen para birimi türünü seçin',
    formLabel3: 'Saklanan değer miktarı:',
    formPlace3: 'Lütfen kayıtlı değer miktarını giriniz',
    formLabel4: 'Yeniden yükleme yöntemi',
    formPlace4: 'Lütfen depolama yöntemini seçin:',
    balanceLabel: 'Geçerli bakiye:',
    amountLabel: 'Gerçek varış:',
    optionsBtn: 'Gönder',
    dialogClose: 'İptal',
    dialogAffirm: 'Onayla',
    currencySelectError: 'Lütfen para birimi türünü seçin',
    currencyError: 'Lütfen para birimini seçin',
    successCopy: 'Başarıyla kopyalandı',
    hintLabel: "Önemli hatırlatma:",
    hintValue: 'Yalnızca ¥ varlık aktarımına izin verilir',
    legalHint: 'Lütfen müşteri hizmetine bağlantı edin!'
  },
  'appShopInfoIndex': {
    navTitle: 'Bilgiyi sakla',
    navEditText: 'Düzenle',
    formLabel1: 'Resmi depola',
    formLabel2: 'mağaza adı',
    formLabel3: 'Giriş e-postası',
    formLabel4: 'davetiye kodu',
    formLabel5: 'Giriş şifresi',
    formLabel6: 'Ödeme şifresi',
    formLabel7: 'Açıklama mesajı',
    formLabel8: 'Arka plan resmini kaydet',
    formLabel9: 'Pazar arka plan resmi',
    formLabel10: 'Sertifika bilgileri',
    formHint1: 'Belgenin ön yüzü',
    formHint2: 'Sertifikanın arka tarafı',
    fileFail: 'Lütfen resimleri ve formatı yükleyin',
    optionsBtn: 'Gönder',
  },
  'appShopInfoEdit': {
    navTitle: 'Mağaza bilgileri düzenleyicisi',
    formLabel1: 'Resmi depola',
    formLabel2: 'mağaza adı',
    formLabel3: 'Giriş e-postası',
    formLabel4: 'davetiye kodu',
    formLabel7: 'Açıklama mesajı',
    formLabel8: 'Arka plan resmini kaydet',
    formLabel9: 'Pazar arka plan resmi',
    fileFail: 'Lütfen resimleri ve formatı yükleyin',
    optionsBtn: 'Gönder',
  },
  'appChangeLoginPass': {
    navTitle: 'Giriş şifresini değiştir',
    formLabel1: 'Eski şifre',
    formLabel2: 'Yeni şifre',
    formLabel3: 'Doğrulama kodu',
    formHint1: 'Lütfen eski şifreyi girin',
    formHint2: 'Lütfen yeni bir şifre girin',
    formHint3: 'Lütfen doğrulama kodunu girin',
    formOption: 'Gönder',
    sendOption: 'Geri sayım:',
    optionsBtn: 'Onayla',
  },
  'appChangePayPass': {
    navTitle: 'Ödeme şifresini değiştir',
    formLabel1: 'Ödeme şifresi',
    formLabel2: 'Doğrulama kodu',
    formHint1: 'Lütfen ödeme şifresini girin',
    formHint2: 'Lütfen doğrulama kodunu girin',
    formOption: 'Gönder',
    sendOption: 'Geri sayım:',
    optionsBtn: 'Onayla',
  },
  "appFundingRecordsIndex": {
    navTitle: 'Fon Kaydı',
    searchRecordsTitle: 'Fon Türü',
    searchDateTitle: 'Zaman seçimi',
    searchFundingLabel1: 'Tüm fonlar',
    searchFundingLabel2: 'Para yatırma türü',
    searchFundingLabel3: 'Para çekme kaydı',
    searchOptions1: 'Sıfırla',
    searchOptions2: 'Onayla',
    searchDateAffirm: 'onayla',
    searchDateClose: 'İptal',
    stareTimePlace: 'Başlangıç ​​zamanı',
    endTimePlace: 'Bitiş zamanı',
    typeAll: 'Tümü',
  },
  "appProjectIndex": {
    navTitle: 'Ürün Listesi',
    searchPlace: 'Lütfen aramak istediğiniz ürün adını giriniz',
    shelvesLabel: 'üst ve alt raflar',
    brandPlace: 'Lütfen bir marka seçin',
    classPlace: 'Lütfen bir kategori seçin',
    searchOptions1: "Sıfırla",
    searchOptions2: 'Onayla',
    shelvesTag1: "Tümü",
    shelvesTag2: "raflarda",
    shelvesTag3: "raflarda değil",
    salesLabel: 'Satış:',
    dialogAffirm: 'Onayla',
    dialogClose: 'İptal',
    listLabel: 'Yukarı ve aşağı raflar:',
  },
  "appProjectLibraryIndex": {
    navTitle: "Ürün Kitaplığı",
    searchPlace: 'Lütfen aramak istediğiniz ürün adını giriniz',
    brandPlace: 'Lütfen bir marka seçin',
    classPlace: 'Lütfen bir kategori seçin',
    searchOptions1: "Sıfırla",
    searchOptions2: 'Onayla',
    salesLabel: 'Satış:',
    dialogAffirm: 'Onayla',
    dialogClose: 'İptal',
    productPackageLabel1: 'Product Package',
    productPackageLabel2: '(Çabuk Produkt Ekle)',

    selectAll: 'Çok seçim',
    selectHint: "Sadece 50 ürüne kadar destekliyor.",
    submitOptions: 'Teslim et',
    dialogHint: "Bu ürünü listeye toplamak için emin misiniz?"

  },
  'appProductPackageIndex': {
    navTitle: 'Product Package',
    listLabel1: "Tavsiye edilmiş yıldız düzeni:",
    listLabel2: 'Synchronization status:',
    listLabel3: 'Product Quantity:',
    optionsBtn: 'Sync',
    dialogHint: "Bu ürün paketi kullanmak istediğinizden emin misiniz?",
    dialogOptions1: 'Lütfen',
    dialogOptions2: 'Confirm',
    messageToast: 'Submission successful, product information synchronization in progress...'
  },
  'appProductPackageList': {
    navTitle: 'Produkt Paket Ayrıntıları',
    salesLabel: 'Satış:',
  },
  "appProductFirstIndex": {
    navTitle: 'Ürün Bilgileri',
    nextStep: 'Sonraki adım',
    label1: 'Ürün adı:',
    label2: 'Ürün markası:',
    label3: 'Ürün kategorisi:',
    label4: 'Minimum satın alma fiyatı:',
    label5: 'Minimum satış fiyatı:',
    label6: 'Minimum piyasa fiyatı:',
    label7: 'Kapak resmi:',
    label8: 'Ürün resmi:',

  },
  'appProductNextStepIndex': {
    navTitle: 'Ürün Özellikleri',
    previous: 'önceki adım',
    navBtn: 'Gönder',
    label1: 'Satın alma fiyatı:',
    placeHint: 'Lütfen girin',
    label2: 'Satış fiyatı:',
    label3: 'Piyasa fiyatı:',
    specLabel: 'Özellik özelliği:',
    optionBtn: "Onayla",
    validateMessage1: 'Orijinal fiyat, satın alma fiyatından düşük olamaz',
    validateMessage2: 'Orijinal fiyat mevcut satış fiyatından düşük olamaz',
    validateMessage3: 'Satış fiyatı satın alma fiyatından düşük olamaz',
    validateMessage4: 'Satış fiyatı orijinal fiyattan yüksek olamaz',
    shelvesTitle: 'Rafları yüklemek mi yoksa boşaltmak mı istiyorsunuz? ',
    shelvesOptions1: 'Hayır',
    shelvesOptions2: 'Evet',
  },
  "appOrderIndex": {
    tagsLabel1: 'Tümü',
    orderTotalNumber: 'Toplam sipariş sayısı',
    listLabel1: 'Sipariş tarihi:',
    listLabel2: 'Toplam satışlar:',
    listLabel3: 'Ürün miktarı:',
    listLabel4: 'Sipariş durumu:',
    listLabel5: 'Ödeme durumu:',
    listHint: 'Satın Alma',
    searchOptionBtn: 'Ara',
    searchPlace: 'Lütfen arama içeriğini girin',
    searchDateStartPlace: 'Başlangıç ​​zamanı',
    searchDateEndPlace: 'Bitiş zamanı',
    searchDateAffirm: 'onayla',
    searchDateClose: 'İptal',
    searchTypePayTitle: 'Ödeme durumu',
    payTags1: 'Tümü',
    payTags2: 'Ödeme bekleniyor',
    payTags3: 'Ücretli',
    searchTypeOrderTitle: 'Sipariş durumu',
    searchOptions1: 'Sıfırla',
    searchOptions2: 'Onayla',
    purDialogTitle: 'Tümünü satın al',
    purDialogLabel1: 'Satın alma tutarı:',
    purDialogLabel2: 'Satış tutarı:',
    purDialogLabel3: 'Kar tutarı:',
    purOptions: "Onayla",
    purRowTitle: 'Satın Alma',
    purRowLabel1: 'Satın alma tutarı:',
    purRowLabel2: 'Satış tutarı:',
    purRowLabel3: 'Kar tutarı:',
  },
  "appOrderDetailIndex": {
    navTitle: 'Sipariş ayrıntıları',
    detailInfoLabel1: 'Sipariş numarası:',
    detailInfoLabel2: 'Sipariş zamanı:',
    detailInfoLabel3: 'Ödeme yöntemi:',
    detailInfoLabel4: 'Sipariş durumu:',
    detailInfoLabel5: 'Ödeme durumu:',
    productLabel1: 'Ürün Bilgileri',
    productAttributesLabel: 'Ürün özellikleri:',
    productAccountLabel1: 'Satışlar:',
    productAccountLabel2: 'Satın alma tutarı:',
    countInfoLabel1: 'Toplam satışlar:',
    countInfoLabel2: 'Toplam satın alma tutarı:',
    countInfoLabel3: 'Toplam kâr:',
    countInfoLabel4: 'Toplam miktar:',
    countInfoLabel5: 'İndirim oranı:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Sipariş Lojistiği',
    logisticsLabel: 'Sipariş',
    listLabel1: "Ad:",
    listLabel2: "Telefon:",
    listLabel3: "Posta kodu:",
    listLabel4: "Adres:",
  },
  "appOrderDetailRecord": {
    navTitle: 'Sipariş Kaydı',
    logisticsLabel: 'Sipariş',
    recordEnumLabel1: 'Müşteri siparişi oluşturur',
    RecordEnumLabel2: 'Müşteri ödeme emri',
    RecordEnumLabel3: 'Mağaza satın alma siparişi',
    RecordEnumLabel4: 'Sipariş gönderilmeye başlıyor',
    RecordEnumLabel5: 'Kullanıcı alındıyı onaylıyor',
    RecordEnumLabel6: 'Sistem geri ödemesi geldi',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Sürüm yükseltme, lütfen onaylayın mı? ',
    loadingTitle: 'İndiriliyor...',
    systemUpgrade: 'Sistem yükseltmesi',
    upgradeImmediately: 'Onayla',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Yükleniyor", // Yükleniyor
    finishedText: "Yok", //Yok
    loadingText: "Yükleniyor", // Yükleniyor
    nullText: 'Henüz veri yok',
    loadingFinished: "Yükleme tamamlandı",
    listNullText: 'Artık yok',
    pullIngText: 'Yenilemek için aşağı çekin',
    loosingText: 'Yenilemek için bırakın',
    pullingSuccess: 'Aşağı açılan yükleme başarılı',
    webSocketSuccess: 'Yeni bir mesajınız var',
    imLinkSuccess: 'IM hizmeti bağlantısı başarılı! ',
    imNonsupport: 'Bu tarayıcı websocket\'i desteklemiyor',
    imLinkLoading: 'IM bağlanıyor, lütfen bekleyin...',
    imLinkErrorLoading: 'IM bağlantısı başarısız oldu, lütfen sayfayı yenileyin veya geliştiriciyle iletişime geçin! ',
    dialogHint: "Zaten bir hesap var mı?",
    dialogClose: 'Register',
    dialogAffirm: "Girin",
  },
};
export default obj;
