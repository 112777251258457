<template>
  <div class="order-record-warper" id="orderRecord">
    <div class="page-header">
      <nav-tab></nav-tab>
    </div>
    <div class="order-record-body" v-if="orderRecordData.length">
      <div class="record-container">
        <div class="content">
          <div
            class="item"
            v-for="(item, index) in orderRecordData"
            :key="index"
          >
            <p class="info">
              {{ language.logisticsLabel }}
              <span>{{ item.no }}</span>
              {{ item.value }}
            </p>
            <p class="time">{{ item.createAt }}</p>
          </div>
        </div>
      </div>
    </div>
    <defaultPage v-else></defaultPage>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
export default {
  name: "appOrderDetailRecordIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      orderRecordData: [],
    };
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("orderRecord").style["height"] =
        seeHeight + "px";
    });
    this.getDetailData();
  },

  methods: {
    getDetailData() {
      let data = JSON.parse(this.$route.query.obj);
      let recordData = [
        {
          no: data.no,
          value: this.language.recordEnumLabel1,
          createAt: data.createAt,
        },
        {
          no: data.no,
          value: this.language.recordEnumLabel2,
          createAt: data.paymentAt,
        },
        {
          no: data.no,
          value: this.language.recordEnumLabel3,
          createAt: data.purchaseAt,
        },
        {
          no: data.no,
          value: this.language.recordEnumLabel4,
          createAt: data.shipAt,
        },
        {
          no: data.no,
          value: this.language.recordEnumLabel5,
          createAt: data.receivingAt,
        },
        {
          no: data.no,
          value: this.language.recordEnumLabel6,
          createAt: data.backAmountAt,
        },
      ];
      data.recordTable = [];
      recordData.map((item, index) => {
        if (index + 1 <= data.state) {
          data.recordTable.unshift(item);
        }
      });
      this.orderRecordData = data.recordTable;
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.order-record-warper {
  width: 100%;
  height: 100vh;
  .order-record-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    .record-container {
      padding: 30px;
      background-color: #fff;
      border-radius: 8px;
      .content {
        .item {
          position: relative;
          padding-bottom: 40px;
          padding-left: 40px;
          overflow: hidden;
          .info {
            color: $color3;
            font-size: 28px;
            line-height: 1.5;
            span {
              color: $blur;
            }
          }
          .time {
            font-size: 28px;
            color: $color3;
            margin-top: 10px;
            line-height: 1.5;
          }
        }
        .item:before {
          content: "";
          display: block;
          width: 4px;
          height: 100%;
          background-color: $blur;
          position: absolute;
          top: 0;
          left: 8px;
        }
        .item:first-child:before {
          top: 16px;
        }
        .item:after {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $blur;
          position: absolute;
          top: 14px;
          left: 0;
        }
        .item:last-child:before {
          height: 30px;
        }
      }
    }
  }
}
</style>
