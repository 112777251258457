import { environment } from './constant'
/**
 * @description 按钮展示权限
 * @params {string} 工作台环境
 * @returns Boolean
 */

let downloadApp = false
let outBtn = false
function permissionCall (key) {

  if (key === environment.web) {
    downloadApp = true
  }
  if (key == environment.web || key == environment.ios) {
    outBtn = true
  }
  return { downloadApp, outBtn }
}
export { permissionCall }