<template>
  <div class="changePass-login-warper" id="payID">
    <div class="page-header">
      <navTab></navTab>
    </div>
    <div class="changePass-body">
      <van-form @submit="onSubmit">
        <van-field
          v-model="formObj.password"
          :label="language.formLabel1"
          type="password"
          :placeholder="language.formHint1"
          clearable
        />
        <van-field
          v-model="formObj.code"
          :label="language.formLabel2"
          clearable
          :placeholder="language.formHint2"
        >
          <template #button>
            <van-button
              size="small"
              :disabled="timer < 60"
              @click="sendEmailPort"
            >
              {{ sendOptions }}
            </van-button>
          </template>
        </van-field>
        <div class="form-option">
          <van-button block type="info" native-type="submit">
            {{ language.optionsBtn }}
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import userApi from "@/api/userApi.js";
export default {
  name: "appChangeLoginPassIndex",
  data() {
    return {
      language: this.$language(),
      formObj: {
        password: "",
        newPass: "",
        code: "",
      },
      sendOptions: "",
      timer: 60,
      sendCodeSetTimer: "",
    };
  },
  components: { navTab },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("payID").style["height"] = seeHeight + "px";
    });
  },
  mounted() {
    this.sendOptions = this.language.formOption;
  },
  methods: {
    async onSubmit() {
      let params = {
        verificationCode: this.formObj.code,
        payPassword: this.formObj.password,
      };
      const { code, data, message } = await userApi.setChangePayPass(params);
      if (code == 200) {
        this.$toast.success(message);
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000);
      }
    },
    // 发送邮箱验证码
    async sendEmailPort() {
      this.timer = 59;
      let params = {
        account: JSON.parse(localStorage.getItem("loginInfo_"))?.account,
        type: 4,
      };
      const { code } = await userApi.sendEmailCode(params);
      if (code === 200) {
        this.sendOptions = this.language.sendOption + this.timer + "s";
        this.sendCodeSetTimer = setInterval(() => {
          if (this.timer > 0) {
            this.timer -= 1;
            this.sendOptions = this.language.sendOption + this.timer + "s";
          }
          if (this.timer <= 0) {
            this.timer = 60;
            this.sendOptions = this.language.formOption;
            clearInterval(this.sendCodeSetTimer);
          }
        }, 1000);
      } else {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.changePass-login-warper {
  width: 100%;
  height: 100vh;
  .changePass-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    ::v-deep .van-form {
      .van-cell {
        padding: 20px;
        .van-field__label {
          width: 20%;
          font-size: 26px;
          font-weight: 600;
        }
        .van-cell__value {
          width: 80%;

          .van-field__control {
            line-height: 1.5;
            text-align: left;
            color: #000 !important;
          }
          .van-button {
            background-color: $blur;
            color: #fff;
            font-size: 24px;
            border-radius: 8px;
          }
        }
      }
      .form-option {
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        .van-button {
          width: 80%;
          height: 80px;
          background: $blur;
          color: #fff;
          border-radius: 8px;
          font-size: 24px;
          border-color: $blur;
        }
        .van-button:active {
          box-shadow: 0 0 5px 0 $blur;
        }
      }
    }
  }
}
</style>
<style>
.changePass-login-warper input:-webkit-autofill {
  background: transparent;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aaa !important;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #aaa !important;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #aaa !important;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #aaa !important;
}
</style>
