import service from '../required/axios'
export default {
  // 订单页数据
  getOrderList_interface (data) {
    return service({
      url: 'order.lists',
      method: "get",
      params: data
    })
  },
  // 订单tags标签数据
  getOrderTags_interface (data) {
    return service({
      url: 'order.list_over_view',
      method: "get",
      params: data
    })
  },
  // 采购所有
  getOrderPurAllView_interface (data) {
    return service({
      url: 'order.purchase_all_over_view',
      method: "post",
      params: data
    })
  },
  // 确认采购所有
  getOrderPurAll_interface (data) {
    return service({
      url: 'order.purchase_all',
      method: "post",
      params: data
    })
  },
  // 确认采购
  getOrderPur_interface (data) {
    return service({
      url: 'order.purchase',
      method: "post",
      params: data
    })
  },
}