<template>
  <div class="order-logistics-warper" id="orderRecord">
    <div class="page-header">
      <nav-tab></nav-tab>
    </div>
    <div class="logistics-body">
      <div class="info-list">
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.listLabel1 }}</div>
          <div class="value text-warper">{{ logisticsAddress.name }}</div>
        </div>
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.listLabel2 }}</div>
          <div class="value text-warper">{{ logisticsAddress.mobilePhone }}</div>
        </div>
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.listLabel3 }}</div>
          <div class="value text-warper">{{ logisticsAddress.uzip }}</div>
        </div>
        <div class="detail-info-boxers flexbox">
          <div class="label">{{ language.listLabel4 }}</div> 
          <div class="value text-warper">{{ logisticsAddress.addressData }}</div>
        </div>
      </div>
      <div class="logistics-container" v-if="logisticsListData.format">
        <div class="content">
          <div
            class="item"
            v-for="(item, index) in logisticsListData.format"
            :key="index"
          >
            <p class="info">
              {{ language.logisticsLabel }}
              <span>{{ logisticsListData.no }}</span>
              {{ item.string }}
            </p>
            <p class="time">{{ item.time }}</p>
          </div>
        </div>
      </div>  
      <defaultPage v-else></defaultPage>
    </div>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
export default {
  name: "appOrderDetailLogisticsIndex",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      logisticsListData: [],
      logisticsAddress: {},
    };
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      if (document.getElementById("orderRecord")) {
        document.getElementById("orderRecord").style["height"] =
          seeHeight + "px";
      }
    });
    this.getDetailData();
  },
  methods: {
    getDetailData() {
      let data = JSON.parse(this.$route.query.obj);
      console.log(data);
      if (Object.keys(data).length) {
        this.logisticsListData = data.logistic;
        const { province, city, county, address, mobilePhone } = data.address;
        data.address.addressData = `${province} ${city} ${county} ${address}`;
        data.address.mobilePhone =
          mobilePhone.substr(0, 3) + "****" + mobilePhone.substr(-2);
        this.logisticsAddress = data.address;
      }
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.order-logistics-warper {
  width: 100%;
  height: 100vh;
  .logistics-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    .info-list {
      padding: 24px 0;
      background-color: #fff;
      .detail-info-boxers {
        font-size: 26px;
        padding: 14px 28px;
        color: #aaa;
        line-height: 1.5;
        justify-content: space-between;
        align-items:flex-start;
        .label {
          text-align: left;
        }
        .value {
          max-width: 70%;
          text-align: right;
        }
      }
      .detail-info-boxers:nth-child(1) {
        .value {
          opacity: 0.8;
        }
      }
    }
    .logistics-container {
      margin-top: 30px;
      padding: 30px;
      background-color: #fff;
      border-radius: 8px;
      .content {
        .item {
          position: relative;
          padding-bottom: 40px;
          padding-left: 40px;
          overflow: hidden;
          .info {
            color: $color3;
            font-size: 28px;
            line-height: 1.5;
            span {
              color: $blur;
            }
          }
          .time {
            font-size: 28px;
            color: $color3;
            margin-top: 10px;
            line-height: 1.5;
          }
        }
        .item:before {
          content: "";
          display: block;
          width: 4px;
          height: 100%;
          background-color: $blur;
          position: absolute;
          top: 0;
          left: 8px;
        }
        .item:first-child:before {
          top: 16px;
        }
        .item:after {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $blur;
          position: absolute;
          top: 14px;
          left: 0;
        }
        .item:last-child:before {
          height: 30px;
        }
      }
    }
  }
}
</style>
