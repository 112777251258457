<template>
  <div class="appAmount-recharge-warper" id="appAmountRecharge">
    <div class="page-header">
      <nav-tab></nav-tab>
    </div>
    <div class="recharge-body">
      <div class="field-list-boxers">
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel1 }}</p>
          <van-field
            v-model="formObj.currencyType"
            :placeholder="language.formPlace1"
            readonly
            right-icon="arrow-down"
            @click="onClickLine('type')"
          />
        </div>
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel2 }}</p>
          <van-field
            v-model="formObj.currencyKindType"
            :placeholder="language.formPlace2"
            readonly
            right-icon="arrow-down"
            @click="onClickLine('kind')"
          />
        </div>
        <div class="field-list">
          <p class="label flexbox">{{ language.formLabel3 }}</p>
          <van-field
            ref="amountInputRef"
            v-model="formObj.amount"
            type="number"
            :readonly="amountReadonly"
            :placeholder="language.formPlace3"
            @click="onClickInput('number')"
            @keyup.native="onInputAmount(formObj.amount)"
          />
        </div>
        <div class="field-amount-list" v-if="countMoney.toString()">
          <p class="amount-list">
            <span>{{ formObj.amount }}{{ moneyIcon }}</span>
            =
            <span>
              {{ countMoney }}
              {{ formObj.currencyKindType }}
            </span>
          </p>
        </div>
        <div class="field-list channel-field" v-if="channelIsShow()">
          <p class="label flexbox">{{ language.formLabel4 }}</p>
          <van-field
            v-model="formObj.rechargeChannel"
            :placeholder="language.formPlace4"
            readonly
            right-icon="arrow-down"
            @click="onClickLine('channel')"
          />
        </div>
        <div class="field-list qrCode-field" v-if="qrCodeIsShow()">
          <p class="label flexbox">{{ language.formLabel4 }}</p>
          <div class="qrCode flexbox" ref="qrCodeUrl" v-if="qrCode"></div>
          <div
            class="qrCopy_boxes flexbox"
            v-clipboard:copy="qrCode"
            v-clipboard:success="onCopy"
          >
            <span class="test text-warper">
              {{ qrCode }}
            </span>
            <span class="svg">
              <svg-icon icon-class="copy-svg"></svg-icon
            ></span>
          </div>
          <div class="qrCode-hint">
            <p>{{ language.hintLabel }}</p>
            <p>{{ hintValue }}</p>
          </div>
        </div>
        <div class="recharge-options" v-if="optionIsShow">
          <van-button @click="onChangeSubmit">{{
            language.optionsBtn
          }}</van-button>
        </div>
      </div>
    </div>
    <van-popup
      v-model="currencyVisibleIsShow"
      position="bottom"
      class="currency-popup"
    >
      <div class="currency-popup-body">
        <van-picker
          show-toolbar
          :confirm-button-text="language.dialogAffirm"
          :cancel-button-text="language.dialogClose"
          :columns="popupDataList"
          @confirm="onConfirmPicker"
          @cancel="onCancelPicker"
        />
      </div>
    </van-popup>
    <van-popup v-model="legalIsShow" class="legal-popup">
      <div class="legal-body">
        <p class="title">{{ language.legalHint }}</p>
        <div class="options">
          <span @click="legalIsShow = !legalIsShow">
            {{ language.dialogAffirm }}
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import navTab from "@/layout/navTab";
import amountRechargeApi from "@/api/amountRechargApi";
export default {
  name: "appAmountRechargeIndex",
  data() {
    return {
      language: this.$language(),
      legalIsShow: false,
      moneyIcon: localStorage.getItem("moneyIcon"),
      formObj: {
        currencyType: null, //货币类型
        currencyTypeCode: null,
        currencyKindType: null,
        currencyKindTypeCode: null,
        amount: "", //充值金额
        rechargeChannel: null,
        rechargeChannelCode: null,
      },
      countMoney: "",
      lineType: "",
      currencyVisibleIsShow: false,
      rechargeChannelList: [], //充值通道
      currencyTypeList: [], //货币类型
      currencyFailList: [], //法定货币
      currencyVirtualList: [], //虚拟货币
      popupDataList: [],
      amountReadonly: true,
      timer: null,
      qrCode: "",
      hintValue: "",
      optionIsShow: false,
      lastTime: new Date(),
    };
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("appAmountRecharge").style["height"] =
        seeHeight + "px";
    });
  },
  methods: {
    onInputAmount() {
      setTimeout(() => {
        this.throttle();
      }, 500);
    },
    throttle() {
      //函数执行时的时间
      let now = new Date();
      if (now - this.lastTime > 1000) {
        this.getCountPort();
        //调用成功，上一次调用时间值修改
        this.lastTime = now;
      }
    },
    // 获取汇率
    async getCountPort() {
      this.countMoney = "";
      this.rechargeChannelList = [];
      let chainId, id;
      if (this.formObj.currencyTypeCode == 1) {
        id = this.formObj.currencyKindTypeCode;
        chainId = 0;
      }
      if (this.formObj.currencyTypeCode == 2) {
        id = this.formObj.currencyKindTypeCode[1];
        chainId = this.formObj.currencyKindTypeCode[0];
      }
      const params = {
        amount: this.formObj.amount * 100,
        chainId: chainId,
        id: id,
        type: this.formObj.currencyTypeCode,
      };
      const { code, data } = await amountRechargeApi.getExchangeRate(params);
      if (code == 200) {
        this.optionIsShow = false;
        const { exchangeRateAmount } = data;
        this.countMoney = (exchangeRateAmount / 100).toFixed(2);
        this.qrCode = "";
        this.hintValue = "";
        this.getChannelPort();
      }
    },
    // 获取通道
    async getChannelPort() {
      let chainId, id;
      if (this.formObj.currencyTypeCode == 1) {
        id = this.formObj.currencyKindTypeCode;
        chainId = 0;
      }
      if (this.formObj.currencyTypeCode == 2) {
        id = this.formObj.currencyKindTypeCode[1];
        chainId = this.formObj.currencyKindTypeCode[0];
      }
      const params = {
        amount: this.formObj.amount * 100,
        chainId: chainId,
        id: id,
        type: this.formObj.currencyTypeCode,
      };
      const { code, data } = await amountRechargeApi.getChannelList(params);
      if (code == 200) {
        if (this.formObj.currencyTypeCode == 1) {
          data.channel.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.rechargeChannelList = data.channel;
          this.optionIsShow = true;
        }
        if (this.formObj.currencyTypeCode == 2) {
          this.qrCode = data.address;
          this.hintValue = this.language.hintValue.replace(
            /¥/,
            this.formObj.currencyKindType
          );
          setTimeout(() => {
            this.bindQRCode();
          });
        }
      }
    },
    async onChangeSubmit() {
      let chainId, id;
      if (this.formObj.currencyTypeCode == 1) {
        id = this.formObj.currencyKindTypeCode;
        chainId = this.formObj.rechargeChannelCode || 0;
        this.legalIsShow = true;
        return false;
      }
      if (this.formObj.currencyTypeCode == 2) {
        id = this.formObj.currencyKindTypeCode[1];
        chainId = 0;
      }
      const params = {
        amount: this.formObj.amount * 100,
        channelId: chainId,
        client: "web",
        id: id,
      };
      const { code, data } = await amountRechargeApi.setRechargePort(params);
      if (code == 200) {
        if (this.formObj.currencyTypeCode === 1) {
          window.open(data.paymentUrl);
        }
      }
    },
    onClickInput() {
      let isShow = this.versionCall();
      if (isShow) {
        this.amountReadonly = false;
        this.$nextTick(() => {
          this.$refs.amountInputRef.focus();
        });
      }
    },
    onClickLine(key) {
      this.lineType = key;
      if (key == "type") {
        this.qrCode = "";
        this.hintValue = "";
        this.onLoad();
        this.currencyVisibleIsShow = true;
      }
      if (key === "kind") {
        this.qrCode = "";
        this.hintValue = "";
        if (!this.formObj.currencyType) {
          this.$toast({
            type: "fail",
            message: this.language.currencySelectError,
            duration: 1200,
          });
          return false;
        }
        if (this.formObj.currencyTypeCode === 1) {
          this.getCurrencyFiatList();
        }
        if (this.formObj.currencyTypeCode === 2) {
          this.getCurrencyVirtualList();
        }
      }
      if (key === "channel") {
        this.popupDataList = this.rechargeChannelList;
        this.currencyVisibleIsShow = true;
      }
    },
    onConfirmPicker(val, e) {
      if (this.lineType === "channel") {
        this.formObj.rechargeChannel = this.popupDataList[e].text;
        this.formObj.rechargeChannelCode = this.popupDataList[e].value;
      }
      if (this.lineType === "type") {
        this.optionIsShow = false;
        this.countMoney = "";
        this.formObj.currencyType = this.popupDataList[e].text;
        this.formObj.currencyTypeCode = this.popupDataList[e].value;
        this.currencyVisibleIsShow = false;
        this.formObj.currencyKindType = null;
        this.formObj.currencyKindTypeCode = null;
        this.formObj.amount = "";
        this.formObj.rechargeChannel = null;
        this.formObj.rechargeChannelCode = null;
        this.rechargeChannelList = [];
      }
      if (this.lineType === "kind") {
        this.optionIsShow = false;
        this.countMoney = "";
        this.formObj.amount = "";
        this.formObj.rechargeChannel = null;
        this.formObj.rechargeChannelCode = null;
        this.rechargeChannelList = [];
        if (this.formObj.currencyTypeCode === 1) {
          this.formObj.currencyKindType = this.popupDataList[e].text;
          this.formObj.currencyKindTypeCode = this.popupDataList[e].value;
        }
        if (this.formObj.currencyTypeCode === 2) {
          const kindTextArr = [];
          const kindCodeArr = [];
          kindTextArr.push(
            this.popupDataList[e[0]].text,
            this.popupDataList[e[0]].children[e[1]].text
          );
          kindCodeArr.push(
            this.popupDataList[e[0]].value,
            this.popupDataList[e[0]].children[e[1]].value
          );
          this.formObj.currencyKindType = kindTextArr.join("/");
          this.formObj.currencyKindTypeCode = kindCodeArr;
        }
      }
      this.currencyVisibleIsShow = false;
    },
    onCancelPicker() {
      this.lineType = "";
      this.popupDataList = [];
      this.currencyVisibleIsShow = false;
    },
    // 获取法定货币
    async getCurrencyFiatList() {
      if (this.currencyFailList.length) {
        this.popupDataList = this.currencyFailList;
        this.currencyVisibleIsShow = true;
        return false;
      }
      const { code, data } = await amountRechargeApi.getCurrencyFiatList();
      if (code == 200) {
        data.map((item) => {
          item.text = item.code;
          item.value = item.id;
        });
        this.currencyFailList = data;
        this.popupDataList = data;
        this.currencyVisibleIsShow = true;
      }
    },
    // 获取虚拟货币
    async getCurrencyVirtualList() {
      if (this.currencyVirtualList.length) {
        this.popupDataList = this.currencyVirtualList;
        this.currencyVisibleIsShow = true;
        return false;
      }
      const { code, data } = await amountRechargeApi.getCurrencyVirtualList();
      if (code == 200) {
        data.map((item) => {
          item.text = item.code;
          item.value = item.id;
          item.children = item.tokens;
          item.children.map((child) => {
            child.text = child.name;
            child.value = child.id;
          });
        });
        this.currencyVirtualList = data;
        this.popupDataList = data;
        this.currencyVisibleIsShow = true;
      }
    },
    onCopy() {
      this.$toast(this.language.successCopy);
    },
    bindQRCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.qrCode,
        width: 100,
        height: 100,
        colorDark: "#333333", //二维码颜色
        colorLight: "#FFFF", //二维码背景色
        correctLevel: QRCode.CorrectLevel.H, //容错率，L/M/H
      });
    },
    onLoad() {
      this.popupDataList = [];
      this.currencyTypeList = [
        { value: 1, text: this.language.currencyType1 },
        { value: 2, text: this.language.currencyType2 },
      ];
      this.popupDataList = this.currencyTypeList;
    },
    channelIsShow() {
      let isShow = false;
      if (
        this.formObj.currencyTypeCode == 1 &&
        this.rechargeChannelList.length
      ) {
        isShow = true;
      }
      return isShow;
    },
    qrCodeIsShow() {
      let isShow = false;
      if (this.formObj.currencyTypeCode == 2 && this.qrCode) {
        isShow = true;
      }
      return isShow;
    },
    versionCall() {
      let isShow = true;
      if (!this.formObj.currencyType) {
        this.$toast({
          type: "fail",
          message: this.language.currencySelectError,
          duration: 1200,
        });
        isShow = false;
        return false;
      }
      if (!this.formObj.currencyKindType) {
        this.$toast({
          type: "fail",
          message: this.language.currencyError,
          duration: 1200,
        });
        isShow = false;
        return false;
      }
      return isShow;
    },
  },
  components: { navTab },
};
</script>

<style lang="scss" scoped>
.appAmount-recharge-warper {
  width: 100%;
  height: 100vh;

  .page-header {
    border-color: 1px solid currentColor;
  }

  .recharge-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    background-color: #fff;

    .field-list-boxers {
      width: 100%;
      padding: 0 24px;
      border-radius: 8px;

      .field-list {
        margin-bottom: 30px;

        .label {
          font-size: 24px;
          color: $color3;
          margin-bottom: 20px;
        }

        .label::before {
          content: "*";
          display: block;
          color: red;
          margin-right: 4px;
        }

        ::v-deep .van-cell {
          border: 1px solid rgb(238, 238, 238);
          box-sizing: border-box;
          height: 88px;
          border-radius: 8px;
          padding: 20px 32px;

          .van-cell__value {
            .van-field__control {
              display: block;
              box-sizing: border-box;
              width: 100%;
              min-width: 0;
              margin: 0;
              padding: 0;
              font-size: 24px;
              color: #323233 !important;
              line-height: inherit;
              text-align: left;
              background-color: transparent;
              border: 0;
              resize: none;
              -webkit-user-select: auto;
              -moz-user-select: auto;
              user-select: auto;
              // -webkit-text-fill-color: #aaa;
            }
          }
        }
      }

      .qrCode-field {
        .qrCode {
          justify-content: center;
        }

        .qrCopy_boxes {
          margin-top: 30px;
          justify-content: space-between;
          padding: 20px 24px;
          background-color: #f8f8f8;
          border-radius: 8px;

          .test {
            width: 95%;
            padding-right: 21px;
            font-size: 24px;
            text-align: left;
            line-height: 1.5;
          }

          .svg {
            width: 5%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 42px;
              height: 42px;
            }
          }
        }

        .qrCode-hint {
          margin-top: 30px;
          font-size: 26px;
          color: #aaa;
          font-weight: 600;
          line-height: 1.5;
        }
      }

      .field-amount-list {
        margin-top: 20px;
        margin-bottom: 30px;
        text-align: right;

        .amount-list {
          font-size: 24px;
          color: #999;
          line-height: 1.5;

          span:nth-child(1) {
            color: $blur;
          }
        }
      }
    }

    .recharge-options {
      margin-top: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .van-button {
        width: 80%;
        border-radius: 16px;
        background-color: $blur;
        color: #fff;
        font-size: 28px;
        border: 1px solid $blur;
      }
    }
  }

  .currency-popup {
    padding: 20px 30px 40px 30px;
    overflow-y: scroll;
    max-height: 50%;
    min-height: 25%;

    .currency-popup-body {
      height: 100%;

      ::v-deep .van-picker {
        height: 100%;

        .van-picker__toolbar {
          font-size: 32px;

          .van-picker__confirm {
            color: $blur;
          }
        }
      }
    }
  }
  .legal-popup {
    border-radius: 8px;
    padding: 40px 30px;
    width: 420px;

    .legal-body {
      .title {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: $color3;
        line-height: 1.5;
      }

      .options {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: inline-block;
          text-align: center;
          background: $blur;
          color: #fff;
          border-radius: 8px;
          font-size: 26px;
          width: 80%;
          padding: 20px 32px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
input {
  caret-color: #323233;
}

input:-webkit-autofill {
  background: transparent;
  transition: background-color 50000s ease-in-out 0s;
  -webkit-text-fill-color: #aaa !important;
}
</style>
