<template>
  <div class="discount-activity-warper" id="activity">
    <div class="page-header">
      <nav-tab>
        <template #navRight>
          <svg-icon icon-class="add-svg" @click="addVisible = true"></svg-icon>
        </template>
      </nav-tab>
    </div>
    <div class="discount-body">
      <div class="discount-list-boxers" v-if="discountList.length">
        <div
          class="discount-list"
          v-for="(item, index) in discountList"
          :key="index"
        >
          <div class="list-row">
            <svg-icon icon-class="report-salesIcon"></svg-icon>
            <span class="label">{{ language.listLabel1 }}</span>
            <span class="value">{{ moneyIcon }}{{ item.totalUnitAmount }}</span>
          </div>
          <div class="list-row">
            <svg-icon icon-class="report-discount"></svg-icon>
            <span class="label">{{ language.listLabel2 }}</span>
            <span class="value">{{ item.ratio }}%</span>
          </div>
          <div class="list-row">
            <svg-icon icon-class="report-timeIcon"></svg-icon>
            <span class="label">{{ language.listLabel3 }}</span>
            <span class="value time">{{ item.createAt }}</span>
          </div>
          <div class="remove-item">
            <svg-icon
              icon-class="remove-svg"
              @click="onChangeRemove(item, index)"
            ></svg-icon>
          </div>
        </div>
      </div>
      <default-page v-else></default-page>
    </div>
    <template v-if="addVisible">
      <van-popup v-model="addVisible" class="discount-popup">
        <div class="filed-list">
          <p class="label">
            {{ language.popupLabel1 }}
          </p>
          <van-field
            v-model="popupObj.account"
            :placeholder="language.popupPlace1"
            clearable
            type="number"
          ></van-field>
        </div>
        <div class="filed-list">
          <p class="label">
            {{ language.popupLabel2 }}
          </p>
          <van-field
            v-model="popupObj.discount"
            :placeholder="language.popupPlace2"
            type="number"
            clearable
          ></van-field>
        </div>
        <div class="options-list">
          <span class="btn" @click="onChangeAffirm">
            {{ language.popupOption }}
          </span>
        </div>
      </van-popup>
    </template>
    <template>
      <van-popup v-model="removeVisible" class="remove-dialog">
        <div class="remove-dialog-body">
          <div class="body-title">
            <van-icon name="question" />
            <span>{{ language.dialogTitle }}</span>
          </div>
          <div class="body-options">
            <span @click="removeVisible = false">
              {{ language.dialogClose }}
            </span>
            <span @click="affirmRemove">{{ language.dialogAffirm }}</span>
          </div>
        </div>
      </van-popup>
    </template>
  </div>
</template>

<script>
import navTab from "@/layout/navTab.vue";
import discountActivityApi from "@/api/discountActivityApi.js";
export default {
  name: "appDiscountActivity",
  data() {
    return {
      language: this.$language(),
      moneyIcon: localStorage.getItem("moneyIcon"),
      addVisible: false,
      popupObj: {
        account: "",
        discount: "",
      },
      discountList: [],
      removeVisible: false,
      removeObj: {},
    };
  },
  components: { navTab },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("activity").style["height"] = seeHeight + "px";
    });
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getDiscountList();
    },
    // 获取折扣数据列表
    async getDiscountList() {
      const { code, data } =
        await discountActivityApi.getDiscountActivityList();
      if (code == 200) {
        data.map((item) => {
          item.totalUnitAmount = Number(item.totalUnitAmount / 100).toFixed(2);
        });
        this.discountList = data;
      }
    },
    onChangeRemove(record) {
      this.removeObj = record;
      this.removeVisible = true;
    },
    affirmRemove() {
      this.discountList = this.discountList.filter((item) => {
        return item.id !== this.removeObj.id;
      });
      this.removeVisible = false;
      this.discountList.map((item) => {
        item.amount = item.totalUnitAmount * 100;
      });
      this.setDiscountData(this.discountList);
    },
    async setDiscountData(params, key) {
      const { code } = await discountActivityApi.setDiscountActivity(params);
      if (code == 200) {
        if (key == "add") {
          this.addVisible = false;
        }
        this.getDiscountList();
      }
    },
    onChangeAffirm() {
      this.discountList.map((item) => {
        item.amount = item.totalUnitAmount * 100;
      });
      let params = [
        {
          amount: Number(this.popupObj.account) * 100,
          ratio: Number(this.popupObj.discount),
        },
        ...this.discountList,
      ];
      this.setDiscountData(params, "add");
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-activity-warper {
  width: 100%;
  height: 100vh;
  .page-header {
    ::v-deep .navTab-right {
      cursor: pointer;
      svg {
        width: 42px !important;
        height: 42px !important;
      }
    }
  }
  .discount-body {
    width: 100%;
    height: calc(100% - 92px);
    overflow-y: scroll;
    padding: 30px;
    .discount-list-boxers {
      .discount-list {
        padding: 30px 24px;
        border-radius: 8px;
        background: #fff;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
        .remove-item {
          position: absolute;
          right: 0px;
          top: 0px;
          cursor: pointer;
          svg {
            width: 56px !important;
            height: 56px !important;
            color: $blur;
          }
        }
        .list-row {
          display: flex;
          align-items: center;
          font-size: 24px;
          color: $color1;
          line-height: 1.5;
          svg {
            margin-right: 20px;
            width: 32px !important;
            height: 32px !important;
          }
          .label {
            font-size: 26px;
            font-weight: 500;
            color: $color1;
          }
          .value {
            font-size: 24px;
            color: $blur;
            font-weight: 600;
          }
          .time {
            font-weight: 400;
            color: #aaa;
          }
        }
        .list-row:nth-child(2) {
          margin: 15px 0;
        }
      }
    }
  }
  .discount-popup {
    width: 520px;
    border-radius: 8px;
    padding: 30px;
    .filed-list {
      margin-bottom: 30px;
      p {
        font-size: 28px;
        font-weight: 600;
        color: $color1;
        line-height: 1.5;
        margin-bottom: 15px;
      }
      ::v-deep .van-cell {
        border: 1px solid #aaa;
        border-radius: 8px;
        padding: 0 12px 0 20px;
        .van-field__control {
          padding: 15px 0;
          font-size: 24px;
          color: #030303 !important;
          // -webkit-text-fill-color: $color1;
        }
        .van-button {
          padding: 10px 30px;
          background-color: $blur;
          color: #fff;
          font-size: 24px;
          border-radius: 42px;
          border: 1px solid $blur;
        }
      }
    }

    .options-list {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        width: 70%;
        font-size: 26px;
        color: #fff;
        background-color: $blur;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 30px;
        border-radius: 8px;
      }
    }
  }
  .remove-dialog {
    width: 560px;
    padding: 40px 30px;
    border-radius: 8px;
    .remove-dialog-body {
      .body-title {
        display: flex;
        align-items: center;
        i {
          color: $blur;
          font-size: 42px;
          font-weight: 700;
          margin-right: 8px;
        }
        span {
          color: $color3;
          font-size: 32px;
          font-weight: 600;
          line-height: 1.5;
        }
      }
      .body-options {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        span {
          display: inline-block;
          padding: 14px 30px;
          border: 1px solid $blur;
          background: #fff;
          color: $color3;
          font-weight: 500;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
        }
        span:nth-child(2) {
          background: $blur;
          color: #fff;
        }
      }
    }
  }
}
</style>
